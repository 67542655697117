import React, { Component } from "react";
import { connect } from "react-redux";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { RiSearch2Line } from "react-icons/ri";
import { FaHome } from "react-icons/fa";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import Location from "../../../assets/images/location.png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
    geocodeByPlaceId,
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import Flip from "react-reveal/Flip";
import { TbCurrentLocation } from "react-icons/tb";
import { GET_ADDRESS_FROM_COORDINATES_URL } from "../../../api/index";
import Axios from "axios";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import loading from "../../../assets/lottie/locationLoading.json";
import BackgroundLoading from "../../Elements/loading"
import Lottie from "react-lottie";
import { Form } from "react-bootstrap";
import {
    getUserDetails,
    saveAddress,
} from "../../../redux/mobile/user/action.js";
import Bounce from "react-reveal/Bounce";
import marker from "../../../assets/images/marker.png"
import Loading from "../../Elements/loading";
import LoadingTwo from "../../Elements/loadingTwo.js";

const LoadingContainer = (props) => {

    return (<Loading />)
}

export class SetLocation extends Component {
    constructor() {
        super();
        // this.mapRef = React.createRef();
        this.google = React.createRef();
    }
    state = {
        loading: false,
        type: "HOME",
        other_address: false,
        home: true,
        work: false,
        google_script_loaded: true,
        current_location: true,
        gps_loading: false,
        gps_error: false,
        location: "",
        center: {},
        usual_address: "",
        work_address: "",
        defaultAddress: "",
        land_address: "",
        centerAroundCurrentLocation: false,
        // google map
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        mapCenter: {
            lat: 16.9898,
            lng: 73.3135,
        },
        latitude: null,
        longitude: null,
        dragging: false,
        full_loading: true,
        store_courier: false,
        isSearching: false,
    };
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    handleReport = (e, type) => {
        e.preventDefault();
        this.setState({ type: type });
        if (type === "HOME") {
            this.setState({ home: true, other_address: false, currentAddress: null });
        } else if (type === "WORK") {
            this.setState({ work: true, other_address: false, currentAddress: null });
        } else {
            this.setState({ other_address: true, home: false, work: false, currentAddress: localStorage.getItem("address") });
        }
    };
    componentDidMount() {
        if (sessionStorage.getItem("is_location") === "true") {
            this.setState({ full_loading: false })
        } else if (sessionStorage.getItem("is_location") === null) {
            this.getMyLocation(() => {

                const userSetAddress = {
                    latitude: localStorage.getItem("userLat"),
                    longitude: localStorage.getItem("userLng"),
                    address: localStorage.getItem("address"),
                    landmark: null,
                    house: null,
                    tag: "HOME",
                };

                ////// console.log("Here1")
                localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
                sessionStorage.setItem("is_location", "true")
                localStorage.setItem("is_location", "UPDATED");
                this.setState({ loading: false })
                this.props.history.push("/home");


            })
        }

        // this.getMyLocation();
        if (localStorage.getItem("geoLocation") !== null) {
            const location = JSON.parse(localStorage.getItem("geoLocation")).geometry.location;
            this.setState({
                initialPosition: { lat: location.lat, lng: location.lng },
            });
        }
        if (localStorage.getItem("LocationFrom") === "STORE" || localStorage.getItem("LocationFrom") === "COURIER" || localStorage.getItem("LocationFrom") === "CART" || localStorage.getItem("LocationFrom") === "PRESCRIPTION") {
            this.setState({ store_courier: true });
        }
        if (!localStorage.getItem("LocationFrom") === "STORE" || !localStorage.getItem("LocationFrom") === "COURIER" || localStorage.getItem("LocationData") === "UPDATE") {
            if (localStorage.getItem("userSetAddress") !== null) {
                const userAddress = JSON.parse(localStorage.getItem("userSetAddress"))
                // ////// console.log(userAddress.address, 'sssssss')
                localStorage.setItem("address", userAddress.address)
                this.setState({ current_location: false })
                localStorage.setItem("userLat", userAddress.latitude);
                localStorage.setItem("userLng", userAddress.longitude);
                this.setState({ location: { address: userAddress } });
            }

        }

    }

    handleGeoLocationClick = (results, latType) => {
        this.setState({ gps_loading: true });
        const saveGeoLocation = new Promise((resolve) => {
            localStorage.setItem("geoLocation", JSON.stringify(results[0]));
            localStorage.setItem("address", results[0]?.formatted_address);
            this.setState({ defaultAddress: results[0].formatted_address });
            setTimeout(() => {
                if (latType === 'normal') {
                    localStorage.setItem("userLat", results[0].geometry.location.lat);
                    localStorage.setItem("userLng", results[0].geometry.location.lng);
                    this.setState({
                        mapCenter: {
                            lat: results[0].geometry.location.lat,
                            lng: results[0].geometry.location.lng,
                        },
                    });
                } else {
                    localStorage.setItem("userLat", results[0].geometry.location.lat());
                    localStorage.setItem("userLng", results[0].geometry.location.lng());
                    this.setState({
                        mapCenter: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        },
                    });
                }
            }, 200);

            resolve("GeoLocation Saved");
        });

        saveGeoLocation.then(() => {
            this.setState({ gps_loading: false, current_location: false });
            // window.location.reload()
            ////// console.log("Here2")

            // this.props.history.push("/home");
        });
    };
    getMyLocation = (callback) => {
        const location = navigator && navigator.geolocation;
        if (window.ReactNativeWebView) {
            const response = {
                type: "location_permission",
                data: "no-data",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        this.setState({ gps_loading: true });
        if (location) {
            location.getCurrentPosition(
                (position) => {
                    this.reverseLookup(
                        position.coords.latitude,
                        position.coords.longitude,
                        callback
                    );
                    if (this.google && this.google.maps) {
                        var latLng = new this.google.maps.LatLng(
                            position.coords.latitude,
                            position.coords.longitude
                        );
                        this.mapRef.panTo(latLng);
                    }
                },
                (error) => {
                    this.setState({ gps_loading: false, gps_error: true });

                    ////// console.log(error);
                    if (window.ReactNativeWebView) {
                        const response = {
                            type: "location_permission",
                            data: "no-data",
                        };
                        window.ReactNativeWebView.postMessage(JSON.stringify(response));
                    }
                    alert("GPS Access Not Granted, Please allow GPS access and try again");
                },
                {
                    enableHighAccuracy: true, // Use high accuracy for location
                    timeout: 10000, // Timeout after 10 seconds
                    maximumAge: 60000, // Use a cached position up to 1 minute old
                }
            );
        }
    };
    reverseLookup = (lat, lng, callback) => {
        Axios.post(GET_ADDRESS_FROM_COORDINATES_URL, { lat: lat, lng: lng, }).then((response) => {
            localStorage.setItem("address", JSON.stringify(response.data));
            ////// console.log("reverseLookup");
            this.setState({
                current_location: false,
                defaultAddress: response.data,
            });
            const myLocation = [
                {
                    formatted_address: response.data,
                    geometry: {
                        location: {
                            lat: lat,
                            lng: lng,
                        },
                    },
                },
            ];
            ////// console.log(lat, lng, "hi");
            localStorage.setItem("userLat", lat);
            localStorage.setItem("userLng", lng);

            this.handleGeoLocationClick(myLocation, 'normal');
            try {
                if (callback) callback()
            } catch (error) {
            }
        })
            .catch(function (error) {
                console.warn(error.response.data);
            });
        this.setState({ centerAroundCurrentLocation: true });
    };


    handleSetDefaultAddress = (e) => {
        e.preventDefault();
        const { type, work_address, defaultAddress, land_address, currentAddress } = this.state;
        const { user } = this.props;
        if (currentAddress) {
            localStorage.setItem("address", currentAddress)
        }
        let address = localStorage.getItem("address");
        if (localStorage.getItem("LocationFrom") === "STORE") {
            const saveUserSetAddress = new Promise((resolve) => {
                const userSetAddress = {
                    latitude: localStorage.getItem("userLat"),
                    longitude: localStorage.getItem("userLng"),
                    address: address ? address : defaultAddress.replace('"', ""),
                    landmark: land_address ? land_address : null,
                    house: work_address ? work_address : null,
                    tag: type,
                };
                if (localStorage.getItem("BackTo") === "STORE") {
                    localStorage.setItem("storeSetAddress", JSON.stringify(userSetAddress));
                } else if (localStorage.getItem("BackTo") === "DELIVERY") {
                    localStorage.setItem("storeDeliveryAddress", JSON.stringify(userSetAddress));
                }
                this.setState({
                    location: { address: userSetAddress },
                });
                resolve("Address Saved");
            });
            saveUserSetAddress.then(() => {
                if (localStorage.getItem("LocationFrom") === "STORE") {
                    localStorage.removeItem("LocationFrom");
                    localStorage.removeItem("BackTo");
                    this.props.history.push("/any-store");
                } else {
                    localStorage.removeItem("LocationFrom");
                    localStorage.removeItem("BackTo");
                    ////// console.log("Here3")
                    localStorage.setItem("is_location", true);

                    this.props.history.push("/home");
                }
            });
        } else if (localStorage.getItem("LocationFrom") === "COURIER") {
            const saveUserSetAddress = new Promise((resolve) => {
                const userSetAddress = {
                    latitude: localStorage.getItem("userLat"),
                    longitude: localStorage.getItem("userLng"),
                    address: address ? address : defaultAddress.replace('"', ""),
                    landmark: land_address ? land_address : null,
                    house: work_address ? work_address : null,
                    tag: type,
                };
                if (localStorage.getItem("BackTo") === "PICKUP") {
                    localStorage.setItem("pickUpAddress", JSON.stringify(userSetAddress));
                } else if (localStorage.getItem("BackTo") === "DELIVERY") {
                    localStorage.setItem("deliveryAddress", JSON.stringify(userSetAddress));
                }
                this.setState({ location: { address: userSetAddress } });
                resolve("Address Saved");
            });
            saveUserSetAddress.then(() => {
                if (localStorage.getItem("LocationFrom") === "COURIER") {
                    localStorage.removeItem("BackTo");
                    localStorage.removeItem("LocationFrom");
                    this.props.history.push("/courier");
                } else {
                    localStorage.removeItem("BackTo");
                    localStorage.removeItem("LocationFrom");
                    ////// console.log("Here4")
                    localStorage.setItem("is_location", true);

                    this.props.history.push("/home");
                }
            });
        } else {
            if (user.success) {
                this.setState({ loading: true });
                this.props.saveAddress(
                    user.data.auth_token,
                    localStorage.getItem("userLat"),
                    localStorage.getItem("userLng"),
                    localStorage.getItem("address"),
                    work_address,
                    type
                ).then((response) => {
                    if (response.payload.success) {
                        const saveUserSetAddress = new Promise(
                            (resolve) => {
                                const userSetAddress = {
                                    latitude: localStorage.getItem("userLat"),
                                    longitude: localStorage.getItem("userLng"),
                                    address: address ? address : defaultAddress.replace('"', ""),
                                    landmark: land_address ? land_address : null,
                                    house: work_address ? work_address : null,
                                    tag: type,
                                };
                                localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
                                this.setState({ loading: false, location: { address: userSetAddress }, });
                                resolve("Address Saved");
                            }
                        );
                        saveUserSetAddress.then(() => {
                            if (
                                localStorage.getItem("LocationFrom") === "CART") {
                                localStorage.removeItem("LocationFrom");
                                this.props.history.push("/cart");
                            } else if (localStorage.getItem("LocationFrom") === "COURIER") {
                                localStorage.removeItem("LocationFrom");
                                this.props.history.push("/courier");
                            } else if (localStorage.getItem("LocationFrom") === "PRESCRIPTION") {
                                localStorage.removeItem("LocationFrom");
                                this.props.history.push("/with-prescription");
                            } else {
                                ////// console.log("Here5")
                                localStorage.setItem("is_location", true);

                                this.props.history.push("/home");
                            }
                        });
                    }
                });
            } else {
                const userSetAddress = {
                    latitude: localStorage.getItem("userLat"),
                    longitude: localStorage.getItem("userLng"),
                    address: address ? address : defaultAddress.replace('"', ""),
                    house: work_address ? work_address : null,
                    tag: type,
                };

                //else save in localstorage for future use (Later when user loggsin or registers, and orders, send this address to db)

                const saveUserSetAddress = new Promise((resolve) => {
                    localStorage.setItem(
                        "userSetAddress",
                        JSON.stringify(userSetAddress)
                    );
                    resolve("Address Saved");
                });
                saveUserSetAddress.then(() => {
                    if (localStorage.getItem("LocationFrom") === "CART") {
                        localStorage.removeItem("LocationFrom");
                        this.props.history.push("/cart");
                    } else if (localStorage.getItem("LocationFrom") === "PRESCRIPTION") {
                        localStorage.removeItem("LocationFrom");
                        this.props.history.push("/with-prescription");
                    } else {
                        ////// console.log("Here6")
                        localStorage.setItem("is_location", true);

                        this.props.history.push("/home");
                    }
                });
            }
        }
    };

    onMarkerDragEnd = (map) => {
        // ////// console.log({ lat: map.center.lat(), lng: map.center.lng() });
        localStorage.setItem("userLat", map.center.lat());
        localStorage.setItem("userLng", map.center.lng());
        this.setState({
            mapCenter: {
                lat: map.center.lat(),
                lng: map.center.lng(),
            },
        });
        this.reverseLookup(map.center.lat(), map.center.lng());
    };
    handleDragging = () => {
        this.setState({ dragging: true });
        // this.refs.confirmGpsLocation.style.height = "44.7vh";
    };
    usualAddress = (e) => {
        this.setState({ usual_address: e });
        // ////// console.log(this.state.usual_address);
    };
    currentAddress = (e) => {
        this.setState({ currentAddress: e });
    };
    otherAddress = (e) => {
        this.setState({ work_address: e });
        // ////// console.log(this.state.work_address);
    };

    landmarkAddress = (e) => {
        this.setState({ land_address: e });
        // ////// console.log(this.state.land_address);
    };

    goBackBtn = () => {
        const userAddress = JSON.parse(localStorage.getItem("userSetAddress"));
        localStorage.setItem("userLat", userAddress.latitude);
        localStorage.setItem("userLng", userAddress.longitude);
        this.props.history.goBack()
    };




    render() {
        const defaultOptions = {
            animationData: loading,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        let address = localStorage.getItem("geoLocation");
        // alert(address)
        const { defaultAddress, full_loading, store_courier } = this.state;
        return (
            <React.Fragment>
                {full_loading ? (
                    <React.Fragment>
                        {this.state.gps_error == true ? (
                            <>
                                <div className="d-flex align-items-center bg-white justify-content-center" style={{ paddingTop: '180px' }}>
                                    <Lottie
                                        options={defaultOptions}
                                        height={250}
                                        width={250}
                                    />
                                </div>
                                <div style={{ "display": "flex", "flexWrap": "nowrap", "justifyContent": "center", "alignContent": "center", "marginTop": "19px", "fontSize": "23px", "padding": "20px", "textAlign": "center", "fontWeight": "800" }}>
                                    GPS Access is denied, Please Allow GPS & Try again.
                                </div>

                                <div style={{ "padding": "40px" }}>
                                    <button className="set-current-location-btn" onClick={() => window.location.reload()}>
                                        <TbCurrentLocation /> Try GPS Again
                                    </button>
                                </div>

                            </>
                        ) : (
                            <BackgroundLoading />

                        )}

                    </React.Fragment>
                ) : (
                    <div>
                        <div>
                            {this.state.loading || this.state.gps_loading ? (
                                <div className="">
                                    <div style={{ paddingTop: "35vh" }}>
                                        <Lottie
                                            options={defaultOptions}
                                            height={130}
                                            width={130}
                                        />
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <span
                                            className="mt-3"
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "16px",
                                            }}
                                        >
                                            Going to location...
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <Map
                                    ref={(ref) => {
                                        this.mapRef = ref;
                                    }}
                                    // ref={(map) => map && map.panTo({lat: 25.0112183,lng: 121.52067570000001})}
                                    initialCenter={{
                                        lat: localStorage.getItem("userLat") ? localStorage.getItem("userLat") : this.state.mapCenter.lat,
                                        lng: localStorage.getItem("userLng") ? localStorage.getItem("userLng") : this.state.mapCenter.lng,
                                    }}
                                    google={window.google}
                                    center={this.state.mapCenter}
                                    zoom={15}
                                    // onDragend={(t, map, coord) => this.onMarkerDragEnd( coord, map)}
                                    onDragend={(t, map, coord) => {
                                        ////// console.log(t, map, coord, 't, map, coord')
                                        this.onMarkerDragEnd(map)
                                    }}
                                    // onDragend={(t, map, coord) =>
                                    //     this.onMarkerDragEnd( map, coord)
                                    // }
                                    style={this.state.current_location ? null : { height: '75%', width: '100%' }}
                                    zoomControl={false}
                                    mapTypeControl={false}
                                    scaleControl={true}
                                    streetViewControl={false}
                                    fullscreenControl={false}
                                    onReady={(mapProps, map) => {
                                        this.props.dragging && this.props.reverseLookup(
                                                this.state.initialPosition.lat,
                                                this.state.initialPosition.lng
                                            );
                                        localStorage.setItem(
                                            "userLat",
                                            map.center.lat()
                                        );
                                        localStorage.setItem("userLng", map.center.lng()
                                        );
                                    }}
                                    onDragstart={() => {
                                        this.handleDragging(true);
                                        // this.setState({ isAllowedLocationToDetect: true });
                                        // this.onDrag();
                                    }}
                                    centerAroundCurrentLocation={
                                        this.state.centerAroundCurrentLocation
                                    }
                                // center={this.state.center}
                                >
                                    {/* {(address && address != null) ||
                                    address != undefined ? (
                                    <Marker
                                        // onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                                        position={{
                                            lat: JSON.parse(localStorage.getItem("geoLocation")).geometry.location.lat,
                                            lng: JSON.parse(localStorage.getItem("geoLocation")).geometry.location.lng,
                                        }}
                                    />
                                ) : null} */}
                                    <div className="center-marker-pulse" >
                                        <Bounce top duration={1000}>
                                            <img src={marker} alt="Marker" className={this.state.current_location ? "center-marker-full" : "center-marker"} />
                                        </Bounce>
                                    </div>
                                </Map>
                            )}

                            <div
                                className=" position-fixed w-100 p-2"
                                style={{ top: "0", zIndex: 9 }}
                            >
                                <div className="d-flex flex-row justify-content-between py-2 px-2 set-location-searchbar"
                                    style={{ backgroundColor: "#fff", boxShadow: "rgb(167 167 167) 0px 0px 20px 2px" }} >
                                    <div className="" style={{ paddingTop: "15px" }}>
                                        <MdOutlineKeyboardArrowLeft
                                            size={30}
                                            color="#DA6317" onClick={() => this.goBackBtn()}
                                        />
                                    </div>
                                    <div className="set-loaction-input me-2 ">
                                        {this.state.google_script_loaded && (
                                            <GooglePlacesAutocomplete
                                                debounce={750}

                                                withSessionToken={true}
                                                loader={
                                                    <div style={{ padding: "2vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div class="spinner-border h-10"></div>
                                                    </div>
                                                }
                                                renderInput={(props) => (
                                                    <div className="input-location-icon-field">
                                                        <div>
                                                            <input
                                                                {...props}
                                                                className="search-input-location"
                                                                placeholder={"Search your location on google"}
                                                                ref={(input) => { this.searchInput = input; }}
                                                                onFocus={() => this.setState({ isSearching: true })}  // Set isSearching to true on focus
                                                                onBlur={() => this.setState({ isSearching: false })}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                renderSuggestions={(active, suggestions, onSelectSuggestion) => (
                                                    <div className="location-suggestions-container">
                                                        {suggestions.map((suggestion, index) => (
                                                            <Flip top
                                                                delay={index * 50}
                                                                key={suggestion.id}>
                                                                <div className="location-suggestion"
                                                                    onClick={(event) => {
                                                                        onSelectSuggestion(suggestion, event);
                                                                        // ////// console.log(suggestion, event, 'suggestion, event')
                                                                        // ////// console.log('event')
                                                                        geocodeByPlaceId(suggestion.place_id).then((results) => {
                                                                            // ////// console.log(suggestion, event, 'suggestion, event')
                                                                            this.handleGeoLocationClick(results);
                                                                            // getLatLng(results[0]).then(({ lat, lng }) => {
                                                                            //     var latLng = new this.google.maps.LatLng(parseFloat(lat).toFixed(4), parseFloat(lng).toFixed(4));
                                                                            //     this.mapRef.panTo(latLng);
                                                                            //     ////// console.log('Successfully got latitude and longitude', { lat, lng })
                                                                            // }

                                                                            // );
                                                                        }).catch((error) => console.error(error));
                                                                    }} >
                                                                    <span className="location-main-name">
                                                                        {suggestion.structured_formatting.main_text}
                                                                    </span>
                                                                    <br />
                                                                    <span className="           ">
                                                                        {suggestion.structured_formatting.secondary_text}
                                                                    </span>
                                                                </div>
                                                            </Flip>
                                                        )
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        )}
                                    </div>
                                    {/* <div>
                                    <RiSearch2Line size={20} color='#DA6317' />
                                </div> */}
                                </div>
                            </div>
                            {this.state.loading || this.state.gps_loading ? ("") : (
                                <div className="w-100 position-fixed px-2 pb-3" style={{ top: "100px" }}>
                                    {!this.state.isSearching  && (
                                        <div className="mb-3 px-1 " onClick={this.getMyLocation} >
                                            <button className="set-current-location-btn d-flex flex-column align-items-center justify-content-center">
                                                <div className="d-flex align-items-center justify-content-center gap-2 py-2">
                                                    <div style={{ marginTop: "-5px" }}>

                                                        <TbCurrentLocation size={20} />
                                                    </div>
                                                    <div className="d-flex align-items-start flex-column">
                                                        <div>
                                                            Use Current Location Using GPS
                                                        </div>
                                                        {/* <div className="">

                                                            <p>Using GPS</p>
                                                        </div> */}
                                                    </div>
                                                </div>

                                            </button>

                                        </div>
                                    )}
                                    {!this.state.current_location &&

                                        <div className="setlocation-address-type p-3 position-fixed w-100 bottom-0">
                                            <Form onSubmit={(e) => this.handleSetDefaultAddress(e)}>
                                                {(localStorage.getItem("BackTo") === "STORE") ? (
                                                    <React.Fragment>
                                                        <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Enter Store Name"
                                                            onChange={(e) => { let value = e.target.value; this.otherAddress(value); }} required />
                                                        <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Area Name - Eg Maruti Mandir / Mirkarwada…"
                                                            onChange={(e) => { let value = e.target.value; this.landmarkAddress(value); }} required />
                                                    </React.Fragment>
                                                ) : (
                                                    <>
                                                        {this.state.home || this.state.work ? (
                                                            <div>
                                                                <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Building No / Flat No / Wing No / Bullnglow Name"
                                                                    onChange={(e) => { let value = e.target.value; this.otherAddress(value); }} required />
                                                                <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Area Name - Eg Maruti Mandir / Mirkarwada…"
                                                                    onChange={(e) => { let value = e.target.value; this.landmarkAddress(value); }} required />
                                                            </div>
                                                        ) : ("")}
                                                        {this.state.other_address && (
                                                            <div className="">
                                                                <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Building No / Flat No / Wing No / Bullnglow Name"
                                                                    onChange={(e) => { let value = e.target.value; this.otherAddress(value); }} required />
                                                                <input type="text" className="location-input w-100 p-2 mt-2" placeholder="Area Name - Eg Maruti Mandir / Mirkarwada…"
                                                                    onChange={(e) => { let value = e.target.value; this.landmarkAddress(value); }} required />
                                                            </div>
                                                        )}
                                                        <div className="pt-3">
                                                            <button className={this.state.type === "HOME" ? "set-location-address-btn-active me-2 px-1" : "set-location-address-btn me-2 px-1"}
                                                                onClick={(e) => this.handleReport(e, "HOME")} >
                                                                {" "}
                                                                &nbsp; <FaHome /> Home{" "}
                                                            </button>
                                                            <button className={this.state.type === "WORK" ? "set-location-address-btn-active me-2 px-1" : "set-location-address-btn me-2 px-1"}
                                                                onClick={(e) => this.handleReport(e, "WORK")}  >
                                                                &nbsp;{" "}
                                                                <HiOutlineOfficeBuilding /> Work{" "}
                                                            </button>
                                                            <button className={this.state.type === "OTHER" ? "set-location-address-btn-active px-1" : "set-location-address-btn px-1"}
                                                                onClick={(e) => this.handleReport(e, "OTHER")}  >
                                                                {" "}
                                                                Other{" "}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="d-flex pt-3">
                                                    <img src={Location} alt="grosav" height={40} width={40} className="me-3" />
                                                    <span className="" style={{ fontWeight: "500", fontSize: "15px", }}>
                                                        {localStorage.getItem("address")?.replace('"', "")}
                                                    </span>
                                                </div>
                                                <div className="pt-4 mb-3">
                                                    <button type="submit" className="set-location-btn py-3"  >
                                                        {(localStorage.getItem("BackTo") === "STORE") ?
                                                            " Set Store Location" : " Set Location"}
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default GoogleApiWrapper({
    apiKey: "AIzaSyDTBMw0W9-8GhxyKmsSzh1QXCXbOYuBdE8",
    LoadingContainer: LoadingContainer,

})(
    connect(mapStateToProps, {
        getUserDetails,
        saveAddress,
    })(SetLocation)
);
