import { GET_PAYOUTS} from "./actionType";


const initialState = {
   payouts: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYOUTS:
            return { ...state, payouts: action.payload };
        default:
            return state;
    }
}
