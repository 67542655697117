import React, { Component } from 'react'
import { connect } from 'react-redux'
import LightBox from 'react-awesome-lightbox';
import ProgressiveImage from 'react-progressive-image';
import "react-awesome-lightbox/build/style.css";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../../api';

export class ImageList extends Component {
    state = {
        image: '',
        open: false
    }
    openViewer = () => {
        this.setState({ open: !this.state.open })
    }
    render() {
        const { item } = this.props;
        return (
            <React.Fragment>
                {this.state.open &&
                    <LightBox image={IMAGE_BASE_URL + item.image}
                        onClose={this.openViewer} />
                }
                <div onClick={this.openViewer} >
                    <ProgressiveImage
                        delay={20}
                        // src={item}
                        placeholder={PLACE_HOLDER_IMAGE}
                    >
                        {/* {////// console.log(this.state.files, 'in')} */}
                        {(src, loading) => (
                            <img
                                src={IMAGE_BASE_URL + item.image}
                                style={{
                                    width: '26vw', height: '28vw', objectFit: 'cover', borderRadius: '12px 12px 0px 0px',
                                    // outline: '2px solid #c7c7c7',
                                    border: '2px solid #ffd9b5',
                                    "boxShadow": "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px", backgroundImage: `url(${PLACE_HOLDER_IMAGE})`, backgroundRepeat: 'round'
                                }}
                                className="trending-brand-image"
                                alt="brandko"
                            />
                        )}

                    </ProgressiveImage>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ImageList)
