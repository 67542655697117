import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaAppleAlt } from 'react-icons/fa';
import Truncate from 'react-truncate';
// import Ink from "react-ink";
import ProgressiveImage from 'react-progressive-image';
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import { Rating } from "react-simple-star-rating";
import { getStoreCategoryTopStores } from '../../../../redux/mobile/storeCategory/action'
import { GiHamburger } from 'react-icons/gi';
import { MdOutlineStorefront } from 'react-icons/md';

export class TopStores extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        this.props.getStoreCategoryTopStores(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
    }
    render() {
        const { topstores } = this.props;
        return (
            <React.Fragment>
                {topstores && topstores?.data?.length > 0 && this.state.loading == false ? (
                    <div className='px-3 mt-3'>
                        <MdOutlineStorefront className="mb-2" color="FF9A62" size={20} />{" "}
                        <span className='top-res-header'>Top Stores For You</span>
                        <div className='mt-1 main-menu hidden-scroll-bar gap-3 '
                            style={topstores?.data?.length > 5 ?
                                { gridTemplateColumns: 'repeat(' + topstores?.data?.length + ',1fr)' } :
                                { gridTemplateColumns: 'repeat(6,1fr)' }}>
                            {topstores?.data?.map((store) =>
                                <div>
                                    <Link to={"/single-store/" + store.id} style={{ width: '20vw' }}>
                                        <div className='mt-2 position-relative'>
                                            <ProgressiveImage delay={20}
                                                src={IMAGE_BASE_URL + store.image}
                                                placeholder={PLACE_HOLDER_IMAGE}>
                                                {(src, loading) => (
                                                    <img src={src}
                                                        style={{
                                                            objectFit: "cover", backgroundRepeat: 'none', height: '20vw',
                                                            width: '20vw', borderRadius: '10px'
                                                        }}
                                                        className="" alt="grosav" />
                                                )}
                                            </ProgressiveImage>
                                            {/* <div className='d-flex justify-content-center position-absolute w-100 ' style={{ bottom: '5px', }}>
                                                <Ink className="touch-ink " />
                                                <span className='top-res-offer text-center px-2 '>20 % off</span>
                                            </div> */}
                                        </div>
                                        <div className='top-res-name'>
                                            <Truncate lines={1}>
                                                {store.name}
                                            </Truncate>
                                            <div>
                                            {store?.ratings.length > 0 ?
                                                    <div>
                                                        <Rating
                                                            size={"1em"}
                                                            className="mobile-rate-star"
                                                            initialValue={this.ratingCount(store?.ratings, store?.ratings.length)}
                                                            readonly="true"
                                                        />
                                                    </div>
                                                    :
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={store?.rating}
                                                        readonly="true"
                                                    />
                                                }
                                            </div>
                                            <div className='d-inline-block'>
                                                <span className='top-res-deli-time text-muted '>
                                                    {store.approx_time_delivery > 0 ? store.approx_time_delivery + "Mins" : "0Min"}
                                                    {store.delivery_radius > 0 ? "- " + store.delivery_radius + "km" : "- 0km"}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    topstores: state.mobilestorecategory.topstores,
})

export default connect(mapStateToProps, { getStoreCategoryTopStores })(TopStores)
