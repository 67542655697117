import React, { useState, useEffect } from 'react';
import { formatPrice } from '../../../Helpers/formatPrice';


const calculateTotalSavings = (cartProducts, coupon,user) => {
    let total = 0;
    let couponDiscount = 0;
    let freeItemAmount = 0;
    let lastPrice = 0;
    let dealOfferDis = 0;
    let dealOffer = 0;
    let deliveryChargesForSubscribers = 0;
    if (coupon?.finalDiscount > 0) {
        couponDiscount = coupon?.finalDiscount;
    }

    cartProducts?.forEach((c) => {
        if (c.is_free_item) {
            freeItemAmount += c.selling_price;
        }

        if (c.market_price > 0) {
            lastPrice += (c.market_price - c.selling_price) * c.quantity;
        }

        if (c?.dealofdays?.length > 0) {
            let dis = c.selling_price * c.dealofdays[0].offer / 100;
            dealOfferDis = c.selling_price - dis;
            dealOffer = c.selling_price - dealOfferDis;
        }
    });

    if (user?.data?.is_active_plan == 0 || user?.data?.remainining_plan_days > 0  ) {
        deliveryChargesForSubscribers = 0;
    } else {

        deliveryChargesForSubscribers = parseFloat(localStorage.getItem("delivery_charges_for_subscribers")) || 0;
    }

    const deliveryChargesDistanceFreeDelivery = parseFloat(localStorage.getItem("delivery_charges_distance_free_delivery")) || 0;

    total = couponDiscount + freeItemAmount + lastPrice + dealOffer + deliveryChargesForSubscribers + deliveryChargesDistanceFreeDelivery;

    return formatPrice(total);
};

const Savings = ({ cartProducts, coupon ,user}) => {
    const [totalSavings, setTotalSavings] = useState(0);
    useEffect(() => {
        const updateSavings = () => {
            const newTotalSavings = calculateTotalSavings(cartProducts, coupon,user);
            setTotalSavings(newTotalSavings);
        };

        updateSavings();

        const handleStorageChange = (e) => {
            if (e.key === "delivery_charges_for_subscribers" || e.key === "delivery_charges_distance_free_delivery") {
                updateSavings();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Set up an interval to check for localStorage changes
        const intervalId = setInterval(updateSavings, 200); // Check every second

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearInterval(intervalId);
        };
    }, [cartProducts, coupon]);
    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 99999 }}>
            {totalSavings > 0 &&

                <div className="px-3 d-flex justify-content-between mobile-cart-savings-bg mt-2">
                    <div className="mobile-cart-savings">
                        {" "}
                        Your total savings{" "}
                    </div>
                    <div className="mobile-cart-savings">
                        {" "}
                        Rs{" "}
                        {totalSavings}
                    </div>
                </div>
            }
        </div>
    );
};

export default Savings;
