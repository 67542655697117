import React, { Component } from 'react'
import { connect } from 'react-redux'
import VendorBackButton from '../../Elements/VendorBackButton';
import { TbEdit, TbFloatNone } from "react-icons/tb";
import { TimePicker } from 'antd';
import moment from 'moment';
import { RiDeleteBinLine } from "react-icons/ri";
import "antd/dist/antd.css";
import Loading from '../../Elements/loading'
import { getScheduleCategoryDate, addScheduleCategoryDate } from '../../../redux/vendor/items/action'
import { set } from 'lodash';
import { Modal, Button } from 'react-bootstrap';
import { MdToggleOff, MdToggleOn } from 'react-icons/md';
import { CHANGE_ITEMS_CATEGORY_SCHEDULE_STATUS_URL } from '../../../api';
import axios from 'axios';


export class VendorItemCategoryShedule extends Component {
    state = {
        editMonday: false,
        editTuesday: false,
        editWednesday: false,
        editThursday: false,
        editFriday: false,
        editSaturday: false,
        editSunday: false,
        loading: false,
        newMonday: false,
        newTuesday: false,
        newWednesday: false,
        newThursday: false,
        newFriday: false,
        newSaturday: false,
        newSunday: false,
        schedule_data: [],
        id: this.props.match.params.id,
        item: {},
    }
    componentDidMount() {
        let dummy = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        }
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.getScheduleCategoryDate(user?.data?.auth_token, this.state.id)
            .then((response) => {
                if (response && response.payload) {
                    this.setState({ loading: false,item : response.payload.item_id, schedule_data: response.payload.dates || dummy })
                    //// console.log(response.payload, 'data coming')
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }

    __handleSchedule = (type) => {
        // Retrieve the data array or initialize it if undefined
        let data = this.state.schedule_data[type] || [];

        // Ensure data is an array
        if (!Array.isArray(data)) {
            data = [];
        }

        // Add a new schedule item
        data.push({ open: '', close: '' });

        // Update the state with the new data array
        this.setState((prev) => ({
            schedule_data: {
                ...prev.schedule_data,
                [type]: data
            }
        }));
    }


    __saveTimings = (day) => {
        // this.setState({ loading: true })
        const { store, user } = this.props;
        const schedule_date = this.state.schedule_data;

        this.props.addScheduleCategoryDate(user?.data?.auth_token, this.state.id, schedule_date)
            .then((response) => {
                //// console.log(response);
                if (response && response.payload) {
                    this.setState({
                        loading: false, schedule_data: response.payload.dates, editMonday: false, editTuesday: false,
                        editWednesday: false, editThursday: false, editFriday: false, editSaturday: false, editSunday: false,
                    });
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }

    _addNewDate = (key, value, day, type) => {
        const { schedule_data, items } = this.state;
        const single_schedule = this.state.schedule_data[day];
        const data = single_schedule[key]
        data[type] = value
        this.setState((prev) => ({ ...prev, [day]: [...single_schedule] }));
    }

    __handleDay = (day) => {
        if (day == 'editMonday') {
            this.setState({ editMonday: true });
        } else if (day == 'editTuesday') {
            this.setState({ editTuesday: true });
        } else if (day == 'editWednesday') {
            this.setState({ editWednesday: true });
        } else if (day == 'editThursday') {
            this.setState({ editThursday: true });
        } else if (day == 'editFriday') {
            this.setState({ editFriday: true });
        } else if (day == 'editSaturday') {
            this.setState({ editSaturday: true });
        } else if (day == 'editSunday') {
            this.setState({ editSunday: true });
        }
    }

    __handleCopyToAll = (type) => {
        this.setState({ loading: true })
        const data = this.state.schedule_data[type];
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        const newState = {}
        for (let day of days) {
            newState[day] = data
        }
        this.setState({ schedule_data: newState })
        this.setState({ loading: false })
    }

    deleteTime = (day, key) => {
        const data = this.state.schedule_data[day]
        data.splice(key, 1)
        this.setState((prev) => ({ ...prev, [day]: [...data] }))
    }

    getTime = (time) => {
        if (typeof time === 'string') {
            const hour = time.split(":")[0];
            const minute = time.split(":")[1];
            const today = hour + ':' + minute;
            const dates = moment(today, 'HH:mm').format('hh:mm A')
            return dates
        } else {
            return time
        }
    }
    getEditTime = (time, index) => {
        if (typeof time === 'string') {
            const hour = time.split(":")[0];
            const minute = time.split(":")[1];
            const format = time.split(" ")[1];
            const today = hour + ':' + minute + format;
            const dates = moment(today, 'HH:mm').format('HH:mm')
            // //// console.log(dates)
            return dates
        } else {
            return time
        }
    }

    changeSchedule = () => {
        const { user } = this.props;
        this.setState({ loading: true });


        axios.post(CHANGE_ITEMS_CATEGORY_SCHEDULE_STATUS_URL, {
            id: this.state.item?.id,
            token: user?.data?.auth_token
        }).then(response => {
            this.props.getScheduleCategoryDate(user?.data?.auth_token, this.state.id)
            .then((response) => {
                if (response && response.payload) {

                    let dummy = {
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                        sunday: [],
                    }
                    this.setState({ loading: false,item : response.payload.item_id, schedule_data: response.payload.dates || dummy })
                    //// console.log(response.payload, 'data coming')
                } else {
                    //// console.log(response, 'API error')
                }
            })
            this.setState({ loading: false });

        });



    }
    render() {
        const { schedule_data, newMonday } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading /> :
                    <React.Fragment>
                        <div>
                            <VendorBackButton title={this.state.item.name} />
                        </div>
                        <div className='d-flex justify-content-between align-items-center px-3' style={{ paddingTop: '25vw' }}>
                            <div className='fs-6 fw-bolder'>
                                Item Category Schedule
                            </div>
                            <div onClick={this.changeSchedule}>
                                {this.state.item.is_schedule == 1 ?
                                    <MdToggleOn set="curved" style={{ fontSize: "4em", color: "#59D69D", }} />
                                    :
                                    <MdToggleOff set="curved" style={{ fontSize: "4em", color: "#cfcfcf" }} />
                                }
                            </div>
                        </div>
                        <div style={{ paddingTop: '10vw' }}>
                            <div className="d-flex justify-content-between px-3">
                                <div>
                                    <span className="timing-day">Monday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editMonday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editMonday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.monday && schedule_data.monday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'monday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'monday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('monday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('monday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div onClick={() => { this.__handleCopyToAll('monday') }}>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('monday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.monday && schedule_data.monday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />

                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Tuesday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editTuesday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editTuesday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.tuesday && schedule_data.tuesday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'tuesday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'tuesday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('tuesday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('tuesday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div onClick={() => { this.__handleCopyToAll('tuesday') }}>
                                            <span className='copy-days' >Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('tuesday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.tuesday && schedule_data.tuesday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />
                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Wednesday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editWednesday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editWednesday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.wednesday && schedule_data.wednesday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'wednesday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'wednesday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('wednesday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('wednesday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('wednesday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.wednesday && schedule_data.wednesday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />

                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Thursday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editThursday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editThursday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.thursday && schedule_data.thursday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'thursday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'thursday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('thursday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('thursday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('thursday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.thursday && schedule_data.thursday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />

                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Friday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editFriday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editFriday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.friday && schedule_data.friday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'friday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'friday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('friday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('friday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('friday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.friday && schedule_data.friday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />

                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Saturday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editSaturday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editSaturday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.saturday && schedule_data.saturday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'saturday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'saturday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('saturday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('saturday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('saturday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.saturday && schedule_data.saturday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />

                        <div>
                            <div className="d-flex justify-content-between px-3 mt-4">
                                <div>
                                    <span className="timing-day">Sunday</span>
                                </div>
                                <div onClick={() => this.__handleDay('editSunday')}>
                                    <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                </div>
                            </div>
                            {this.state.editSunday ?
                                <div className='mt-3 px-3'>
                                    <div className="d-flex flex-column px-3 mb-3">
                                        {schedule_data && schedule_data.sunday && schedule_data.sunday.map((item, index) => (
                                            <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                <div className='flex-grow-1'>
                                                    <input type="time" value={this.getEditTime(item.open)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min="01:00" max={this.getEditTime(item.close)}
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'sunday', "open")
                                                        }} />
                                                </div>
                                                <div className='flex-grow-1 ms-2'>
                                                    <input type="time" value={this.getEditTime(item.close)}
                                                        style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                        min={this.getEditTime(item.open)} max="23:59"
                                                        onChange={e => {
                                                            let value = e.target.value;
                                                            this._addNewDate(index, value, 'sunday', "close")
                                                        }} />
                                                </div>
                                                <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('sunday', index)}>
                                                    <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-between  px-2 mb-3'>
                                        <div onClick={() => { this.__handleSchedule('sunday') }}>
                                            <span className='slot-add'>Add Slot +</span>
                                        </div>
                                        <div>
                                            <span className='copy-days'>Copy To All Days</span>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('sunday',)}>
                                            <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div>
                                    {schedule_data && schedule_data.sunday && schedule_data.sunday.map((item, index) => (
                                        <div className="d-flex flex-row" >
                                            <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <hr className="seperator-timing" />
                    </React.Fragment>
                }


            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    // store: state.vendor.store,
    user: state.vendor.store_user
})
export default connect(mapStateToProps, {
    getScheduleCategoryDate,
    addScheduleCategoryDate
})(VendorItemCategoryShedule)


