import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BiSearch } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg'
import { TbDiscount2 } from 'react-icons/tb';
// import Truncate from 'react-truncate';
import GroceryBanner from './GroceryBanner';
import GroceryCategory from './GroceryCategory';
import TopItems from './TopItems';
import TopStores from './TopStores';
// import GroceryPopularBrand from './GroceryPopularBrand';
import GroceryTopDeals from './GroceryTopDeals';
import StoreNearYou from './StoreNearYou';
import RecommendedStore from './RecommendedStore';
// import GroceryFilter from './GroceryFilter';
import AllStore from './AllStore';
import ContentLoader from 'react-content-loader'
import { Link } from 'react-router-dom';
import { HiOutlineChevronLeft } from 'react-icons/hi'
import Truncate from 'react-truncate';
import map from "../../../assets/images/map.png";
import { getStoreCategory } from '../../../redux/mobile/storeCategory/action'
import Footer from "../Footer";
import TodayDeals from './TodayDeals';
import PopularBrands from './PopularBrands';

export class Groceries extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        storecategory: [],
        address: [],
        topdealcount: 0,
        storecount: 0,
        bannercount: 0,
        topitems: [],
    }
    componentDidMount() {
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")), loading: true })
        this.props.getStoreCategory(this.props.match.params.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        storecategory: response.payload.storecategory,
                        topdealcount: response.payload.topdealcount,
                        storecount: response.payload.storecount,
                        bannercount: response.payload.bannercount,
                        topitems: response.payload.topitems,
                    });
                }
            })
    }
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        const { user } = this.props;
        const { address, storecategory, topdealcount, bannercount, storecount } = this.state;
        return (
            <React.Fragment >
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                            <rect x="13" y="10" rx="8" ry="8" width="27" height="28" />
                            <rect x="185" y="15" rx="0" ry="0" width="60" height="18" />
                            <rect x="250" y="12" rx="8" ry="8" width="22" height="22" />
                            <rect x="12" y="50" rx="10" ry="10" width="254" height="47" />
                            <rect x="12" y="110" rx="10" ry="10" width="200" height="100" />
                            <rect x="220" y="110" rx="10" ry="10" width="100" height="100" />
                            <rect x="12" y="240" rx="8" ry="8" width="27" height="28" />
                            <rect x="50" y="240" rx="5" ry="5" width="115" height="28" />
                            <circle cx="45" cy="320" r="30" />
                            <circle cx="114" cy="320" r="30" />
                            <circle cx="183" cy="320" r="30" />
                            <rect x="12" y="370" rx="8" ry="8" width="27" height="28" />
                            <rect x="50" y="370" rx="5" ry="5" width="115" height="28" />
                            <rect x="18" y="420" rx="10" ry="10" width="150" height="98" />
                            <rect x="185" y="420" rx="10" ry="10" width="150" height="98" />

                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className=" " style={{ paddingBottom: '24vh' }}>
                            <div className='mobile-home-bg-img py-3 d-flex flex-column px-2 justify-content-between '>
                                <div className='d-flex flex-row justify-content-between w-100'>
                                    <div className='d-flex flex-row'>
                                        <div className='flex-shrink-1 text-center mobile-back-button position-relative'
                                            onClick={() => this.onHandleBack()} >
                                            <span className="fw-bold fs-2 back-button-icon">
                                                <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                                            </span>
                                        </div>
                                        <div>
                                            <div className='ms-2  mt-2 fs-5' style={{ fontWeight: "bold" }}>
                                                {storecategory.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row align-items-center'>
                                        {user && user.success ? (
                                            <Link to="/my-coupon" className='text-dark'>
                                                <div>
                                                    <TbDiscount2 style={{ fontSize: "30px" }} />
                                                    <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link to="/signup" className='text-dark'>
                                                <div>
                                                    <TbDiscount2 style={{ fontSize: "30px" }} />
                                                    <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                </div>
                                            </Link>
                                        )}
                                        {user && user.success && user?.data?.auth_token? (
                                            <Link to="/my-profile" className='text-dark'>
                                                <CgProfile style={{ fontSize: "30px" }} />
                                            </Link>
                                        ) : (
                                            <Link to="/signup" className='text-dark'>
                                                <CgProfile style={{ fontSize: "30px" }} />
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                {user && user.success ? (
                                    <div className='w-75 mobile-home-span-2 mt-2 text-black  px-1'>
                                        <Link to={'/set-location'} style={{ color: '#000' }}>
                                            <div>
                                                <Truncate lines={1}>
                                                    {address?.house ? address?.house + ", " : ""}{address.address}
                                                </Truncate>
                                            </div>
                                        </Link>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="px-3 sticky-search">
                                <Link to="/explore-menu">
                                    <div className="d-flex flex-row align-items-center mobile-searchBar py-2 px-2">
                                        <input
                                            type="text"
                                            className="mobile-inventory-search w-100"
                                            placeholder="What do you want to order ?"
                                        />
                                        <span className="">
                                            <BiSearch
                                                set="curved"
                                                style={{
                                                    fontSize: "2em",
                                                    color: "#FEA24B",
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                            {(storecount > 0 || topdealcount > 0 || bannercount > 0) ? (
                                <div>
                                    <GroceryBanner id={this.state.id} />
                                    <GroceryCategory id={this.state.id} />
                                    <TodayDeals id={this.state.id} />
                                    <TopItems id={this.state.id} />
                                    <TopStores id={this.state.id} />
                                    <PopularBrands id={this.state.id} />
                                    <GroceryTopDeals id={this.state.id} />
                                    <StoreNearYou id={this.state.id} />
                                    <RecommendedStore id={this.state.id} />
                                    <AllStore id={this.state.id} />
                                </div>
                            ) : (
                                <div className='mt-5 d-flex flex-column align-items-center'>
                                    <div className='mt-5 pt-3 d-flex flex-column align-items-center'>
                                        <img src={map} alt="no items" style={{ objectFit: 'cover', width: '70%' }} />
                                    </div>
                                    <span className='mt-4 fw-bolder fs-6'>Sorry!</span>
                                    <span className='mt-1 fw-bolder fs-6'>We don't deliver to your location.</span>
                                </div>
                            )}
                        </div>
                        <Footer />
                    </React.Fragment>
                )
                }
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})

export default connect(mapStateToProps, {
    getStoreCategory
})(Groceries)
