import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    UPDATE_CART,
    UPDATE_CART_ITEMS,
    GET_CART_PRODUCTS,
    CALCULATE_STORE_MAX_DISTANCE,
    GET_DELIVERY_SLAB,
    UPDATE_CURRENT_DELIVERY_SLAB,
    UPDATE_NEXT_DELIVERY_SLAB,
    CALCULATE_STORE_STORE_CHARGE_TAX,
    PRODUCT_STORE,
    PRESCRIPTION_IMAGE
} from "./actionType";

const initialState = {
    products: [],
    total: [],
    items: [],
    max_distance: [],
    slabs: [],
    current_slab: [],
    next_slab: [],
    charges: [],
    store_info: [],
    prescriptionImage: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload),
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload),
            };
        case UPDATE_CART:
            return {
                ...state,
                total: action.payload,
            };
        case UPDATE_CART_ITEMS:
            return {
                ...state,
                products: [],
            };
        case GET_CART_PRODUCTS:
            return { ...state, items: action.payload, };
        case CALCULATE_STORE_MAX_DISTANCE:
            return { ...state, max_distance: action.payload };
        case CALCULATE_STORE_STORE_CHARGE_TAX:
            return { ...state, charges: action.payload };
        case GET_DELIVERY_SLAB:
            return { ...state, slabs: action.payload };
        case UPDATE_CURRENT_DELIVERY_SLAB:
            return { ...state, current_slab: action.payload };
        case UPDATE_NEXT_DELIVERY_SLAB:
            return { ...state, next_slab: action.payload };
        case PRODUCT_STORE:
            return { ...state, store_info: action.payload };
        case PRESCRIPTION_IMAGE:
            return { ...state, prescriptionImage: action.payload };
        default:
            return state;
    }
}
