import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import ProgressiveImage from 'react-progressive-image'
import { Rating } from "react-simple-star-rating";
import { AiOutlineHeart, AiFillHeart, AiOutlineMinus, AiOutlinePlus, AiOutlineStop, AiFillStar } from "react-icons/ai";
// import { RiShareForwardLine } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi"
import { getSingleSlider } from '../../../redux/mobile/home/action'
import { toggleFavItem } from '../../../redux/mobile/item/action'
import { getSingleDeal, getSingleBanner } from '../../../redux/mobile/storeCategory/action'
import { addProduct, removeProduct } from '../../../redux/mobile/cart/action'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../api/index';
import image from '../../../assets/images/noitems.png'
import ContentLoader from 'react-content-loader'
import { Link } from "react-router-dom";
import FloatCart from '../Cart/FloatCart'
import { GiScooter } from 'react-icons/gi';
import Truncate from 'react-truncate';
// import { RiEyeCloseFill } from 'react-icons/ri';
// import Items from './../Store/Items/index';


export class TopOffer extends Component {
    state = {
        id: this.props.match.params.id,
        type: this.props.match.params.type,
        slider: {},
        items: [],
        stores: [],
        isRunning: false,
        loading: false
    };
    componentDidMount() {
        this.setState({ loading: true });
        if (this.state.type === 'slider') {
            this.props.getSingleSlider(this.state.id)
                .then((response) => {
                    let { data } = response.payload;
                    this.setState({ slider: data, items: data.items, stores: data.stores })
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 2500);
                });
        } else if (this.state.type === 'deal') {
            this.props.getSingleDeal(this.state.id)
                .then((response) => {
                    let { data } = response.payload;
                    this.setState({ slider: data, items: data.items, stores: data.stores })
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 2500);
                });
        } else if (this.state.type === 'banner') {
            this.props.getSingleBanner(this.state.id)
                .then((response) => {
                    let { data } = response.payload;
                    this.setState({ slider: data, items: data.items, stores: data.stores })
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 2500);
                });
        }
    }
    toggleFavItem = (item, index, type, store_id) => {
        const { items, stores } = this.state;
        const { user, addProduct, cartProducts, removeProduct } = this.props;
        if (type === 'ITEM') {
            const item = items[index];
            const favUser = item.favorites.find((fav) => fav.user_id === user.data.id)
            if (favUser) {
                const favIndex = item.favorites.indexOf(favUser);
                item.favorites.splice(favIndex, 1);
                this.setState({ items: items })
            } else {
                item.favorites.push({ user_id: user.data.id });
                this.setState({ items: items })
            }
        } else if (type === 'STORE') {
            const store = stores.find(store => store.id === store_id);
            const item = store.items[index];
            const favUser = item.favorites.find((fav) => fav.user_id === user.data.id)
            if (favUser) {
                const favIndex = item.favorites.indexOf(favUser);
                item.favorites.splice(favIndex, 1);
                this.setState({ stores: stores })
            } else {
                item.favorites.push({ user_id: user.data.id });
                this.setState({ stores: stores })
            }
        }
        this.props.toggleFavItem(this.props.user.data.auth_token, item.id);
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { user, addProduct, cartProducts, removeProduct } = this.props;
        const { slider, items, stores } = this.state;
        return (

            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader speed={1} viewBox="0 0 280 576" backgroundColor={"#F3F3F3"} foregroundColor={"#FFFFFF"}>
                            <rect x="26" y="32" rx="8" ry="8" width="31" height="34" />
                            <rect x="107" y="39" rx="8" ry="8" width="85" height="18" />
                            <rect x="15" y="87" rx="9" ry="9" width="250" height="119" />
                            <rect x="15" y="211" rx="9" ry="9" width="54" height="19" />
                            <rect x="15" y="267" rx="9" ry="9" width="250" height="100" />
                            <rect x="15" y="398" rx="9" ry="9" width="250" height="100" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="mobile-top-offer-bg">
                            <div>
                                <MobileBackButtonWithCenterTitle history={this.props.history}
                                    title={this.state.type === 'deal' ? "Top Deals" :
                                        this.state.type === 'slider' ? "Top Offers" :
                                            this.state.type === 'banner' ? "Latest Deal" : ("")} />
                            </div>
                            <div className="px-4 mt-3">
                                {slider ? (
                                    <>
                                        {/* {////// console.log(this.state.slider[0].stores.length)} */}
                                        <div>
                                            <ProgressiveImage
                                                delay={20}
                                                src={this.state.type === 'deal' ? IMAGE_BASE_URL + slider?.image_2 : IMAGE_BASE_URL + slider?.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img src={src}
                                                        className="" style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '12px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
                                                        alt='main-item' />
                                                )}

                                            </ProgressiveImage>
                                        </div>
                                        <div className='p-2 mobile-top-offer-item mt-3 mt-2' style={{ fontSize: '17px', fontWeight: '690' }}>
                                            <HiTemplate size={"1.3em"} style={{ color: '#FF9012' }} />
                                            <span className="ps-1"> {slider.type == "ITEM" ? "Items" : "Stores"}</span>
                                        </div>
                                        <div className="">
                                            <div style={{ paddingBottom: '25vw' }}>
                                                {slider.type == "ITEM" ? (
                                                    items?.length > 0 ? (
                                                        <div className='pt-1 pb-1'>
                                                            {items?.map((item, index) =>
                                                                <div className="mobile-top-offer-item-div d-flex justify-content-between px-2 pt-3 pb-2 mt-2">
                                                                    <div className="ps-3">
                                                                        <Link to={'/single-product/' + item.id}>
                                                                            <div className='mobile-top-offer-item' style={{ fontSize: '15px' }}>   {item.name}  </div>
                                                                            <div className='mobile-top-offer-item-desc'>  {item.description} </div>
                                                                            <div>
                                                                                {item?.ratings?.length > 0 ?
                                                                                    <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item?.ratings, item?.ratings?.length)} readonly="true" />
                                                                                    : null}
                                                                            </div>
                                                                            <div className='d-flex justify-content-start'>
                                                                                <span className='mobile-top-offer-item' style={{ fontSize: '16px' }}>   Rs {item.selling_price}   </span>
                                                                                {item?.market_price > 0 ?
                                                                                    <del className='mobile-top-offer-item ps-1 pt-1' style={{ fontSize: '14px', color: '#FF9012' }}>
                                                                                        Rs {item.market_price}
                                                                                    </del>
                                                                                    : ""}
                                                                            </div>
                                                                        </Link>
                                                                        <div className='d-flex justify-content-start pt-1'>
                                                                            {user && user.success > 0 ? (
                                                                                <div className=""
                                                                                    onClick={() => this.toggleFavItem(item, index, 'ITEM')}>
                                                                                    {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                                        <AiFillHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                                                    ) : (
                                                                                        <AiOutlineHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Link to="/signUp">
                                                                                    <div className="" >
                                                                                        <AiOutlineHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                                                    </div>
                                                                                </Link>
                                                                            )}
                                                                            {/* <div className="ps-1">
                                                                            <RiShareForwardLine
                                                                                size={"1.6em"}
                                                                                color={"#FEA24B"}
                                                                            />
                                                                        </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="pe-2">
                                                                        <div className='mobile-top-offer-item-img'>
                                                                            <Link to={'/single-product/' + item.id}>
                                                                                <ProgressiveImage delay={20}
                                                                                    src={IMAGE_BASE_URL + item.image}
                                                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                                                >
                                                                                    {(src, loading) => (
                                                                                        <img src={src}
                                                                                            className='mobile-top-offer-item-img' style={{ objectFit: 'cover' }}
                                                                                            alt='main-item' />
                                                                                    )}
                                                                                </ProgressiveImage>
                                                                            </Link>
                                                                        </div>
                                                                        {user && user.success > 0 ? (
                                                                            <React.Fragment>
                                                                                {item?.stock > 0 ? (
                                                                                    <>
                                                                                        {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                                            <>
                                                                                                {cartProducts.filter((cp) => cp.id === item.id).map((product) =>
                                                                                                    <div className="d-flex align-items-center pt-1">
                                                                                                        <div className="top-food-btn" onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            removeProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}>
                                                                                                            <AiOutlineMinus
                                                                                                                style={{
                                                                                                                    color: "#fff",
                                                                                                                }}
                                                                                                                size={14}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="top-food-count ms-2 me-2">
                                                                                                            {product.quantity}
                                                                                                        </div>
                                                                                                        {product.stock > product.quantity ? (
                                                                                                            <div className="top-food-btn" onClick={() => {
                                                                                                                item.quantity = 1;
                                                                                                                addProduct(item);
                                                                                                                this.forceStateUpdate();
                                                                                                            }}>
                                                                                                                <AiOutlinePlus
                                                                                                                    style={{
                                                                                                                        color: "#fff",
                                                                                                                    }}
                                                                                                                    size={14}
                                                                                                                />
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="top-food-btn">
                                                                                                                <AiOutlineStop
                                                                                                                    style={{
                                                                                                                        color: "#fff",
                                                                                                                    }}
                                                                                                                    size={16}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}

                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className="d-flex align-items-center pt-2 ms-1 me-2x">
                                                                                                <div className="top-food-btn px-3" onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    addProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}>
                                                                                                    <AiOutlinePlus
                                                                                                        style={{
                                                                                                            color: "#fff",
                                                                                                        }}
                                                                                                        size={14}
                                                                                                    />
                                                                                                    <span style={{
                                                                                                        color: "#fff",
                                                                                                    }} className='ps-1'>ADD</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex align-items-center pt-2 ms-1 me-2x">
                                                                                        <div className="top-food-btn px-3" >
                                                                                            <AiOutlineStop
                                                                                                style={{
                                                                                                    color: "#fff",
                                                                                                }}
                                                                                                size={16}
                                                                                            />
                                                                                            <span style={{
                                                                                                color: "#fff",
                                                                                            }} className='ps-1'>ADD</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <Link to="/signUp">
                                                                                <div className="d-flex align-items-center pt-2 ms-1 me-2x">
                                                                                    <div className="top-food-btn px-3">
                                                                                        <AiOutlinePlus
                                                                                            style={{
                                                                                                color: "#fff",
                                                                                            }}
                                                                                            size={14}
                                                                                        />
                                                                                        <span style={{
                                                                                            color: "#fff",
                                                                                        }} className='ps-1'>ADD</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className='pt-3 d-flex flex-column align-items-center mt-5'>
                                                            <div className='mt-3 d-flex flex-column align-items-center'>
                                                                <img src={image} alt="no items" style={{ objectFit: 'cover', width: '70%' }} />
                                                            </div>
                                                            <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No items Found !!!</span>
                                                        </div>
                                                    )
                                                ) : (
                                                    slider.type === "STORE" || slider.type === "MULTI_STORE" || slider.type === "SINGLE_STORE" ? (
                                                        stores?.length > 0 ? (
                                                            <div className='pb-2 pt-1'>
                                                                {/* top offer store items */}
                                                                {stores?.map((store) =>
                                                                    <div className='mobile-top-offer-item-div py-2 px-2 mt-2'>
                                                                        <Link to={"/single-store/" + store.id}>
                                                                            <div className="d-flex flex-row">
                                                                                <div className="">
                                                                                    <ProgressiveImage
                                                                                        delay={20}
                                                                                        src={IMAGE_BASE_URL + store?.image}
                                                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                                                    >
                                                                                        {(src, loading) => (
                                                                                            <img src={src}
                                                                                                style={{
                                                                                                    width: "30vw",
                                                                                                    height: "30vw",
                                                                                                    borderRadius: "20px",
                                                                                                    objectFit: "cover",
                                                                                                    backgroundRepeat: "round",
                                                                                                }}
                                                                                                className="" alt="grosav" />
                                                                                        )}
                                                                                    </ProgressiveImage>
                                                                                </div>
                                                                                <div className="flex-grow-1  d-flex flex-column justify-content-center   ms-3 mt-4">
                                                                                    <div>
                                                                                        <h5 className="fs-6 m-0" style={{ color: "#09051C", fontWeight: "600" }}>
                                                                                            {store.name}
                                                                                        </h5>
                                                                                        <div className="d-flex align-items-center">
                                                                                            {store?.ratings?.length > 0 ? <>
                                                                                                <span className="m all-resturant-rating">
                                                                                                    {this.ratingCount(store?.ratings, store?.ratings?.length)}
                                                                                                </span>{" "}
                                                                                                <AiFillStar className="me-2"
                                                                                                    style={{ color: "FF9A62" }}
                                                                                                    size={11}
                                                                                                />
                                                                                            </>
                                                                                                : <>
                                                                                                    <span className="m all-resturant-rating">
                                                                                                        {store?.rating}
                                                                                                    </span>{" "}
                                                                                                    <AiFillStar className="me-2"
                                                                                                        style={{ color: "FF9A62" }}
                                                                                                        size={11}
                                                                                                    />
                                                                                                </>}
                                                                                            <span className=" me-2"
                                                                                                style={{ fontSize: "12px", color: "#3B3B3B" }} >
                                                                                                {store?.preparation_time ? store.preparation_time + ' Min - ' : ""}  {store?.delivery_radius ? store.delivery_radius + ' Km ' : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className={store?.is_freedelivery === 1 ? "resturant-description" : "mb-4"}
                                                                                            style={{ color: "rgba(9, 5, 28, 0.25)", fontSize: "13px", }}  >
                                                                                            <Truncate lines={2}>
                                                                                                {store?.description}
                                                                                            </Truncate>
                                                                                        </div>
                                                                                        {store?.is_freedelivery === 1 ? (
                                                                                            <div className="d-flex align-items-center">
                                                                                                <GiScooter style={{ color: "FF9A62" }} size={20} />
                                                                                                <span className="all-resturant-delivery ms-2 mt-1">
                                                                                                    Free Delivery Upto...
                                                                                                </span>
                                                                                            </div>
                                                                                        ) : ('')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className='pt-3 d-flex flex-column align-items-center mt-5'>
                                                                <div className='mt-3 d-flex flex-column align-items-center'>
                                                                    <img src={image} alt="no items" style={{ objectFit: 'cover', width: '70%' }} />
                                                                </div>
                                                                <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Stores Found !!!</span>
                                                            </div>
                                                        ))
                                                        : (""))}
                                            </div>
                                        </div>
                                    </>
                                ) : ("")}
                            </div>
                        </div>
                        <FloatCart noFooter={true} />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    singleslider: state.home.singleslider,
    user: state.user.user,
    cartProducts: state.cart.products
})

export default connect(mapStateToProps, {
    getSingleSlider, toggleFavItem, getSingleDeal, getSingleBanner, addProduct, removeProduct
})(TopOffer)
