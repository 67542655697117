import { PAYTM_SUBSCRIPTION_STATUS_CHECK, SUBSCRIPTION_PLACE } from "./actionType";

const initialState = {
    data: {
        subscibedPlan: [],
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYTM_SUBSCRIPTION_STATUS_CHECK:
            return {
                ...state,
                subscibedPlan: action.payload
            };
        case SUBSCRIPTION_PLACE:
            return {
                ...state,
                subscibedPlan: action.payload
            };
        default:
            return state;
    }
}
