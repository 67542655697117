import {
    GET_STORE_CATEGORY_TOP_ITEMS,
    GET_STORE_CATEGORY_STORES,
    GET_STORE_CATEGORY_ITEM_CATEGORY,
    GET_STORE_CATEGORY_BANNER,
    GET_STORE_CATEGORY_TOP_STORES,
    GET_STORE_CATEGORY_TOP_DEALS,
    GET_STORE_CATEGORY_RECOMMENDED_STORES,
    GET_STORE_CATEGORY_NEAR_STORES,
    GET_STORE_CATEGORY_TODAY_DEALS,
} from './actionType'


const initialState = {
    itemcategory: [],
    topitems: [],
    topstores: [],
    stores: [],
    banners: [],
    topdeals: [],
    recommendedstores: [],
    nearstores: [],
    todaydeals: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STORE_CATEGORY_TOP_ITEMS:
            return { ...state, topitems: action.payload };
        case GET_STORE_CATEGORY_STORES:
            return { ...state, stores: action.payload };
        case GET_STORE_CATEGORY_ITEM_CATEGORY:
            return { ...state, itemcategory: action.payload };
        case GET_STORE_CATEGORY_BANNER:
            return { ...state, banners: action.payload };
        case GET_STORE_CATEGORY_TOP_STORES:
            return { ...state, topstores: action.payload };
        case GET_STORE_CATEGORY_TOP_DEALS:
            return { ...state, topdeals: action.payload };
        case GET_STORE_CATEGORY_RECOMMENDED_STORES:
            return { ...state, recommendedstores: action.payload };
        case GET_STORE_CATEGORY_NEAR_STORES:
            return { ...state, nearstores: action.payload };
        case GET_STORE_CATEGORY_TODAY_DEALS:
            return { ...state, todaydeals: action.payload };
        default:
            return state;
    }
}
