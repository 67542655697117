import moment from 'moment'
import React, { Component } from 'react'
import { Form, FormControl, InputGroup, input } from 'react-bootstrap'
import { HiOutlineChevronRight } from 'react-icons/hi'
import noDealImage from '../../../assets/images/Discount-cuate.png'
import { connect } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'
import Shake from "react-reveal/Shake";
import { getAllStoreItems, addDealOfToday, changeItemStatus, changeDealStatus } from '../../../redux/vendor/items/action'
import Loading from '../../Elements/loading'
import VendorBackButton from '../../Elements/VendorBackButton'
import Modal from "react-modal";
import Ink from 'react-ink'
import Lottie from 'react-lottie'
import animationData from "../../../assets/lottie/lf30_editor_bspltctv.json";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../api'
import { Rating } from 'react-simple-star-rating'
import Truncate from 'react-truncate'

export class VendorDealsToday extends Component {
    state = {
        loading: false,
        open: false,
        noItem: false,
        items: [],
        name: '',
        percentage: '',
        selectedItems: [],
        start_time: '',
        end_time: '',
        dealsToday: [],
        requestOn: false
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.getAllStoreItems(user?.data?.auth_token, store?.store?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, items: response.payload.items, dealsToday: response.payload.dealsToday })
                    // //// console.log(response.payload.items, 'data coming')
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }

    notStart = () => {
        let date;
        date = moment().format("YYYY-MM-DD HH:MM");
        return date;
    }
    notEnd = () => {
        let date;
        date = moment().add(1, 'hours').format("YYYY-MM-DD HH:MM");
        return date;
    }
    _listItems = () => {
        this.setState({ open: !this.state.open })
        this.setState({ noItem: false })
    }

    __requestSuccess = () => {
        this.setState({ requestOn: false })
        // this.props.history.replace("/vendor-profile")
    }
    openSelectedItems = (id) => {
        let selectedItems = this.state.selectedItems;
        let index = selectedItems.indexOf(id)
        if (index >= 0) {
            selectedItems.splice(index, 1)
        } else {
            selectedItems = [...selectedItems, id];
        }
        this.setState({ selectedItems });
        localStorage.setItem("selectedCategoryIndex", selectedItems);
        this.setState({ selectedItems: selectedItems });
    };

    onSubmit = (e) => {
        e.preventDefault();
        //// console.log(this.state.date)
        const { selectedItems, start_time, end_time, name, percentage } = this.state;
        const { store, user } = this.props;
        if (selectedItems && selectedItems.length > 0) {
            this.setState({ loading: true })
            this.props.addDealOfToday(user?.data?.auth_token, store?.store?.id, selectedItems, start_time ? start_time : this.notStart(), end_time ? end_time : this.notEnd(), percentage, name)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.setState({ loading: false, requestOn: true, selectedItems: [] });
                    } else {
                        //// console.log(response, 'API error')
                    }
                })
        } else {
            this.setState({ noItem: true })

        }
    }

    changeStatus = (e, item_id, deal_id) => {
        e.preventDefault()
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.changeItemStatus(user?.data?.auth_token, store?.store?.id, item_id, deal_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, items: response.payload.items, dealsToday: response.payload.dealsToday })
            } else {
                //// console.log(response, 'API error')
            }
        })
    }

    changeDealStatus = (e, deal_id) => {
        e.preventDefault()
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.changeDealStatus(user?.data?.auth_token, store?.store?.id, deal_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, items: response.payload.items, dealsToday: response.payload.dealsToday })
            } else {
                //// console.log(response, 'API error')
            }
        })
    }
    render() {
        const { start_time, end_time, selectedItems, noItem, items, dealsToday } = this.state;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading /> :
                    <React.Fragment>
                        <VendorBackButton title="Deals Today" />
                        <div style={{ paddingBottom: '8vw' }} className="px-3">
                            {/* <div className="coupon-heading-one">
                                <span>Create Your Own Coupon</span>
                            </div>
                            <div className="coupon-heading-two text-muted">
                                <span>Enter details to Create Coupon</span>
                            </div> */}
                            <div style={{ backgroundColor: '#fff' }}>


                                <Tabs defaultActiveKey="item"
                                    style={{ paddingTop: '25vw', top: '0', backgroundColor: '#fff', zIndex: '9999' }}
                                    transition={false}
                                    className="mb-3 text-center gap-3"
                                    justify   >

                                    <Tab eventKey="normal" title="Add New Deal " className="w-100">
                                        <Form onSubmit={(e) => this.onSubmit(e)}>
                                            <div className="px-2 form-coupon">
                                                <InputGroup className="mt-3 coupon-input" style={{ boxSizing: "border-box" }}>
                                                    <FormControl type="number" placeholder="Offer Percentage %" className="input-coupon"
                                                        required
                                                        onChange={(e) => {
                                                            this.setState({ percentage: e.target.value });
                                                        }} />
                                                </InputGroup>

                                                <div className="mt-3 deal-today-input w-100 text-muted d-flex justify-content-between"
                                                    onClick={() => this._listItems()} >
                                                    {selectedItems && selectedItems.length > 0 ?
                                                        <div>
                                                            {selectedItems?.length} items selected
                                                        </div>
                                                        :
                                                        <div>
                                                            Select Items
                                                        </div>
                                                    }
                                                    <div className=''>
                                                        <HiOutlineChevronRight style={{ color: "#DA6317" }} />
                                                    </div>
                                                </div>
                                                {noItem &&
                                                    <Shake duration={250} right>
                                                        <div className="text-center mb-2" style={{ color: 'red' }}> Items Required</div>
                                                    </Shake>
                                                }
                                                <BottomSheet open={this.state.open} onDismiss={() => this._listItems()} >
                                                    <div className="px-3" style={{ paddingBottom: '18vw' }}>
                                                        <div className="mt-1 mb-2" style={{ fontWeight: "600", fontSize: "16px" }}>
                                                            Make Your Coupons For
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                            <div className="d-grid gap-2 px-2 mb-2">
                                                                {items && items.map((item, index) => (
                                                                    <button className="offer-sheet-btn "
                                                                        style={selectedItems.filter(cd => cd == item.id) == item.id ? {
                                                                            background: "#FF9012",
                                                                            color: "#fff",
                                                                        } : {}}
                                                                        onClick={() => this.openSelectedItems(item.id)}>
                                                                        {item.name}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='position-fixed bottom-0 d-flex justify-content-between gap-2 py-2 w-100 px-3' style={{ backgroundColor: '#fff', color: '#fff' }}>
                                                        <button className='btn coupon-btn-close py-3 w-100' onClick={this._listItems}>
                                                            Close
                                                        </button>
                                                        <button className='btn coupon-btn py-3 w-100' onClick={this._listItems}>
                                                            Done
                                                        </button>
                                                    </div>
                                                </BottomSheet>

                                                <Form.Group className="mt-2">
                                                    <Form.Label className="text-muted ps-2">Start Time</Form.Label>
                                                    <Form.Control type='datetime-local' className='input-deals-today w-100'
                                                        placeholder='Start Date' required
                                                        value={start_time ? start_time : this.notStart()}
                                                        min={this.notStart()}
                                                        onChange={(e) => {
                                                            //// console.log(e.target.value)
                                                            let start_time = e.target.value ? e.target.value : this.notStart();
                                                            this.setState({ start_time: start_time, noData: false, });
                                                        }} />
                                                </Form.Group>

                                                <Form.Group className="mt-1" >
                                                    <Form.Label className="text-muted ps-2">End Time</Form.Label>
                                                    <Form.Control type='datetime-local' className='input-deals-today w-100'
                                                        placeholder='End Date' required
                                                        value={end_time ? end_time : this.notEnd()}
                                                        min={this.notStart()}
                                                        onChange={(e) => {
                                                            let end_time = e.target.value ? e.target.value : this.notEnd();
                                                            this.setState({ end_time: end_time, noData: false, });
                                                        }} />
                                                </Form.Group>
                                            </div>
                                            <div className='position-fixed bottom-0 py-2 w-100 px-3' style={{ backgroundColor: '#fff', left: '0%' }}>
                                                <button type='submit' className='btn coupon-btn py-3 w-100' >
                                                    Submit
                                                </button>
                                            </div>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="item" title="View Today Deals">
                                        <div className='px-2  mt-4'>
                                            {dealsToday && dealsToday.length > 0 ?
                                                dealsToday.map((value, index) => (
                                                    <div className='mt-2'>
                                                        <div className='px-1 py-1' style={{ backgroundColor: '#fff', borderRadius: "12px", border: '1px solid #FF9012' }}>
                                                            <div className='d-flex justify-content-between align-items-center' style={{ color: "#FF9012", fontSize: "14px", fontWeight: "700" }}>
                                                                <button className='btn fw-bolder px-1 me-1' style={{ color: "#fff", backgroundColor: '#FF9012', borderRadius: "10px",whiteSpace: 'nowrap' }}>{value.offer}% off</button>
                                                                <div className='text-center'>
                                                                <span style={{whiteSpace: 'nowrap' }}> {moment(value.start_time).format('DD-MM-Y ')}</span> <span style={{whiteSpace: 'nowrap' }}>({moment(value.start_time).format('hh:mm A')}-{moment(value.end_time).format('hh:mm A')})</span>
                                                                </div>
                                                                <div className="form-check form-switch" onClick={(e) => this.changeDealStatus(e, value.id)}>
                                                                    {value.is_active ?
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                        :
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {value.items.length > 0 ? value.items.map((item, index) => (
                                                            <div className="store-menu px-3 mt-2 py-2 d-flex flex-row justify-content-between position-relative">
                                                                <div className="pe-2">
                                                                    <ProgressiveImage delay={20} src={IMAGE_BASE_URL + item.image}
                                                                        placeholder={PLACE_HOLDER_IMAGE} >
                                                                        {(src, loading) => (
                                                                            <img className=""
                                                                                style={{
                                                                                    width: "20vw", objectFit: "cover", height: "20vw", borderRadius: "15px",
                                                                                    backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                                                    backgroundRepeat: "round", boxShadow: "0px 0px 15px 8px #cccccc50",
                                                                                }}
                                                                                src={src} alt="main-item" />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                </div>
                                                                <div className='flex-grow-1' style={{ color: "#09051C", fontWeight: "600", fontSize: "15px" }} >
                                                                    <Truncate lines={1}>
                                                                        {item.name}
                                                                    </Truncate>
                                                                    <div>
                                                                        <Rating
                                                                            size={"1em"}
                                                                            className="mobile-rate-star"
                                                                            initialValue={item.rating}
                                                                            readonly="true"
                                                                        />
                                                                    </div>
                                                                    <div className="">
                                                                        <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px", }}>
                                                                            Rs {item.selling_price}
                                                                        </span>
                                                                        <span className="ps-2" style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", textAlign: 'bottom' }} >
                                                                            Rs {item.market_price}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='position-absolute top-50 start-100 translate-middle pe-5'>
                                                                    <div className="form-check form-switch" onClick={(e) => this.changeStatus(e, item.id, value.id)}>
                                                                        {item?.pivot.is_active ?
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                            :
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) :
                                                            <div className='text-center py-2'>
                                                                No Items
                                                            </div>
                                                        }
                                                    </div>
                                                )) : (
                                                    <div className="d-flex flex-column align-items-center  mt-5" >
                                                        <ProgressiveImage delay={20} src={noDealImage}
                                                            placeholder={PLACE_HOLDER_IMAGE} >
                                                            {(src, loading) => (
                                                                <img src={src} style={{ width: "80%", objectFit: "cover", borderRadius: "10px", marginRight: "10px", }} alt={"grosav"} />
                                                            )}
                                                        </ProgressiveImage>
                                                        <span className="fs-5" style={{ fontFamily: "fantasy", fontWeight: 'bold' }}>No Deals Available</span>
                                                    </div>
                                                )}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </React.Fragment>
                }

                <Modal
                    isOpen={this.state.requestOn}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.__requestSuccess()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "200px",
                            zIndex: "999999999999999999999",
                        }}
                    >
                        <React.Fragment>

                            <div className="px-10  pt-3 text-center fs-6">
                                Your Request Has Been Placed SuccessFully
                            </div>
                            <div className="">
                                <Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />
                            </div>
                            <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                <div className="text-center position-fixed" style={{ bottom: '5vw' }}>
                                    <button className="add-button-meals p-2 px-4 text-center text-white position-relative"
                                        onClick={this.__requestSuccess}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#ce0060",
                                            borderColor: "#ce0060",
                                        }}
                                    >
                                        OK
                                        <Ink
                                            duration={500}
                                            style={{ color: "#000" }}
                                        />
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => ({
    store: state.vendor.store,
    user: state.vendor.store_user
})
export default connect(mapStateToProps, {
    getAllStoreItems,
    addDealOfToday,
    changeItemStatus,
    changeDealStatus
})(VendorDealsToday)

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
