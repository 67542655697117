import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../api/index';
import {
    getSingleCoupon,
} from '../../../redux/mobile/home/action'
import { AiFillTags } from "react-icons/ai"
import { MdNotes, MdOutlineContentCopy,MdDone } from "react-icons/md"
import { CopyToClipboard } from 'react-copy-to-clipboard';





export class SingleCoupon extends Component {
    state = {
        id: this.props.match.params.id,
        coupon: [],
        copied: false,
    };
    componentDidMount() {
        this.props.getSingleCoupon(this.state.id)
            .then((response) => {
                // ////// console.log(response)
                // this.setState({ coupon: response.payload.data })
            });
    }
    render() {
        const {coupon} = this.props;
        return (
            <React.Fragment>
                <div className="mobile-top-offer-bg pt-4 px-4">
                    <div className="">
                        <MobileBackButtonWithCenterTitle title="Coupons"  history={this.props.history}/>
                    </div>
                    <div className="px-1 mt-3">
                        {coupon && coupon.success ? (
                            <>
                                <div className='mobile-single-coupon-img'>
                                    {/* {////// console.log(this.state.coupon?.data.stores.length)} */}
                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + "/" + coupon?.data?.image}
                                        placeholder={PLACE_HOLDER_IMAGE}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                className="mobile-single-coupon-img" style={{
                                                    objectFit: 'cover'
                                                }}
                                                alt='main-item'
                                            />
                                        )}

                                    </ProgressiveImage>
                                </div>
                                <div className='mt-3'>
                                    <div className="mt-5 mobile-single-coupon-item pt-1" >
                                        <div className="text-muted ps-3 pt-2" style={{ fontWeight: '600' }}>
                                            <AiFillTags size={"1em"} style={{ color: '#FF9012' }} />
                                            <span className='ps-1'>Coupon </span>
                                        </div>
                                        <div className="ps-4 pt-1 pb-2" style={{ fontWeight: '650' }}>
                                            <span>{coupon?.data?.name}</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 mobile-single-coupon-item pt-1" >
                                        <div className="text-muted ps-3 pt-2" style={{ fontWeight: '600' }}>
                                            <MdNotes size={"1.2em"} style={{ color: '#FF9012' }} />
                                            <span className='ps-1'>Description </span>
                                        </div>
                                        <div className="ps-4 pt-1" style={{ fontWeight: '650' }}>
                                            <span>{coupon?.data?.description}</span>
                                        </div>
                                    </div>
                                    <div className="mt-3 mobile-single-coupon-item pt-1 d-flex justify-content-between" >
                                        <CopyToClipboard text={coupon?.data?.code}
                                            onCopy={() => this.setState({ copied: true })}>
                                            <div className="ps-3 pt-2" style={{ fontWeight: '680' }}>
                                                <MdOutlineContentCopy size={"1.2em"} style={{ color: '#FF9012' }}/>
                                                <span className='text-success ps-3'>{coupon?.data?.code} </span>
                                            </div>
                                        </CopyToClipboard>
                                        {this.state.copied ?
                                        <div className='me-3 py-1 pe-3'>
                                            <MdDone size={'1.2em'} style={{color:'grey'}}/>
                                        <span style={{color: 'grey'}}>Copied</span>
                                        </div>
                                         : null}
                                    </div>

                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    coupon: state.home.singlecoupon,
})

export default connect(mapStateToProps, { getSingleCoupon })(SingleCoupon)
