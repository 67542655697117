import { ADD_VENDOR_COUPON,GET_COUPONS,GET_SINGLE_COUPON } from "./actionType";
import { ADD_VENDOR_COUPON_URL } from "../../../api";
import { UPDATE_VENDOR_COUPON_URL } from "../../../api";
import { GET_ALL_VENDOR_COUPON_URL } from "../../../api";
import { GET_SINGLE_VENDOR_COUPON_URL } from "../../../api";

import { UPDATE_ITEM_DISCOUNT_URL } from "../../../api";
import { UPDATE_ALL_STORE_ITEM_DISCOUNT_URL } from "../../../api";
import Axios from "axios";


export const addVendorCoupon = (formdata) => (dispatch) => {
    // console.log(formdata,7899);
    return Axios.post(ADD_VENDOR_COUPON_URL, formdata ,{

    })
}

export const updateItemDiscount = (formdata) => (dispatch) => {
    // console.log(formdata,7899);
    return Axios.post(UPDATE_ITEM_DISCOUNT_URL, formdata ,{

    })
}

export const updateAllStoreItemDiscount = (formdata) => (dispatch) => {
    // console.log(formdata,7899);
    return Axios.post(UPDATE_ALL_STORE_ITEM_DISCOUNT_URL, formdata ,{

    })
}

export const getAllVendorCoupons = (token,id) => (dispatch) => {
    return Axios.post(GET_ALL_VENDOR_COUPON_URL, {
        token, id:id
    })
        .then((response) => {
            let coupons = response.data;
            return dispatch({
                type: GET_COUPONS,
                payload: coupons
            })

        })
}

export const getSingleVendorCoupon = (token,id) => (dispatch) => {
    return Axios.post(GET_SINGLE_VENDOR_COUPON_URL, {
        token, id:id
    })
        .then((response) => {
            let singleCoupon = response.data;
            return dispatch({
                type: GET_SINGLE_COUPON,
                payload: singleCoupon
            })

        })
}


export const updateSingleCoupon = (formdata) => (dispatch) => {
    // console.log(formdata,7899);
    return Axios.post(UPDATE_VENDOR_COUPON_URL, formdata ,{

    })
}
