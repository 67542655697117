import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';


class BackButtonFixed extends Component {
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <React.Fragment>
                <div className='position-fixed top-0 d-flex flex-row justify-content-start align-items-center pt-2 w-100' style={{backgroundColor:'#fff',zIndex:'999999999'}}>
                    <div className='flex-shrink-1 text-center  mobile-back-button  position-relative m-3'
                        onClick={() => this.onHandleBack()} >
                        <span className="fw-bold fs-2 back-button-icon">
                            <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                        </span>
                    </div>
                    <div className='flex-grow-1 w-75 text-center pe-5'>
                        <span className='fw-bd user-select-none text-black  fs-3 fw-bolder'>{this.props.title}</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps, {})(BackButtonFixed)
