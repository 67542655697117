import React, { Component } from "react";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
// import Ink from "react-ink";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export class MedOffer extends Component {
    render() {
        const { Sliders } = this.props;
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            speed: 400,
            autoplaySpeed: 8000,
            cssEase: "linear",
            customPaging: function (i) {
              return <div className="dot"></div>;
            },
            dotsClass: "slick-dots slick-thumb"
          };
        return (
            <React.Fragment>
                {Sliders && Sliders.nearbanners && Sliders.nearbanners.length > 0 &&
                    <div className="px-3 mt-3">
                        <Slider {...settings} >
                            {Sliders && Sliders.nearbanners && Sliders.nearbanners.map((value) => (
                                <Link to={'/top-offer/banner/' + value.id}>
                                    <div className="d-flex flex-column justify-content-center align-items-center text-center position-relative b-r-40">
                                        {/* <Ink className="touch-ink " /> */}
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + value.image}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "88vw",
                                                        height: "35vw",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        backgroundRepeat: "round",
                                                    }}
                                                    className=""
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                </Link>
                            ))}
                        </Slider>

                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    Sliders: state.medicine.medicine
});

export default connect(mapStateToProps, {})(MedOffer);
