import React, { Component } from "react";
import { connect } from "react-redux";
import VendorBackButton from "../../Elements/VendorBackButton";
import { TbEdit } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import Loading from "../../Elements/loading";
import { getUserStore, changePreparationTime } from '../../../redux/vendor/user/action'

export class VendorPreparationTime extends Component {
    state = {
        time: true,
        editTime: false,
        loading: false,
        count: '',
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, count: response.payload.store.preparation_time })
            } else {
                //// console.log(response, 'API error')
            }
        })
    }
    displayEditTime = () => {
        this.setState({ editTime: true, time: false });
    };
    displayTime = () => {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.changePreparationTime(user && user.data && user.data.auth_token, this.state.count)
            .then((response) => {
                if (response && response.data && response.data.success) {
                    this.setState({ loading: false, editTime: false, time: true });
                } else {
                    //// console.log(response, 'API error')
                }
            })
    };
    handleIncrement = (e) => {
        this.setState(prevState => {
            return { count: prevState.count + 1 }
        })
        // this.setState({ count: this.state.count + 1 });
    };
    handleDecrement = (e) => {
        this.setState({ count: this.state.count - 1 });
    };
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Preparation Time" />
                        </div>
                        <div className="px-3 " style={{ fontSize: "16px", color: "#09051C", fontWeight: "600", paddingTop: '25vw' }} >
                            Average
                        </div>
                        {this.state.time ? (
                            <div className="d-flex justify-content-between mx-3 p-3 preparation-time-card">
                                <div className="d-flex flex-column px-3 my-1">
                                    <span style={{ fontSize: "12px", color: "#ff9012", fontWeight: "700", }} >
                                        Preparation time
                                    </span>
                                    <span style={{ fontSize: "23px", color: "#09051C", fontWeight: "700", lineHeight: "131.02%" }}
                                        className="my-1">
                                        {this.state.count > 0 ? (
                                            <>
                                            {this.state.count} Mins
                                            </>
                                        ) : (
                                            <span style={{fontSize:'17px' ,}} className='text-muted'>
                                            No Preparation Time
                                            </span>
                                        )}
                                    </span>
                                    <span style={{ fontSize: "12px", color: "#ff9012", fontWeight: "700", }} >
                                        All Days
                                    </span>
                                </div>
                                <div className=" px-3" onClick={() => this.displayEditTime()} >
                                    <TbEdit style={{ fontSize: "23", color: "#FF9A62", }} />
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column mx-3 p-3 preparation-time-card text-center">
                                <span style={{ fontSize: "12px", color: "#ff9012", fontWeight: "700", }} >
                                    Preparation time
                                </span>
                                <div className="d-flex justify-content-between align-items-center px-2 py-2 my-3 text-center"
                                    style={{ background: "#F5F5F7", borderRadius: "8px", }} >
                                    {this.state.count == 0 ? (
                                        <div>
                                            <AiOutlineMinus size={"1em"} style={{ color: "#FF9A62", }} className="ms-2" />
                                        </div>
                                    ) : (
                                        <div onClick={() => this.handleDecrement()} >
                                            <AiOutlineMinus size={"1em"} style={{ color: "#FF9A62", }} className="ms-2" />
                                        </div>
                                    )}
                                    <span style={{ fontSize: "18px", color: "#09051C", fontWeight: "700", letterSpacing: "0.5px", lineHeight: "25px" }}>
                                        {this.state.count ? (
                                            <>
                                            {this.state.count} Mins
                                            </>
                                        ):(
                                            <>
                                            0 Min
                                            </>
                                        )}
                                    </span>
                                    <div onClick={() => this.handleIncrement()}>
                                        <AiOutlinePlus size={"1em"} style={{ color: "#FF9A62" }} className="me-2" />
                                    </div>
                                </div>
                                <span style={{ fontSize: "12px", color: "#ff9012", fontWeight: "700", }} >
                                    All Days
                                </span>
                                <button className="preparation-save-btn p-3 w-100 mt-3" onClick={() => this.displayTime()} >
                                    Save
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user
});

export default connect(mapStateToProps, {
    getUserStore, changePreparationTime
})(VendorPreparationTime);
