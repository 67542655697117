import React, { Component } from 'react'
import { connect } from 'react-redux'
import meal from '../../../assets/images/meal.png'
import { Rating } from 'react-simple-star-rating'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import ContentLoader from "react-content-loader";


class RateMeal extends Component {
    state = {
        rating: '',
        setRating: false,
        loading:false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }


    render() {
        return (

            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <circle cx="140" cy="150" r="60" />
                            <rect x="100" y="250" rx="0" ry="0" width="80" height="20" /> 
                            <rect x="80" y="280" rx="0" ry="0" width="120" height="20" /> 
                            <rect x="90" y="310" rx="0" ry="0" width="100" height="20" /> 
                            <rect x="90" y="350" rx="0" ry="0" width="120" height="20" /> 
                            <rect x="15" y="500" rx="5" ry="5" width="250" height="25" /> 
                            <rect x="15" y="540" rx="5" ry="5" width="145" height="30" /> 
                            <rect x="210" y="540" rx="5" ry="5" width="50" height="30" /> 

                        
                        </ContentLoader>
                    </React.Fragment>
                ) : (
            <React.Fragment>
                <div>
                    <div className='mobile-rate-meal-bg d-flex justify-content-center align-items-center'>
                        <div className='mt-5'>
                            <div className="mobile-rate-meal-round d-flex justify-content-center align-items-center mt-5">
                                <div className="">
                                    <img src={meal} alt="meal" style={{ width: '100%', height: '100%',borderRadius:'50%' }} />
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mobile-cmpltd-order-text mt-4">
                        <span>Thank You!</span>
                    </div>
                    <div className="mobile-cmpltd-order-text">
                        <div>
                            <span>Enjoy Your Meal</span>
                        </div>
                    </div>
                    <div className="text-muted d-flex justify-content-center mobile-cmpltd-order-rate mt-3">
                        <span>Please Rate Your Order</span>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <Rating size={'2.5em'} className='mobile-rate-star'
                            onClick={(e) => this._handleRating(e)} />
                    </div>
                    <div className="position-fixed bottom-0 w-100">
                        <div className="d-flex flex-row align-items-center mobile-cmpltd-order-cmnt-div px-3 mx-3 py-2 mt-5">
                            <span className='ms-2'>
                                <HiOutlinePencilAlt
                                    style={{ fontSize: "2em", color: "#FEA24B" }} />
                            </span>
                            <input
                                type="text"
                                placeholder="Leave Feedback about Delivery"
                                className="mobile-cmpltd-order-cmnt ms-2"
                            />
                        </div>
                        <div className='d-flex justify-content-between mx-3 py-4'>
                            <button className='mobile-cmpltd-order-submit '>Submit</button>
                            <button className='mobile-cmpltd-order-skip btn btn-lg'>Skip</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(RateMeal)
