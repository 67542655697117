import React, { Component } from "react";
import { connect } from "react-redux";
import { BiBell } from "react-icons/bi";

import PickupServices from "../../../assets/images/pickup-services.png";
import MenBike from "../../../assets/images/men-bike.png";
import Footer from "../Footer";
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader'





export class AddPickUpAndDrop extends Component {

    state = {
        loading: false,
    }

    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
    }


    render() {
        return (
            <React.Fragment>
                    {this.state.loading ?
                        (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="15" y="35" rx="8" ry="8" width="180" height="25" />
                                <rect x="235" y="30" rx="8" ry="8" width="30" height="30" />
                                <rect x="15" y="65" rx="8" ry="8" width="100" height="25" />
                                <rect x="15" y="115" rx="8" ry="8" width="220" height="15" />
                                <rect x="15" y="150" rx="8" ry="8" width="80" height="30" />
                                <rect x="110" y="150" rx="8" ry="8" width="80" height="30" />
                                <rect x="15" y="200" rx="8" ry="8" width="80" height="30" />
                                <rect x="110" y="200" rx="8" ry="8" width="80" height="30" />
                                <rect x="15" y="245" rx="8" ry="8" width="100" height="30" />
                                <rect x="80" y="280" rx="20" ry="20" width="180" height="220" />
                                <rect x="15" y="505" rx="8" ry="8" width="250" height="40" />







                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                <div className="mobile-add-pickup-bg d-flex justify-content-between px-3">
                    <div className="mobile-pickup-drop-heading">
                        Pick up or drop any items
                    </div>
                    <div className=" mobile-bell-icon">
                        <BiBell size={"2em"} style={{ color: "#FF9012" }} />
                    </div>
                </div>
                <div
                    className="px-3 mt-4"
                    style={{
                        color: "#FF9012",
                        fontSize: "15px",
                        fontWeight: "700",
                    }}
                >
                    Somethings we can pick or drop for you
                </div>
                <div className="px-3 mt-4">
                    <img src={PickupServices} alt="" />
                </div>
                <div className="px-3 mt-4 d-flex position-fixed" style={{bottom:"11vh",right:"-19px",height:"43vh"}}>
                    <img src={MenBike} alt="" style={{width:"100%"}}/>
                </div>
                <div className="px-3 d-grid gap-2 position-fixed w-100" style={{bottom:"5vh"}}>
                <Link to="/pickup-drop">
                    <button className="mobile-add-pickup-btn p-3">
                        Add pickup/drop details
                    </button>
                    </Link>
                </div>
                {/* <Footer active_favourite={true} /> */}
                </React.Fragment>
             )}

             </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AddPickUpAndDrop);
