import React, { Component } from "react";
import { connect } from "react-redux";
// import { TickSquare, ChevronRight, InfoCircle,Discount } from "react-iconly";
import { TbDiscount2 } from 'react-icons/tb'
import { HiChevronRight } from 'react-icons/hi'
import { BsPatchCheckFill } from 'react-icons/bs'
import Ink from "react-ink";
import { Link } from "react-router-dom";
import { BiErrorCircle } from "react-icons/bi";

export class CouponBlock extends Component {
    static contextTypes = {
        router: () => null,
    };
    redirectToCoupon = () => {
        localStorage.setItem('fromCartCoupon', 1)
        localStorage.setItem("store_id", this.props.store_id);
        this.props.history.push("/coupon-list");
    }
    render() {
        const { coupon, coupon_error, store_id } = this.props;
        return (
            <React.Fragment>
                {coupon && coupon.code ? (
                    <div
                        className="d-flex flex-column  align-items-start position-relative border-radius-10 p-3"
                        style={{ backgroundColor: "#fead1d1a" }}
                    >
                        <div className="d-flex align-items-center flex-shrink-1" style={{ color: '#fd8814' }}>
                            <TbDiscount2 size={23} set="light" />
                            <div className="ml-3 fw-bolder text-muted ms-2 fs-4">Used Coupon</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3"
                            style={{ width: "90vw" }} >
                            <div className="d-flex justify-content-between">
                                <BsPatchCheckFill size={20} set="light" color="#00b981" />
                                <div className="ml-3 fs-6 ms-2" style={{ color: '#00b981', fontWeight: '600' }}>
                                    Code {coupon.code} applied !
                                </div>
                            </div>
                            <div className="mr-5">
                                <div onClick={() => this.redirectToCoupon()}
                                    className="mobile-cart-chng"
                                    style={{ position: "relative" }}  >
                                    Try Another
                                    <Ink duration="500" radius={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="d-flex flex-column position-relative border-radius-10 p-3" style={{ backgroundColor: "#fdf5eb" }}>
                            <div onClick={() => this.redirectToCoupon()} >
                                <div className="d-flex flex-row justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center flex-shrink-1" style={{ color: '#fd8814' }}>
                                        <TbDiscount2 size={25} set="bulk" />
                                        <div className="ml-3 fw-bolder fs-6 ms-3">
                                            Use coupons to save more money
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex-shrink-1" style={{ position: "relative" }}>
                                            <HiChevronRight size={20} set="light" color="#fd8814" />
                                            <Ink duration="500" radius={20} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {coupon_error &&
                                <div className="d-flex  align-items-center mt-3" style={{ color: 'red' }} >
                                    <div>
                                        <BiErrorCircle size={25} set="bulk" />
                                    </div>
                                    <div className="ml-3 ms-2 fw-bolder fs-6">
                                        Coupon removed need more subtotal
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <div className='text-center pb-2 mt-3'>
                            <Link to="/my-coupon" style={{ color: "#FF9012", fontSize: "1.2em", fontWeight: "400" }} >
                                <u>View Coupons</u>
                            </Link>
                        </div> */}
                    </>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {

})(CouponBlock)

