import React, { Component } from "react";
import { connect } from "react-redux";
import { HiOutlineChevronLeft } from "react-icons/hi";
import VendorBackButton from "../../Elements/VendorBackButton";
import Loading from "../../Elements/loading";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import {addItemCategory} from '../../../redux/vendor/items/action'

export class VendorAddMenu extends Component {
    state = {
        loading: false,
        category: {},
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { category } = this.state;

        let formData = new FormData();
        formData.append("name", category.name);
        formData.append("description", category.description);
        formData.append("store_id", user.user.store_id);
        formData.append("token", user.data.auth_token);

        this.props.addItemCategory(formData).then((response) => {
            if (response) {
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 500);
                window.location.reload();
                // this.props.history.replace('/vendor-offer');
            }
        });
    };
    handleInput(e, name, index) {
        let ItemCategory = this.state.category;

        ItemCategory[name] = e;
        //// console.log(ItemCategory)
        this.setState({ category: ItemCategory });
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <VendorBackButton title="Item Category" />

                        <div className="px-4  " style={{ paddingTop: "30vw" }}>
                            <span
                                style={{ fontSize: "22px", color: "#FD8814" }}
                            >
                                Add Item Category to your store
                            </span>
                            <p>Enter Item Category Details</p>
                        </div>
                        <div className="px-4">
                            <Form onSubmit={this.onSubmit}>
                                <InputGroup
                                    className="mt-4 coupon-input "
                                    style={{ boxSizing: "border-box" }}
                                >
                                    <FormControl
                                        type="text"
                                        placeholder="Enter Item Category Name"
                                        className="input-coupon"
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.handleInput(value, "name");
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup
                                    className="mt-3 coupon-input"
                                    style={{ boxSizing: "border-box" }}
                                >
                                    <FormControl
                                        as="textarea"
                                        rows={3}
                                        placeholder="Item Category Description"
                                        className="input-coupon"
                                        required
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.handleInput(
                                                value,
                                                "description"
                                            );
                                        }}
                                    />
                                </InputGroup>

                                <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                                    <button
                                        className="Addmenu-button btn btn py-3 w-100 border-0"
                                        type="submit"
                                    >
                                        <b style={{ color: "white" }}>Save</b>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
});

export default connect(mapStateToProps, {addItemCategory})(VendorAddMenu);
