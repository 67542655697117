import { combineReducers } from "redux";
import vendorReducer from "./vendor/user/reducer";
import vendorOrderReducer from '../redux/vendor/order/reducer';
import itemReducer from "./vendor/items/reducer";
import couponReducer from "./vendor/coupon/reducer";
import payoutReducer from "./vendor/payout/reducer";
import cartReducer from "./mobile/cart/reducer";
import userReducer from "./mobile/user/reducer";
import homeReducer from "./mobile/home/reducer";
import cardReducer from "./mobile/scratchCard/reducer";
import mobileItemReducer from "./mobile/item/reducer";
import medicineReducer from './mobile/medicine/reducer';
import mobileCouponReducer from './mobile/mobileCoupon/reducer';
import storeCategoryReducer from './mobile/storeCategory/reducer';
import notficationReducer from './mobile/notifications/reducer'
import singleStoreReducer from './mobile/mobileSingleStore/reducer'
import helperReducer from "./mobile/helper/reducer"
import singleProduct from './mobile/item/reducer'
import orderReducer from './mobile/mobileOrder/reducer';
import anystoreReducer from './mobile/store/reducer'
import totalReducer from './mobile/total/reducer'

import checkOutReducer from './mobile/checkOut/reducer';
import subscriptionReducer from './mobile/subscription/reducer';




export default combineReducers({
    vendor: vendorReducer,
    order: vendorOrderReducer,
    items: itemReducer,
    coupons: couponReducer,
    payouts: payoutReducer,
    cart: cartReducer,
    user: userReducer,
    home: homeReducer,
    cards: cardReducer,
    mobileitems: mobileItemReducer,
    medicine: medicineReducer,
    mobileCoupon: mobileCouponReducer,
    mobilestorecategory: storeCategoryReducer,
    notification: notficationReducer,
    singleStore: singleStoreReducer,
    helper: helperReducer,
    mobileOrder: orderReducer,
    checkout: checkOutReducer,
    anyStore: anystoreReducer,
    total: totalReducer,
    subscription:subscriptionReducer
});
