import React, { Component } from "react";
import { connect } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { FaClinicMedical } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ProgressiveImage from "react-progressive-image";
import Truncate from "react-truncate";
// import { AiFillStar } from "react-icons/ai";
// import Ink from "react-ink";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";

export class TopStore extends Component {
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { allStores } = this.props;
        return (
            <React.Fragment>
                {allStores?.topstores?.length > 0 && (
                    <div className="px-3 mt-3  ">
                        <div className=" py-2 d-flex flex-row align-items-center ">
                            <FaClinicMedical
                                style={{ fontSize: "1.5rem", color: "#FF9A62" }}
                            />
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                }}
                            >
                                Top Stores
                            </span>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <div
                                className="mt-3 main-menu hidden-scroll-bar gap-3 "
                                style={allStores?.topstores?.length > 4 ?
                                    { gridTemplateColumns: `repeat(${allStores?.topstores?.length},1fr)` } :
                                    { gridTemplateColumns: "repeat(4,1fr)" }}>
                                {allStores?.topstores?.map(
                                    (store, index) => (
                                        <Link to={'/single-store/' + store.id}>
                                            <div className="mt-2 position-relative">
                                                {/* <ProgressiveImage
                                                    delay={20}
                                                    src={
                                                        IMAGE_BASE_URL +
                                                        store.image
                                                    }
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                objectFit:
                                                                    "cover",
                                                                backgroundRepeat:
                                                                    "none",
                                                                height: "20vw",
                                                                width: "10vh",
                                                                borderRadius:
                                                                    "10px",
                                                            }}
                                                            className=""
                                                            alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                                {(store?.offer_promotion_text || store?.coupons?.length > 0) &&
                                                    <div className='d-flex justify-content-center position-absolute w-100 px-1' style={{ bottom: '5px' }}>
                                                        <div className='restuarant-near-offer text-center px-2 w-100'>
                                                            {store?.coupons?.length > 0 ? (
                                                                <>
                                                                    {store?.coupons?.map((cp) => (
                                                                        <div className=''>
                                                                            {cp?.description.toUpperCase().substring(0, (cp?.description.length / 20) - 1)}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {store?.offer_promotion_text}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                } */}

                                                <ProgressiveImage delay={20}
                                                        src={IMAGE_BASE_URL + store?.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}   >
                                                        {(src, loading) => (
                                                            <img src={src}
                                                                style={{ objectFit: "cover", backgroundRepeat: "none", height: "22vw", width: "22vw", borderRadius: "10px", }}
                                                                className="" alt="grosav" />
                                                        )}
                                                    </ProgressiveImage>
                                                    {(store?.coupons?.length > 0 || store?.offer_promotion_text) &&
                                                        <div className="popular-tag-content px-1 py-1">
                                                            <div className="popular-tag">
                                                                <p><b>{store?.coupons?.length > 0 &&
                                                                    store?.coupons[0]?.description.length > 0 ? (
                                                                    <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                                                        <Truncate lines={1}>
                                                                            {store?.coupons[0]?.description.toUpperCase()}
                                                                        </Truncate>
                                                                    </div>
                                                                ) : (
                                                                    <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                                                        <Truncate lines={1}>
                                                                            {store?.offer_promotion_text}
                                                                        </Truncate>
                                                                    </div>
                                                                )}</b></p>
                                                            </div>
                                                        </div>
                                                    }
                                            </div>

                                            <div className="d-flex flex-column">
                                                <Truncate lines={1} className="top-res-name fw-700"> {store?.name} </Truncate>
                                                {store?.ratings?.length > 0 ?
                                                    <div>
                                                        <Rating
                                                            size={"1em"}
                                                            className="mobile-rate-star"
                                                            initialValue={this.ratingCount(store?.ratings, store?.ratings.length)}
                                                            readonly="true"
                                                        />
                                                    </div>
                                                    :
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={store?.rating}
                                                        readonly="true"
                                                    />
                                                }
                                                <span className="top-res-deli-time text-muted fs-15">
                                                    <Truncate lines={1}>
                                                        {store?.approx_time_delivery ? store?.approx_time_delivery + " Min - " : ""}{" "}
                                                        {store?.delivery_radius ? store?.delivery_radius + " Km " : ""}
                                                    </Truncate>
                                                </span>
                                            </div>
                                        </Link>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(TopStore);
