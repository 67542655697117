import {
    GET_SINGLE_STORE
} from "./actionType";

import Axios from "axios";
import {
    GET_SINGLE_STORE_URL,
    GET_SINGLE_STORE_URL_V2
} from "../../../api";





// export const getAllFood =(id)=>(dispatch) =>{
//     return Axios.post(GET_ALL_FOOD_URL,{
//         id
//     })
//     .then((response)=>{
//         let food = response.data;
//         return dispatch({
//             type: GET_ALL_FOOD,
//             payload:food
//         })
//     })
// }
// export const getAllMedicines =(id)=>(dispatch) =>{
//     return Axios.post( GET_ALL_MEDICINES_URL,{
//         id
//     })
//     .then((response)=>{
//         let medicines = response.data;
//         return dispatch({
//             type:GET_ALL_MEDICINES,
//             payload:medicines
//         })
//     })
// }

// export const getAllGrocery =(id)=>(dispatch) =>{
//     return Axios.post( GET_MOST_GROCERY_URL,{
//         id
//     })
//     .then((response)=>{
//         let grocery = response.data;
//         return dispatch({
//             type:GET_ALL_GROCERY,
//             payload:grocery
//         })
//     })
// }

export const getSingleStore =(id)=>(dispatch)=>{
    return Axios.post( GET_SINGLE_STORE_URL_V2,{
        id:id
    })
    .then((response)=>{
        let store = response.data;
        return dispatch({
            type:GET_SINGLE_STORE,
            payload:store
        })
    })
}
