import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import VendorBackButton from '../../Elements/VendorBackButton'
import img from '../../../assets/images/arrow-icon.png';
import Loading from '../../Elements/loading'
import { getUserStore } from '../../../redux/vendor/user/action'


export class VendorOutletInfo extends Component {
    state = {
        loading: false,
        store: ''

    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, store: response.payload.store })
                //// console.log(response.payload.store)
            } else {
                //// console.log(response, 'API error')
            }
        })
    }

    render() {
        const { store } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <div className=''>
                            <VendorBackButton title="Outlet Info" />
                        </div>

                        <div className="px-4 pb-3 outlet-header" style={{paddingTop:'25vw'}}>
                            <div className='mt-2'>
                                <span>
                                    {store.name}
                                </span>
                            </div>
                            <div className="outlet-place" >
                                <span>
                                    {store.address}
                                </span>
                            </div>
                        </div>

                        <div className="px-3 py-4 outlet-info-container">
                            <Link
                                to={{
                                    pathname: "/vendor-account",
                                    state: { status: "account" } // your data array of objects
                                }}
                            >
                                <div className="outlet-link py-3 px-3 mt-2 d-flex justify-content-between">
                                    <span className="">
                                        Account
                                    </span>
                                    <img src={img} alt="" height={16} width={8} />
                                </div>
                            </Link>
                            <Link
                                to={{
                                    pathname: "/vendor-account",
                                    state: { status: "finance" }, // your data array of objects
                                }}
                            >
                                <div className="outlet-link py-3 px-3 mt-3  d-flex justify-content-between">
                                    <span className="">
                                        Finance
                                    </span>
                                    <img src={img} alt="" height={16} width={8} />
                                </div>
                            </Link>
                            {/* <Link to="/vendor-store-users">
                                <div className="outlet-link py-3 px-3 mt-3  d-flex justify-content-between">
                                    <span className="">
                                        Users
                                    </span>
                                    <img src={img} alt="" height={16} width={8} />
                                </div>
                            </Link> */}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user
})
export default connect(mapStateToProps, {
    getUserStore
})(VendorOutletInfo)
