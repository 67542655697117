import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from '../../Elements/MobileBackButton'
import editlocation from "../../../assets/images/location.png"

export class EditLocation extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="mobile-ntfcn-head py-4">
                    <div className="pt-3 px-1">
                        <MobileBackButton />
                    </div>
                    <div className="mt-5 ms-4">
                        <span className="">Shipping</span>
                    </div>
                </div>

                <div className="p-2 d-flex flex-column align-items-center">
                <div className="mobile-card p-2 d-flex flex-column">
                    <div style={{marginLeft:'20px' }}>
                        <p style={{color:'#3B3B3B'}} className="text-muted">Order location</p>
                    </div>
                    
                    
                    <div className="mobile-location d-flex flex-row" style={{marginLeft:'20px'}}>     
                    <img src={editlocation} className="loc" alt="EditLocation" />
                    <span style={{marginLeft:'10px'}} className="d-flex flex-column"><h6 style={{marginBottom:'0px'}}>Deepak</h6> <h6 style={{marginBottom:'0px',fontSize:'12px'}}>East road</h6></span>
                    </div>
                </div>
                
                <div className="mobile-card1 p-2 d-flex flex-column">
                    <div style={{marginLeft:'20px' }}>
                        <p style={{color:'#3B3B3B'}} className="text-muted">Deliver To</p>
                    </div>

                    <div className="mobile-location d-flex flex-row" style={{marginLeft:'20px'}}>     
                    <img src={editlocation} className="loc" alt="EditLocation" />
                    <span style={{marginLeft:'10px'}} className="d-flex flex-column"><h6 style={{marginBottom:'0px'}}>Hafil mohammed</h6> <h6 style={{marginBottom:'0px',fontSize:'12px'}}>Westcort</h6></span>
                    </div>
                    <span><h6 style={{marginleft:'30px',paddingLeft:'60px',marginTop:'20px',color:'#FE8F22'}}>set location</h6></span>
                </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EditLocation);
