import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../../api/index';
import { Link } from 'react-router-dom';
import { getAllSliders } from '../../../../redux/mobile/home/action'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export class TopOffersForYou extends Component {
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getAllSliders(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
    }
    render() {
        const { sliders } = this.props
        // ////// console.log(sliders,'sliderr')
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            speed: 400,
            autoplaySpeed: 8000,
            cssEase: "linear",
            customPaging: function (i) {
              return <div className="dot"></div>;
            },
            dotsClass: "slick-dots slick-thumb"
          };
        return (
            <React.Fragment>
                {sliders && sliders.data && sliders.data.length > 0 ?
                    <div className='pt-3'>
                        <div>
                            <h3 className='mobile-home-top-offer px-3'>Top Offers For You</h3>
                        </div>

                        <Slider {...settings} className="px-3">
                            {sliders?.data?.map((slider, key) =>
                                <div key={slider?.id} className='position-relative w-100 my-3'  >

                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + slider.image}
                                        placeholder={PLACE_HOLDER_IMAGE}
                                    >
                                        {(src, loading) => (
                                            <img
                                                className="mobile-home-offer-img" style={{
                                                    objectFit: 'cover'
                                                }}
                                                src={src}
                                                alt='main-item'
                                            />
                                        )}

                                    </ProgressiveImage>
                                    <Link to={"/top-offer/slider/" + slider.id} >
                                        <span className='position-absolute grab-back px-1' style={{ bottom: '11%', left: '15px', color: '#fff' }}>
                                            Grab Now
                                            <HiOutlineArrowNarrowRight />
                                        </span>
                                    </Link>
                                </div>
                            )}
                        </Slider>

                    </div>
                    :
                    ""
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    sliders: state.home.sliders,
})



export default connect(mapStateToProps, { getAllSliders })(TopOffersForYou)
