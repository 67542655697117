import React, { Component } from "react";
import { connect } from "react-redux";
import successtick from "../../../../assets/images/successtick.png";
import cancel from "../../../../assets/images/cancel.png";
import ContentLoader from "react-content-loader";
import { courierStatus } from "../../../../redux/mobile/mobileOrder/action";
import { Link } from "react-router-dom"
class CourierSuccess extends Component {
    state = {
        loading: false,
        message: '',

    };
    componentDidMount() {
        let message = localStorage.getItem("courierErrorMessage")
        this.setState({ message: message, loading: true });
        const { user } = this.props;
        this.props.courierStatus(user?.data?.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false });
                setTimeout(() => {
                    window.location.replace('/running-orders/couriers')
                }, 3000);
            }
        })
    }
    render() {
        const { courier_status } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader speed={1} viewBox="0 0 280 576" backgroundColor={"#F3F3F3"} foregroundColor={"#FFFFFF"} >
                            <circle cx="140" cy="230" r="50" />
                            <rect x="90" y="290" rx="" ry="" width="100" height="18" />
                            <rect x="22" y="320" rx="" ry="" width="230" height="18" />
                            <rect x="90" y="350" rx="10" ry="10" width="100" height="18" />
                            <rect x="13" y="510" rx="10" width="250" height="30" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className="mobile-subs-success-bg"></div>
                            <div className="d-flex justify-content-center mobile-subs-success-img">
                                {courier_status?.courier?.courier_status_id == 1 ? (
                                    <img src={successtick} alt="Success" />
                                ) : courier_status?.courier?.courier_status_id == 3 ? (
                                    <img src={cancel} alt="Success" />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="d-flex justify-content-center mobile-subs-success-head mt-5">
                                {courier_status?.courier?.courier_status_id == 1 ? (
                                    <span>Congrats!</span>
                                ) : courier_status?.courier?.courier_status_id == 3 ? (
                                    <span>Sorry, {this.state.message}</span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="mobile-subs-success-text px-4 mt-3">
                                <div>
                                    {courier_status?.courier?.courier_status_id == 1 ? (
                                        <span>
                                            Your Request is Accepted
                                        </span>
                                    ) : courier_status?.courier?.courier_status_id == 3 ? (
                                        <span>Your Request is Failed</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-center mobile-order-success-button">
                                <button className="py-1 px-2 mobile-order-success-btn">
                                    Scratch Card Earned
                                </button>
                            </div> */}
                            {/* <Link to="/home">
                                <div className="d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn">
                                    <button className="p-3 mobile-subs-success-button">
                                        Back to Home
                                    </button>
                                </div>
                            </Link> */}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    courier_status: state.mobileOrder.courierStatus,
});

export default connect(mapStateToProps, {
    courierStatus
})(CourierSuccess);
