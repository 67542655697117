import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaAppleAlt } from 'react-icons/fa';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import { getStoreCategoryTopDeals } from '../../../../redux/mobile/storeCategory/action'
import { Link } from 'react-router-dom';
import { TbDiscount2 } from 'react-icons/tb';

export class GroceryTopDeals extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getStoreCategoryTopDeals(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
    }
    render() {
        const { topdeals } = this.props;
        return (
            <React.Fragment>
                {topdeals && topdeals?.data?.length > 0 && this.state.loading == false ? (
                    <div className='px-3 mt-3  '>
                        <TbDiscount2 className='mb-2' color='FF9A62' size={20} /> <span className='top-res-header'>Top Deals</span>
                        <div className='d-flex flex-row align-items-center'>
                            <div className=' main-menu hidden-scroll-bar gap-3 '
                                style={topdeals?.data?.length > 5 ?
                                    { gridTemplateColumns: 'repeat(' + topdeals?.data?.length + ',1fr)' } :
                                    { gridTemplateColumns: 'repeat(5,1fr)' }}>
                                {topdeals?.data?.map((deal) =>
                                    <Link to={'/top-offer/deal/' + deal.id}>
                                        <div className='mt-2 position-relative'>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + deal.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "35vw",
                                                            height: "43vw",
                                                            borderRadius: '10px',
                                                            objectFit: "cover",
                                                            backgroundRepeat: "round",
                                                        }}
                                                        className=""
                                                        alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    topdeals: state.mobilestorecategory.topdeals,
})

export default connect(mapStateToProps, { getStoreCategoryTopDeals })(GroceryTopDeals)
