import {
    GET_VENDOR_SINGLE_ORDER_URL,
    ACCEPT_VENDOR_ORDER_URL,
    DECLINE_VENDOR_ORDER_URL,
    GET_ALL_PAST_ORDERS_URL,
    GET_ORDER_PRESCRIPTION_URL,
    PREPARE_VENDOR_ORDER_URL,
    PICKUP_VENDOR_ORDER_URL,
    COMPELETE_PRESCRIPTION_ORDER_NEW_URL,
    COMPELETE_PRESCRIPTION_ORDER_URL
} from "../../../api";
import {
    GET_VENDOR_SINGLE_ORDER,
    ACCEPT_VENDOR_ORDER,
    DECLINE_VENDOR_ORDER,
    GET_ALL_PAST_ORDERS,
    GET_ORDER_PRESCRIPTION,
    PREPARE_VENDOR_ORDER,
    PICKUP_VENDOR_ORDER,
    ADD_PRODUCT,
    PRODUCT_STORE,
    REMOVE_PRODUCT,
    UPDATE_CART,
    GET_PRESCRIPTION_IMAGES,
    COMPELETE_PRESCRIPTION_ORDER,
    COMPELETE_PRESCRIPTION_ORDER_NEW
} from "./actionType";
import Axios from "axios";
import { formatPrice } from "../../../components/Helpers/formatPrice";

export const getVendorSingleOrder = (token, order_id) => (dispatch) => {
    return Axios.post(GET_VENDOR_SINGLE_ORDER_URL, {
        token,
        order_id,
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: GET_VENDOR_SINGLE_ORDER, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const acceptVendorOrder = (token, order_id, check) => (dispatch) => {
    return Axios.post(ACCEPT_VENDOR_ORDER_URL, {
        token,
        order_id,
        check,
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: ACCEPT_VENDOR_ORDER, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });

};

export const prepareVendorOrder = (token, order_id) => (dispatch) => {
    return Axios.post(PREPARE_VENDOR_ORDER_URL, {
        token,
        order_id,
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: PREPARE_VENDOR_ORDER, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });

};
export const pickupVendorOrder = (token, order_id) => (dispatch) => {
    return Axios.post(PICKUP_VENDOR_ORDER_URL, {
        token,
        order_id,
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: PICKUP_VENDOR_ORDER, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });

};
export const getPastOrders = (token, store_id) => (dispatch) => {
    return Axios.post(GET_ALL_PAST_ORDERS_URL, {
        token,
        store_id,
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: GET_ALL_PAST_ORDERS, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const declineVendorOrder = (token, order_id, reason) => (dispatch) => {
    return Axios.post(DECLINE_VENDOR_ORDER_URL, {
        token,
        order_id,
        reason
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: DECLINE_VENDOR_ORDER, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });

};

export const getOrderPrescription = (token, order_id, store_id) => (dispatch, getState) => {
    return Axios.post(GET_ORDER_PRESCRIPTION_URL, {
        token,
        order_id, store_id,
    })
        .then((response) => {
            const order = response.data;
            if (order.success) {
                const state = getState();
                state.order.products = [];
                clearCart();
                order.items.map((item) => {
                    if (order.order.orderitems.find((cp) => (cp.item_id === item.id))) {
                        item.quantity = order.order.orderitems.find((cp) => (cp.item_id === item.id)).quantity;
                        // console.log(item)
                        if (item) {
                            state.order.products.push(item)
                            const vendorProducts = state.order.products;
                            dispatch(updateCart(vendorProducts));
                        }
                    }
                })
                return dispatch({ type: GET_ORDER_PRESCRIPTION, payload: order });
            } else {
                return dispatch({ type: GET_ORDER_PRESCRIPTION, payload: order });
            }
        })
        .catch(function (error) {
            // console.log(error);
        });

};

export const compeletePrescriptionOrder = (token, products, order_id, total, store_id) => (dispatch, getState) => {
    return Axios.post(COMPELETE_PRESCRIPTION_ORDER_URL, {
        token, products, order_id, total, store_id
    })
        .then((response) => {
            const order = response.data;
            if (order.success) {
                // console.log(order)
                const state = getState();
                const vendorProducts = state.order.products;
                for (let i = vendorProducts.length - 1; i >= 0; i--) {
                    vendorProducts.splice(i, 1);
                }
                dispatch(updateCart(vendorProducts));
                clearCart();
                return dispatch({ type: COMPELETE_PRESCRIPTION_ORDER, payload: order });;
            } else {
                return dispatch({ type: COMPELETE_PRESCRIPTION_ORDER, payload: order });;
            }
        })
        .catch(function (error) {
            // console.log(error);
        });

};

export const compeletePrescriptionOrderNew = (token, medicines, total, order_id, store_id) => (dispatch) => {
    return Axios.post(COMPELETE_PRESCRIPTION_ORDER_NEW_URL, {
        token,
        medicines,
        total,
        order_id,
        store_id
    })
        .then((response) => {
            const order = response.data;
            return dispatch({ type: COMPELETE_PRESCRIPTION_ORDER_NEW, payload: order });
        })
        .catch(function (error) {
            // console.log(error);
        });

};



export const addProduct = (product) => (dispatch) => {
    dispatch({ type: ADD_PRODUCT, payload: product });
    dispatch({ type: PRODUCT_STORE, payload: product.store });
};


export const removeProduct = (product) => (dispatch) => {
    return dispatch({ type: REMOVE_PRODUCT, payload: product });
};
// export const saveDeliveryCharge = (charge) => (dispatch) => {
//     return dispatch({ type: SAVE_DELIVERY_CHARGE, payload: charge });
// };

export const updateCart = (orderProducts) => (dispatch) => {
    let productQuantity = orderProducts.reduce((sum, p) => {
        // sum += p.quantity;
        sum++;
        return sum;
    }, 0);
    let totalProductPrice = orderProducts.reduce((sum, p) => {
        if (p.deal_price > 0) {
            sum += p.deal_price * p.quantity;
            sum = parseFloat(sum);
            formatPrice(sum);
            let totalAddonPrice = 0
            if (p.selectedaddons && p.selectedaddons.length > 0) {
                totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, p) => {
                    totalAddonPrice += parseFloat(p.price);
                    totalAddonPrice = parseFloat(totalAddonPrice) * p.quantity;
                    return totalAddonPrice
                }, 0);
            }
            return sum + totalAddonPrice;
        } else {
            sum += p.selling_price * p.quantity;
            sum = parseFloat(sum);
            formatPrice(sum);
            let totalAddonPrice = 0
            if (p.selectedaddons && p.selectedaddons.length > 0) {
                totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, ap) => {
                    totalAddonPrice += parseFloat(ap.price * p.quantity);
                    // console.log(totalAddonPrice, 'totalAddonPrice', p.quantity)
                    totalAddonPrice = parseFloat(totalAddonPrice);
                    return totalAddonPrice
                }, 0);
            }
            // console.log(totalAddonPrice, sum, 'add')
            return sum + totalAddonPrice;
        }
    }, 0);

    let totalDiscount = orderProducts.reduce((sum, p) => {
        if (p.deal_price > 0) {
            if (p.selling_price && p.selling_price > p.deal_price) {
                sum += (p.selling_price - p.deal_price) * p.quantity;
            }
            sum = parseFloat(sum);
            formatPrice(sum);
            return sum;
        } else {
            if (p.market_price && p.market_price > p.selling_price) {
                sum += (p.market_price - p.selling_price) * p.quantity;
            }
            sum = parseFloat(sum);
            formatPrice(sum);
            return sum;
        }
    }, 0);

    let cartTotal = {
        productQuantity,
        totalPrice: totalProductPrice,
        totalDiscount,
    };

    dispatch({
        type: UPDATE_CART,
        payload: cartTotal,
    });
};

export const clearCart = () => (dispatch) => {
    let data = [];
    return dispatch({ type: UPDATE_CART, payload: data });
};
