import React, { Component } from 'react'
import { connect } from 'react-redux'
import store from '../../../../assets/images/homestore.png'
import courier from '../../../../assets/images/homecourier.png'
import plan from '../../../../assets/images/plan.png'
import homelike from '../../../../assets/images/homelike.png'
import scratch from '../../../../assets/images/scratch.png'
import { Link } from 'react-router-dom';
// import { getAllItemGroup } from '../../../../redux/mobile/home/action'
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL } from '../../../../api'
import { GoHeart } from 'react-icons/go'
import { MdKeyboardDoubleArrowRight, MdOutlineAddReaction } from "react-icons/md";

export class HomeCategory extends Component {
    state = {
        loading: true,
    };
    // componentDidMount() {
    //     this.props.getAllItemGroup();
    // }
    render() {
        const { user, item_group } = this.props;
        return (
            <React.Fragment>
                <div className='px-3 py-3 mobile-btm-brdr'>
                    {localStorage.getItem('referrer_register') !== undefined && localStorage.getItem('referrer_register') > 0 &&
                        <Link to={user && user.success ? '/refer-and-earn' : '/'}>
                            <div className='d-flex justify-content-between align-items-center text-center px-2 py-1 mb-3' style={{ backgroundColor: '#fff', border: '2px solid #0b2da0', color: '#0b2da0', borderRadius: '15px', fontSize: '17px' }}>
                                <div className='fw-700'>
                                    <MdOutlineAddReaction size={20} />
                                </div>
                                <div className='ps-2 flex-grow-1 fw-700'>
                                    Invite Others & Earn <span style={{ color: '#ff4b4b', whiteSpace: 'nowrap' }}>Rs {localStorage.getItem('referrer_register')}</span>
                                </div>
                                <div>
                                    <span style={{ backgroundColor: '#000', color: '#fff', borderRadius: '8px', fontSize: '10px', paddingLeft: '8px', paddingRight: '8px', paddingTop: '2px', paddingBottom: '1px', whiteSpace: 'nowrap' }}>
                                        Know More
                                    </span>
                                </div>
                            </div>
                        </Link>
                    }
                    {user?.scratchCards?.length > 0 ?
                        <Link to='/my-rewards'>
                            <div className='d-flex justify-content-between align-items-center ps-3 pe-2 py-1 mb-3' style={{ backgroundColor: '#f3d136', color: '#000', borderRadius: '8px', fontSize: '12px' }}>
                                <div className='fw-700'>
                                    YOU HAVE A SCRATCH CARD WAITING
                                </div>
                                <div>
                                    <MdKeyboardDoubleArrowRight size={25} />
                                </div>
                            </div>
                        </Link>
                        : null
                    }

                    <div className='mobile-home-category'>Categories </div>
                    <div className="d-grid gap-3 pt-2 hidden-scroll-bar"
                        style={{ gridTemplateColumns: `repeat(5,1fr)`, overflowX: "scroll" }}    >
                        <Link to='/any-store'>
                            <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                    <img src={store} alt="Success" style={{ width: '50%' }} />
                                    <span className='pt-2'>Any Store</span>
                                </div>
                            </div>
                        </Link>
                        <Link to='/courier'>
                            <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                    <img src={courier} alt="Success" style={{ width: '50%' }} />
                                    <span className='pt-2'>Courier</span>
                                </div>
                            </div>
                        </Link>
                        {/* {user && user.success ? (
                            <Link to='/liked-items'>
                                <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                    <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                        <GoHeart size={'3.8em'} color={'#FF8080'} className='pt-1' />
                                        <img src={homelike} alt="Success" style={{ width: '50%' }} />
                                        <span className='pt-1'>Liked Items</span>
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            <Link to="/signup">
                                <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                    <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                        <img src={homelike} alt="Success" style={{ width: '50%' }} />
                                        <GoHeart size={'3.8em'} color={'#FF8080'} className='pt-1' />
                                        <span className='pt-1'>Liked Items</span>
                                    </div>
                                </div>
                            </Link>
                        )} */}

                        {localStorage.getItem('scratch_card') !== undefined && localStorage.getItem('scratch_card') == 1 &&
                            <>
                                {user && user.success ? (
                                    <Link to='/my-rewards'>
                                        <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                            <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                                <img src={scratch} alt="Success" style={{ width: '55%' }} />
                                                <span className='pt-1'>Scratch Card</span>
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <Link to="/signup">
                                        <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                            <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                                <img src={scratch} alt="Success" style={{ width: '55%' }} />
                                                <span className='pt-1'>Scratch</span>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </>
                        }

                        {localStorage.getItem('subscription') !== undefined && localStorage.getItem('subscription') == 1 &&
                            <>
                                {user && user.success ? (
                                    <Link to='/subscription-plans'>
                                        <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                            <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                                <img src={plan} alt="Success" style={{ width: '50%' }} />
                                                <span className='pt-2'>Subscription</span>
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <Link to='/signup'>
                                        <div className='mobile-home-category-div text-dark d-flex align-items-center'>
                                            <div className='flex-grow-1 d-flex flex-column justify-content-around align-items-center '>
                                                <img src={plan} alt="Success" style={{ width: '50%' }} />
                                                <span className='pt-2'>Subscription</span>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </>
                        }
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    // item_group: state.home.item_group
})
export default connect(mapStateToProps, {
    // getAllItemGroup
})(HomeCategory)
