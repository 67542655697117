import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IoStorefrontSharp } from 'react-icons/io5';
import ProgressiveImage from 'react-progressive-image';
// import Ink from "react-ink";
import Truncate from 'react-truncate';
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import { getStoreCategoryNearStores } from '../../../../redux/mobile/storeCategory/action'


export class StoreNearYou extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getStoreCategoryNearStores(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
        // ////// console.log(JSON.parse(localStorage?.getItem("userSetAddress")).lng)
    }
    render() {
        const { stores } = this.props;
        return (
            <React.Fragment>
                {stores && stores?.data?.length > 0 && this.state.loading == false ? (
                    <>
                        <div className='px-3 mt-3  '>
                            <IoStorefrontSharp className='mb-2' color='FF9A62' size={20} />{" "}
                            <span className='top-res-header'>Store Near You</span>
                            <div className='d-flex flex-row align-items-center'>
                                <div className='mt-1 main-menu hidden-scroll-bar gap-3 '
                                    style={stores?.data?.length > 5 ?
                                        { gridTemplateColumns: 'repeat(' + stores?.data?.length + ',1fr)' } :
                                        { gridTemplateColumns: 'repeat(6,1fr)' }}>
                                    {stores?.data?.map((store) =>
                                        <div>
                                            <Link to={"/single-store/" + store.id}>
                                                <div className='mt-2 position-relative'>
                                                    <ProgressiveImage
                                                        delay={20}
                                                        src={IMAGE_BASE_URL + store.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                    >
                                                        {(src, loading) => (
                                                            <img
                                                                src={src}
                                                                style={{
                                                                    objectFit: "cover",
                                                                    backgroundRepeat: 'none',
                                                                    height: '20vw',
                                                                    width: '10vh',
                                                                    borderRadius: '10px'
                                                                }}
                                                                className=""
                                                                alt="grosav"
                                                            />
                                                        )}
                                                    </ProgressiveImage>
                                                    {/* <div className='d-flex justify-content-center position-absolute w-100' style={{ bottom: '5px' }}>
                                                    <Ink className="touch-ink " />
                                                    <span className='restuarant-near-offer text-center px-2'>20 % off</span>
                                                </div> */}
                                                </div>
                                                <div className='top-res-name text-center'>
                                                    <Truncate lines={1}>
                                                        <span>
                                                            {store.name}
                                                        </span>
                                                    </Truncate>

                                                    <div className='d-inline-block'>
                                                        <div>
                                                            <span className='top-res-deli-time text-muted '>
                                                                {store.approx_time_delivery > 0 ? store.approx_time_delivery + "Mins" : "0Min"}
                                                                {store.delivery_radius > 0 ? "- " + store.delivery_radius + "km" : "- 0km"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    stores: state.mobilestorecategory.nearstores,
})

export default connect(mapStateToProps, { getStoreCategoryNearStores })(StoreNearYou)
