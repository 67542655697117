import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from "../../Elements/MobileBackButton";
import ContentLoader from "react-content-loader";
import { getsingleProduct, toggleFavItem } from '../../../redux/mobile/item/action'
import { addProduct } from '../../../redux/mobile/cart/action'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../api";
import ProgressiveImage from "react-progressive-image";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import { GiAlarmClock } from 'react-icons/gi'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { Link } from "react-router-dom";
import Customization from '../Customization';
import Ink from 'react-ink'
import FloatCart from '../Cart/FloatCart'
import Countdown, { zeroPad } from "react-countdown";
import moment from 'moment'
import { TbLocationOff } from "react-icons/tb";
import ComboCustomization from "../ComboCustomization";
import discount from "../../../assets/images/discount.png";
import { Rating } from "react-simple-star-rating";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MdOutlineStar, MdRateReview } from "react-icons/md";

export class Product extends Component {
    state = {
        count: 0,
        loading: false,
        item: [],
        float: false,
        isRunning: false,
        availableItem: "",
        coupons: [],

    };
    componentDidMount() {
        this.setState({ loading: true });
        this.props.getsingleProduct(this.props.match.params.id, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, item: response.payload.data, availableItem: response.payload.availableItem, coupons: response.payload.coupons });
            }

        });
    }
    toggleFavItem = (item) => {
        const { user } = this.props;
        const favUser = item.favorites.find((fav) => fav.user_id === user.data.id)
        if (favUser) {
            const favIndex = item.favorites.indexOf(favUser);
            item.favorites.splice(favIndex, 1);
            this.setState({ item: item })
        } else {
            item.favorites.push({ user_id: user.data.id });
            this.setState({ item: item })
        }
        this.props.toggleFavItem(this.props.user.data.auth_token, item.id)
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    floatCart = (on) => {
        this.setState({ float: on })
    }
    getDay = (start) => {
        let dayNow = moment().format("DD-MM-YYYY");
        let dayDate = moment(start).format("DD-MM-YYYY");
        let day = moment(dayDate, "DD-MM-YYYY").diff(moment(dayNow, "DD-MM-YYYY"), 'days');
        return day;

    }
    getTime = (start) => {
        // let timeNow = moment().format("HH:mm:ss a");
        // let startDate = moment(start).format("HH:mm:ss a");
        // let hours = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("hh");
        // let minutes = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("mm");
        // let seconds = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("ss");
        // let result = hours * 3600000 + minutes * 60000 + seconds * 1000;
        const createdAt = start; // Replace this with your deal end date at date string
        const currentDate = new Date(); // This will set the end time to the current time
        const diff = currentDate.getTime() - new Date(createdAt).getTime(); // Calculate the duration in milliseconds
        const duration = Math.max(0, 24 * 60 * 60 * 1000 - diff); // Calculate the remaining duration in milliseconds (24 hours in this example)
        return duration;

    }

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })

        total = total / (count ? count : 1);
        total = Math.round(total);

        return total;
    }

    render() {
        const timeRenderer = ({ days, hours, minutes, seconds }) => {
            return (
                <div className='d-flex align-items-center justify-content-center' style={{ color: '#32BA7C', fontWeight: '600' }}>
                    <span>
                        {zeroPad(hours)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(minutes)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(seconds)}
                    </span>
                </div>
            );
        }
        const { user, addProduct, cartProducts } = this.props;
        const { item, coupons } = this.state;

        const createdAt = item?.dealofdays && item?.dealofdays[0]?.end_time; // Replace this with your deal end date at date string
        const currentDate = new Date(); // This will set the end time to the current time
        const diff = currentDate.getTime() - new Date(createdAt).getTime(); // Calculate the duration in milliseconds
        const duration = Math.max(0, 24 * 60 * 60 * 1000 - diff); // Calculate the remaining duration in milliseconds (24 hours in this example)

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="25" y="20" rx="10" ry="10" width="30" height="30" />
                            <rect x="25" y="68" rx="10" ry="10" width="230" height="235" />
                            <rect x="32" y="330" rx="10" ry="10" width="110" height="20" />
                            <rect x="32" y="360" rx="10" ry="10" width="90" height="20" />
                            <rect x="32" y="390" rx="10" ry="10" width="110" height="20" />
                            <rect x="13" y="520" rx="10" ry="10" width="250" height="45" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {item ? (
                            <>
                                <div className="mobile-image px-3" style={cartProducts.length > 0 ? { paddingBottom: '45vw' } : { paddingBottom: '10vw' }}>
                                    <div className="pt-3 d-flex flex-row justify-content-between">
                                        <MobileBackButton history={this.props.history} />
                                    </div>
                                    <div className="mt-5">
                                        <div className="single-product-img d-flex position-relative " >
                                            <ProgressiveImage
                                                delay={20}
                                                src={
                                                    IMAGE_BASE_URL +
                                                    item?.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        className="single-product-img"
                                                        style={{
                                                            objectFit:
                                                                "cover",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            {user && user.success > 0 ? (
                                                <div className="mt-4 me-1 d-flex position-absolute"
                                                    style={{ bottom: '20px', right: '15px' }}
                                                    onClick={() => this.toggleFavItem(item)}>
                                                    {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                        <AiFillHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                    ) : (
                                                        <AiOutlineHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                    )}
                                                </div>
                                            ) : (
                                                <Link to="/signUp">
                                                    <div className="mt-4 me-1 d-flex position-absolute"
                                                        style={{ bottom: '20px', right: '15px' }}>
                                                        <AiOutlineHeart style={{ color: '#FF9012' }} size={'2em'} />
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="px-2 py-2">
                                            <div className='pt-3'>
                                                <div className="d-flex flex-row  justify-content-between">
                                                    <span className="single-item-name-tag">{item?.name}</span>
                                                </div>
                                                {item?.addoncategories?.length > 0 ? (
                                                    <div className="d-flex justify-content-between" >
                                                        <div className="fw-bolder"
                                                            style={{ color: 'grey', fontSize: "16px", }}  >
                                                            Customizable
                                                        </div>
                                                    </div>
                                                ) : ("")}
                                                <>
                                                    {parseInt(item?.selling_price) > 0 ? (
                                                        item?.dealofdays?.length > 0 ?
                                                            <div className='d-flex gap-4 text-center'>
                                                                <span className="single-item-price" style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                    Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                                </span>
                                                                <s style={{ color: "#000", fontSize: "13px", }} >
                                                                    Rs. {item?.selling_price}
                                                                </s>
                                                            </div>
                                                            :
                                                            <span className="single-item-price" style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                Rs. {item?.selling_price}
                                                            </span>
                                                    ) : ("")}
                                                    {parseInt(item?.market_price) > parseInt(item?.selling_price) && parseInt(item?.market_price) !== parseInt(item?.selling_price) && (
                                                        <div className="d-flex flex-row ">
                                                            <div className="" >
                                                                <span className="single-item-mrp">
                                                                    <del>Rs. {item?.market_price}</del>
                                                                </span>
                                                            </div>
                                                            <div className="ms-1" style={{ color: "#32BA7C", fontWeight: "600px", fontSize: "13px", }}  >
                                                                <span>{Math.round(((item?.market_price - item?.selling_price) / item?.market_price) * 100)}% off</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                                {item?.dealofdays && item?.dealofdays?.length > 0 &&
                                                    <div className='d-flex align-items-center text-center' style={{ color: '#32BA7C' }}>
                                                        <div className="pe-2">
                                                            End In
                                                        </div>

                                                        {this.getDay(item.dealofdays[0].end_time) > 0 ? this.getDay(item.dealofdays[0].end_time) + " days" : (
                                                            <Countdown
                                                                style={{ color: '#00ab3a' }}
                                                                date={Date.now() + this.getTime(item.dealofdays[0].end_time)}
                                                                renderer={timeRenderer}
                                                            // onComplete={() => {
                                                            //     checkOrderStatus();
                                                            // }}
                                                            />
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {coupons?.length > 0 ? (
                                            <div className="main-menu hidden-scroll-bar gap-3 mt-3" style={{ gridTemplateColumns: "repeat(" + (coupons?.length + 1) + ",1fr)", }}>
                                                {coupons?.map((coupon) => (
                                                    <div className="d-flex flex-row align-items-center coupon-store py-2 px-2" onClick={() => this.openCoupon(coupon)} >
                                                        <img src={discount} style={{ width: '23px' }} alt="discount" />
                                                        <div className="d-flex flex-shrink-1 flex-column ps-2">
                                                            <span style={{ fontWeight: 600 }} >
                                                                {coupon.discount_type === "FIXED" ? '₹' + coupon.coupon_discount + " Off" : coupon.coupon_discount + '%Off'}
                                                                {
                                                                    coupon.max_discount && coupon.discount_type === "PERCENTAGE" && " Upto ₹" + coupon.max_discount
                                                                }
                                                            </span>
                                                            <span style={{ fontWeight: 600 }} >
                                                                Use{" "} <span style={{ fontWeight: 800 }} >{coupon?.code}</span> | Above ₹{coupon?.min_sub_total}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : ("")}

                                        <div className="mt-3">
                                            <div className=" " >
                                                <HiOutlineMenuAlt2 style={{ color: '#FF9012' }} size={'1.2em'} />
                                                <span style={{
                                                    fontWeight: '600',
                                                    fontSize: '16px'
                                                }} className='ps-1'>Description</span>
                                            </div>

                                            <div className="ps-4" >
                                                {item?.description ?
                                                    <span className="text-muted">
                                                        {item?.description}
                                                    </span>
                                                    :
                                                    <div className="text-muted text-center">
                                                        -No Description-
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {item?.store?.store_category?.id === 2 ? (
                                            ""
                                        ) : (
                                            <div className="mt-3" >
                                                <div className=""
                                                    style={{ fontWeight: "600", fontSize: "16px" }}
                                                >
                                                    <GiAlarmClock style={{ color: '#FF9012' }} size={'1.2em'} />
                                                    <span className="ps-1">Preparation Time</span>{" "}
                                                </div>
                                                <div className="ps-4" >
                                                    <span className="text-muted" >
                                                        {item?.preperation_time && item.data?.preperation_time > 0 ? (
                                                            <>
                                                                {item?.preperation_time}
                                                            </>
                                                        ) : (
                                                            <div className="text-center">-No Preperation Time-</div>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <div className=" " >
                                            <MdRateReview style={{ color: '#FF9012' }} size={'1.2em'} />
                                            <span style={{
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }} className='ps-1'>Reviews</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center gap-3 mt-3 p-4 " style={{ backgroundColor: '#fff', boxShadow: '0px 0px 20px 0px #eee', borderRadius: '12px' }}>
                                        <div className="d-flex flex-column justify-content-between align-items-center">
                                            <div className="fw-bolder" style={{ letterSpacing: '2px', fontSize: '40px' }}>{this.ratingCount(item?.ratings, item?.ratings?.length)}<span className="ms-1 text-muted" style={{ fontSize: '30px' }}>/5</span></div>
                                            <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item?.ratings, item?.ratings?.length)} readonly="true" />
                                            {item?.ratings?.length} {item?.ratings?.length > 1 ? 'Reviews' : 'Review'}
                                        </div>
                                        <div className="flex-grow-1 d-flex flex-column justify-content-between align-items-center ps-3">
                                            <div className='d-flex justify-content-between align-items-center w-100 gap-3'>
                                                <div className="d-flex align-items-center gap-1">
                                                    5 <MdOutlineStar style={{ color: '#ffbc0b' }} />
                                                </div>
                                                <ProgressBar className='w-100' variant="warning" now={item?.ratings?.filter((cp) => cp.rating == 5)?.length} />
                                                <div>{item?.ratings?.filter((cp) => cp.rating == 5)?.length}</div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center w-100 gap-3'>
                                                <div className="d-flex align-items-center gap-1">
                                                    4 <MdOutlineStar style={{ color: '#ffbc0b' }} />
                                                </div>
                                                <ProgressBar className='w-100' variant="warning" now={item?.ratings?.filter((cp) => cp.rating == 4)?.length} />
                                                <div>{item?.ratings?.filter((cp) => cp.rating == 4)?.length}</div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center w-100 gap-3'>
                                                <div className="d-flex align-items-center gap-1">
                                                    3 <MdOutlineStar style={{ color: '#ffbc0b' }} />
                                                </div>
                                                <ProgressBar className='w-100' variant="warning" now={item?.ratings?.filter((cp) => cp.rating == 3)?.length} />
                                                <div>{item?.ratings?.filter((cp) => cp.rating == 3)?.length}</div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center w-100 gap-3'>
                                                <div className="d-flex align-items-center gap-1">
                                                    2 <MdOutlineStar style={{ color: '#ffbc0b' }} />
                                                </div>
                                                <ProgressBar className='w-100' variant="warning" now={item?.ratings?.filter((cp) => cp.rating == 2)?.length} />
                                                <div>{item?.ratings?.filter((cp) => cp.rating == 2)?.length}</div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center w-100 gap-3'>
                                                <div className="d-flex align-items-center gap-1">
                                                    1 <MdOutlineStar style={{ color: '#ffbc0b' }} />
                                                </div>
                                                <ProgressBar className='w-100' variant="warning" now={item?.ratings?.filter((cp) => cp.rating == 1)?.length} />
                                                <div>{item?.ratings?.filter((cp) => cp.rating == 1)?.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {item?.ratings?.length > 0 && item?.ratings?.map((rating) => (
                                        <div className="d-flex justify-content-between gap-3 p-3 mt-3" style={{ backgroundColor: '#fff', boxShadow: '0px 0px 20px 0px #eee', borderRadius: '12px' }}>
                                            <div className="d-flex flex-column text-center fw-bolder" style={{ fontSize: '16px' }}>
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={
                                                        IMAGE_BASE_URL + rating?.user?.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            className="single-product-img"
                                                            style={{
                                                                width: '20vw',
                                                                height: '20vw',
                                                                objectFit: "cover",
                                                            }}
                                                            src={src}
                                                            alt="main-item"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                                {rating?.user?.name}
                                            </div>
                                            <div className="flex-grow-1 ">
                                                <div className="d-flex justify-content-between">
                                                    <Rating size={"1em"} className="mobile-rate-star" initialValue={rating.rating} readonly="true" />
                                                    <div style={{ fontSize: '12px' }}>
                                                        {moment(rating?.created_at).format('lll')}
                                                    </div>
                                                </div>
                                                {rating?.review ?
                                                    <div className="fw-bolder" style={{ fontSize: '16px' }}>
                                                        {rating?.review}
                                                    </div>
                                                    :
                                                    <div className="text-muted text-center" style={{ fontSize: '15px' }}>
                                                       -No Feedback-
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>

                        ) : ("")}
                        {this.state.availableItem ?
                            user && user.success > 0 ? item?.is_active ?
                                (item?.store?.store_category?.id == 1 ? 1 == 1 : item.stock > 0) ?
                                    item?.is_combo && item?.combo_categories?.length > 0 ? (
                                        <div className="position-fixed bottom-0 w-100 py-2" style={{ backgroundColor: '#fff' }}>
                                            <ComboCustomization
                                                floatCart={(e) => this.floatCart(e)}
                                                product={item}
                                                addProduct={addProduct}
                                                forceUpdate={this.forceStateUpdate}
                                                singleBtn={true}
                                            />
                                        </div>
                                    ) : item?.addoncategories?.length > 0 ? (
                                        <div className="position-fixed bottom-0 w-100 py-2" style={{ backgroundColor: '#fff' }}>
                                            <Customization
                                                floatCart={(e) => this.floatCart(e)}
                                                product={item}
                                                addProduct={addProduct}
                                                forceUpdate={this.forceStateUpdate}
                                                singleBtn={true} />
                                        </div>
                                    ) : (
                                        <div className="position-fixed bottom-0 w-100 px-3 py-2 " style={{ backgroundColor: '#fff' }}>
                                            {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                <Link to="/cart">
                                                    <button className="text-center w-100 add-button-meals py-3 px-2">
                                                        Go to Cart
                                                        {/* <Ink duration={500} style={{ color: "#aaa" }} /> */}
                                                    </button>
                                                </Link>
                                            ) : (
                                                <button
                                                    className="text-center w-100 add-button-meals py-3 px-2"
                                                    onClick={() => {
                                                        item.quantity = 1;
                                                        addProduct(item);
                                                        this.forceStateUpdate();
                                                    }} >
                                                    Add to Cart
                                                    {/* <Ink duration={500} style={{ color: "#aaa" }} /> */}
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="px-3 bottom-0 position-fixed w-100 pb-3">
                                            <button className="delete-button-meals w-100 py-3">
                                                No Stock
                                                <Ink duration={500} style={{ color: "#aaa" }} />
                                            </button>
                                        </div>
                                    ) : (
                                    <div className="px-3 bottom-0 position-fixed w-100 pb-3">
                                        <button className="delete-button-meals w-100 py-3">
                                            Item Not Available
                                            <Ink duration={500} style={{ color: "#aaa" }} />
                                        </button>
                                    </div>
                                ) : (
                                <Link to="/signUp">
                                    <div className="px-3 bottom-0 position-fixed w-100 pb-3">
                                        <button className="single-product-cart-btn py-3">
                                            Add To Cart
                                        </button>
                                    </div>
                                </Link>
                            )
                            :
                            <div className="position-fixed bottom-0 w-100">
                                <div className="w-100 px-3 pb-3 py-2 header-body bottom-0 " style={{ paddingBottom: '60vw', boxShadow: "0px -5px 10px 5px #00000015", borderRadius: "14px 14px 0px 0px", backgroundColor: '#fff', zIndex: '9999' }} >
                                    <div className="d-flex flex-column justify-content-between align-items-center w-100">
                                        <div className="d-flex flex-row align-items-center w-100" >
                                            <TbLocationOff color="#ff9012" size={45} />
                                            <span className="ps-2 pt-2" style={{ fontSize: "1em", fontWeight: '650' }}> We are not currently available at this location.
                                                Please try changing your location</span>
                                        </div>
                                        <Link to="/my-address" className="w-100" >
                                            <div className="text-center w-100 py-3 px-3 cart-no-address mt-3" style={{ boxShadow: "0px -5px 10px 5px #00000015", fontSize: "1.2em" }}  >
                                                Change Location
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }

                        <FloatCart float={this.state.float} isRunning={this.state.isRunning} singleProduct={true} />
                    </React.Fragment>
                )
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    item: state.mobileitems.singleItem,
    user: state.user.user,
    cartProducts: state.cart.products
});

export default connect(mapStateToProps, {
    getsingleProduct, toggleFavItem, addProduct
})(Product);
