import {
    APPLY_COUPON,
    COUPON_ERROR,
    AUTO_APPLY_COUPON,
    GET_USER_OFFERS,
    GET_MY_COUPON
} from "./actionTypes";
import {
    APPLY_COUPON_URL,
    AUTO_APPLY_COUPON_URL,
    GET_USER_OFFERS_URL,
    GET_MY_COUPON_URL

} from "../../../api/index";
import Axios from "axios";


export const getUserOffers = (token, store_id,item_ids) => (dispatch) => {

    return Axios.post(GET_USER_OFFERS_URL, {
        token, store_id,item_ids
    })
        .then((response) => {
            const coupons = response.data;
            // // console.log(coupons);
            return dispatch({ type: GET_USER_OFFERS, payload: coupons });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const applyCoupon = (token, coupon, products, subtotal, store_id) => (dispatch) => {

    return Axios.post(APPLY_COUPON_URL, {
        token, coupon, products, subtotal, store_id
    }).then((response) => {
        const coupon = response.data;
        return [
            dispatch({ type: APPLY_COUPON, payload: coupon }),
            dispatch({ type: COUPON_ERROR, payload: null }),
        ];
    }).catch(function (error) {
        // console.log(error);
        if (error.response.status === 401) {
            return dispatch({ type: COUPON_ERROR, payload: "NOTLOGGEDIN" });
        }
    });
};

export const autoApplyCoupon = (token, products, subtotal) => (
    dispatch
) => {
    return Axios.post(AUTO_APPLY_COUPON_URL, {
        token: token,
        products: products,
        subtotal: subtotal,
    })
        .then((response) => {
            const coupon = response.data;

            return [
                dispatch({ type: AUTO_APPLY_COUPON, payload: coupon }),
                dispatch({ type: COUPON_ERROR, payload: null }),
            ];
        })
        .catch(function (error) {
            // console.log(error);
            if (error.response.status === 401) {
                return dispatch({ type: COUPON_ERROR, payload: "NOTLOGGEDIN" });
            }
        });
};

export const removeCoupon = () => (dispatch) => {

    const coupon = { hideMessage: true, coupon_error: "" };
    return dispatch({ type: APPLY_COUPON, payload: coupon });
};

export const couponApplied = (coupon, percentage_discount) => (dispatch) => {
    coupon.appliedAmount = percentage_discount;
    return dispatch({ type: APPLY_COUPON, payload: coupon });
};

export const getMyCoupon = (token, latitude, longitude) => (dispatch) => {

    return Axios.post(GET_MY_COUPON_URL, {
        token, latitude, longitude
    }).then((response) => {
        let myCoupons = response.data;
        return dispatch({
            type: GET_MY_COUPON,
            payload: myCoupons
        })
    })
};
