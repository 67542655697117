import React, { Component } from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import MobileBackButton from '../../../Elements/MobileBackButton'
import bike from '../../../../assets/images/003.png'
import sub from "../../../../assets/images/001.png"
import coin from '../../../../assets/images/coins.png'
import ProgressiveImage from 'react-progressive-image'
import { getSinglePlan, subscribeNow } from '../../../../redux/mobile/subscription/action'
import Truncate from 'react-truncate'
import { PLACE_HOLDER_IMAGE, PLAN_PAYTM_PAYMENT_URL, PLAN_PROCESS_RAZORPAY_URL, PLAN_PROCESS_STATUS_UPDATE_RAZORPAY_URL } from '../../../../api'
import axios from 'axios'

export class SingleSubscriptionPlan extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        plan: [],
        succesRazorData:[]
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getSinglePlan(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, plan: response.payload.plan })
            }
        })


		const script = document.createElement('script');
		script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);

    }

    sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
		axios.post(PLAN_PROCESS_STATUS_UPDATE_RAZORPAY_URL, {
			id: id,
			token: this.props.user.data.auth_token,
			payment_id,
			order_id,
			signature
		}).then((response) => {
            this.setState({succesRazorData: response?.data})

            // if (response?.data?.success) {
            	this.props.history.push("/subscription-success/" + response?.data?.data?.id);

            // }
        });
	};

    componentWillReceiveProps(nextProps) {
        //// console.log('====================================');
        //// console.log(nextProps);
        //// console.log('====================================');
        if (nextProps.subscibedPlan?.success && nextProps.subscibedPlan.data.payment_mode == 'ONLINE' && nextProps.subscibedPlan.data.payment_status == "PENDING") {
            this.launchRazor(nextProps.subscibedPlan.data, this.props.history, this.sendOrderCompleteResponse, this.successRazor);
        }
    }

    launchRazor = (order, history, runFunction, successRazor) => {
		//// console.log(parseFloat(order.price).toFixed(2));
		axios.post(PLAN_PROCESS_RAZORPAY_URL, {
			amount: parseFloat(order.price).toFixed(2),
			id: order.id,
			token: this.props.user.data.auth_token
		})
			.then((res) => {

				if (res.data.razorpay_success) {


						const options = {
							key: 'rzp_live_KvJRO6e2wr8Yb9',
							amount: parseFloat(order.price).toFixed(2) * 100,
							name: 'Grosav',
                        webview_intent: true,
							currency: 'INR',
							order_id: res.data.response.id,
							payment_capture: 1,
							method: {
								netbanking: true,
								card: true,
								wallet: false,
								upi: true
							},

							handler(response) {
								//// console.log("Final Response", response);
								runFunction(order.id, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature);

								successRazor();
							},
							modal: {
								ondismiss: function () {
									//// console.log("closed");
									runFunction(order.id, null, null, null);
								},
								confirm_close: true
							},
							prefill: {
								name: this.props.user.data.name,
								contact: this.props.user.data.phone,
								email: this.props.user.data.email ? this.props.user.data.email : '',
							},

						};
						const rzp1 = new window.Razorpay(options);
						rzp1.open();


				}
			});
	}

    successRazor = () => {
		// this.setState({ order_succes: true, order_confirm: false });
		setTimeout(() => {
			// this.context.router.history.push("/my-orders");
		}, 5000);
	}
    // __subscribe = (e) => {
    //     e.preventDefault();
    //     this.setState({ loading: true })
    //     const { user } = this.props;
    //     this.props.subscribeNow(user.data.auth_token, this.state.id).then((response) => {
    //         if (response && response.payload && response.payload.success) {
    //             ////// console.log(response)
    //             // this.setState({ loading: false })
    //             if (response.payload.data.payment_mode === "ONLINE") {
    //                 window.location =
    //                     PLAN_PAYTM_PAYMENT_URL + "/" + response.payload.data.id;
    //             } else {
    //                 setTimeout(() => {
    //                     this.props.history.replace(
    //                         "/subscription-success/" + response.payload.data.id
    //                     );
    //                 }, 2000);
    //             }
    //         } else {
    //             ////// console.log("API ERROR", response);
    //         }
    //     });
    // }
    __subscribe = (e) => {
        e.preventDefault();
        // this.setState({ loading: true })
        const { user } = this.props;
        this.props.subscribeNow(user.data.auth_token, this.state.id).then((response) => {
            // if (response && response.payload && response.payload.success) {
            //     ////// console.log(response)
            //     // this.setState({ loading: false })
            //     if (response.payload.data.payment_mode === "ONLINE") {
            //         window.location =
            //             PLAN_PAYTM_PAYMENT_URL + "/" + response.payload.data.id;
            //     } else {
            //         setTimeout(() => {
            //             this.props.history.replace(
            //                 "/subscription-success/" + response.payload.data.id
            //             );
            //         }, 2000);
            //     }
            // } else {
            //     ////// console.log("API ERROR", response);
            // }
        });
    }
    render() {
        const { plan } = this.state;
        const { user } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                                <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                                <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                                <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                                <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {plan ? (
                                <React.Fragment>

                                    <div className="d-flex flex-column px-3">
                                        <div className="mobile-ntfcn-head py-4">
                                            <div className="pt-3 px-1">
                                                <MobileBackButton
                                                    history={this.props.history}
                                                />
                                            </div>
                                            <div className="text-center w-100 mt-3">
                                                <ProgressiveImage
                                                    delay={20} src={sub}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{ height: "45vw", borderRadius: "10px", objectFit: "cover", backgroundRepeat: "round", }}
                                                            className=""
                                                            alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                            <div className="text-center">
                                                <span className="">{plan.plan_name}</span>
                                            </div>
                                            <div className="text-center">
                                                <span style={{ fontSize: '0.9em', color: '#fea24b' }}>Rs {Math.round(plan.price)}</span>  <span style={{ fontSize: '0.7em', color: '#fea24b' }}>({plan.period} Days)</span>
                                            </div>
                                            <div className="text-center " style={{ fontSize: '0.7em', color: '#fea24b' }}>
                                                <Truncate lines={10} className='shadow-xs'>
                                                    {plan.plan_description}
                                                </Truncate>
                                            </div>
                                        </div>
                                    </div>
                                    {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 && user?.data?.activePlan?.subscription_id == plan?.id ? (

                                        <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                            <button className="btn w-100 py-3 fs-4" style={{ color: "#FEFEFF", fontWeight: "700",  background: '#FEA24B', borderRadius: '15px' }}>
                                                Active Subscription
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 ? (
                                                <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                                    <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700", fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }}>
                                                       Already Subscribed To <span className='fw-bold fs-6'>"{user?.data?.activePlan?.subscription?.plan_name}"</span> Plan
                                                    </button>
                                                </div>
                                            ) : (

                                                <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                                    <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700", fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }} onClick={(e) => this.__subscribe(e)}>
                                                        Subscribe now
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div className="position-fixed  w-100" style={{ zIndex: "-1", bottom: '0px', backgroundColor: "#fff", backgroundImage: `url(${bike})`, backgroundRepeat: 'round', height: '100vw' }}>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="d-flex flex-column">
                                    <div className="mobile-ntfcn-head py-4">
                                        <div className="pt-3 px-1">
                                            <MobileBackButton
                                                history={this.props.history}
                                            />
                                        </div>
                                        <div className="pt-5 ps-4 text-center">

                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <img
                                            src={coin}
                                            style={{
                                                width: "90%",
                                                borderRadius: "10px",
                                                objectFit: "cover",
                                                backgroundRepeat:
                                                    "round",
                                            }}
                                            className=""
                                            alt="grosav"
                                        />

                                    </div>
                                    <div className="text-center" style={{ fontSize: '1.2em', color: '#fea24b' }}>
                                        No Plans Found
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    subscibedPlan: state.subscription.subscibedPlan
})
export default connect(mapStateToProps, {
    getSinglePlan, subscribeNow
})(SingleSubscriptionPlan)
