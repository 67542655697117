import {
    GET_SEARCH_MOBILE_ITEMS,
    GET_SINGLE_ITEM,
    GET_STORE_INFO,
    GET_ALL_CATEGORY,
    GET_ALL_LIKED_ITEMS,
    GET_SINGLE_ITEM_GROUP,
    GET_ALL_ANY_STORE_COURIER_CATEGORY
} from "./actionType";

import { FORCE_RELOAD } from "../helper/actionTypes";
import {
    GET_SEARCH_MOBILE_ITEMS_URL,
    GET_SINGLE_ITEM_URL,
    GET_SEARCH_CATEGORIES_URL,
    GET_STORE_INFO_AND_OPERATIONAL_STATUS_URL,
    GET_ALL_CATEGORY_URL,
    GET_TOGGLE_FAV_ITEM_URL,
    GET_ALL_LIKED_ITEMS_URL,
    GET_REMOVE_FAV_ITEM_URL,
    GET_SINGLE_ITEM_GROUP_URL,
    GET_ALL_ANY_STORE_COURIER_CATEGORY_URL
} from "../../../api";

import Axios from "axios";

export const getMobileSearchItems = (input, category, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SEARCH_MOBILE_ITEMS_URL, {
        category,
        input, latitude, longitude
    }).then((response) => {
        let searchitems = response.data;
        return dispatch({
            type: GET_SEARCH_MOBILE_ITEMS,
            payload: searchitems,
        });
    });
};

export const getsingleProduct = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SINGLE_ITEM_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let singleItem = response.data;
            return dispatch({
                type: GET_SINGLE_ITEM,
                payload: singleItem
            })

        })
}
export const getSearchCategories = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SEARCH_CATEGORIES_URL, { latitude, longitude })
}

export const getStoreInfoAndOperationalStatus = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_INFO_AND_OPERATIONAL_STATUS_URL, {
        id: id,
        latitude: latitude,
        longitude: longitude,
    })
        .then((response) => {
            const store_info = response.data;
            return dispatch({ type: GET_STORE_INFO, payload: store_info });
        })
        .catch(function (error) {
            // console.log(error);
            if (error.response.status === 400) {
                const force_reload = true;
                return dispatch({ type: FORCE_RELOAD, payload: force_reload });
            }
        });
};
export const toggleFavItem = (token, id) => (dispatch) => {
    return Axios.post(GET_TOGGLE_FAV_ITEM_URL, {
        token, id,
    })
}
export const getAllCategory = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_CATEGORY_URL, { latitude, longitude }).then((response) => {
        let category = response.data;
        return dispatch({
            type: GET_ALL_CATEGORY,
            payload: category,
        });
    });
}

export const getLikedItems = (token) => (dispatch) => {
    return Axios.post(GET_ALL_LIKED_ITEMS_URL, { token }).then((response) => {
        let likeditems = response.data;
        return dispatch({
            type: GET_ALL_LIKED_ITEMS,
            payload: likeditems,
        });
    });
}

export const removeLikedItem = (token, id) => (dispatch) => {
    return Axios.post(GET_REMOVE_FAV_ITEM_URL, { token, id })
}

export const getSingleItemGroup = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SINGLE_ITEM_GROUP_URL, {
        id: id, latitude, longitude
    })
        .then((response) => {
            let item = response.data;
            return dispatch({
                type: GET_SINGLE_ITEM_GROUP,
                payload: item
            })
        })
}
//category
export const getAllAnyStoreCourierCategory = () => (dispatch) => {
    return Axios.post(GET_ALL_ANY_STORE_COURIER_CATEGORY_URL).then((response) => {
        let categories = response.data;
        return dispatch({
            type: GET_ALL_ANY_STORE_COURIER_CATEGORY,
            payload: categories,
        });
    });
}
