import React, { Component } from "react";
import { connect } from "react-redux";
import VendorBackButton from "../../Elements/VendorBackButton";
import Loading from '../../Elements/loading'
import { getVendorAccountDetails } from "../../../redux/vendor/user/action";
import moment from 'moment';
export class VendorAccount extends Component {
    state = {
        account: false,
        finance: false,
        loading: false,
    };
    componentDidMount() {
        const { vendor } = this.props;



        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500);

        if (this.props.location && this.props.location.state && this.props.location.state.status && this.props.location.state.status === "account") {
            this.setState({ account: true });
            this.setState({ finance: false });

        }
        else if (this.props.location && this.props.location.state && this.props.location.state.status && this.props.location.state.status === "finance") {
            this.setState({ finance: true });
            this.setState({ account: false });

        }
        else {
            this.setState({ account: true });
        }
    }
    getDate() {
        let dat = this.props.vendor.user.created_at;
        let date = moment(dat).format('LL');
        return date;
    }

    render() {

        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <VendorBackButton title="Account" />
                        <div className="d-flex justify-content-between vendor-account-head px-3 pb-3" style={{ paddingTop: '25vw' }}>
                            <div className="">
                                <h6
                                    className="mt-2 mb-0"
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        color: "#38393D",
                                        lineHeight: "30px",
                                    }}
                                >
                                    {this.props.vendor && this.props.vendor.user && this.props.vendor.user.stores && this.props.vendor.user.stores[0].name}

                                </h6>
                                <span className="mt-1 ">
                                    {this.props.vendor && this.props.vendor.user && this.props.vendor.user.stores && this.props.vendor.user.stores[0].phone}
                                </span>
                                <div className="mt-4">
                                    <h6
                                        className=" mb-0"
                                        style={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            color: "#09051C",
                                        }}
                                    >
                                        Address
                                    </h6>
                                    <div className="mt-2">
                                        <span>
                                            {this.props?.vendor?.user?.stores[0]?.address ? this.props?.vendor?.user?.stores[0]?.address : "No Address Provided"}
                                        </span>

                                    </div>
                                </div>
                            </div>
                            {this.props.vendor && this.props.vendor.user && this.props.vendor.user.stores && this.props.vendor.user.stores[0].is_active == 1 ?
                                <span
                                    className="mt-4 me-2"
                                    style={{
                                        color: "#32BA7C",
                                        fontSize: "12px",
                                        fontWeight: 700,
                                    }}
                                >
                                    Active
                                </span>
                                :
                                <span
                                    className="mt-4 me-2"
                                    style={{
                                        color: "#FF0000",
                                        fontSize: "12px",
                                        fontWeight: 700,
                                    }}
                                >
                                    In Active
                                </span>
                            }
                        </div>
                        {this.state.account ? (
                            <div className="px-3 py-3 vendor-account-container">
                                <div className="mt-3">
                                    <p className="vendor-account-title">Name</p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.name}</span>
                                </div>
                                {/* <div className="mt-3">
                                    <p className="vendor-account-title">Grosav ID</p>
                                    <span className="vendor-account-details">4555166</span>
                                </div> */}
                                <div className="mt-3">
                                    <p className="vendor-account-title">
                                        Onboarding Date
                                    </p>
                                    <span className="vendor-account-details">{this.getDate()}</span>
                                </div>
                                <div className="mt-3">
                                    <p className="vendor-account-title">Phone Number</p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.phone}</span>
                                </div>
                                <div className="mt-3">
                                    <p className="vendor-account-title">Email id</p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.email}</span>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.finance ? (
                            <div className="px-3 py-3 vendor-account-container">
                                <div className="mt-3">
                                    <p className="vendor-account-title">Bank Name</p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.bank_name ? this.props.vendor.user.bank_name : "-------- Not Updated -------"}</span>
                                </div>
                                <div className="mt-3">
                                    <p className="vendor-account-title">Account Number</p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.account ? this.props.vendor.user.account : "-------- Not Updated -------"}</span>
                                </div>
                                <div className="mt-3">
                                    <p className="vendor-account-title">
                                        Bank IFSC Code
                                    </p>
                                    <span className="vendor-account-details">{this.props.vendor && this.props.vendor.user && this.props.vendor.user.ifsc_code ? this.props.vendor.user.ifsc_code : "-------- Not Updated -------"}</span>
                                </div>

                            </div>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    vendor: state.vendor.store_user,


});

export default connect(mapStateToProps, { getVendorAccountDetails })(VendorAccount);
