import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom"
import { getOrderForReview, submitOrderReview } from '../../../../../redux/mobile/mobileOrder/action'
import { IMAGE_BASE_URL } from "../../../../../api";
import { Rating } from 'react-simple-star-rating';
import { Form, InputGroup } from "react-bootstrap";
import { BiEdit, BiGridSmall } from "react-icons/bi";
import Shake from "react-reveal/Shake";
import successtick from "../../../../../assets/images/successtick.png";
import BackButtonOnly from "../../../../Elements/BackButtonOnly";
import ProgressiveImage from "react-progressive-image";
import { BsDot } from "react-icons/bs";

class PlaceReview extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        rating: '',
        review: '',
        noRating: false
    };
    componentDidMount() {
        const { user } = this.props;
        ////// console.log(this.state.id);
        this.setState({ loading: true });
        this.props.getOrderForReview(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false });
            }
        })
    }
    handleInput = (e) => {
        this.setState({ review: e })
    }
    handleRating = (e) => {
        this.setState({ noRating: false })
        this.setState({ rating: e / 20 })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user,reviewOrder } = this.props;
        const { id, rating, review } = this.state;
        this.setState({ noRating: false });
        if (rating) {
            this.setState({ loading: true });
            this.props.submitOrderReview(user.data.auth_token, id, rating*20, review).then((response) => {
                if (response && response.payload && response.payload.success) {
                    // setTimeout(() => {
                    window.location.replace('/single-order/' + reviewOrder?.orderItem?.order_id)
                    // }, 100);
                }
            })
        } else {
            setTimeout(() => {
                this.setState({ noRating: true })
            }, 1);
        }
    }
    render() {
        const { reviewOrder } = this.props;
        const { rating, review } = this.state;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader speed={1} viewBox="0 0 280 576" backgroundColor={"#F3F3F3"} foregroundColor={"#FFFFFF"}  >
                            <circle cx="140" cy="230" r="50" />
                            <rect x="90" y="290" rx="" ry="" width="100" height="18" />
                            <rect x="22" y="320" rx="" ry="" width="230" height="18" />
                            <rect x="90" y="350" rx="10" ry="10" width="100" height="18" />
                            <rect x="13" y="510" rx="10" ry="10" width="250" height="30" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            {/* {////// console.log(reviewOrder.orderItem,'reviewOrder')} */}
                            <BackButtonOnly history={this.props.history} />{" "}
                            <div className="mobile-subs-success-bg">
                            </div>
                            <div className="d-flex flex-column justify-content-center mobile-subs-success-img">
                                <div className="d-flex justify-content-center">
                                    <ProgressiveImage
                                        delay={20}
                                        src={IMAGE_BASE_URL + reviewOrder?.orderItem?.item?.image}
                                        placeholder={successtick}
                                    >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                className="" style={{ borderRadius: '50%', width: '50vw', height: '50vw', objectFit: 'cover',backgroundColor:'#fff',padding:'2px' }}
                                                alt='main-item'
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                <div className="mt-3 text-center fs-5 fw-bolder">
                                    {reviewOrder?.orderItem?.name}
                                </div>
                                <div style={{ margin: '0 auto' }} >
                                    {reviewOrder?.orderItem?.orderitemaddons?.map((addn) => (
                                        <div className="d-flex align-items-center gap-3" style={{ fontSize: '14px' }}>
                                            <BiGridSmall />  {addn.name} <span className="badge badge-pill" style={addn.combo_type == 'FREE' ? { backgroundColor: 'green' } : { backgroundColor: '#ffa431' }}>{addn.is_combo ? addn.combo_type : 'ADDON'}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-3 text-center fs-4 fw-bolder">
                                    Thank You!
                                </div>
                                {/* <div className="text-center fs-4 fw-bolder">
                                    Your Order Completed
                                </div> */}
                                <div className="text-center text-muted px-4 mt-2">
                                    Please rate your order
                                </div>
                                <div className="mt-4 text-center">
                                    <Rating
                                        size={"3em"}
                                        fillColor="#ff9012"
                                        ratingValue={this.state.rating && this.state.rating * 20}
                                        onClick={(e) => this.handleRating(e)}
                                    />
                                </div>
                                {this.state.noRating &&
                                    <div className="text-center" style={{ color: "red" }} >
                                        <Shake>
                                            Select rating
                                        </Shake>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between w-100 position-fixed p-4" style={{ bottom: '0', backgroundColor: '#fff' }} >
                            <Form onSubmit={(e) => this.onSubmit(e)}>
                                <InputGroup className="py-2" style={{ backgroundColor: '#fff', borderRadius: '14px', border: '1px solid #eaeaea' }}>
                                    <div className='py-2 px-3'>
                                        <BiEdit size={25} color={'#ffa137'} />
                                    </div>
                                    <Form.Control
                                        className='px-3'
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            this.handleInput(value)
                                        }}
                                        value={review}
                                        placeholder="Leave Feedback"
                                        style={{ border: 'none', backgroundColor: '#fff', borderRadius: '14px' }}
                                        aria-describedby="basic-addon2"
                                        required
                                    />
                                </InputGroup>
                                <div className="d-flex justify-content-between w-100 mt-4" >
                                    <button type="submit" className="py-3 px-3 mobile-place-review-btn w-100">
                                        Submit
                                    </button>
                                    {/* <button className="ms-4 py-2 px-4 mobile-place-review-btn-skip">
                                        Skip
                                    </button> */}
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    status: state.checkout.orderStatus,
    reviewOrder: state.mobileOrder.reviewOrder
});

export default connect(mapStateToProps, {
    getOrderForReview,
    submitOrderReview
})(PlaceReview);
