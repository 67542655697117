import { GET_NOTIFICATIONS_URL } from "../../../api";
import { GET_NOTIFICATIONS } from "./actionType";
import Axios from "axios";
export  const  getAllNotifictions = (token,id) => (dispatch) => {
    return Axios.post(GET_NOTIFICATIONS_URL, {
        token,id
    })
        .then((response) => {
            const notification = response.data
            return dispatch({
                type: GET_NOTIFICATIONS,
                payload: notification
            })

        })
}

