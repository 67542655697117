export const GET_CART_PRODUCTS = "GET_CART_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const UPDATE_CART = "UPDATE_CART";
export const CALCULATE_STORE_MAX_DISTANCE = "CALCULATE_STORE_MAX_DISTANCE";
export const CALCULATE_STORE_STORE_CHARGE_TAX = "CALCULATE_STORE_STORE_CHARGE_TAX";
export const GET_DELIVERY_SLAB = "GET_DELIVERY_SLAB";
export const UPDATE_CURRENT_DELIVERY_SLAB = "UPDATE_CURRENT_DELIVERY_SLAB";
export const UPDATE_NEXT_DELIVERY_SLAB = "UPDATE_NEXT_DELIVERY_SLAB";
export const PRODUCT_STORE = "PRODUCT_STORE";
export const PRESCRIPTION_IMAGE = "PRESCRIPTION_IMAGE";
