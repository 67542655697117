import React, { Component } from "react";
import { connect } from "react-redux";


export class FoodFilter extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-column px-3 mt-3 ">
                    <div className="d-flex flex-row align-items-center ">
                        <div className="filter-menu hidden-scroll-bar gap-2 pb-3">

                            <div className="d-flex">
                                <form className="">
                                    <select
                                        className="event-type-select  "
                                        id="dropdown-basic-button"
                                    >
                                        <option selected disabled>Filter</option>
                                        <option value="sec2">section 2</option>
                                        <option value="sec3">section 3</option>
                                        <option value="sec4">section 4</option>
                                        <option value="sec5">section 5</option>
                                        <option value="sec6">section 6</option>
                                    </select>
                                </form>
                                <form className="">
                                    <select
                                        className="event-type-select  "
                                        id="dropdown-basic-button"
                                    >
                                        <option selected disabled>Sort By</option>
                                        <option value="sec2">section 2</option>
                                        <option value="sec3">section 3</option>
                                        <option value="sec4">section 4</option>
                                        <option value="sec5">section 5</option>
                                        <option value="sec6">section 6</option>
                                    </select>
                                </form>
                                <form className="">
                                    <select
                                        className="event-type-select  "
                                        id="dropdown-basic-button"
                                    >
                                        <option selected disabled>Free Delivery</option>

                                    </select>
                                </form>
                                <form className="">
                                    <select
                                        className="event-type-select  "
                                        id="dropdown-basic-button"
                                    >
                                        <option selected disabled>Pure Vegitarian</option>

                                    </select>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(FoodFilter);
