import React, { Component } from "react";
import { BiInfoCircle, BiWallet } from "react-icons/bi";
import Modal from "react-responsive-modal";
import Fade from 'react-reveal/Flip';
import { BottomSheet } from "react-spring-bottom-sheet";

class PagePopup extends Component {
    state = {
        open: false
    };
    handlePopupOpen = () => {
        this.setState({ open: true });
    };
    handlePopupClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { page } = this.props;
        return (
            <React.Fragment>

                <div className="d-flex profile-opt py-3 mt-3 px-3" onClick={this.handlePopupOpen}>
                    <div>
                        <BiInfoCircle className="profile-btns" />
                    </div>
                    <div className="ms-4">
                        {" "}
                        <span className="profile-tag">
                            {page.name}
                        </span>
                    </div>
                </div>
                <BottomSheet
                    snapPoints={({ maxHeight }) => 0.8 * maxHeight}
                    open={this.state.open}
                    onDismiss={() => this.handlePopupClose()}
                >
                    <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around p-3">
                            <div className="fw-bold fs-3 text-center mb-2">{page?.name}</div>
                        <div className="mt-50" dangerouslySetInnerHTML={{ __html: page.body }} />

                    </div>
                </BottomSheet>

            </React.Fragment>
        );
    }
}

export default PagePopup;
