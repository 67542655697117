import React, { Component } from 'react'
import { connect } from 'react-redux'
import VendorBackButton from '../../Elements/VendorBackButton';
import { MdToggleOn } from "react-icons/md";
import { MdToggleOff } from "react-icons/md";
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { AiOutlineMinus } from "react-icons/ai";
import Loading from '../../Elements/loading'
import Modal from 'react-modal';
import { getUserPassword, toggleDisplayOrder } from "../../../redux/vendor/user/action";




export class AccountSettings extends Component {
    state = {
        order: false,
        passwordChange: false,
        show: false,
        loading: false,
        account: {},
        old_password: "",
        password_1: '',
        password_2: "",
        modalVisible: false,
        wrongpassmodal: false,
    }
    handleOrder() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.toggleDisplayOrder(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ order: !this.state.order, loading: false, });
                    //// console.log(response.payload)
                } else {
                    this.setState({ Loading: false });
                    //// console.log(response, 'api error');
                }
            })

    }
    openChangePassword() {
        if (this.state.passwordChange == false) {
            this.setState({ passwordChange: true });
        } else {
            this.setState({ passwordChange: false });
        }
    }
    handleClick() {
        this.setState({ show: !this.state.show });
    }
    oldPasswordChange = (e) => {
        this.setState({ old_password: e })
    };
    PasswordNew1 = (e) => {
        this.setState({ password_1: e })
    };
    PasswordNew2 = (e) => {
        this.setState({ password_2: e })
    };
    onSubmit = (e) => {
        const { user } = this.props;

        e.preventDefault();
        this.setState({ loading: true });
        if (this.state.password_1 == this.state.password_2) {
            this.props.getUserPassword(user.data.auth_token, this.state.old_password, this.state.password_2)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.setState({
                            modalVisible: true, title: 'Password changed', old_password: null,
                            password_1: null, loading: false,
                            password_2: null,
                        })
                    } else {
                        this.setState({
                            wrongpassmodal: true, title: 'Password not changed', old_password: null,
                            password_1: null, loading: false,
                            password_2: null,
                        })
                        return "Password did not change"
                    }
                })
        } else {
            this.setState({
                wrongpassmodal: true, title: 'Password not changed', old_password: null,
                password_1: null, loading: false,
                password_2: "",
            })
        }
    }
    closeModal = () => {
        this.setState({ modalVisible: false, show: false })

    }
    closeModal1 = () => {
        this.setState({ wrongpassmodal: false, show: false })
    }
    componentDidMount() {
        const { display_order } = this.props;
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 500);
        // //// console.log(display_order);
        if (display_order && display_order.data?.length > 0) {
            this.setState({ order: true })
        }
    }
    render() {
        const customStyles = {
            content: {
                top: '40%',
                left: '20%',
                right: '20%',
                bottom: 'auto',
                backgroundColor: '#fff',
                color: '#000000',
                borderRadius: '10px',
                boxShadow: ' -2px 8px  15px 10px grey',
                fontSize: '15px',
            },
        };
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <VendorBackButton title="Account Settings" />
                        <div className='d-flex justify-content-between px-3' style={{ paddingTop: '25vw' }}>
                            <div>
                                <span className='settings-text-one'>Display Order</span>
                            </div>
                            <div onClick={() => this.handleOrder()}>
                                {this.state.order ?
                                    <MdToggleOn set="curved" style={{ fontSize: "4em", color: "#59D69D", }} />
                                    :
                                    <MdToggleOff set="curved" style={{ fontSize: "4em", color: "#cfcfcf" }} />
                                }
                            </div>
                        </div>
                        <div className='px-3' >
                            <span className='text-muted' style={{ fontSize: '13px' }}>If you close this, you will still continue to recieve orders, but you will not revive any notifications untill you open app again.</span>
                        </div>
                        <div className='d-flex justify-content-between px-3 password-change py-3' style={{ color: 'black' }} onClick={() => this.handleClick()}>
                            <div className='ms-4'>
                                <span className='password-text'>Change Password </span>
                            </div>
                            <div className='me-3'>
                                {this.state.show ?
                                    <AiOutlineMinus style={{ fontSize: '22px', color: '#FF9A62' }} />
                                    :
                                    <HiOutlineChevronRight style={{ fontSize: '22px', color: '#FF9A62' }} />


                                }
                            </div>
                        </div>

                        <Fade right opposite when={this.state.show}>

                            <div>
                                <Form onSubmit={this.onSubmit}>
                                    <div className='mb-4 input-password-change py-2'>

                                        <InputGroup className="px-4 ">
                                            <FormControl
                                                type="password"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.oldPasswordChange(value);
                                                }}
                                                disabled={!this.state.show}
                                                placeholder="Enter Old Password"
                                                className="px-3 py-3 acc-set-input "
                                                style={{ border: 'none' }}
                                            />
                                        </InputGroup>
                                    </div>

                                    <div className='mb-4 input-password-change py-2'>

                                        <InputGroup className="px-4">
                                            <FormControl
                                                type="password"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.PasswordNew1(value);
                                                }}
                                                disabled={!this.state.show}
                                                placeholder="Enter New Password"
                                                className="px-3 py-3 acc-set-input"
                                                style={{ border: 'none' }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className='mb-2 input-password-change py-2'>

                                        <InputGroup className="px-4">
                                            <FormControl
                                                type="password"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.PasswordNew2(value);
                                                }}
                                                disabled={!this.state.show}
                                                placeholder="Re-enter Password"
                                                className="px-3 py-3 acc-set-input"
                                                style={{ border: 'none' }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="px-4">
                                        <button
                                            type='submit'
                                            style={{ color: 'white', fontWeight: 'bold' }}
                                            disabled={!this.state.show}
                                            className="password-submit py-3">
                                            Save
                                        </button>
                                    </div>

                                </Form>
                            </div>
                        </Fade>
                        <Modal
                            isOpen={this.state.modalVisible}
                            style={customStyles}
                            contentLabel="Example Modal"
                            ariaHideApp={false}     //password success modal
                            className=""
                        >
                            <Fade right>
                                <div className="sucess-modal">
                                    <p className=" text-cenetr">
                                        Password was updated successfully
                                    </p>
                                    <div className="text-center" onClick={() => {
                                        this.closeModal()
                                    }}>
                                        <span className="btn  btn-success">OK</span>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                        <Modal
                            isOpen={this.state.wrongpassmodal}
                            style={customStyles}
                            contentLabel="Example Modal"        //wrong password modal
                            ariaHideApp={false}
                            className=""
                        >
                            <Fade right>
                                <div className="sucess-modal">
                                    <p className=" text-cenetr">
                                        Your Password did not match
                                    </p>
                                    <div className="text-center" onClick={() => {
                                        this.closeModal1()
                                    }}>
                                        <span className="btn  btn-success">OK</span>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    display_order: state.vendor.display_order,
})


export default connect(mapStateToProps, {
    getUserPassword, toggleDisplayOrder
})(AccountSettings)
