import React, { Component } from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { TbDiscount2 } from 'react-icons/tb'
import ProgressiveImage from 'react-progressive-image'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import moment from 'moment'
import Truncate from 'react-truncate'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api'
import { getDealsToday } from '../../../../redux//mobile/home/action'
import Countdown, { zeroPad } from "react-countdown";

export class DealsToday extends Component {
    state = {
        data: false,
        dataLength: ''
    }

    componentDidMount() {
        this.props.getDealsToday(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude, 1).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ data: true });
            }
        });
    }
    getDay = (start) => {
        let dayNow = moment().format("DD-MM-YYYY");
        let dayDate = moment(start).format("DD-MM-YYYY");
        let day = moment(dayDate, "DD-MM-YYYY").diff(moment(dayNow, "DD-MM-YYYY"), 'days');
        return day;

    }
    getTime = (start) => {
        const createdAt = start; // Replace this with your deal end date at date string
        const currentDate = new Date(); // This will set the end time to the current time
        const diff = currentDate.getTime() - new Date(createdAt).getTime(); // Calculate the duration in milliseconds
        const duration = Math.max(0, 24 * 60 * 60 * 1000 - diff); // Calculate the remaining duration in milliseconds (24 hours in this example)
        return duration;
    }



    render() {
        const timeRenderer = ({ days, hours, minutes, seconds }) => {
            return (
                <div className='d-flex align-items-center justify-content-center' style={{ color: '#32BA7C', fontWeight: '600' }}>
                    <span>
                        {zeroPad(hours)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(minutes)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(seconds)}
                    </span>
                </div>
            );
        }
        const { deals_today } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {data ? deals_today && deals_today.items && deals_today.items.length > 0 && (
                    <div className='px-3 mt-3  '>
                        <TbDiscount2 className='mb-2' color='FF9A62' size={20} /> <span className='top-res-header'>Deals Today</span>
                        <div className='d-flex flex-row align-items-center'>
                            <div className=' main-menu hidden-scroll-bar gap-3 ' style={{ gridTemplateColumns: `repeat(6,1fr)` }}>
                                {deals_today && deals_today.items && deals_today.items.map((item, key) => (
                                    <div className='mt-2' key={item?.id}>
                                      <Link to={"/single-product/" + item.id}>
                                            <div className='position-relative'>
                                                <ProgressiveImage
                                                    delay={20} src={IMAGE_BASE_URL + item.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}  >
                                                    {(src, loading) => (
                                                        <img src={src}
                                                            style={{ width: "30vw", height: "30vw", borderRadius: "15%", objectFit: "cover", backgroundRepeat: "round", }}
                                                            className="" alt="grosav" />
                                                    )}
                                                </ProgressiveImage>
                                                <div className="popular-tag-content px-2 py-2">
                                                    <div className="popular-tag">
                                                        <p><b>{item.dealofdays[0].offer}% off</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='top-res-name d-flex flex-column justify-content-between'>
                                                <Truncate lines={1} className="fw-700">
                                                    {item.name}
                                                </Truncate>
                                                <Truncate lines={1} style={{ fontSize: '.9em' }}>
                                                    {item.store.name}
                                                </Truncate>
                                                {item?.selling_price > 0 ?
                                                    <div className='d-flex justify-content-between text-center'>
                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                            Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                        </span>
                                                        <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                            Rs. {item?.selling_price}
                                                        </s>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-between text-center'>
                                                        <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9A62' }}>
                                                            {item.dealofdays[0].offer}% OFF
                                                        </span>
                                                    </div>}
                                                <div className='d-flex align-items-center justify-content-between text-center' style={{ color: '#32BA7C' }}>
                                                    <div>
                                                        Ends In
                                                    </div>
                                                    {this.getDay(item.dealofdays[0].end_time) > 0 ? this.getDay(item.dealofdays[0].end_time) + " days" : (
                                                        <Countdown
                                                            style={{ color: '#00ab3a' }}
                                                            date={Date.now() + this.getTime(item.dealofdays[0].end_time)}
                                                            renderer={timeRenderer}
                                                        // onComplete={() => {
                                                        //     checkOrderStatus();
                                                        // }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                                {deals_today.items.length > 3 &&
                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ whiteSpace: 'nowrap' }}>
                                        <Link to="/single-deals-today">
                                            <div className='fw-bolder text-black'>
                                                Show More
                                            </div>
                                        </Link>
                                        <Link to="/single-deals-today">
                                            <div>
                                                <BsArrowRightCircleFill color='#FF9A62' size={'1.5em'} />
                                            </div>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ) : ("")}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    deals_today: state.home.deals_today
})
export default connect(mapStateToProps, {
    getDealsToday
})(DealsToday)
