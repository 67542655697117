import {
    GET_STORE_CATEGORY_TOP_ITEMS,
    GET_STORE_CATEGORY_STORES,
    GET_STORE_CATEGORY_ITEM_CATEGORY,
    GET_STORE_CATEGORY_BANNER,
    GET_STORE_CATEGORY_TOP_STORES,
    GET_STORE_CATEGORY_TOP_DEALS,
    GET_STORE_CATEGORY_RECOMMENDED_STORES,
    GET_STORE_CATEGORY_NEAR_STORES,
    GET_SINGLE_DEAL,
    GET_SINGLE_BANNER,
    GET_STORE_CATEGORY,
    GET_STORE_CATEGORY_TODAY_DEALS
} from './actionType';
import {
    GET_STORE_CATEGORY_TOP_ITEMS_URL,
    GET_STORE_CATEGORY_STORES_URL,
    GET_STORE_CATEGORY_ITEM_CATEGORY_URL,
    GET_STORE_CATEGORY_BANNER_URL,
    GET_STORE_CATEGORY_TOP_STORES_URL,
    GET_STORE_CATEGORY_TOP_DEALS_URL,
    GET_STORE_CATEGORY_RECOMMENDED_STORES_URL,
    GET_STORE_CATEGORY_NEAR_STORES_URL,
    GET_SINGLE_DEAL_URL,
    GET_SINGLE_BANNER_URL,
    GET_STORE_CATEGORY_URL,
    GET_STORE_CATEGORY_TODAY_DEALS_URL
} from "../../../api";

import Axios from 'axios'


//store categories
export const getStoreCategoryTopItems = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_TOP_ITEMS_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let topitems = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_ITEMS,
                payload: topitems
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_ITEMS,
                payload: []
            })
        })
}
export const getStoreCategoryStores = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_STORES_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let stores = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_STORES,
                payload: stores
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_STORES,
                payload: []
            })
        })
}

export const getStoreCategoryItemCategory = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_ITEM_CATEGORY_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let itemcategory = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_ITEM_CATEGORY,
                payload: itemcategory
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_ITEM_CATEGORY,
                payload: []
            })
        })
}

export const getStoreCategoryBanners = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_BANNER_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let banners = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_BANNER,
                payload: banners
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_BANNER,
                payload: []
            })
        })
}

export const getStoreCategoryTopStores = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_TOP_STORES_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let topstores = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_STORES,
                payload: topstores
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_STORES,
                payload: []
            })
        })
}
export const getStoreCategoryTopDeals = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_TOP_DEALS_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let topstores = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_DEALS,
                payload: topstores
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_TOP_DEALS,
                payload: []
            })
        })
}
export const getStoreCategoryRecommendedStores = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_RECOMMENDED_STORES_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let recommendedstores = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_RECOMMENDED_STORES,
                payload: recommendedstores
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_RECOMMENDED_STORES,
                payload: []
            })
        })
}
export const getStoreCategoryNearStores = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_NEAR_STORES_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let nearstores = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_NEAR_STORES,
                payload: nearstores
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY_NEAR_STORES,
                payload: []
            })
        })
}

export const getSingleDeal = (id) => (dispatch) => {
    return Axios.post(GET_SINGLE_DEAL_URL, {
        id
    })
        .then((response) => {
            let deal = response.data;
            return dispatch({
                type: GET_SINGLE_DEAL,
                payload: deal
            })
        })
}
export const getSingleBanner = (id) => (dispatch) => {
    return Axios.post(GET_SINGLE_BANNER_URL, {
        id
    })
        .then((response) => {
            let banner = response.data;
            return dispatch({
                type: GET_SINGLE_BANNER,
                payload: banner
            })
        })
}

export const getStoreCategory = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_URL, {
        id, latitude, longitude
    })
        .then((response) => {
            let banner = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY,
                payload: banner
            })
        }).catch((err) => {
            return dispatch({
                type: GET_STORE_CATEGORY,
                payload: []
            })
        })
}

export const getStoreCategoryTodayDeals = (store_category_id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORY_TODAY_DEALS_URL, {
        store_category_id, latitude, longitude
    })
        .then((response) => {
            let deals = response.data;
            return dispatch({
                type: GET_STORE_CATEGORY_TODAY_DEALS,
                payload: deals
            })
        })
}
