import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../../api/index';
import { Link } from 'react-router-dom';
import { getAllCoupons } from '../../../../redux/mobile/home/action'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class CoupenForYou extends Component {
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getAllCoupons(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
    }
    render() {
        const { coupons } = this.props;
        // ////// console.log(coupons.data,'coupons')
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            speed: 400,
            autoplaySpeed: 8000,
            cssEase: "linear",
            customPaging: function (i) {
              return <div className="dot"></div>;
            },
            dotsClass: "slick-dots slick-thumb"
          };
        return (
            <React.Fragment>
                {coupons && coupons.data && coupons.data.length > 0 ?
                    <div className='px-3 py-3 pb-5 mobile-btm-brdr'>
                        <div>
                            <h3 className='mobile-home-top-offer'>Coupons For You</h3>
                        </div>

                        <Slider {...settings} className="">
                            {coupons?.data?.map(coupon =>
                                <>
                                    <Link to={"/single-coupon/" + coupon.id}>
                                        <div className='position-relative  w-100 my-3'>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + "/" + coupon.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        className="mobile-home-cpn-img" style={{
                                                            objectFit: 'cover'
                                                        }}
                                                        src={src}
                                                        alt='main-item'
                                                    />
                                                )}

                                            </ProgressiveImage>
                                            <button className='cpn-btn position-absolute' style={{ bottom: '10%', right: '8%' }}>{coupon.code}</button>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </Slider>

                    </div>
                    :
                    ""
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    coupons: state.home.coupons,
})



export default connect(mapStateToProps, { getAllCoupons })(CoupenForYou)
