import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButton from "../../../Elements/MobileBackButtonWithCenterTitle";
import { BsSearch } from "react-icons/bs";
import { AiOutlineFieldTime } from "react-icons/ai";
import { GiScooter } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import { TbDiscount2 } from "react-icons/tb";
import { BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";
import { Rating } from "react-simple-star-rating";
import {
    AiOutlineHeart,
    AiFillHeart,
    AiOutlinePlus,
    AiOutlineMinus,
    AiOutlineStop,
} from "react-icons/ai";
import { BottomSheet } from "react-spring-bottom-sheet";
import image from "../../../../assets/images/noitems.png";
import { Link } from "react-router-dom";
// import { RiShareForwardLine } from "react-icons/ri";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from "react-content-loader";
import { getSingleItemGroup } from "../../../../redux/mobile/item/action";
import { toggleFavItem } from "../../../../redux/mobile/item/action";
import { addProduct, removeProduct } from "../../../../redux/mobile/cart/action";
// import PopularItems from "./PopularItems";
import FloatCart from "../../Cart/FloatCart";
// import Items from "./Items";
import Truncate from "react-truncate";
import { MdOutlineContentCopy, MdDone } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";

export class ListItemGroup extends Component {
    state = {
        cart: false,
        isveg: true,
        isnonveg: true,
        couponsheet: false,
        coupon: {},
        loading: false,
        id: this.props.match.params.id,
        rating: [],
        items: {},
        category_id: "",
        copied: false,
        copied_code: null,
    };
    componentDidMount() {
        this.setState({ loading: true });
        this.props.getSingleItemGroup(this.state.id,JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({
                    loading: false,
                    itemGroup: response.payload.item,
                });
            } else {
                ////// console.log("API error");
            }
        });
    }
    toggleFavItem = (item) => {
        const { categoryitems } = this.state;
        const { user } = this.props;
        const singleItem = categoryitems.find((data) => data.id === item.id);
        const favUser = singleItem.favorites.find(
            (fav) => fav.user_id === user.data.id
        );
        if (favUser) {
            const favIndex = singleItem.favorites.indexOf(favUser);
            singleItem.favorites.splice(favIndex, 1);
            this.setState({ categoryitems: categoryitems });
        } else {
            singleItem.favorites.push({ user_id: user.data.id });
            this.setState({ categoryitems: categoryitems });
        }
        this.props.toggleFavItem(this.props.user.data.auth_token, item.id);
    };
    filterCategory = (category) => {
        const { items } = this.state;
        if (category === "ALL") {
            const newitems = items;
            this.setState(
                { categoryitems: newitems, category_id: category },
                this.closeMenu()
            );
        } else {
            let data = parseInt(category);
            const newitems = items.filter(
                (item) => item.item_category_id === data
            );
            this.setState(
                { categoryitems: newitems, category_id: category },
                this.closeMenu()
            );
        }
    };
    openCoupon = (data) => {
        this.setState({ couponsheet: true, coupon: data, floatCart: true });
    };
    closeCoupon = () => {
        this.setState({ couponsheet: false, floatCart: false });
    };
    openMenu = () => {
        this.setState({ cart: true, floatCart: true });
    };
    closeMenu = () => {
        this.setState({ cart: false, floatCart: false });
    };
    toggleItemVeg = (type) => {
        if (type === "VEG") {
            if (this.state.isveg === false) {
                this.setState({ isveg: true });
            } else {
                if (this.state.isnonveg === true) {
                    this.setState({ isveg: false });
                } else {
                    this.setState({ isveg: true, isnonveg: true });
                }
            }
        } else {
            if (this.state.isnonveg === false) {
                this.setState({ isnonveg: true });
            } else {
                if (this.state.isveg === true) {
                    this.setState({ isnonveg: false });
                } else {
                    this.setState({ isnonveg: true, isveg: true });
                }
            }
        }
    };
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    render() {
        const { user, store, addProduct, cartProducts, removeProduct } =
            this.props;
        const { categoryitems, itemGroup } = this.state;
        let showItems = [];
        if (this.state.isveg === true && this.state.isnonveg === false) {
            showItems = categoryitems.filter((item) => item.is_veg === 1);
        } else if (this.state.isveg === false && this.state.isnonveg === true) {
            showItems = categoryitems.filter((item) => item.is_veg === 0);
        } else {
            showItems = categoryitems;
        }
        return (

            <React.Fragment>
                {this.state?.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="10" y="20" rx="5" ry="5" width="22" height="22" />
                            <rect x="13" y="55" rx="0" ry="0" width="140" height="30" />
                            <rect x="13" y="100" rx="0" ry="0" width="120" height="15" />
                            <rect x="200" y="61" rx="10" ry="10" width="30" height="30" />
                            <rect x="13" y="120" rx="5" ry="5" width="240" height="100" />
                            <rect x="13" y="230" rx="10" ry="10" width="120" height="40" />
                            <rect x="140" y="230" rx="10" ry="10" width="120" height="40" />
                            <rect x="130" y="290" rx="10" ry="10" width="30" height="20" />
                            <rect x="164" y="295" rx="0" ry="0" width="20" height="10" />
                            <rect x="200" y="290" rx="10" ry="10" width="30" height="20" />
                            <rect x="235" y="295" rx="0" ry="0" width="30" height="10" />
                            <rect x="13" y="320" rx="" ry="" width="87" height="15" />
                            <rect x="15" y="350" rx="10" ry="10" width="250" height="100" />
                            <rect x="15" y="460" rx="10" ry="10" width="250" height="100" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {itemGroup && (
                            <div className="store-bg-image pt-4 px-3" style={{ paddingBottom: "5vh" }} >
                                <div className="" style={{ marginLeft: "-2vh" }}   >
                                    <MobileBackButton history={this.props.history} title={itemGroup?.name} />
                                </div>
                                {/* <div className="mt-5 d-flex justify-content-between">
                                    <h2 className="" style={{ fontWeight: "700", fontSize: "31px", }}   >
                                        {itemGroup.name}
                                    </h2>

                                </div> */}

                                <div className="frame-img py-2 mt-2">
                                    <div className="d-flex position-relative">
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + itemGroup?.background_image}
                                            placeholder={
                                                PLACE_HOLDER_IMAGE
                                            }
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "90vw", height: "40vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round",
                                                    }}
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                        <div className="p-2 d-flex justify-content-between position-absolute w-100"
                                            style={{ top: "15px", right: "7px", }}   >
                                        </div>
                                        <div
                                            className="d-flex position-absolute"
                                            style={{
                                                top: "58px",
                                                left: "-3px",
                                            }}
                                        >

                                        </div>
                                    </div>
                                </div>

                                {itemGroup && itemGroup.items &&
                                    itemGroup?.items?.length > 0 ? (
                                    <div>
                                        <div className="description mt-2">
                                            Items
                                        </div>
                                        {itemGroup?.items.map((item) => (
                                            <div>
                                                <div className="store-menu px-4 mt-3 py-2 d-flex flex-row justify-content-between">
                                                    <div>
                                                        <Link to={"/single-product/" + item.id}  >
                                                            <div style={{ color: "#09051C", fontWeight: "600", fontSize: "15px", }}>
                                                                {item.name}
                                                            </div>

                                                            <span style={{ color: "#B3B2B6", }}  >
                                                                {item.description}
                                                            </span>
                                                        </Link>
                                                        <div className="">
                                                            <Rating
                                                                size={"1em"}
                                                                className="mobile-rate-star"
                                                                initialValue={item.rating}
                                                                readonly="true"
                                                            />
                                                        </div>
                                                        <div className="d-flex">
                                                            <Link to={"/single-product/" + item.id}  >
                                                                <div>
                                                                    <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px", }}>
                                                                        Rs.{" "} {item.selling_price}
                                                                    </span>
                                                                </div>
                                                            </Link>
                                                            {item.market_price > item?.selling_price ? (
                                                                <div className="px-1 py-1"
                                                                    style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", }} >
                                                                    Rs.  {item.market_price}
                                                                </div>
                                                            ) : ("")}
                                                        </div>

                                                        <div className="d-flex"
                                                            style={{ paddingBottom: "0.8em", }}>
                                                            {user &&
                                                                user.success > 0 ? (
                                                                <div className="" onClick={() => this.toggleFavItem(item)}>
                                                                    {item?.favorites?.length > 0 &&
                                                                        item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                        <AiFillHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                    ) : (
                                                                        <AiOutlineHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <Link to="/signUp">
                                                                    <div className="">
                                                                        <AiOutlineHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                    </div>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column ">
                                                        <Link to={"/single-product/" + item.id}   >
                                                            <div className="flex-grow-1">
                                                                <ProgressiveImage
                                                                    delay={20}
                                                                    placeholder={
                                                                        PLACE_HOLDER_IMAGE
                                                                    }
                                                                    src={IMAGE_BASE_URL + item.image}   >
                                                                    {(src, loading) => (
                                                                        <img className=""
                                                                            style={{
                                                                                width: "8em", objectFit: "cover", height: "25vw", borderRadius: "15px", backgroundRepeat: "round",
                                                                                backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                                            }}
                                                                            src={src} alt="main-item"
                                                                        />
                                                                    )}
                                                                </ProgressiveImage>
                                                            </div>
                                                        </Link>
                                                        <React.Fragment>

                                                            <>
                                                                {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                    <>
                                                                        {cartProducts.filter((cp) => cp.id === item.id).map((product) => (
                                                                            <div className="d-flex align-items-center pt-1 ms-3">
                                                                                <div className="top-food-btn"
                                                                                    onClick={() => {
                                                                                        item.quantity = 1;
                                                                                        removeProduct(item);
                                                                                        this.forceStateUpdate();
                                                                                    }}
                                                                                >
                                                                                    <AiOutlineMinus style={{ color: "#fff", }} size={14} />
                                                                                </div>
                                                                                <div className="top-food-count ms-2 me-2">
                                                                                    {product.quantity}
                                                                                </div>

                                                                                <div className="top-food-btn"
                                                                                    onClick={() => {
                                                                                        item.quantity = 1;
                                                                                        addProduct(item);
                                                                                        this.forceStateUpdate();
                                                                                    }}
                                                                                >
                                                                                    <AiOutlinePlus style={{ color: "#fff", }} size={14} />
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <div className="d-flex align-items-center pt-2 ms-3 me-2">
                                                                        <div className="top-food-btn px-3"
                                                                            onClick={() => {
                                                                                item.quantity = 1;
                                                                                addProduct(item);
                                                                                this.forceStateUpdate();
                                                                            }}
                                                                        >
                                                                            <AiOutlinePlus style={{ color: "#fff", }} size={14} />
                                                                            <span style={{ color: "#fff", }} >
                                                                                ADD
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>

                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div
                                        className="pt-3 d-flex flex-column align-items-center"
                                        style={{ paddingBottom: "20vw" }}
                                    >
                                        <div className="mt-3 d-flex flex-column align-items-center">
                                            <img
                                                src={image}
                                                alt="no items"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "70%",
                                                }}
                                            />
                                        </div>
                                        <span className="mt-4" style={{ fontSize: '1em' }}>
                                            Sorry No items Found !!!
                                        </span>
                                    </div>
                                )}


                            </div>
                        )}

                        <FloatCart float={this.state.floatCart} noFooter={true} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({

    store: state.singleStore.store,
    user: state.user.user,
    cartProducts: state.cart.products,
})

export default connect(mapStateToProps, {
    getSingleItemGroup,
    toggleFavItem,
    addProduct,
    removeProduct,

})(ListItemGroup)
