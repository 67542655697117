
import {
    GET_VENDOR_SINGLE_ORDER,
    GET_ORDER_PRESCRIPTION,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    UPDATE_CART,
    GET_PRESCRIPTION_IMAGES
} from "./actionType";

const initialState = {
    products: [],
    total: [],
    singleOrder: [],
    prescriptionOrder: [],
    prescriptionImages: []
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload),
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload),
            };
        case UPDATE_CART:
            return {
                ...state,
                total: action.payload,
            };
        case GET_VENDOR_SINGLE_ORDER:
            return { ...state, singleOrder: action.payload };
        case GET_ORDER_PRESCRIPTION:
            return { ...state, prescriptionOrder: action.payload };
        case GET_PRESCRIPTION_IMAGES:
            return { ...state, prescriptionImages: action.payload };
        default:
            return state;
    }
}
