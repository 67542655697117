export const GET_VENDOR_SINGLE_ORDER = 'GET_VENDOR_SINGLE_ORDER';
export const TOGGLE_SINGLE_STORE = 'TOGGLE_SINGLE_STORE';
export const ACCEPT_VENDOR_ORDER = 'ACCEPT_VENDOR_ORDER';
export const DECLINE_VENDOR_ORDER = 'DECLINE_VENDOR_ORDER';
export const GET_ALL_PAST_ORDERS = 'GET_ALL_PAST_ORDERS';
export const GET_ORDER_PRESCRIPTION = "GET_ORDER_PRESCRIPTION";
export const PREPARE_VENDOR_ORDER = "PREPARE_VENDOR_ORDER";
export const PICKUP_VENDOR_ORDER = "PICKUP_VENDOR_ORDER";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCT_STORE = "PRODUCT_STORE";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_CART = "UPDATE_CART";
export const GET_PRESCRIPTION_IMAGES = "GET_PRESCRIPTION_IMAGES";
export const COMPELETE_PRESCRIPTION_ORDER = "COMPELETE_PRESCRIPTION_ORDER";
export const COMPELETE_PRESCRIPTION_ORDER_NEW = "COMPELETE_PRESCRIPTION_ORDER_NEW";
