import React, { Component } from "react";
import { RiCoupon3Fill } from "react-icons/ri";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../Elements/loading";

export class OnBoarding extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        if (user && user.success) {
            window.location.replace("/home");
        } else {
            this.setState({ loading: false })
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <div className="mobile-onboarding-img"></div>
                            <p
                                className="text-center px-3 "
                                style={{
                                    color: "#09051C",
                                    fontWeight: 700,
                                    fontSize: "22px",
                                    lineHeight: "29px",
                                    marginTop: "10vh",
                                }}
                            >
                                Find your Comfort Food , Grocery and Essential Medicines
                            </p>
                        </div>
                        <div className="px-4  position-fixed  " style={{ bottom: "62px", width: "100%" }}>
                            <div className="d-flex align-items-center py-2 px-2 mobile-onboarding-btn" style={{ backgroundColor: '#000' }}>
                                {/* <Link className="flex-grow-1" to="/set-location">
                                    <button className="mobile-explore-btn ">Explore</button>
                                </Link> */}
                                <Link className="flex-grow-1" to="/signup">
                                    <button className="mobile-login-btn">Proceed to Login</button>
                                </Link>
                            </div>
                        </div>

                        {/* <div className="position-fixed top-0 px-3 pt-3" style={{ right: '0' }}>
                            <Link className="flex-grow-1" to="/vendor-login">
                                <div className="px-2 py-1 ms-3" style={{ backgroundColor: '#fd8814', borderRadius: '10px ', color: '#fff' }} >
                                    <RiCoupon3Fill /> vendor
                                </div>
                            </Link>
                        </div> */}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps)(OnBoarding);
