import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButton from '../../Elements/MobileBackButton'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import ContentLoader from 'react-content-loader'
import { RiHome2Line } from 'react-icons/ri';
import { TbBriefcase } from 'react-icons/tb';
import { saveAddress } from '../../../redux/mobile/user/action.js';
export class SignUpProcess extends Component {
    state = {
        loading: false,
        type: 'HOME',
        home: true,
        work: false,
        other: false,
        Address: {},
        phone: ''
    }
    handleReport = (type) => {
        this.setState({ type: type })
        if (type == 'HOME') {
            this.setState({ home: true, other: false })
        } else if (type == 'WORK') {
            this.setState({ work: true, other: false, })
        } else {
            this.setState({ other: true, home: false, work: false })
        }
    }
    number = (e) => {
        this.setState({ phone: e })
    }
    saveAddress(e, name) {

        let Address = this.state.Address
        Address[name] = e
        this.setState({ Address: Address })
        ////// console.log(this.state.Address);
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
    }

    onSubmit = (e) => {
        const { user } = this.props;
        ////// console.log(user.data.auth_token);
        this.setState({ loading: true })
        e.preventDefault();
        let formData = new FormData()
        formData.append("name", this.state.Address.name);
        formData.append("token", user.data.auth_token);
        formData.append("address", this.state.Address.address);
        formData.append("number", this.state.Address.number);
        formData.append("house", this.state.Address.house);
        formData.append("landmark", this.state.Address.landmark);
        formData.append("tag", this.state.type);
        this.props.saveAddress(formData).then((response) => {
            if (response && response.payload && response.payload.success) {
                ////// console.log(response)
                this.setState({ loading: false });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}

                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>

                            <rect x="15" y="25" rx="8" ry="8" width="30" height="30" />
                            <rect x="10" y="75" rx="8" ry="8" width="220" height="15" />
                            <rect x="10" y="105" rx="8" ry="8" width="76" height="15" />
                            <rect x="10" y="135" rx="8" ry="8" width="195" height="7" />
                            <rect x="10" y="150" rx="8" ry="8" width="76" height="7" />
                            <rect x="10" y="180" rx="8" ry="8" width="259" height="40" />
                            <rect x="10" y="230" rx="8" ry="8" width="259" height="40" />
                            <rect x="10" y="278" rx="8" ry="8" width="259" height="40" />
                            <rect x="10" y="328" rx="8" ry="8" width="259" height="40" />
                            <rect x="10" y="385" rx="5" ry="5" width="76" height="7" />
                            <rect x="10" y="403" rx="13" ry="13" width="83" height="28" />
                            <rect x="99" y="403" rx="13" ry="13" width="83" height="28" />
                            <rect x="188" y="403" rx="13" ry="13" width="83" height="28" />
                            <rect x="10" y="515" rx="13" ry="13" width="259" height="40" />




                        </ContentLoader>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div>

                            <div className='mobile-sign-up-bg-img py-4'>
                                <div className='pt-2 px-1'>
                                    <MobileBackButton history={this.props.history} />
                                </div>
                                <div className='px-3 mobile-sign-up-head mt-5'>
                                    <div className='px-2'>
                                        <span>Fill your details to get started</span>
                                    </div>
                                </div>
                                <div className='px-4'>
                                    <span>
                                        This data will be displayed in your account profile for security
                                    </span>
                                </div>
                            </div>
                            <div className='px-2'>
                                <div className=' mobile-sign-up-content px-3'>

                                    <div>
                                        <Form onSubmit={(e) => this.onSubmit(e)}>
                                            <InputGroup className="mt-4 mobile-address-input ">
                                                <FormControl
                                                    type="text"
                                                    required
                                                    placeholder="Name"
                                                    className="mobile-address-input py-3"
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.saveAddress(value, 'name');
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mt-3">
                                                <FormControl
                                                    type="text"
                                                    required
                                                    placeholder="Address"
                                                    className="mobile-address-input py-3"
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.saveAddress(value, 'address');
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mt-3">
                                                <FormControl
                                                    type="text"
                                                    required
                                                    placeholder="Apartment"
                                                    className="mobile-address-input py-3"
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.saveAddress(value, 'house');
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mt-3">
                                                <FormControl
                                                    type="tel"
                                                    placeholder="Contact Number"
                                                    required
                                                    className="mobile-address-input py-3"
                                                    minLength="10"
                                                    maxLength="10"
                                                    // isValid={
                                                    // this.state.phone &&
                                                    // this.state.phone.match(
                                                    // /^[6-9]{1}[0-9]{9}$/
                                                    // )
                                                    // }
                                                    // isInvalid={
                                                    // this.state.phone &&
                                                    // !this.state.phone.match(
                                                    // /^[6-9]{1}[0-9]{9}$/
                                                    // )
                                                    // }
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.number(value)
                                                        this.saveAddress(value, 'number');
                                                    }}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mt-3">
                                                <FormControl
                                                    type="text"
                                                    required
                                                    placeholder="Landmark"
                                                    className="mobile-address-input py-3"
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.saveAddress(value, 'landmark');
                                                    }}
                                                />
                                            </InputGroup>
                                            <div className=' mt-4' >
                                                <div className='mobile-sign-up-address-type'>Address Type</div>
                                                <div className='d-flex flex-row justify-content-between gap-2 mt-2  w-100'>
                                                    <div className={this.state.type == 'HOME' ? "mobile-sign-up-adress-btn text-center py-2 px-1" : "mobile-sign-up-adress-btn-2 text-center py-2  px-1"} onClick={() => this.handleReport('HOME')}> <RiHome2Line /> &nbsp;  Home </div>
                                                    <div className={this.state.type == 'WORK' ? "mobile-sign-up-adress-btn text-center py-2 px-1" : "mobile-sign-up-adress-btn-2 text-center py-2 px-1"} onClick={() => this.handleReport('WORK')}><TbBriefcase />  &nbsp;  Work </div>
                                                    <div className={this.state.type == 'OTHER' ? "mobile-sign-up-adress-btn text-center py-2  px-1" : "mobile-sign-up-adress-btn-2 text-center py-2 px-1"} onClick={() => this.handleReport('OTHER')}> Other </div>
                                                </div>
                                            </div>
                                            <div className=" mt-4  w-100 ">
                                                <button
                                                    className="mobile-yr--cpn-sbcr-btn p-3 w-100"
                                                    type='submit'
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>



                            </div>


                        </div>




                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
})


export default connect(mapStateToProps, { saveAddress })(SignUpProcess)
