import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GiHamburger } from 'react-icons/gi';
import { getPopularBrands } from '../../../../redux/mobile/home/action';
import ProgressiveImage from 'react-progressive-image';
import Truncate from 'react-truncate';
import { AiFillStar } from 'react-icons/ai';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api';
import { Rating } from 'react-simple-star-rating';
import { Link } from 'react-router-dom';

export class PopularBrands extends Component {
    state = {
        data: false,
    }
    componentDidMount() {
        this.props.getPopularBrands(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude,1).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ data: true });
            }
        });
    }

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { popular_brands } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {data ? popular_brands && popular_brands.store && popular_brands.store?.length > 0 && (
                    <div className='px-3 mt-3  '>
                        <GiHamburger className='mb-2' color='FF9A62' size={20} /> <span className='top-res-header'>Popular Brands</span>
                        <div className='d-flex flex-row align-items-center'>
                            <div className=' main-menu hidden-scroll-bar gap-3 ' style={{ gridTemplateColumns: "repeat(4 ,1fr)" }}>
                                {popular_brands && popular_brands.store && popular_brands.store.map((store, index) => (
                                    <Link to={"/single-store/" + store.id} key={store.id}>
                                        <div className={`mt-2 ${!store?.is_active && 'store-not-active'}`}>
                                            <div className='position-relative'>
                                                <ProgressiveImage
                                                    delay={20} src={IMAGE_BASE_URL + store.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}  >
                                                    {(src, loading) => (
                                                        <img src={src}
                                                            style={{ width: "20vw", height: "20vw", borderRadius: "50%", objectFit: "cover", backgroundRepeat: "round", }}
                                                            className="" alt="grosav" />
                                                    )}
                                                </ProgressiveImage>
                                                {store.is_free_item ? (
                                                    <>
                                                        <div className="popular-tag-content">
                                                            <div className="popular-tag">
                                                                <p>
                                                                    <div className='w-100'>
                                                                        FREE ITEM
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {(store?.offer_promotion_text || store?.coupons?.length > 0) &&
                                                            <div className="popular-tag-content">
                                                                <div className="popular-tag">
                                                                    <p>
                                                                        {store?.coupons?.length > 0 &&
                                                                            store?.coupons[0]?.description?.length > 0 ? (
                                                                            <>
                                                                                <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }} >
                                                                                    <Truncate lines={1}>
                                                                                        {store?.coupons[0]?.description.toUpperCase()}
                                                                                    </Truncate>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className='w-100'>
                                                                                    <Truncate lines={1}>
                                                                                        {store?.offer_promotion_text}
                                                                                    </Truncate>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )}
                                                {store.is_buy_text ? (
                                                    <div className="ribbon-buy-round">
                                                        <span className='clipped'>{store.is_buy_text}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className='top-res-name d-flex flex-column justify-content-between'>
                                                <Truncate lines={1}>
                                                    <span className='fw-700' >
                                                        {store.name}
                                                    </span>
                                                </Truncate>
                                                {store?.rating > 0 ?
                                                    <div>
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={store?.rating}
                                                        readonly="true"
                                                    />
                                                </div>
                                                :
                                                <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={0}
                                                        readonly="true"
                                                    />
                                                    }

                                                {store?.is_active ? (
                                                    <div className='d-inline-block'>
                                                        <div className='top-res-deli-time text-muted'>
                                                            {store.preparation_time}Min - {store.delivery_radius}km
                                                        </div>
                                                    </div>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </div>
                                        {!store?.is_active ? (
                                            <div className="text-danger" style={{fontSize:'13px'}}>
                                                Store Closed
                                            </div>
                                        ) : (
                                            null
                                        )}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : ("")}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    popular_brands: state.home.popular_brands
})
export default connect(mapStateToProps, {
    getPopularBrands
})(PopularBrands)
