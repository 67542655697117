import React, { Component } from "react";
import { connect } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import img from "../../../../assets/images/1234.jpg";
import Ink from "react-ink";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { getAllItemCategory, getAllItemGroup } from "../../../../redux/mobile/home/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
export class FoodCategory extends Component {
    state = {
        data: false,
    }
    componentDidMount() {
        // this.props.getAllItemCategory().then((response) => {
        //     if (response && response.payload && response.payload.success) {
        //         this.setState({ data: true });
        //     }
        // });
        // this.props.getAllItemGroup(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude);
    }
    render() {
        const { itemCategory, item_group } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {item_group && item_group.itemGroup &&
                    item_group.itemGroup.length > 0 && (
                        <React.Fragment>
                            <div className="px-3 mt-3 d-flex align-items-center">
                                <GiHamburger size={20} style={{ color: "FF9A62" }} />
                                <span className="home-heading ms-2">
                                    Category
                                </span>
                            </div>
                            <div className="d-grid gap-2 pt-2 hidden-scroll-bar pt-3 px-3"
                                style={{ gridTemplateColumns: `repeat(${item_group?.itemGroup.length > 4 ? item_group?.itemGroup?.length : '5'},1fr)`, overflowX: "scroll" }}    >
                                {item_group && item_group.itemGroup && item_group.itemGroup.map((item, index) => (
                                    <Link to={'/item-group-stores/' + item.id} key={item.id}>
                                        <div className="d-flex flex-column  text-center position-relative">
                                            <Ink className="touch-ink" />
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + item.image}
                                                placeholder={
                                                    PLACE_HOLDER_IMAGE
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            height: "20vw",
                                                            width: "20vw", borderRadius: "50%", objectFit: "cover", backgroundRepeat: "round",
                                                        }}
                                                        className="" alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <span className="mt-1 text-black" style={{ fontWeight: "700", fontSize: "11px", }}>
                                                {item.name}
                                            </span>
                                        </div>
                                    </Link>

                                ))}
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    itemCategory: state.home.itemCategory,
    item_group: state.home.item_group
});

export default connect(mapStateToProps, {
    getAllItemCategory,
    getAllItemGroup
})(FoodCategory);
