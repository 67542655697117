import React, { Component } from 'react'
import { connect } from 'react-redux'
import burgur from '../../../assets/images/1234.jpg'
import { Rating } from 'react-simple-star-rating'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import ContentLoader from 'react-content-loader'
import { getOrderRating } from '../../../redux/mobile/mobileOrder/action.js';
import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-image'
import { Form } from 'react-bootstrap'
import { PLACE_HOLDER_IMAGE } from '../../../api/index.js'




class CompletedOrder extends Component {
    state = {
        rating: '',
        setRating: false,
        loading: false,
        feedback: '',
        item:''
    };

    _handleRating = (e) => {
        this.setState({ rating: e / 20 })
        // ////// console.log(e / 20)
    }
    feedback = (e) => {
        this.setState({ feedback: e })
        // ////// console.log(e)
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
    }


    addReview = (e) => {
        e.preventDefault();
        const { user } = this.props
        const formData = new FormData();
        formData.append('token', user.data.auth_token)
        formData.append('id', this.state.id)
        formData.append('message', this.state.feedback)
        formData.append('rating', this.state.rating)
        formData.append('store_id', this.state.item.store.id)
        this.props.getOrderRating(formData)
            .then((response) => {
                if (response.data && response.data.success) {
                    // ////// console.log(response)
                    // this.setState({ loading: false });
                }
            })
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}

                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>

                            <circle cx="140" cy="140" r="50" />
                            <rect x="95" y="250" rx="10" ry="10" width="92" height="18" />
                            <rect x="72" y="275" rx="10" ry="10" width="140" height="18" />
                            <rect x="85" y="318" rx="10" ry="10" width="115" height="5" />
                            <rect x="81" y="345" rx="10" ry="10" width="125" height="18" />
                            <rect x="10" y="490" rx="10" ry="10" width="259" height="30" />
                            <rect x="10" y="530" rx="10" ry="10" width="129" height="35" />
                            <rect x="230" y="535" rx="10" ry="10" width="40" height="27" />
                        </ContentLoader>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div>
                            <Form onSubmit={(e) => this.addReview(e)}>
                                <div className='mobile-wallet-bg d-flex justify-content-center align-items-center'>
                                    <div className='mt-5'>
                                        <div className="mobile-cmpltd-order-round d-flex justify-content-center align-items-center mt-5">
                                            <div className="">
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={burgur}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            className="" style={{
                                                                objectFit: 'cover',
                                                                height: "30vw",
                                                                width: "30vw",
                                                                borderRadius: "50%",
                                                                backgroundRepeat: "round",
                                                            }}
                                                            src={src}
                                                            alt='main-item'
                                                        />
                                                    )}

                                                </ProgressiveImage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mobile-cmpltd-order-text mt-4">
                                    <span>Thank You!</span>
                                </div>
                                <div className="mobile-cmpltd-order-text">
                                    <div>
                                        <span>Order Completed</span>
                                    </div>
                                </div>
                                <div className="text-muted d-flex justify-content-center mobile-cmpltd-order-rate mt-3">
                                    <span>Please Rate Your Order</span>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <Rating size={'2.5em'} className='mobile-rate-star' ratingValue={this.state.rating && this.state.rating * 20}
                                        onClick={(e) => this._handleRating(e)} />
                                </div>
                                <div className="position-fixed bottom-0 w-100">
                                    <div className="d-flex flex-row align-items-center mobile-cmpltd-order-cmnt-div px-3 mx-3 py-2 mt-5">
                                        <span className='ms-2'>
                                            <HiOutlinePencilAlt
                                                style={{ fontSize: "1.5em", color: "#FEA24B" }} />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="Leave Feedback about Delivery"
                                            className="mobile-cmpltd-order-cmnt ms-2"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.feedback(value, e);
                                            }}
                                        />
                                    </div>
                                    <div className='d-flex pt-3 justify-content-between px-3 pb-3'>
                                        <button className='mobile-cmpltd-order-submit py-3 ' type='submit' > Submit</button>
                                        <Link to='/home'>
                                            <button className='mobile-cmpltd-order-skip p-4 ' >Skip</button>
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,

})

export default connect(mapStateToProps, { getOrderRating })(CompletedOrder)
