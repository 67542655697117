import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';


class MobileBackButtonWithSearch extends Component {
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-between">
                    <div className=" ms-3 position-absolute mobile-back-button" onClick={() => this.onHandleBack()}>
                        <span className="fw-bold fs-2 back-button-icon">
                            <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                        </span>
                    </div>

                    <div className=' mt-3 mobile-back-button-title fs-5'>
                        <span className='mt-3'>{this.props.title}</span>
                    </div>

                    {/* <Link to="/explore-menu">
                        <div className="store-search-button p-3">
                            <BsSearch size={"1.4em"} />
                        </div>
                    </Link> */}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps, {})(MobileBackButtonWithSearch)
