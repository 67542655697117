import {
    GET_ANY_STORE_DELIVERY_CHARGE,
} from "./actionType";

const initialState = {
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {

        default:
            return state;
    }
}
