import React, { Component } from "react";
import { connect } from "react-redux";
import img from "../../../assets/images/grosav-logo.png";
import Loading from "../../Elements/loading";
import ProgressiveImage from "react-progressive-image";
import {
    getOrderPrescription,
    compeletePrescriptionOrder,
    compeletePrescriptionOrderNew,
    clearCart,
} from "../../../redux/vendor/order/action";
import {
    IMAGE_BASE_URL,
    PLACE_HOLDER_IMAGE,
    WEBSITE_BASE_URL,
} from "../../../api";
import { BottomSheet } from "react-spring-bottom-sheet";
import Ink from "react-ink";
import { Link } from "react-router-dom";
import { updateCart } from "../../../redux/vendor/order/action";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineStop } from "react-icons/ai";
import Truncate from "react-truncate";
// import OrderCart from './OrderCart';
import ViewImage from "./ViewImage";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import { BsDot } from "react-icons/bs";
import { TiMinus, TiPlus } from "react-icons/ti";
import { IoClose } from "react-icons/io5";



export class Prescription extends Component {
    state = {
        loading: false,
        acceptSheet: false,
        declineSheet: false,
        isOpen: false,
        orderitems: [],
        medicines: [{ name: '', price: '', quantity: 1 }],
        quantity: 1,
        errorMessage: '',
        total : 0
    };
    componentDidMount() {
        const { user, store, order } = this.props;



        this.setState({ loading: true });
        this.props
            .getOrderPrescription(
                user.data.auth_token,
                this.props.match.params.id,
                store.id
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        orderitems: response?.payload?.order?.orderitems,
                    });
                }


            });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps?.order?.order?.orderitems) {
            const medicines = nextProps.order.order.orderitems.map(item => ({
                name: item.name,
                price: item.price,
                quantity: item.quantity
            }));

            const total = medicines.reduce((acc, curr) => acc + (curr.price * curr.quantity), 0);

            this.setState({ medicines, total });
        }
    }



    _acceptSheetOpen = (order_id) => {
        this.setState({ acceptSheet: true, order_id: order_id });
    };
    _acceptSheetClose = () => {
        this.setState({ acceptSheet: false });
    };
    _declineSheetOpen = (order_id) => {
        this.setState({ declineSheet: true, order_id: order_id });
    };
    _declineSheetClose = () => {
        this.setState({ declineSheet: false });
    };
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    addProductQuantity = (product) => {
        const { vendorProducts, updateCart, user } = this.props;
        let productAlreadyInCart = false;
        vendorProducts.forEach((cp) => {
            if (cp.id === product.id) {
                if (
                    JSON.stringify(cp.selectedaddons) ===
                    JSON.stringify(product.selectedaddons)
                ) {
                    cp.quantity += 1;
                    productAlreadyInCart = true;
                }
            }
        });

        if (!productAlreadyInCart) {
            vendorProducts.push(product);
        }
        updateCart(vendorProducts);
    };

    removeProductQuantity = (product) => {
        const { vendorProducts, updateCart, cartTotal } = this.props;
        const index = vendorProducts.findIndex(
            (p) =>
                p.id === product.id &&
                JSON.stringify(p) === JSON.stringify(product)
        );
        //// console.log(index)
        if (index >= 0) {
            vendorProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (JSON.stringify(cp) === JSON.stringify(product)) {
                        if (cp.quantity === 1) {
                            vendorProducts.splice(index, 1);
                        } else {
                            cp.quantity -= 1;
                        }
                    }
                }
            });

            updateCart(vendorProducts);
        }
    };
    viewAllProducts = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };


    compeleteOrder = () => {
        this.setState({ loading: false });
        const { user, vendorProducts, store, orderTotal } = this.props;
        this.props.compeletePrescriptionOrderNew(
                user?.data?.auth_token,
                this.state.medicines,
                this.state.total,
                this.props.match.params.id,
                store?.id
            )
            .then((response) => {

                if (response && response.payload && response.payload.success) {
                    this.props.clearCart();
                    this.props.history.push("/vendor-order");
                }
       });
    };

    // compeleteOrder = () => {
    //     this.setState({ loading: false });
    //     // console.log(this.state.medicines);
    //     const { user, vendorProducts, store, orderTotal } = this.props;
    //     // this.props
    //     //     .compeletePrescriptionOrder(
    //     //         user?.data?.auth_token,
    //     //         vendorProducts,
    //     //         this.props.match.params.id,
    //     //         orderTotal?.totalPrice,
    //     //         store?.id
    //     //     )
    //     //     .then((response) => {
    //     //         if (response && response.payload && response.payload.success) {
    //     //             this.props.clearCart();
    //     //             this.props.history.push("/vendor-order");
    //     //         }
    //     });
    // };

    // handleIncrement = () => {
    //     this.setState((prevState) => ({ quantity: prevState.quantity + 1 }));
    // };

    // handleDecrement = () => {
    //     this.setState((prevState) => {
    //         if (prevState.quantity > 1) {
    //             return { quantity: prevState.quantity - 1 };
    //         }
    //         return null;
    //     });
    // };

    handleAddMedicine = () => {
        // Validation check
        // const allFieldsFilled = this.state.medicines.every(
        //     (medicine) => medicine.name.trim() !== '' && medicine.price.trim() !== ''
        // );

        // if (allFieldsFilled) {

        //     this.setState((prevState) => ({
        //         medicines: [...prevState.medicines, { name: '', price: 0, quantity: 1 }],
        //         errorMessage: '',
        //     }));
        // } else {

        //     this.setState({
        //         errorMessage: 'Please fill in all fields before adding another medicine.',
        //     });
        // }

        this.setState((prevState) => ({
            medicines: [...prevState.medicines, { name: '', price: 0, quantity: 1 }],
            errorMessage: '',
        }));
    };

    handleInputChange = (index, event) => {
        const { name, value } = event.target;
        this.setState((prevState) => {
          const medicines = [...prevState.medicines];
          medicines[index][name] = value;
          const total = this.calculateTotal();
          return { medicines, errorMessage: '', total };
        });
      };

      removeMedicine = (index, event) => {
        const updatedMedicines = [...this.state.medicines];
        updatedMedicines.splice(index, 1);
        const total = this.calculateTotal(updatedMedicines);
        this.setState({ medicines: updatedMedicines, total });
    };



    calculateTotal = (medicines = this.state.medicines) => {
        return medicines.reduce((total, medicine) => {
            return total + (parseFloat(medicine.price) || 0) * medicine.quantity;
        }, 0);
    };

    handleQuantityChange = (index, increment) => {

        this.setState((prevState) => {
            const medicines = [...prevState.medicines];
            medicines[index].quantity += increment;
            const total = medicines.reduce((acc, med) => acc + (med.price * med.quantity), 0);
            this.setState({ total : total })
            return { medicines };
        });
    };





    render() {
        const { order, vendorProducts, orderTotal, images } = this.props;
        // // console.log("orderTotal : ", orderTotal);

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <MobileBackButtonWithCenterTitle
                                title="Prescription"
                                history={this.props.history}
                            />
                        </div>
                        {/* {order && order.order && order.order.is_prescription ? ( */}
                        <React.Fragment>
                            <div className="d-flex justify-content-center">
                                <span className="text-muted fs-6 fw-bolder">
                                    Check the prescription and add items
                                </span>
                            </div>
                            <div className="px-3 mt-3">
                                <div
                                    className="main-menu hidden-scroll-bar gap-2"
                                    style={{
                                        gridTemplateColumns: `repeat(${order &&
                                            order.images &&
                                            order.images.length + 1
                                            },2fr)`,
                                    }}
                                >
                                    {order &&
                                        order.images &&
                                        order.images.map((item, index) => (
                                            <div className="d-flex flex-column align-items-center">
                                                {/* {//// console.log(item, 'item')} */}
                                                <ViewImage item={item} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div
                                className="px-4"
                                style={{ paddingBottom: "45vw" }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="fs-6 fw-bolder mt-3">Items</div>
                                    <div className="fs-6 fw-bolder mt-3">

                                        <span style={{ "color": "white", "background": "orange", "padding": "5px 10px", "borderRadius": "10px", "fontSize": "13px" }} onClick={this.handleAddMedicine}>
                                            <TiPlus style={{ "color": "white", "fontSize": "12px", "marginRight": "4px" }} />
                                            Add Medicine</span>
                                    </div>
                                </div>

                                {this.state.errorMessage && (
                                    <p style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>
                                        {this.state.errorMessage}
                                    </p>
                                )}


                                {this.state.medicines.map((medicine, index) => (
                                    <div
                                        key={index}
                                        className="align-items-center mt-3 single-medicine"
                                        style={{
                                            border: '1px solid rgb(217, 217, 217)',
                                            borderRadius: '6px',
                                            paddingBottom: '12px',
                                            margin: '0 !important',
                                            width: '100%',
                                            marginTop: '11px !important',
                                        }}
                                    >
                                        <div className="d-flex align-items-center mt-2 mb-2" style={{ fontWeight: '500', fontSize: '12px', lineHeight: '16px', color: '#B3B3B3', position: 'relative' }}>
                                            <BsDot style={{ color: '#E86D28', fontSize: '1.5rem' }} />
                                            <input
                                                type="text"
                                                placeholder="Add item"
                                                name="name"
                                                value={medicine.name}
                                                onChange={(e) => this.handleInputChange(index, e)}
                                                className="py-2"
                                                style={{
                                                    border: 'none',
                                                    outline: 'none',
                                                    color: 'black',
                                                    fontSize: '16px',
                                                    backgroundColor: 'transparent',
                                                    caretColor: '#FF9012',
                                                    width: '75%',
                                                }}
                                            />
                                            <IoClose  style={{"color":"#ff4d4da8","position":"absolute","right":"8px","top":"0","fontSize":"21px"}} onClick={(e) => this.removeMedicine(index, e)} />
                                        </div>
                                        <div className="d-flex justify-content-between ps-3 pe-3">
                                            <div className="input-group w-50">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">₹</span>
                                                </div>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    value={medicine.price}
                                                    onChange={(e) => this.handleInputChange(index, e)}
                                                    className="form-control"
                                                    style={{ color: 'green', fontWeight: 'bold' }}
                                                    aria-label="Amount (to the nearest INR)"
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">.00</span>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex justify-content-between align-items-center bg-white"
                                                style={{
                                                    border: '1px solid orange',
                                                    borderRadius: '8px',
                                                    height: '37px',
                                                    padding: '18px 12px',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                <TiMinus onClick={() => this.handleQuantityChange(index, -1)} style={{ fontSize: '10px', color: '#E86D28' }} />
                                                <div className="ms-2 me-2" style={{ color: '#E86D28' }}>
                                                    {medicine.quantity}
                                                </div>
                                                <TiPlus onClick={() => this.handleQuantityChange(index, 1)} style={{ fontSize: '10px', color: '#E86D28' }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {( this.state?.total >= 1) && (
                                <div
                                    className="position-fixed w-100 px-3 py-3"
                                    style={{
                                        bottom: "0",
                                        backgroundColor: "#fff",
                                        boxShadow: "0px 0px 20px 5px #cecece",
                                        borderRadius: "15px 15px 0px 0px",
                                    }}
                                >
                                    <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{ zIndex: "99" }}
                                    >
                                        <div
                                            className="d-flex flex-column justify-content-between"
                                            style={{
                                                fontSize: "1.2em",
                                                fontWeight: "700",
                                            }}
                                        >
                                            <div>
                                                Total : <span style={{ color: "#00bf62" }}>Rs. { this.state.total}</span>
                                            </div>

                                        </div>
                                        <div
                                            className="btn text-white py-2 "
                                            style={{
                                                backgroundColor: "#fd8814",
                                                borderRadius: "10px",
                                                fontWeight: "700",
                                            }}
                                            onClick={this.compeleteOrder}
                                        >
                                            Complete Order
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>

                        <BottomSheet
                            open={this.state.acceptSheet}
                            onDismiss={() => this._acceptSheetClose()}
                        >
                            <div
                                className="d-flex flex-column align-items-center"
                                style={{
                                    backgroundColor: "#fff",
                                    padding: "13px",
                                }}
                            >
                                <div className="text-center">
                                    <h3>Accept Order</h3>
                                </div>
                                <div className="text-muted ms-4 mt-3">
                                    <span>
                                        Are you sure you want to Accept this
                                        order?
                                    </span>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center mt-4 mb-4 w-100">
                                    <div
                                        className="text-center py-3 position-relative decline-button"
                                        onClick={(e) =>
                                            this._acceptSheetClose(e)
                                        }
                                    >
                                        Close
                                        <Ink
                                            duration={500}
                                            style={{ color: "#AAAAAA" }}
                                        />
                                    </div>
                                    <div className="text-center accept-button ms-2 py-3 position-relative">
                                        <Link
                                            to={
                                                "/vendor-single-order/" +
                                                order.id +
                                                "/accept"
                                            }
                                        >
                                            <span className="text-white">
                                                Accept Order
                                            </span>
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </BottomSheet>
                        <BottomSheet
                            open={this.state.isOpen}
                            onDismiss={() => this.viewAllProducts()}
                        >
                            <div className="p-3">
                                <div
                                    className="text-center mt-2"
                                    style={{
                                        fontSize: "1.3em",
                                        fontWeight: "700",
                                    }}
                                >
                                    Items
                                </div>
                                {vendorProducts &&
                                    vendorProducts.map((product, index) => (
                                        <div
                                            className="d-flex flex-column justify-content-between align-items-center py-2"
                                            style={{ zIndex: "98" }}
                                        >
                                            <div
                                                className="d-flex flex-row justify-content-between w-100"
                                                style={{
                                                    fontSize: "1.2em",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <div
                                                    className="flex-grow-1 d-flex flex-row justify-content-between"
                                                    style={{
                                                        marginRight: "10vw",
                                                    }}
                                                >
                                                    <div>{product.name}</div>

                                                    <div>
                                                        {product.quantity} x{" "}
                                                        {product.selling_price}
                                                    </div>
                                                </div>

                                                <div className="">
                                                    Rs.{" "}
                                                    {Math.round(
                                                        product.quantity *
                                                        product.selling_price
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                <hr style={{ color: "#cecece" }} />
                                <div
                                    className="d-flex flex-column justify-content-between align-items-center py-2"
                                    style={{ zIndex: "98" }}
                                >
                                    <div
                                        className="d-flex flex-row justify-content-between w-100"
                                        style={{
                                            fontSize: "1.2em",
                                            fontWeight: "700",
                                        }}
                                    >
                                        <div className="flex-grow-1 d-flex flex-row justify-content-between pe-4">
                                            <div>Total</div>
                                        </div>
                                        <div className="">
                                            Rs. {orderTotal?.total?.totalPrice}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="text-end mt-2"
                                    style={{
                                        fontSize: "1.3em",
                                        fontWeight: "700",
                                    }}
                                >
                                    <button
                                        className="text-center py-2 accept-button"
                                        onClick={(e) => this.viewAllProducts(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </BottomSheet>
                        <BottomSheet
                            open={this.state.declineSheet}
                            onDismiss={() => this._declineSheetClose()}
                        >
                            <div
                                className="d-flex flex-column align-items-center"
                                style={{
                                    backgroundColor: "#fff",
                                    padding: "13px",
                                }}
                            >
                                <div className="text-center">
                                    <h3>Cancel Order</h3>
                                </div>
                                <div className="text-muted ms-4 mt-3">
                                    <span>
                                        Are you sure you want to cancel this
                                        order?
                                    </span>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center mt-4 mb-4 w-100">
                                    <div
                                        className="text-center py-3 position-relative decline-button"
                                        onClick={this._declineSheetClose}
                                    >
                                        Close
                                        <Ink
                                            duration={500}
                                            style={{ color: "#AAAAAA" }}
                                        />
                                    </div>
                                    <div className="text-center accept-button ms-2 py-3 position-relative">
                                        <Link
                                            to={
                                                "/vendor-single-order/" +
                                                order.id +
                                                "/reject"
                                            }
                                        >
                                            <span className="text-white">
                                                Cancel Order
                                            </span>
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </BottomSheet>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    vendorProducts: state.order.products,
    user: state.vendor.store_user,
    store: state.vendor.store.store,
    order: state.order.prescriptionOrder,
    orderTotal: state.order,
});
export default connect(mapStateToProps, {
    getOrderPrescription,
    updateCart,
    compeletePrescriptionOrder,
    compeletePrescriptionOrderNew,
    clearCart,
})(Prescription);
