import React, { Component } from "react";
import { connect } from "react-redux";
import VendorBackButton from "../../Elements/VendorBackButton";
import { RiCoupon3Fill } from "react-icons/ri";
import { MdEditNote } from "react-icons/md";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Link } from "react-router-dom";
import Loading from "../../Elements/loading";
import { getVendorItems } from "../../../redux/vendor/items/action";
import { updateItemDiscount } from "../../../redux/vendor/coupon/action";
import { updateAllStoreItemDiscount } from "../../../redux/vendor/coupon/action";
import { getAllVendorCoupons } from "../../../redux/vendor/coupon/action";
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../api";
import Truncate from "react-truncate";
export class VendorOffer extends Component {
    state = {
        coupon: false,
        category: false,
        item: false,
        loading: false,
        type: "",
        selectedItem: "",
        itemDiscount: {},
        items: [],
    };
    componentDidMount() {
        const { user } = this.props;
        const { store } = this.props;
        this.props.getVendorItems(user.data.auth_token, store.user.stores[0].id);
        const { items } = this.props;
        this.setState({ items: items })
        this.props.getAllVendorCoupons(user.data.auth_token, store.user.stores[0].id);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    openDiscountMenu = () => {
        this.setState({ coupon: true });
        // //// console.log(this.state.open)
    };
    _closeDiscountMenu = () => {
        this.setState({ coupon: false });
    };
    openCategoryMenu = (t) => {
        this.setState({ type: t });
        this.setState({ category: true });
        this.setState({ coupon: false });
    };
    closeCategoryMenu = () => {
        this.setState({ category: false });
    };
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { itemDiscount } = this.state;

        if (this.state.type === "single") {
            let formData = new FormData();
            formData.append("discount_price", itemDiscount.discount_price);
            formData.append("id", this.state.selectedItem);
            formData.append("token", user.data.auth_token);

            this.props.updateItemDiscount(formData).then((response) => {
                if (response) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 500);
                    window.location.replace("/vendor-offer");
                    this.props.history.replace("/vendor-offer");
                }
            });
        } else {
            let formData = new FormData();
            formData.append("discount_price", itemDiscount.discount_price);
            formData.append("id", user.data.store[0].id);
            formData.append("token", user.data.auth_token);

            this.props.updateAllStoreItemDiscount(formData).then((response) => {
                if (response) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 500);
                    window.location.replace("/vendor-offer");
                    this.props.history.replace("/vendor-offer");
                }
            });
        }
    };
    handleInput(e, name, index) {
        let discount = this.state.itemDiscount;
        discount[name] = e;
        this.setState({ itemDiscount: discount });
    }
    selectedItem(id) {
        // //// console.log(id)
        this.setState({ selectedItem: id });
    }
    render() {
        const { items } = this.state;
        const { coupons } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Offers" />
                        </div>
                        <div className="px-4" style={{ paddingTop: '20vw' }}>
                            {/* <div classNam="">
                                <div className="px-3 mt-5 pb-1 offer-box">
                                    <div
                                        className=" pt-3"
                                        style={{
                                            fontWeight: "800",
                                            fontSize: "22px",
                                            lineHeight: "30px",
                                            color: " #38393D",
                                        }}
                                    >
                                        {this.props?.user?.user?.stores && this.props?.user?.user?.stores[0]?.name}
                                    </div>

                                    <div className="mt-4 ms-3 offer-discnt-text">
                                        <span>Discount Price</span>
                                    </div>
                                    <Form>
                                        <div className="px-2">
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 mt-1 mb-2 offer-input py-2"
                                                style={{ boxSizing: "border-box", }}
                                            >
                                                <FormControl
                                                    type="number"
                                                    min={0}
                                                    placeholder="Enter Discount Price"
                                                    className="offer-input-text ms-2"
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        this.handleInput(value, "discount_price");
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Form>
                                    <div
                                        className="mt-4 mb-4 w-100 px-2"
                                        onClick={() => this.openDiscountMenu()}>
                                        <button className="discount-btn w-100 p-3">
                                            Apply Discount
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="mt-3 px-1">
                                <div className="mb-3 offer-coupon-head">
                                    <span className="">Make your Coupons</span>
                                </div>
                                <Link to="/vendor-coupon">
                                    <button className="coupon-btn p-3 w-100 mt-3">
                                        Make Coupon
                                    </button>
                                </Link>
                            </div>
                            <div className="mt-4 px-1 py-2">
                                {coupons?.stores?.length > 0 ? (
                                    <>
                                        <div className="mb-3 offer-coupon-head">
                                            <span className="">Coupons</span>
                                        </div>
                                        {coupons?.stores[0]?.coupons?.map((coupon, index) => (
                                            <div className='d-flex flex-row mt-2 mb-2 w-100' style={{ boxShadow: '0px 0px 10px 0px #dddddd', borderRadius: '15px' }}>

                                                <div className="" style={{ backgroundColor: '#fd8814', height: '36vw', width: '10vw', borderRadius: '15px 0px 0px 15px' }}>
                                                    <div className="vendor-coupon-code">
                                                        {coupon.discount_type === "FIXED" ? "Rs." + coupon.coupon_discount : coupon.coupon_discount + "%"} OFF
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 position-relative w-100 coupon-borders" >
                                                    <ProgressiveImage
                                                        delay={20}
                                                        src={IMAGE_BASE_URL + "/" + coupon.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                    >
                                                        {(src, loading) => (
                                                            <img
                                                                className="mobile-home-cpn-img" style={{
                                                                    objectFit: 'cover',
                                                                    width: '100%'
                                                                }}
                                                                src={src}
                                                                alt='main-item'
                                                            />
                                                        )}

                                                    </ProgressiveImage>
                                                    {/* <button className='cpn-btn position-absolute' style={{ top: '2px', right: '2px' }}> <RiCoupon3Fill /> {coupon.code}</button> */}
                                                    <div className="d-flex flex-row align-items-center justify-content-between position-absolute px-2 pt-2" style={{ bottom: '4px', right: '2px' }}>
                                                        <Link to={"/vendor-edit-coupon/" + coupon.id} className="w-100">
                                                            <div className="w-100 px-2 py-2" style={{ backgroundColor: '#fd8814', borderRadius: ' 50px ', color: '#fff' }} >
                                                                <MdEditNote size={20} />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="position-absolute px-2 py-1 w-100" style={{ top: '0', }}>
                                                        <div className="d-flex flex-row align-items-center justify-content-between px-2 py-1" style={{ backgroundColor: '#fff', borderRadius: ' 12px ' }}>
                                                            <div className="flex-grow-1 ms-2 py-2" style={{ color: '#000', fontWeight: '750', fontSize: '1.1em' }}>
                                                                <Truncate lines={1}>
                                                                    {coupon.name?coupon.name:'--not given--'}
                                                                </Truncate>
                                                            </div>
                                                            <div className="px-2 py-1 ms-3" style={{ backgroundColor: '#fd8814', borderRadius: '10px ', color: '#fff' }} >
                                                                <RiCoupon3Fill /> {coupon.code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (" ")}
                            </div>
                        </div>
                        <BottomSheet
                            open={this.state.coupon}
                            onDismiss={() => this._closeDiscountMenu()}
                            snapPoints={({ maxHeight }) => 0.29 * maxHeight}
                        >
                            <div className="px-3 pb-5">
                                <div
                                    className="mt-1 mb-2 offer-coupon-head "
                                    style={{ fontWeight: "600", fontSize: "16px", }}
                                >
                                    Make Your Coupons For
                                </div>
                                <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                    <div className="d-grid gap-2">
                                        <button
                                            className="offer-sheet-btn"
                                            onClick={() => this.openCategoryMenu("single")}
                                        >
                                            Single Item
                                        </button>
                                        <button
                                            className="offer-sheet-btn"
                                            onClick={() => this.openCategoryMenu("total")}
                                        >
                                            All Items
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </BottomSheet>
                        <BottomSheet
                            open={this.state.category}
                            onDismiss={() => this.closeCategoryMenu()}
                            snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                        >
                            {this.state.type === "single" ? (
                                <div className="px-3 pb-5">
                                    <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                        <div className="d-grid gap-2" style={{ paddingBottom: '20vw' }}>
                                            {items?.items?.map(
                                                (item, index) => (
                                                    <button
                                                        className={this.state.selectedItem == item.id
                                                            ? "offer-sheet-btn bg-success text-white"
                                                            : "offer-sheet-btn text-dark"}
                                                        onClick={(e) => { this.selectedItem(item.id, "item"); }}
                                                    >
                                                        {item.name}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                        <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                                            <button
                                                className="coupon-btn p-3 w-100 mt-3 "
                                                type="submit"
                                                onClick={this.onSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="px-3 pb-5">
                                    <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                        <div className="px-3 mt-3" style={{ fontSize: '16px', fontWeight: '600' }}>
                                            Do you want to Apply this
                                            Discount to All your Items?
                                        </div>
                                        <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                                            <button
                                                className="coupon-btn p-3 w-100 mt-3"
                                                type="submit"
                                                onClick={this.onSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </BottomSheet>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    items: state.items.items,
    store: state.vendor.store_user,
    coupons: state.coupons.coupons,
});

export default connect(mapStateToProps, {
    getVendorItems,
    updateItemDiscount,
    updateAllStoreItemDiscount,
    getAllVendorCoupons,
})(VendorOffer);
