//store category
export const GET_STORE_CATEGORY_TOP_ITEMS = "GET_STORE_CATEGORY_ITEMS"
export const GET_STORE_CATEGORY_STORES = "GET_STORE_CATEGORY_STORES"
export const GET_STORE_CATEGORY_ITEM_CATEGORY = "GET_STORE_CATEGORY_ITEM_CATEGORY"
export const GET_STORE_CATEGORY_BANNER = "GET_STORE_CATEGORY_BANNER"
export const GET_STORE_CATEGORY_TOP_STORES = "GET_STORE_CATEGORY_TOP_STORES"
export const GET_STORE_CATEGORY_TOP_DEALS = "GET_STORE_CATEGORY_TOP_DEALS"
export const GET_STORE_CATEGORY_RECOMMENDED_STORES = "GET_STORE_CATEGORY_RECOMMENDED_STORES"
export const GET_STORE_CATEGORY_NEAR_STORES = "GET_STORE_CATEGORY_NEAR_STORES"
export const GET_SINGLE_DEAL = "GET_SINGLE_DEAL"
export const GET_SINGLE_BANNER = "GET_SINGLE_BANNER"
export const GET_STORE_CATEGORY = "GET_STORE_CATEGORY";
export const GET_STORE_CATEGORY_TODAY_DEALS = "GET_STORE_CATEGORY_TODAY_DEALS";
