import { PLACE_ORDER, EMPTY_CHECKOUT, GET_ORDER, GET_NEAR_STORES } from "./actionTypes";
import { PLACE_ORDER_URL, GET_CUSTOMER_ORDER_STATUS_URL, GET_NEAR_STORES_URL, PAYTM_STATUS_CHECK_URL, ANY_STORE_PAYTM_STATUS_CHECK_URL, COURIER_PAYTM_STATUS_CHECK_URL } from "../../../api";
import Axios from "axios";
import { APPLY_COUPON } from "../mobileCoupon/actionTypes";
import { updateCart } from "../total/actions";

export const placeOrder = (
    token,
    order,
    store_id,
    walletAmount,
    partial_wallet,
    coupon,
    location,
    order_comment,
    total,
    method,
    // delivery_type,
    distance,
    tipAmount,
    is_scheduled,
    scheduled_date,
    scheduled_time,
    order_amounts,
    current_slab,
    code,
    image

) => (dispatch, getState) => {
    return Axios.post(PLACE_ORDER_URL, {
        token,
        order,
        store_id: store_id,
        walletAmount,
        partial_wallet: partial_wallet,
        coupon: coupon,
        location: location,
        order_comment: order_comment,
        total: total,
        method: method,
        // delivery_type: delivery_type,
        dis: distance,
        tipAmount: tipAmount,
        is_scheduled: is_scheduled,
        scheduled_date: scheduled_date,
        scheduled_time: scheduled_time,
        order_amounts: order_amounts,
        current_slab: current_slab,
        code,
        image

    })
        .then((response) => {
            const checkout = response.data;

            if (checkout.success) {
                dispatch({ type: PLACE_ORDER, payload: checkout });

                const state = getState();
                // // console.log(state);
                const cartProducts = state.cart.products;

                // const user = state.user.user;
                // localStorage.removeItem("orderComment");

                for (let i = cartProducts.length - 1; i >= 0; i--) {
                    // remove all items from cart
                    cartProducts.splice(i, 1);
                }

                dispatch(updateCart(cartProducts));

                localStorage.removeItem("appliedCoupon");
                localStorage.removeItem("is_scheduled");
                localStorage.removeItem("orderComment");
                localStorage.removeItem("autoCouponApplied");
                const coupon = [];
                dispatch({ type: APPLY_COUPON, payload: coupon });

                return checkout;
            } else {
                return checkout;
            }
        })
        .catch(function (error) {
            return error.response;
        });


};

export const emptyCheckout = () => (dispatch) => {

    const checkout = [];
    return dispatch({ type: EMPTY_CHECKOUT, payload: checkout });
};

// order details after payment

export const orderStatus = (token, id) => (dispatch) => {
    return Axios.post(GET_CUSTOMER_ORDER_STATUS_URL, {
        token, id
    })
        .then((response) => {
            let order = response.data;
            return dispatch({
                type: GET_ORDER,
                payload: order
            })

        })
}


export const getNearStores = (latitude, longitude, store_id) => (dispatch) => {
    return Axios.post(GET_NEAR_STORES_URL, {
        latitude, longitude, store_id
    })
        .then((response) => {
            let address = response.data;
            return dispatch({
                type: GET_NEAR_STORES,
                payload: address
            })

        })
}


export const paymentStatusCheck = (id) => (dispatch) => {
    return Axios.post(PAYTM_STATUS_CHECK_URL, { id })
}
export const anyStorePaymentStatusCheck = (id) => (dispatch) => {
    return Axios.post(ANY_STORE_PAYTM_STATUS_CHECK_URL, { id })
}
export const courierPaymentStatusCheck = (id) => (dispatch) => {
    return Axios.post(COURIER_PAYTM_STATUS_CHECK_URL, { id })
}

