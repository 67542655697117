import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { BiUser } from 'react-icons/bi';
import report from '../../../assets/images/report.png'
import inventory from '../../../assets/images/inventory.png'
import file from '../../../assets/images/file.png'
class VendorFooter extends Component {
    state = {
        active_orders: false,
        active_inventory: false,
        active_reports: false,
        active_profile: false,
    }

    componentDidMount() {
        if (this.props.active_orders === true) {
            this.setState({ active_orders: true });
        }
        if (this.props.active_inventory === true) {
            this.setState({ active_inventory: true });
        }
        if (this.props.active_reports === true) {
            this.setState({ active_reports: true });
        }
        if (this.props.active_profile === true) {
            this.setState({ active_profile: true });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 user-select-none Vendor-fixed py-3 footer-container" >
                    <div className='d-flex flex-row justify-content-evenly align-items-center '>

                        <Link to={"/vendor-order"} className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_orders ? (
                                    <React.Fragment>
                                        <div className="">
                                            <button type="button" className="footer-btn-btn py-2 px-3">
                                                <span>
                                                    <img src={report} alt="report" size={'1.5em'} className="footer-icon me-1" />
                                                    <span className="footer-icon-text me-1 ">Orders</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <img src={report} alt="report" size={'1.5em'} className="footer-icon" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                        <Link to={"/vendor-inventory"} className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_inventory ? (
                                    <React.Fragment>
                                        <div className="">
                                            <button type="button" className="footer-btn-btn py-2 px-3">
                                                <span>
                                                    <img src={inventory} alt='inventory' size={'1.5em'} className="footer-icon me-1" />
                                                    <span className="footer-icon-text me-1 ">Inventory</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <img src={inventory} alt='inventory' size={'1.5em'} className="footer-icon" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                        <Link to={"/vendor-business-report"} className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_reports ? (
                                    <React.Fragment>
                                        <div className="">
                                            <button type="button" className="footer-btn-btn py-2 px-3">
                                                <span>
                                                    <img src={file} alt='file' size={'1.5em'} className="footer-icon me-1" />
                                                    <span className="footer-icon-text me-1 ">Reports</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <img src={file} alt='file' size={'1.5em'} className="footer-icon" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                        <Link to={"/vendor-profile"} className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_profile ? (
                                    <React.Fragment>
                                        <div className="">
                                            <button className="footer-btn-btn py-2 px-3">
                                                <span>
                                                    <BiUser size={'1.5em'} className="footer-icon me-1" />
                                                    <span className="footer-icon-text me-1 ">Profile</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <BiUser size={'1.5em'} className="footer-icon" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps, {})(VendorFooter)
