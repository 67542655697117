import React, { Component } from "react";
import { connect } from "react-redux";
import { FaAppleAlt } from "react-icons/fa";
import { AiFillStar, AiFillHeart, AiOutlineHeart, AiOutlineMinus, AiOutlineFieldTime, AiOutlinePlus, AiOutlineStop } from "react-icons/ai";
import { ImSpoonKnife } from "react-icons/im";
import { IoIosHome } from 'react-icons/io';

import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import { getStoreCategoryTopItems } from '../../../../redux/mobile/storeCategory/action'
import { toggleFavItem } from '../../../../redux/mobile/item/action'
import { addProduct, removeProduct } from '../../../../redux/mobile/cart/action'
import FloatCart from '../../Cart/FloatCart'
import Customization from "../../Customization";
import Ink from "react-ink";
import { MdFastfood } from "react-icons/md";
import Truncate from "react-truncate";


export class TopItem extends Component {
    state = {
        reload: true,
        float: false,
        loading: true,
        isRunning: true,
    }
    componentDidMount() {
        this.props.getStoreCategoryTopItems(this.props.id, JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((res) => {
            this.setState({
                loading: false
            })
        })
    }
    toggleFavItem = (item) => {
        const { topitems } = this.props;
        const { user } = this.props;
        const singleItem = topitems.data.find(data => data.id === item);
        const favUser = singleItem.favorites.find((fav) => fav.user_id === user.data.id)
        const favIndex = singleItem.favorites.indexOf(favUser);
        if (favUser) {
            singleItem.favorites.splice(favIndex, 1);
        } else {
            singleItem.favorites.push({ user_id: user.data.id });
        }
        this.props = { ...this.props, topitems: topitems }
        this.setState({ reload: !this.state.reload })
        this.props.toggleFavItem(this.props.user.data.auth_token, item)
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    floatCart = (on) => {
        this.setState({ float: on })
    }
    render() {
        const { user, topitems, addProduct, cartProducts, removeProduct } = this.props;
        return (
            <React.Fragment>
                {topitems && topitems.success && topitems.data?.length > 0 && this.state.loading == false ? (
                    <>
                        <div className="px-3 mt-3 d-flex align-items-center">
                            <MdFastfood size={20} style={{ color: "FF9A62" }} />
                            <span className="home-heading ms-2">Top Items In Town</span>
                        </div>
                        <div className="d-flex flex-column px-3 mt-3 ">
                            <div className="d-flex flex-row align-items-center ">
                                <div
                                    className="main-menu hidden-scroll-bar gap-3 pb-3"
                                    style={topitems?.data?.length > 5 ?
                                        { gridTemplateColumns: 'repeat(' + topitems?.data?.length + ',1fr)' } :
                                        { gridTemplateColumns: 'repeat(6,1fr)' }}                                >
                                    {topitems?.data?.map((item) =>
                                        <div className="p-3 top-food-item-card mt-3 ">
                                            <div className="d-flex flex-column position-relative b-r-40">
                                                {/* <Ink className="touch-ink " /> */}
                                                <Link to={'/single-product/' + item.id}>
                                                    <ProgressiveImage delay={20} src={IMAGE_BASE_URL + item?.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}  >
                                                        {(src, loading) => (
                                                            <img src={src}
                                                                style={{ width: "55vw", height: "35vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round", }}
                                                                className="" alt="grosav" />
                                                        )}
                                                    </ProgressiveImage>
                                                </Link>
                                                <div className="ribbon">
                                                    <span>POPULAR</span>
                                                </div>
                                                {user && user.success > 0 ? (
                                                    <div className="d-flex position-absolute"
                                                        style={{ color: '#FF9012', top: '8px', right: '11px' }}
                                                        onClick={() => this.toggleFavItem(item.id)}>
                                                        {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                            <AiFillHeart size={"1.6em"} />
                                                        ) : (
                                                            <AiOutlineHeart size={"1.6em"} />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Link to="/signUp">
                                                        <div className="d-flex position-absolute"
                                                            style={{ color: '#FF9012', top: '8px', right: '11px' }}>
                                                            <AiOutlineHeart size={"1.6em"} />
                                                        </div>
                                                    </Link>
                                                )}
                                                <div className="d-flex flex-column mt-2 ">
                                                    <Link to={'/single-product/' + item.id}>
                                                        <div className="d-flex flex-row justify-content-between align-items-center home-heading">
                                                            <div className="flex-grow-1 w-100">
                                                                <Truncate lines={1}>
                                                                    {item?.name}
                                                                </Truncate>
                                                            </div>
                                                            <div className="ms-3 top-food-color">
                                                                {item?.rating} <AiFillStar style={{ color: "FF9A62", }} size={15} />{" "}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to={'/single-product/' + item.id}>
                                                        <div className="d-flex align-items-center">
                                                            <IoIosHome
                                                                style={{
                                                                    color: "FF9A62",
                                                                }}
                                                                size={13}
                                                            />
                                                            <span
                                                                className=" ms-2"
                                                                style={{
                                                                    color: "#3B3B3B",
                                                                }}
                                                            >
                                                                {item?.store?.name}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                    <Link to={'/single-product/' + item.id}>
                                                        <div className="d-flex align-items-center ">
                                                            <AiOutlineFieldTime
                                                                style={{
                                                                    color: "FF9A62",
                                                                    fontSize: "12px",
                                                                }}
                                                                size={14}
                                                            />
                                                            <span
                                                                className=" ms-2"
                                                                style={{
                                                                    color: "#3B3B3B",
                                                                    fontSize: "10px",
                                                                }}
                                                            >

                                                                {item?.store?.approx_time_delivery ? item?.store.approx_time_delivery + ' Min - ' : ""}  {item?.store?.delivery_radius ? item?.store.delivery_radius + ' Km ' : ""}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                    <div className="d-flex justify-content-between">
                                                        {item?.addoncategories?.length > 0 ? (
                                                            <Link to={'/single-product/' + item.id} className="d-flex flex-column ">
                                                                <div className="d-flex justify-content-between" >
                                                                    <div className="fw-bolder mt-3 pt-1"
                                                                        style={{ color: 'grey', fontSize: "15px", }}  >
                                                                        Customizable
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ) : ("")}
                                                        <Link to={'/single-product/' + item.id} className={parseInt(item.market_price) > parseInt(item?.selling_price) ? "d-flex flex-column " : "d-flex flex-column justify-content-end align-items-end"}>
                                                            {parseInt(item.market_price) > parseInt(item?.selling_price) ? (
                                                                <div className=" fw-bolder">
                                                                    <s style={{ color: "#FF9A62", fontSize: "11px", }} >
                                                                        Rs {item?.market_price}
                                                                    </s>
                                                                </div>) : ('')
                                                            }
                                                            {parseInt(item?.selling_price) > 0 ? (
                                                                <div className="d-flex justify-content-between" >
                                                                    <div className=" text-black fw-bolder "
                                                                        style={{ fontSize: "16px", }}  >
                                                                        Rs {item?.selling_price}
                                                                    </div>
                                                                </div>
                                                            ) : ("")}
                                                        </Link>
                                                        <div className="d-flex flex-column justify-content-between align-items-center mt-2  ">
                                                            {user && user.success > 0 ? (
                                                                <>
                                                                    <>
                                                                        {item?.addoncategories?.length > 0 ? (
                                                                            <>
                                                                                {/* {////// console.log(item, 'iteemmmmmm')} */}
                                                                                <Customization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    homeBtn={true}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                                    <>
                                                                                        {cartProducts.filter((cp) => cp.id === item.id).map((product) =>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <div className="top-food-add-min-btn" onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    removeProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}>
                                                                                                    <AiOutlineMinus
                                                                                                        style={{
                                                                                                            color: "green",
                                                                                                        }}
                                                                                                        size={14}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="top-food-count ms-2 me-2">
                                                                                                    {product.quantity}
                                                                                                </div>
                                                                                                <div className="top-food-add-min-btn" onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    addProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}>
                                                                                                    <AiOutlinePlus
                                                                                                        style={{
                                                                                                            color: "green",
                                                                                                        }}
                                                                                                        size={14}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="top-food-add-min-btn" onClick={() => {
                                                                                            item.quantity = 1;
                                                                                            addProduct(item);
                                                                                            this.forceStateUpdate();
                                                                                        }} >
                                                                                            <AiOutlinePlus
                                                                                                style={{
                                                                                                    color: "green",
                                                                                                }}
                                                                                                size={14}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>

                                                                </>
                                                            ) : (
                                                                <Link to="/signUp">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="top-food-add-min-btn">
                                                                            <AiOutlinePlus
                                                                                style={{
                                                                                    color: "green",
                                                                                }}
                                                                                size={14}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                <FloatCart float={this.state.float} isRunning={this.state.isRunning} singleProduct={true} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    topitems: state.mobilestorecategory.topitems,
    user: state.user.user,
    cartProducts: state.cart.products
});

export default connect(mapStateToProps, { getStoreCategoryTopItems, toggleFavItem, addProduct, removeProduct })(TopItem);
