import React, { Component } from 'react'
import { AiFillDelete, AiFillStar } from 'react-icons/ai';
import { ImUpload2 } from 'react-icons/im';
import Ink from 'react-ink';
import Lottie from 'react-lottie';
import animationData from '../../../../assets/lottie/130285-loading.json';
import { connect } from 'react-redux'
import ImageList from './ImageList';
import { getAllStores, getAllAddedImages, orderWithPrescription, addPrescription, prescriptionDelete, clearPrescriptionImage, prescriptionDeliveryCharge } from '../../../../redux/mobile/medicine/action'
import Modal from "react-modal";
import { TiLocationArrow } from 'react-icons/ti';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api';
import { GiCheckMark, GiScooter } from 'react-icons/gi';
import Shake from "react-reveal/Shake";
import BackButtonFixed from '../../../Elements/BackButtonFixed';
import noStore from '../../../../assets/images/no_medic.png';

export class WithPrescription extends Component {
    state = {
        address: [],
        files: [],
        images: [],
        imageAdd: false,
        store_id: '',
        store_added: false,
        delivery_charge: null,
        no_presc: false,
    }

    componentDidMount() {
        const { user } = this.props;
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")), });
        this.props.getAllAddedImages(user.data.auth_token)
        this.props.getAllStores(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false });
            }
        });
    }

    needLocation = (e) => {
        e.preventDefault();
        localStorage.setItem("LocationFrom", "PRESCRIPTION");
        localStorage.setItem("LocationData", "UPDATE");
        this.props.history.push("/set-location");
    }
    getImage = (image) => {
        this.setState({ no_presc: false, imageAdd: true })
        const { user } = this.props;
        let files = this.state.files;
        let images = this.state.images;
        let imageFile = URL.createObjectURL(image);
        files.push(imageFile);
        images.push(image);
        this.setState({ files: files, images: images });
        let formData = new FormData();
        formData.append("token", user.data.auth_token);
        formData.append("image", image);
        this.props.addPrescription(formData).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ imageAdd: false });
            }
        });
        this.setState({ noPres: false });
    };
    removePrescription = () => {
        const { user } = this.props;
        this.props.prescriptionDelete(user.data.auth_token, this.state.image_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ open: !this.state.open });
            }
        });
    };
    handlePopup = (e, id) => {
        if (id) {
            this.setState({ image_id: id });
        }
        this.setState({ open: !this.state.open });
    };

    SelectStore = (e, store) => {
        e.preventDefault();
        const { user } = this.props;
        this.props.prescriptionDeliveryCharge(user.data.auth_token, store.id, JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ store_id: store.id, delivery_charge: response.payload.deliveryCharge ? parseFloat(response.payload.deliveryCharge).toFixed(2) : 0.00 });
            }
        });

    }

    PlaceOrder = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const { user, prescriptionImage } = this.props;
        const { store_id } = this.state;
        if (store_id !== undefined) {
            if (prescriptionImage && prescriptionImage.image && prescriptionImage.image.length > 0) {
                // ////// console.log(user.data.auth_token,
                //     store_id,
                //     JSON.parse(localStorage.getItem("userSetAddress")),
                //     prescriptionImage?.image)
                this.setState({ store_added: false });
                this.props.orderWithPrescription(
                    user.data.auth_token,
                    store_id,
                    JSON.parse(localStorage.getItem("userSetAddress")),
                    prescriptionImage?.image).then((response) => {
                        if (response && response.payload && response.payload.success) {
                            this.props.clearPrescriptionImage()
                            this.props.history.push("/order-success/" + response?.payload?.order?.id);
                        }
                    })
            } else {
                this.setState({ no_presc: false });
                setTimeout(() => {
                    this.setState({ no_presc: true });
                }, 1);
            }
        } else {
            this.setState({ store_added: false });
            setTimeout(() => {
                this.setState({ store_added: true });
            }, 1);
        }
    }
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        const { prescriptionImage, stores } = this.props;
        const { address } = this.state;

        return (
            <React.Fragment>
                <BackButtonFixed title="Add Prescription" history={this.props.history} />
                {stores && stores?.stores && stores?.stores.length > 0 ?
                    <React.Fragment>
                        <div className='px-3 position-sticky' style={{ paddingTop: '20vw' }}>
                            <div className="py-2 w-100 mt-4 position-relative text-center"
                                style={{ border: "2px dashed #DB9F78", borderRadius: "12px", color: "#DB9F78" }}>
                                <ImUpload2 size={20} />
                                <label for="image" className="ps-1 user-select-none" style={{ color: "#DB9F78" }}>
                                    Upload prescription
                                    <Ink duration={500} style={{ color: "#aaa", }} />
                                </label>
                                <input type="file" style={{ display: "none", }} id="image"
                                 accept="image/*"
                                    onChange={(event) => {
                                        let file = event.target.files[0];
                                        this.getImage(file);
                                    }}
                                />

                            </div>
                            <p className='text-danger mt-2 text-center'>Only upload images here</p>

                            <div className={this.state.no_presc ? 'mt-4' : 'mt-4'}>
                                <div className="main-menu hidden-scroll-bar gap-2"
                                    style={{ gridTemplateColumns: `repeat(${prescriptionImage && prescriptionImage.image && prescriptionImage.image.length + 1},2fr)`, }} >
                                    {prescriptionImage &&
                                        prescriptionImage.image &&
                                        prescriptionImage.image.length > 0 &&
                                        prescriptionImage.image.map(
                                            (item, index) => (
                                                <div className="d-flex flex-column align-items-center">
                                                    {/* {////// console.log(item, 'item')} */}
                                                    <ImageList item={item} />
                                                    <div className="py-2 cart-image-delete px-2 text-center"
                                                        style={{ width: "26vw", }} onClick={(e) => this.handlePopup(e, item.id)} >
                                                        remove   <AiFillDelete />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    {this.state.imageAdd &&
                                        <div className="mt-4" style={{ width: "26vw" }}>
                                            <Lottie
                                                options={defaultOptions}
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    }
                                </div>
                                {this.state.no_presc &&
                                    <Shake>
                                        <div className='text-center' style={{ color: 'red', lineHeight: 0 }}>
                                            No prescription image uploaded
                                        </div>
                                    </Shake>
                                }
                            </div>
                        </div>
                        {address?.house === null ? (
                            <div className="w-100 px-3 pb-4 py-2 header-body bottom-0 " style={{ boxShadow: "0px -5px 10px 5px #00000015", borderRadius: "14px 14px 0px 0px", backgroundColor: '#fff', zIndex: '9999' }} >
                                <div className="d-flex flex-column justify-content-between align-items-center w-100 pb-2">
                                    <div className="px-2 w-100" style={{ fontSize: "1.2em", fontWeight: '700' }}>
                                        <TiLocationArrow color="#ff9012" size={40} />  <span className="pt-2">You are in a new Location!</span>
                                    </div>
                                    <div className="text-center w-100 py-3 px-3 cart-no-address mt-2" style={{ boxShadow: "0px -5px 10px 5px #00000015", fontSize: "1.2em" }} onClick={(e) => this.needLocation(e)}>
                                        Add Address To Proceed
                                    </div>
                                </div>
                            </div>
                        ) : ("")}
                        <div className={this.state.no_presc ? 'mt-3' : "mt-3"}>
                            <div className="mobile-cart-yr-dtls px-3 ">
                                Delivery Address
                            </div>
                            <div className="py-3 px-2 mt-3 mobile-cart-cnltn-card" >
                                {address ? (
                                    <div className="d-flex flex-row justify-content-between px-3 align-items-center mt-1 ">
                                        <div className="d-flex flex-column">
                                            <div className="text-muted" style={{ fontSize: "1em" }}>
                                                {address?.house && address?.house !== address.address ? address.house + "," : ""}{" "}
                                                {address.address}
                                            </div>
                                        </div>

                                        <div className="ps-2">
                                            <div className="mobile-cart-chng"
                                                onClick={(e) => this.needLocation(e)}  >
                                                change{" "}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-row justify-content-between align-items-center px-3 mt-1">
                                        <div className="d-flex flex-column">
                                            <div className="text-muted" style={{ fontSize: "1em", }}>
                                                No Address
                                            </div>
                                        </div>

                                        <div className="ps-2">
                                            <div className="mobile-cart-chng" onClick={(e) => this.needLocation(e)}  >
                                                Add{" "}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='mt-3 px-3' style={{ paddingBottom: '30vw' }}>
                            <div className="mobile-cart-yr-dtls">
                                Select Store
                            </div>
                            <div className=" main-menu hidden-scroll-bar mt-3" style={{ gridTemplateRows: `repeat(${stores?.stores.length},1fr)`, gap: 10, }}>
                                {stores && stores?.stores && stores?.stores.map((store, index) => (
                                    <div className="d-flex flex-row" onClick={(e) => { this.SelectStore(e, store) }}>
                                        <div className='position-relative' style={{ width: "30vw", height: "30vw", }} >
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + store.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img src={src} className="" alt="grosav"
                                                        style={{
                                                            width: "30vw", height: "30vw", borderRadius: "20px",
                                                            objectFit: "cover", backgroundRepeat: "round",
                                                        }} />
                                                )}
                                            </ProgressiveImage>
                                            <div className='position-absolute top-50 start-50 translate-middle' style={store.id === this.state.store_id ? { zIndex: '2' } : { display: 'none' }}>
                                                <div className='' style={{ fontSize: '1.2em', fontWeight: '750' }}>
                                                    Selected
                                                </div>
                                            </div>
                                            <div className='position-absolute top-50 start-50 translate-middle' style={store.id === this.state.store_id ? { width: "30vw", height: "30vw", backgroundColor: '#ffce49d1', borderRadius: "20px" } : { display: 'none' }}>
                                            </div>
                                        </div>
                                        <div className="ms-3 pe-4 mt-1">
                                            <h5 className="fs-6 m-0" style={{ color: "#09051C", fontWeight: "600", }}>
                                                {store.name}
                                            </h5>
                                            <div className="d-flex align-items-center">
                                                <p className="m-0 all-resturant-rating">   {store.rating}  </p>
                                                <AiFillStar style={{ color: "FF9A62", }} size={10} />
                                                <span className=" ms-2" style={{ fontSize: "12px", color: "#3B3B3B", }}  >
                                                    {store?.approx_time_delivery ? store.approx_time_delivery + " Min - " : ""}{" "}
                                                    {store?.delivery_radius ? store.delivery_radius + " Km " : ""}
                                                </span>
                                            </div>
                                            <div className="resturant-description" style={{ color: "rgba(9, 5, 28, 0.25)", fontSize: "12px", }}>
                                                <span> {store?.description}  </span>
                                            </div>
                                            {store?.is_freedelivery === 1 ? (
                                                <div className="d-flex align-items-center">
                                                    <GiScooter style={{ color: "FF9A62", }} size={20} />
                                                    <span className="all-resturant-delivery ms-2 mt-1">
                                                        Free Delivery Upto...
                                                    </span>
                                                </div>
                                            ) : ("")}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='position-fixed bottom-0 w-100 py-3 px-3' style={{ backgroundColor: '#fff', borderRadius: '15px 15px 0px 0px', boxShadow: '0px 0px 20px 5px #e3e3e3', zIndex: '5' }}>
                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                {this.state.delivery_charge ?
                                    <div className='d-flex flex-column justify-content-between'>
                                        <div>
                                            Delivery Charge
                                        </div>
                                        <div>
                                            {this.state.delivery_charge}
                                        </div>
                                    </div>
                                    :
                                    <div className='d-flex flex-column align-items-center justify-content-between py-3'>
                                        {this.state.store_added ?
                                            <Shake>
                                                <div style={{ color: 'red' }}>
                                                    Please choose a store to place order
                                                </div>
                                            </Shake>
                                            : 'Please choose a store to place order'}
                                    </div>
                                }
                                {this.state.delivery_charge ?
                                    <div className='py-3 text-center text-white' style={{ width: '40vw', fontWeight: '700', backgroundColor: '#fd8814 ', borderRadius: '12px' }} onClick={(e) => this.PlaceOrder(e)}>
                                        Place Order
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <Modal isOpen={this.state.open}
                            // onAfterOpen={afterOpenModal}
                            ariaHideApp={false}
                            onRequestClose={() => this.handlePopup()}
                            style={customStyles}
                            contentLabel="Example Modal"   >
                            <div className="container" style={{ borderRadius: "5px", height: "150px", zIndex: "999999999999999999999", }}  >
                                <React.Fragment>
                                    <div className="d-flex flex-column justify-content-between">
                                        <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center">
                                            <h1 className="mt-2 mb-0 font-weight-black h4">
                                                Do you really want  to delete this  image?
                                            </h1>
                                        </div>
                                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                                            <div className="text-center mr-4 w-100">
                                                <button className="delete-button-meals p-2 px-2 w-100 position-relative"
                                                    onClick={this.handlePopup} style={{ borderColor: "#fd8814", color: "#fd8814", width: "100%", }}   >
                                                    NO
                                                    <Ink duration={500} style={{ color: "#000", }} />
                                                </button>
                                            </div>

                                            <div className="text-center w-100">
                                                <button className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                                    onClick={this.removePrescription} style={{ width: "100%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}  >
                                                    YES  <Ink duration={500} style={{ color: "#000", }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                    :
                    <div className='px-4 position-sticky' style={{ paddingTop: '20vw' }}>
                        <div className='text-center mt-5'>
                            <img src={noStore} alt='mo' style={{ width: '100%' }} />
                        </div>
                        <div className='text-center mt-3 fs-6 fw-bolder'>
                            No Stores Near You
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    prescriptionImage: state.medicine.prescriptionImage,
    stores: state.medicine.nearStores
})

export default connect(mapStateToProps, {
    addPrescription,
    getAllStores,
    prescriptionDelete,
    orderWithPrescription,
    getAllAddedImages,
    clearPrescriptionImage,
    prescriptionDeliveryCharge
})(WithPrescription)
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
