import React from 'react'
import MobileBackButtonWithSearch from '../../../Elements/MobileBackButtonWithSearch'
import { ITEM_SHARE_URL } from '../../../../api'
import { RiShareFill } from 'react-icons/ri'
import { MdStars } from 'react-icons/md'
import Truncate from 'react-truncate'
import { BsDot } from 'react-icons/bs'
import { IoBicycle } from 'react-icons/io5'
import { IoMdEye } from 'react-icons/io'
import { RWebShare } from 'react-web-share'

const StoreCard = (props) => {
    const { history, store, ratingCount, openShare } = props
    return (
        <div className="p-3 px-3" style={{ backgroundColor: '#ececec', borderRadius: '0px 0px 20px 20px' }}>
            <div className="" style={{ marginLeft: "-2vh" }} >
                <MobileBackButtonWithSearch history={history} />
            </div>
            <div className="d-flex flex-column mt-5 p-3 " style={{ backgroundColor: "#fff", boxShadow: '0px 0px 20px 0px #ddd', borderRadius: '15px' }}>
                <div className="d-flex flex-row">
                    <div className="flex-grow-1" style={{ fontWeight: '800', fontSize: '1.2em' }}>{store?.store?.name}</div>
                    <RWebShare
                        data={{
                            url: ITEM_SHARE_URL + '/single-store/' + store?.store?.id,
                            title: store?.store?.name + " " + store?.store?.description
                        }}
                        onClick={() => openShare(store?.store)}  >
                        <RiShareFill className="" style={{ fontSize: '1.5em', color: '#626262' }} />
                    </RWebShare>
                    {/* <RiHeart3Line className="ms-3" style={{ fontSize: '1.5em', color: '#626262' }} /> */}
                    {/* <RiHeart3Fill className="ms-2" size={25} color={"##646464"}/> */}
                </div>
                <div className="d-flex flex-row align-items-center" style={{ fontSize: '.9em', fontWeight: '800', color: '#2c2c2c' }}>
                    {store?.store.ratings.length > 0 ?
                        <>
                            <MdStars className="" style={{ fontSize: '1.3em', color: '#FEA24B' }} />
                            <div className="px-1">{ratingCount(store?.store.ratings, store?.store.ratings.length)}</div>
                        </>
                        : <>
                            <MdStars className="" style={{ fontSize: '1.3em', color: '#FEA24B' }} />
                            <div className="px-1">{store.store?.rating ?? 3}</div>
                        </>}
                    {/* <div className="ps-1">(50+ ratings)</div> */}
                    <div className="pe-1"><BsDot /></div>
                    <div className="pe-1">{store?.store?.delivery_radius} km </div>
                </div>
                <div className="d-flex flex-row align-items-center" style={{ fontSize: '.9em', fontWeight: '600', color: '#2c2c2c' }}>
                    <div className="flex-grow-1">
                        <Truncate lines={1}>
                            {store?.store?.address ? store?.store?.address : '....'}
                        </Truncate>
                    </div>
                </div>
                <hr style={{ color: '#bfbfbf', margin: ".8em 0" }} />

                <div className="d-flex flex-row position-relative" style={{ fontSize: '.9em', }}>
                    <div className="d-flex flex-column align-items-center justify-content-around">
                        <BsDot className="" style={{ fontSize: '1.2em', color: '#c2c2c2' }} />
                        <BsDot className="mt-1" style={{ fontSize: '1.2em', color: '#c2c2c2' }} />
                        <div className="ps-2 store-box"></div>
                    </div>
                    <div className="d-flex flex-column justify-content-around" style={{ fontWeight: '700', whiteSpace: 'nowrap' }}>
                        <div className="ps-2">Outlet</div>
                        <div className="ps-2 mt-1">{store?.store?.approx_time_delivery ? store?.store?.approx_time_delivery + " mins" : "30 mins"}</div>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column">
                        <div className="ms-3" >
                            <Truncate lines={1}>
                                {store?.store?.address ? store?.store?.address : '--'}
                            </Truncate>
                        </div>
                        <div className="ms-3 mt-1" >
                            To Delivery
                        </div>
                    </div>
                </div>
                {store?.store?.description !== null ? <>
                    <hr style={{ color: '#bfbfbf', margin: ".8em 0" }} />
                    <div className="d-flex flex-row align-items-center" style={{ fontSize: '.9em', color: '2c2c2c' }}>
                        <div>
                            <IoBicycle className="" style={{ fontSize: '1.3em', color: '#626262' }} />
                        </div>
                        <div className="ms-1">
                            {store?.store?.description ? store?.store?.description : '....'}
                        </div>
                    </div>
                </> : null}

                {store?.store?.is_buy_description !== null ?
                    <>
                        <hr style={{ color: '#bfbfbf', margin: ".8em 0" }} />
                        <div className="d-flex flex-row align-items-center" style={{ fontSize: '.9em', color: '2c2c2c' }}>
                            <IoMdEye size={20} style={{ color: '#4f89d0' }} />
                            <div className="ms-1" style={{ color: '#4f89d0' }}>
                                {store?.store?.is_buy_description}
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </div>
    )
}

export default StoreCard
