import React, { Component } from 'react'
import { connect } from 'react-redux'
import VendorBackButton from '../../Elements/VendorBackButton'
import { Link } from 'react-router-dom';
import Loading from '../../Elements/loading'
import { getPastOrders } from '../../../redux/vendor/order/action'
import moment from 'moment';


class VendorPastOrder extends Component {
    state = {
        Loading: false,
        store_id: this.props.match.params.id,
        orders: ''
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.getPastOrders(user && user.data && user.data.auth_token, this.state.store_id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, orders: response.payload.orders })
                    //// console.log(response.payload.orders)
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }
    render() {
        const { orders } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Past Orders" />
                        </div>
                        {orders && orders.length > 0 ? (
                            <div className="px-4 " style={{ paddingTop: '25vw', paddingBottom: '20vw' }}>
                                {orders && orders.length > 0 && orders.map((item, index) => (
                                    <div className="">
                                        <div className="past-order-container px-2 pb-3 pt-2 mt-3">
                                            <Link to={"/vendor-completed-orders/" + item.id}>
                                                <div className="past-order-tag px-3 py-2">
                                                    <span> Order Completed</span>
                                                </div>
                                            </Link>
                                            <div className="past-order-id mt-2 ps-2">
                                                <span># {item.unique_order_id}</span>
                                            </div>
                                            {item.order_delivered_at &&
                                                <div className="past-order-date ps-2 mt-1">

                                                    <span>{moment(item.order_delivered_at).format('DD-MM-Y')} | {moment(item.order_delivered_at).format('h:mm a')}</span>
                                                </div>
                                            }
                                            <div className='mt-2 me-4 px-2'>
                                                {item.orderitems && item.orderitems.map((subItems, index) => (
                                                    <div className="d-flex flex-row justify-content-between  me-5">
                                                        <span className="past-order-date ">{index + 1}. {subItems.name}</span>
                                                        <span className="past-order-price">Rs {subItems.price}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className='position-absolute text-muted '
                                style={{ top: '47%', left: '20%', fontSize: '21px', fontWeight: '600' }}>
                                No Completed Orders...!
                            </div>
                        )}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user
})


export default connect(mapStateToProps, {
    getPastOrders
})(VendorPastOrder)
