export const GET_ALL_FOOD = "GET_ALL_MEDICINE_DATA";
export const GET_ALL_TOP_DEALS = "GET_ALL_TOP_DEALS";
export const GET_MOST_ORDERS = "GET_MOST_ORDERS";
export const GET_ALL_MEDICINE_DATA = "GET_ALL_MEDICINE_DATA";
export const GET_ALL_RECOMMENDED_MEDICINE = "GET_ALL_RECOMMENDED_MEDICINE";
export const GET_MEDICINE_NEAR_STORES = "GET_MEDICINE_NEAR_STORES";
export const WITH_PRESCRIPTION_IMAGE = "PRESCRIPTION_IMAGE";
export const GET_ALL_MEDICAL_STORES = "GET_ALL_MEDICAL_STORES";
export const ORDER_WITH_PRESCRIPTION = "ORDER_WITH_PRESCRIPTION";
export const PRESCRIPTION_IMAGE = "PRESCRIPTION_IMAGE";
export const PRESCRIPTION_DELIVERY_CHARGE = "PRESCRIPTION_DELIVERY_CHARGE";
