import React, { Component } from "react";
import { connect } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import { Link } from "react-router-dom";
import Truncate from "react-truncate";
// import { AiFillStar } from "react-icons/ai";
// import Ink from "react-ink";
import ProgressiveImage from "react-progressive-image";
import { Rating } from "react-simple-star-rating";
import { getAllPopularStores } from "../../../../redux/mobile/home/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";

export class TopRestaurant extends Component {
    state = {
        data: false
    }
    componentDidMount() {
        this.props.getAllPopularStores(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ data: true });
            }
        });
    }
    __getStoreCouponText = (store) => {
        return (
            <div
                className="d-flex flex-column justify-content-center position-absolute w-100"
                style={{
                    borderRadius: "0px 0px 20px 20px",
                    bottom: "0px",
                    lineHeight: "1.2",
                    background: "linear-gradient(0deg,black, transparent )",
                }}
            >

            </div>
        );
    };

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { popularStores } = this.props;
        // const { data } = this.state;
        return (
            <React.Fragment>
                {popularStores &&
                    popularStores.data &&
                    popularStores.data.length > 0 && (
                        <div className="mt-3  ">
                            <div className="px-3">
                                <GiHamburger
                                    className="mb-2"
                                    color="FF9A62"
                                    size={20}
                                />{" "}
                                <span className="top-res-header">
                                    Top Resto For You
                                </span>
                            </div>
                            <div className="">
                                <div className="mt-2 px-3 main-menu hidden-scroll-bar"
                                    style={{ gridTemplateColumns: "repeat(" + popularStores?.data?.length + ",0.3fr)", gap:popularStores?.data?.length > 3 &&  '1.8rem' }}   >
                                    {popularStores?.data?.map((store) => (
                                        <Link to={'/single-store/' + store.id} key={store.id} className="" style={{ width: "20vw" }}>
                                            <div className={`d-flex flex-column ${!store?.is_active && 'store-not-active'}`} style={{ width: "22vw" }}>
                                                <div className="position-relative text-center mt-2" >
                                                    <ProgressiveImage delay={20}
                                                        src={IMAGE_BASE_URL + store?.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}   >
                                                        {(src, loading) => (
                                                            <img src={src}
                                                                style={{ objectFit: "cover", backgroundRepeat: "none", height: "22vw", width: "22vw", borderRadius: "10px", }}
                                                                className="" alt="grosav" />
                                                        )}
                                                    </ProgressiveImage>
                                                    {(store?.coupons?.length > 0 || store?.offer_promotion_text) &&
                                                        <div className="popular-tag-content px-1 py-1">
                                                            <div className="popular-tag">
                                                                <p><b>{store?.coupons?.length > 0 &&
                                                                    store?.coupons[0]?.description.length > 0 ? (
                                                                    <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                                                        <Truncate lines={1}>
                                                                            {store?.coupons[0]?.description.toUpperCase()}
                                                                        </Truncate>
                                                                    </div>
                                                                ) : (
                                                                    <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                                                        <Truncate lines={1}>
                                                                            {store?.offer_promotion_text}
                                                                        </Truncate>
                                                                    </div>
                                                                )}</b></p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {store.is_buy_text ? (
                                                        <div className="ribbon-buy-small">
                                                            <span>{store.is_buy_text}</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <Truncate lines={1} className="top-res-name fw-700">  {store?.name}   </Truncate>
                                                {store?.rating > 0 ?
                                                    <div>
                                                        <Rating
                                                            size={"1em"}
                                                            className="mobile-rate-star"
                                                            initialValue={store?.rating}
                                                            readonly="true"
                                                        />
                                                    </div>
                                                    :
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={0}
                                                        readonly="true"
                                                    />
                                                }
                                                {store?.is_active ? (
                                                    <span className="top-res-deli-time text-muted fs-15">
                                                        <Truncate lines={1}>
                                                            {store?.preparation_time ? store.preparation_time + ' Min - ' : ""}  {store?.delivery_radius ? store.delivery_radius + ' Km ' : ""}
                                                        </Truncate>
                                                    </span>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                            {!store?.is_active ? (
                                                <div className="text-danger">
                                                    Store Closed
                                                </div>
                                            ) : (
                                                null
                                            )}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    popularStores: state.home.popularStores,
});

export default connect(mapStateToProps, { getAllPopularStores })(TopRestaurant);
