import React, { Component } from 'react'
import animationData from '../../assets/lottie/loading2.json';
import Lottie from 'react-lottie';
import { withRouter } from 'react-router-dom';
export class loadingTwo extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <React.Fragment>

                <div className="d-flex flex-column align-items-center  justify-content-center" style={{paddingTop:'180px'}}>
                    <Lottie
                        options={defaultOptions}
                        height={350}
                        width={280}
                    />
                    <div className='fs-2 fw-bolder text-dark mt-4' >Hold On!</div>
                    <div className='fs-8 fw-bolder text-secondary mt-3' >We are verifying your Order</div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter((loadingTwo));
