import React, { Component } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import VendorBackButton from "../../Elements/VendorBackButton";
import { DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { HiOutlineChevronRight } from "react-icons/hi";
import { BottomSheet } from "react-spring-bottom-sheet";
import Loading from "../../Elements/loading";
import { addVendorCoupon } from "../../../redux/vendor/coupon/action";
import moment from "moment";
import Truncate from "react-truncate";
import { IoIosArrowDropright } from "react-icons/io";
import { AiFillCheckSquare } from "react-icons/ai";
import { IoSquareOutline } from "react-icons/io5";
import HeadShake from "react-reveal/HeadShake";
import './VendorCoupon.css';
export class VendorCoupon extends Component {
    state = {
        coupon: false,
        type: "FIXED",
        loading: false,
        file: "",
        imageName: "",
        fileSize: "",
        VendorCoupons: {},
        modalItems: false,
        selectedItem: [],
        noImage: false,
        selectedAllItem: false,
        modalUserType: false,
        user_type: "ALL",
        selectedCoupon : 'STORE'
    };
    openDiscountMenu = () => {
        this.setState({ coupon: true });
    };
    _closeDiscountMenu = () => {
        this.setState({ coupon: false });
    };
    changeUserType = () => {
        this.setState({ modalUserType: !this.state.modalUserType })
    }
    closeCategoryMenu = () => {
        this.setState({ modalItems: !this.state.modalItems })
    }
    selectedAllItems = () => {
        this.setState({ modalItems: !this.state.modalItems, selectedAllItem: true, selectedItem: [] })
    }
    handleUserType = (id) => {
        this.setState({ user_type: id, modalUserType: false });
    };
    selectedItem = (item, index) => {
        this.setState({ selectedAllItem: false });
        let data = this.state.selectedItem;
        if (data.length === 0) {
            data.push(item);
            this.setState({ selectedItem: data });
        } else {
            if (data.find((cp) => cp.id === item.id) === undefined) {
                data.push(item);
                this.setState({ selectedItem: data });
            } else {
                data.splice(data.findIndex(x => x.id === item.id), 1);
                this.setState({ selectedItem: data });
            }
        }
    }
    handleType = (id) => {
        if (id == "FIXED") {
            this.setState({ type: id });
            this.setState({ coupon: false });
        } else {
            this.setState({ type: id });
            this.setState({ coupon: false });
        }
    };
    componentDidMount() {
        //// console.log(this.props.user.user.store_id, 'jkhkhkj');
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }

    handleCheckboxChange = (e) => {
        var selectedCoupon = e.target.value;
        this.setState({ selectedCoupon: selectedCoupon});
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.fileSize) {
            if (this.state.fileSize > 200000) {
                this.setState({ noImage: false });
                this.setState({ fileSize: 1000 })
                setTimeout(() => {
                    this.setState({ fileSize: 2000000 })
                }, 1);
            } else {
                this.setState({ noImage: false });
                //// console.log("response")
                const { user, store } = this.props;
                const { VendorCoupons, selectedItem, selectedAllItem, selectedCoupon } = this.state;
                //// console.log(VendorCoupons.name, 88);

                let formData = new FormData();
                formData.append("coupon_type", selectedCoupon ? selectedCoupon : '');
                formData.append("name", VendorCoupons.name ? VendorCoupons.name : '');
                formData.append("image", VendorCoupons.image);
                formData.append("store_id", user.data.store[0].id);
                formData.append("description", VendorCoupons.description);
                formData.append("coupon_code", VendorCoupons.coupon_code);
                formData.append("max_discount", VendorCoupons.max_discount)
                formData.append("max_count", VendorCoupons.max_count)
                formData.append("type", this.state.type);
                formData.append("user_type", this.state.user_type);
                formData.append("max_count_per_user", VendorCoupons.max_count_per_user);
                // formData.append("image", this.state.file)
                formData.append("coupon_discount", VendorCoupons.coupon_discount);
                formData.append("start", VendorCoupons.start ? VendorCoupons.start : '');
                formData.append("end", VendorCoupons.end ? VendorCoupons.end : '');
                formData.append("sub_total", VendorCoupons.sub_total);
                formData.append("token", user.data.auth_token);
                let selectedItems = JSON.stringify(selectedItem)
                formData.append("selectedItem", selectedItems)
                formData.append("store_id", store?.store?.id)
                let selectAllItems = selectedAllItem ? "ALL" : "SINGLE";
                formData.append("selectAllItems", selectAllItems)
                this.props.addVendorCoupon(formData).then((response) => {
                    //// console.log(response)
                    if (response) {
                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, 500);
                        window.location.replace("/vendor-offer");
                        this.props.history.replace("/vendor-offer");
                    }
                })
            }
        } else {
            this.setState({ noImage: false });
            setTimeout(() => {
                this.setState({ noImage: true });
            }, 1);
        }
    };
    handleInput(e, name, index) {
        let coupon = this.state.VendorCoupons;
        coupon[name] = e;
        this.setState({ VendorCoupons: coupon });
    }
    render() {
        const { selectedItem } = this.state;
        const { items } = this.props;
        const disabledDate = (current) => {
            // Can not select days before today
            return current && current <= moment().startOf("day");
        };
        const userTypes = [{ 'value': 'ALL', 'label': 'Unlimited Times For All Users' },
        { 'value': 'ONCENEW', 'label': 'Once For New User For First Order' },
        { 'value': 'ONCE', 'label': 'Once Per User' },
        { 'value': 'CUSTOM', 'label': 'Define Custom Limit Per User' }];
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Make Coupon" />
                        </div>
                        <div className="px-4" style={{ paddingTop: '22vw' }}>
                            <div className="coupon-heading-one">
                                <span>Create Your Own Coupon</span>
                            </div>
                            <div className="coupon-heading-two text-muted">
                                <span>Enter details to Create Coupon</span>
                            </div>
                        </div>
                        <div className="px-4">
                            <div className="form-coupon">
                                <Form onSubmit={this.onSubmit}>

                                <div className="d-flex mt-3">
                                    <div>
                                        <Form.Check
                                            type="checkbox"
                                            label="Store Coupon"
                                            value="STORE"
                                            checked={this.state.selectedCoupon === "STORE"}
                                            onChange={this.handleCheckboxChange}
                                            className="me-3 coupon_type"
                                        />
                                    </div>
                                    <div>
                                        <Form.Check
                                            type="checkbox"
                                            label="Item Coupon"
                                            value="ITEM"
                                            checked={this.state.selectedCoupon === "ITEM"}
                                            onChange={this.handleCheckboxChange}
                                            className="coupon_type"
                                        />
                                    </div>
                                </div>

                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: "border-box" }} >
                                        <FormControl type="text" placeholder="Enter Your Coupon" className="input-coupon"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }} required />
                                    </InputGroup>
                                    <div className="coupon-input mt-2 p-2 d-flex justify-content-between ">
                                        <label className="text-muted px-3">
                                            Coupon Image
                                        </label>
                                        <input className="upload-button-coupon" style={{ display: "none" }} type="file" id="upload-button-image"
                                            onChange={(event) => {
                                                let file = event.target.files[0];
                                                this.setState({ fileSize: event.target.files[0].size })
                                                this.setState({ imageName: file.name })
                                                this.setState({ file: URL.createObjectURL(file), });
                                                this.handleInput(file, "image");
                                                this.setState({ noImage: false });
                                            }}
                                        />
                                        <label className="upload-button-coupon px-3 py-1 ml-3" for="upload-button-image"  >
                                            Upload File
                                        </label>
                                    </div>
                                    <div className="text-center" style={{ fontSize: '.8em' }}>
                                        <Truncate lines={1}>
                                            {this.state.imageName}
                                        </Truncate>
                                    </div>
                                    {this.state.fileSize > 200000 ? (
                                        <div className="text-center" style={{ color: 'red', fontWeight: '700', fontSize: '.8em' }}>
                                            <HeadShake>Image Max size 200 kb </HeadShake>
                                        </div>
                                    ) : ("")}
                                    {this.state.noImage ?
                                        <div className="text-center" style={{ color: 'red', fontWeight: '700', fontSize: '.8em' }}>
                                            <HeadShake>Need to Upload Image</HeadShake>
                                        </div>
                                        : ("")}
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl as="textarea" rows={3}
                                            placeholder="Coupon Description" className="input-coupon" required
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }} />
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="text" placeholder="Coupon Code"
                                            className="input-coupon" required
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "coupon_code");
                                            }} />
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Maximum Discount"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "max_discount");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input p-2 d-flex justify-content-between" style={{ boxSizing: "border-box" }} onClick={() => this.openDiscountMenu()}>
                                        <div>
                                            <label for="discount-type" className="px-3 py-1" style={{ fontWeight: '700', color: '#ff9012' }}  >
                                                {this.state.type}
                                            </label>
                                        </div>
                                        <div>
                                            <span className="px-1 py-2 ml-3" id="discount-type" >
                                                <IoIosArrowDropright size={18} style={{ fontWeight: '700', color: "#ff9012" }} />
                                            </span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet open={this.state.coupon} onDismiss={() => this._closeDiscountMenu()} snapPoints={({ maxHeight }) => 0.28 * maxHeight}  >
                                        <div className="px-3 pb-5">
                                            <div className="mt-1 mb-2" style={{ fontWeight: "600", fontSize: "16px" }}>
                                                Make Your Coupons For
                                            </div>
                                            <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                <div className="d-grid gap-2">
                                                    <button className="offer-sheet-btn  text-black" onClick={() => this.handleType("FIXED")} >
                                                        Fixed Amount
                                                    </button>
                                                    <button className="offer-sheet-btn  text-black" onClick={() => this.handleType("PERCENTAGE")} >
                                                        Percentange
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} className="input-coupon"
                                            placeholder={this.state.type === "PERCENTAGE" ? "Enter Coupon Discount (%)" : "Enter Coupon Discount (Rs)"}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "coupon_discount");
                                            }} required />
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input d-flex justify-content-between" style={{ boxSizing: "border-box" }}>
                                        <div className="text-muted coupon-date-text px-3">
                                            <span>Start Date</span>
                                        </div>
                                        <div className="">
                                            <DatePicker placeholder="" className="coupon-date" required
                                                onChange={(e) => {
                                                    let value = moment(e).format("YYYY-MM-DD");
                                                    this.handleInput(value, "start");
                                                }} disabledDate={disabledDate} />
                                        </div>
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input d-flex justify-content-between" style={{ boxSizing: "border-box" }} >
                                        <div className="text-muted coupon-date-text px-3">
                                            <span>End Date</span>
                                        </div>
                                        <div className="">
                                            <DatePicker placeholder="" className="coupon-date" required
                                                onChange={(e) => {
                                                    let value = moment(e).format("YYYY-MM-DD");
                                                    this.handleInput(value, "end");
                                                }}
                                                disabledDate={disabledDate} />
                                        </div>
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Max No of use in total"
                                            className="input-coupon"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "max_count");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Min Sub Total" className="input-coupon"
                                            required
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "sub_total");
                                            }} />
                                    </InputGroup>

                                    <InputGroup className="mt-2 coupon-input p-2 d-flex justify-content-between" style={{ boxSizing: "border-box" }} onClick={() => this.changeUserType()}>
                                        <div>
                                            <label for="discount-type" className="px-3 py-1" style={{ fontWeight: '700', color: '#ff9012' }}  >
                                                {userTypes.filter((store) => this.state.user_type === store.value)[0]?.label}
                                            </label>
                                        </div>
                                        <div>
                                            <span className="px-1 py-2 ml-3" id="discount-type" >
                                                <IoIosArrowDropright size={18} style={{ fontWeight: '700', color: "#ff9012" }} />
                                            </span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet open={this.state.modalUserType} onDismiss={() => this.changeUserType()} >
                                        <div className="px-3 pb-5">
                                            <div className="mt-1 mb-2" style={{ fontWeight: "600", fontSize: "16px" }}>
                                                Select User Type
                                            </div>
                                            <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                <div className="d-grid gap-2">
                                                    {userTypes.map((value, index) => (
                                                        <button className="offer-sheet-btn  text-black" onClick={() => this.handleUserType(value.value)} >
                                                            {value.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>

                                    {this.state?.user_type === "CUSTOM" ?
                                        <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                            <FormControl type="number" min={0} placeholder="Max Number Of Use Per User"
                                                className="input-coupon"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.handleInput(value, "max_count_per_user");
                                                }}
                                            />
                                        </InputGroup>
                                        : null}


                                    {this.state.selectedCoupon === 'ITEM' && (

                                    <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2 coupon-input mt-2" onClick={(e) => this.closeCategoryMenu(e)} id="selectItems">
                                        <div className="flex-grow-1 d-flex flex-column py-1 ms-1">
                                            <div className=" fw-bolder text-muted">
                                                {selectedItem.length > 0 ? "Selected Items" : "Select Items"}
                                            </div>
                                            <div className="text-muted w-75" style={{ fontSize: '.9em' }}>
                                                {this.state.selectedAllItem ? (
                                                    <span>All Items Selected</span>
                                                ) :
                                                    <Truncate lines={1} className="">
                                                        {selectedItem && selectedItem.length > 0 ? selectedItem?.map((item, index) => (
                                                            <span className="text-muted">{index === selectedItem.length - 1 ? item.name + "" : item.name + ', '}</span>
                                                        )) : (
                                                            <span className="text-muted">Set offer for items</span>
                                                        )}
                                                    </Truncate>
                                                }
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <IoIosArrowDropright size={20} style={{ fontWeight: '700', color: "#ff9012" }} />
                                        </div>
                                    </div>

                                    )}
                                    <div className=" py-2 pb-4 w-100">
                                        <button className="coupon-btn p-3 w-100 mt-3" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <BottomSheet open={this.state.modalItems}
                    onDismiss={() => this.closeCategoryMenu()}  >
                    <div className="px-3 pb-5">
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            <div className="text-end" style={{ fontWeight: 700, fontSize: '1.2em', color: '#fd8814 ' }}>Total Items: {items?.items?.length}</div>
                            <div className="d-grid gap-2 mt-1" style={{ paddingBottom: '20vw' }}>
                                {items?.items?.map((item, index) => (
                                    <span className="form-check" onClick={(e) => { this.selectedItem(item, index); }} >
                                        {this.state.selectedItem?.find((cp) => (cp.id === item.id)) !== undefined ? (
                                            <AiFillCheckSquare className="me-2" style={{ color: "orange", fontSize: "1.6rem", }} />
                                        ) : (
                                            <IoSquareOutline className="me-2" style={{ fontSize: "1.6rem", color: "#c3c3c3", }} />
                                        )}
                                        <span style={{ color: "black" }} >
                                            {item.name}
                                        </span>
                                    </span>
                                ))}
                            </div>
                            <div className="fixed-bottom d-flex flex-row justify-content-between align-items-center px-3 py-2 pb-4 w-100">
                                {/* <div style={{ whiteSpace: "nowrap" }}>
                                    <span style={{ fontWeight: 700, fontSize: '1.1em', color: '#0d6d0d' }}>Selected Items:  {this.state.selectedItem?.length}</span>
                                </div> */}
                                <button className="coupon-btn p-3 w-100 ms-4" type="submit" onClick={this.selectedAllItems}  >
                                    Select All Items
                                </button>
                                <button className="coupon-btn p-3 w-100 ms-4" type="submit" onClick={this.closeCategoryMenu}  >
                                    Select
                                </button>
                            </div>
                        </div>
                    </div>

                </BottomSheet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    items: state.items.items,
    store: state.vendor.store
});
export default connect(mapStateToProps, {
    addVendorCoupon,
})(VendorCoupon);
