import { PLACE_ORDER, EMPTY_CHECKOUT,GET_ORDER } from "./actionTypes";

const initialState = {
  checkout: [],
  orderStatus: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PLACE_ORDER:
      return { ...state, checkout: action.payload };
    case EMPTY_CHECKOUT:
      return { ...state, checkout: action.payload };
    case GET_ORDER:
      return { ...state, orderStatus: action.payload };
    default:
      return state;
  }
}
