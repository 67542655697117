import { GET_SCRATCH_CARD,GET_REFFERALS } from "./actionType";

const initialState = {
    cards: [],
    refferals: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SCRATCH_CARD:
            return { ...state, cards: action.payload };
        case GET_REFFERALS:
            return { ...state, refferals: action.payload };
        default:
            return state;
    }
}
