import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BiSearch } from "react-icons/bi";
import { BsToggleOff, BsToggleOn, BsPencilSquare } from "react-icons/bs";
import { SiSquare } from "react-icons/si";
import VendorFooter from "../../VendorFooter";
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
    getAllAddonCategories,
    vendorToggleAddonCategory,
    getVendorSearchAddonCategories,
} from "../../../../redux/vendor/items/action";
import image from '../../../../assets/images/nodata.png'
import { Link } from "react-router-dom";
import { AiOutlinePlus } from 'react-icons/ai';



export class AddonCategories extends Component {
    state = {
        addoncategory: false,
        addoncategories: [],
        addonsearch: [],
        category_id: 'ALL',
        showItem: [],
        searchinput: '',
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getAllAddonCategories(user.data.auth_token, user.data.store[0].id)
            .then((response) => {
                this.setState({ addoncategories: response.payload.addoncategories });
                this.setState({ addonsearch: response.payload.addoncategories });
            })
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    toggleCategory = (category, input) => {
        const { user, setUpdatedAddOn } = this.props;
        this.props.vendorToggleAddonCategory(category.id, input, user.user.stores[0].id)
            .then((response) => {
                this.setState({ addonsearch: response.data.data });
            });
        setUpdatedAddOn(category);
    };
    filterAddonCategory = (id) => {
        this.setState({ category_id: id }, () => {
            this.props.getVendorSearchAddonCategories(this.state.searchinput, this.state.category_id)
                .then((response) => {
                    this.setState({ addonsearch: response.data.data });
                });
        })
        if (id === 'ALL') {
            const categories = this.props.addoncategories.addoncategories;
            this.setState({ addonsearch: categories });
            this.closeAddOnMenu();
        } else {
            const categories = this.props.addoncategories.addoncategories.filter((addoncategory) => addoncategory.id === id);
            this.setState({ addonsearch: categories });
            this.closeAddOnMenu();
        }
    };
    search = (input) => {
        this.setState({ searchinput: input })
        this.props.getVendorSearchAddonCategories(input, this.state.category_id)
            .then((response) => {
                this.setState({ addonsearch: response.data.data });
            });
    };
    showItems = (category) => {
        if (category === this.state.showItem) {
            this.setState({ showItem: 0 });
        } else {
            this.setState({ showItem: category })
        }
    }
    closeAddOnMenu = () => {
        this.setState({ addoncategory: false })
    };
    openAddOnMenu = () => {
        this.setState({ addoncategory: true })
    }
    render() {
        const { addoncategories } = this.state;
        const { addonsearch } = this.state;
        return (
            <React.Fragment>
                {/* <div className="d-flex flex-row align-items-center mt-3 searchBar py-2 px-2">
                    <input
                        type="text"
                        className="inventory-search w-100"
                        placeholder="Search for Add-Ons"
                        onChange={(event) => { this.search(event.target.value) }}
                    />
                    <span className="">
                        <BiSearch
                            set="curved"
                            style={{
                                fontSize: "2em",
                                color: "#FEA24B",
                            }}
                        />
                    </span>
                </div> */}
                <div className="inventory-add-on-count-head w-100 mt-3 px-2 py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div className="ms-3">
                            <span style={{ fontSize: '17px', fontWeight: '600' }}>Add-On Categories</span>
                        </div>
                        <div className="mt-1 ms-3">
                            <span>{addonsearch?.length} Categories</span>
                        </div>
                    </div>
                    <Link to="/vendor-add-addon-category">
                        <div className="py-2 px-2">
                            <button className="btn inventory-add-btn px-2">
                                <AiOutlinePlus /> ADD
                            </button>
                        </div>
                    </Link>
                </div>
                <div style={{ paddingBottom: '44vw' }}>
                    {addonsearch?.length > 0 ? (
                        <>
                            {addonsearch?.map(addoncategory =>
                                <div className="inventory-product-list mt-4">
                                    <div className="inventory-product-list-items mt-4">
                                        <div className="inventory-item-icon d-flex align-items-start justify-content-between">
                                            <div className=" d-flex">
                                                <div>
                                                    <SiSquare
                                                        set="curved"
                                                        style={{
                                                            fontSize: "9px",
                                                            color: "#32BA7C",
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-2 pt-1">
                                                    <div className="inventory-item-name">
                                                        <span>
                                                            {addoncategory.name}
                                                        </span>
                                                    </div>
                                                    <div className="inventory-item-desc mt-1">
                                                        <span>
                                                            {addoncategory.description}
                                                        </span>
                                                    </div>
                                                    <div className="inventory-item-addon-count mt-1" onClick={() => this.showItems(addoncategory.id)}>
                                                        <div>
                                                            <span className='text-blue' >
                                                                {addoncategory.addons?.length} Items
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-around'>
                                                {addoncategory.addons?.length > 0 ? (
                                                    <div>
                                                        {addoncategory.addons.find(addon => addon.is_active === 1) ? (
                                                            <BsToggleOn set="curved" style={{ fontSize: "2em", color: "#59D69D", }} onClick={() => this.toggleCategory(addoncategory, 'OFF')} />
                                                        ) : (
                                                            <BsToggleOff set="curved" style={{ fontSize: "2em", }} onClick={() => this.toggleCategory(addoncategory, 'ON')} />
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <Link to={"/vendor-edit-addon-category/" + addoncategory.id}>
                                                        <BsPencilSquare className='pt-1' style={{ fontSize: "1.6em", color: "#fea24b", }} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {addoncategory?.addons?.map((addon) =>
                                            <div style={{ display: this.state.showItem === addoncategory.id ? 'block' : 'none' }}>
                                                <div className="inventory-add-on-item-head d-flex justify-content-between mx-4 mt-2 ps-2 py-1 pe-2 ">
                                                    <div className='px-2 pb-1'>
                                                        <div className="inventory-item-name mt-1">
                                                            <span>{addon.name}</span>
                                                        </div>
                                                        <div className='mt-1 inventory-item-price'>
                                                            <span>Rs.{addon.price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className='pt-3 d-flex flex-column align-items-center'>
                                <div className='mt-3 d-flex flex-column align-items-center'>
                                    <img src={image} alt="no items"
                                        style={{
                                            objectFit: 'cover',
                                            width: '70%'
                                        }} />
                                </div>
                                <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Categories Found !!!</span>
                            </div>
                        </>
                    )
                    }
                </div>
                <div className="d-flex justify-content-end inventory-btn pb-4 py-2 px-2" onClick={() => this.openAddOnMenu()}>
                    <button className=" btn text-white inventory-browse-btn">
                        Browse Menu
                    </button>
                </div>
                <VendorFooter active_inventory={true} />

                <BottomSheet
                    open={this.state.addoncategory}
                    onDismiss={() => this.closeAddOnMenu()}
                    snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                >
                    <div className='px-3 pb-5' >
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            <div className="d-grid gap-2">
                                <button className="offer-sheet-btn" onClick={() => this.filterAddonCategory('ALL')}>
                                    All
                                </button>
                                {addoncategories?.map((category) =>
                                    <>
                                        <button className={this.state?.category_id === category.id
                                            ? "offer-sheet-btn text-black border-dark"
                                            : "offer-sheet-btn"}
                                            onClick={() => this.filterAddonCategory(category.id)} >
                                            {category.name}  {"( " + category.addons.length + " Items )"}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    addoncategories: state.items.addoncategories,
})

export default connect(mapStateToProps, { getAllAddonCategories, vendorToggleAddonCategory, getVendorSearchAddonCategories })(AddonCategories)
