import React, { Component } from "react";
// import Modal from "react-modal";
import { BottomSheet } from "react-spring-bottom-sheet";
import { AiOutlineStop, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { addProduct, updateCart } from '../../../redux/mobile/cart/action'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formatPrice } from "../../Helpers/formatPrice";
import Truncate from "react-truncate";
import { IoStopCircleOutline, IoMdSquareOutline } from "react-icons/io5";
import { MdCheckBoxOutlineBlank, MdCheckBox, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ImMinus, ImPlus } from "react-icons/im";
import HeadShake from 'react-reveal/HeadShake';
import { IoIosArrowDown } from "react-icons/io";

export class ComboCustomization extends Component {
    state = {
        open: false,
        showAddon: '',
        comboItems: [],
        freeItems: [],
        extraItems: [],
        itemQuantity: 0,
        combo_limit: false,
        notSelected: false,
        category_id: ''
    };

    _processCombos = (product) => {
        const { cartBtn, cartProducts } = this.props;
        const { comboItems, freeItems, itemQuantity, category_id, extraItems } = this.state;
        let combos = [];
        if (comboItems.every(c => c.status === true) && freeItems.every(c => c.status === true)) {
            const index = cartProducts.findIndex((p) => p.id === product.id);
            if (index > -1) {
                cartProducts.forEach((cp) => {
                    if (cp.id === product.id) {
                        combos["selectedcombos"] = [];
                        cp.quantity = itemQuantity - 1;
                        cp.selectedcombos.splice(0, 1);
                        cp.selectedcombos.push({
                            selected_combo_items: comboItems,
                            selected_free_items: freeItems,
                            selected_extra_items: extraItems,
                            price: this.totalComboPrice(),
                            quantity: itemQuantity,
                            category_id: category_id
                        });
                        this.props.addProduct(cp);
                    }
                });
                updateCart(cartProducts)
                this.forceStateUpdate()
            } else {
                combos["selectedcombos"] = [];
                combos["selectedcombos"].push({
                    selected_combo_items: comboItems,
                    selected_free_items: freeItems,
                    selected_extra_items: extraItems,
                    price: this.totalComboPrice(),
                    quantity: itemQuantity,
                    category_id: category_id
                });
                product.quantity = itemQuantity;
                this.props.addProduct(Object.assign(combos, product));
            }
            this.forceStateUpdate()
            this.handlePopupClose()
        } else {
            this.setState({ notSelected: false })
            setTimeout(() => {
                this.setState({ notSelected: true })
            }, 1);
        }
    };
    totalComboPrice = () => {
        let total = 0;
        const { product } = this.props;
        const { comboItems, freeItems, extraItems } = this.state;
        comboItems.filter((c) => {
            if (c?.price) {
                total += parseFloat(c?.price);
            }
        })
        freeItems.filter((free) => {
            if (free?.price) {
                total += parseFloat(free?.price);
            }
        })
        extraItems.filter((free) => {
            if (free?.price) {
                total += parseFloat(free?.price);
            }
        })

        return formatPrice(total);
    }
    totalPrice = () => {
        let total = 0;
        const { product } = this.props;
        const { comboItems, freeItems, extraItems, itemQuantity } = this.state;

        total += parseFloat(product?.selling_price);
        let subtotal = total;
        let sell_price = 0;
        if (product?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(product.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        if (itemQuantity) {
            total = parseFloat(sell_price) * itemQuantity;
        }

        comboItems.filter((c) => {
            if (c?.price) {
                total += parseFloat(c?.price);
            }
        })
        freeItems.filter((free) => {
            if (free?.price) {
                total += parseFloat(free?.price);
            }
        })
        extraItems.filter((free) => {
            if (free?.price) {
                total += parseFloat(free?.price);
            }
        })

        return parseFloat(total);
    }

    onMinus = () => {
        const { itemQuantity } = this.state;
        if (itemQuantity === 1) {
            this.handlePopupClose()
        } else {
            let quantity;
            quantity = itemQuantity - 1;
            this.setState({ itemQuantity: quantity })
        }
    }

    onPlus = () => {
        const { itemQuantity } = this.state;
        let quantity;
        quantity = itemQuantity + 1;
        this.setState({ itemQuantity: quantity })
    }

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    handlePopupOpen = () => {
        if (this.props.singleBtn) {
            this.props.floatCart(true)
        }
        let comboItems = [];
        let freeItems = [];
        let extraItems = [];
        this.setState({ open: true });
        const { product, cartProducts } = this.props;
        for (let i = 0; i < product?.combo_items_count; i++) {
            comboItems.push({ itemCombo: i, selectedItemsId: [], price: 0, status: false });
        }
        for (let i = 0; i < product?.free_items_count; i++) {
            freeItems.push({ itemFree: i, selectedItemsId: [], price: 0, status: false });
        }
        let extraLength = (product?.combo_categories?.filter((combo_category, index) => (combo_category.is_extra)))
        // for (let i = 0; i < extraLength.length; i++) {
        //     extraItems.push({ itemExtra: i, selectedItemsId: [], price: 0, status: false });
        // }
        extraLength.forEach(section => {
            extraItems.push({ itemExtra: section.id, selectedItemsId: [], price: 0, status: false });
        })
        this.setState({ freeItems: freeItems, comboItems: comboItems, itemQuantity: 1, extraItems: extraItems });
        if (cartProducts.find((cp) => cp.id === product.id) !== undefined) {
            let item = cartProducts.find((cp) => cp.id === product.id)
            this.setState({
                freeItems: item?.selectedcombos[0].selected_free_items,
                comboItems: item?.selectedcombos[0].selected_combo_items,
                extraItems: item?.selectedcombos[0].selected_extra_items,
                itemQuantity: item?.quantity
            });
        }
    };
    handlePopupClose = () => {
        if (this.props.singleBtn) {
            this.props.floatCart(false)
        }
        this.setState({ open: false });
        this.forceStateUpdate();
        this.setState({ freeItems: [], comboItems: [] });
    };

    handleOnChangeFree = (e, chooseItem, combo_quantity, combo, offer) => {
        e.preventDefault();
        const { freeItems } = this.state;
        const index = freeItems.findIndex(obj => obj.itemFree === chooseItem);
        if (index !== -1) {
            const updatedItemId = [...freeItems[index].selectedItemsId];
            let updatedprice = freeItems[index].price;
            let updatedstatus = freeItems[index].status;
            if (updatedItemId.includes(combo.id)) {
                updatedItemId.splice(updatedItemId.indexOf(combo.id), 1);
                if (offer && offer > 0) {
                    updatedprice -= (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                } else {
                    updatedprice -= (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                }
                if (updatedItemId.length === combo_quantity) {
                    updatedstatus = true;
                } else {
                    updatedstatus = false;
                }
            } else {
                if (updatedItemId.length < combo_quantity) {
                    updatedItemId.push(combo.id);
                    if (offer && offer > 0) {
                        updatedprice += (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                    } else {
                        updatedprice += (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                    }
                } else {
                    this.setState({ combo_limit: false })
                    setTimeout(() => {
                        this.setState({ combo_limit: true })
                    }, 1);
                    setTimeout(() => {
                        this.setState({ combo_limit: false })
                    }, 2000);
                }
                if (updatedItemId.length === combo_quantity) {
                    updatedstatus = true;
                } else {
                    updatedstatus = false;
                }
            }
            const updatedObject = { ...freeItems[index], selectedItemsId: updatedItemId, price: updatedprice, status: updatedstatus };
            const updatedArray = [...freeItems];
            updatedArray[index] = updatedObject;
            this.setState({ freeItems: updatedArray });
        }
        this.forceStateUpdate()
    }

    handleOnChange = (e, chooseItem, combo_quantity, combo, offer) => {
        e.preventDefault();
        const { comboItems } = this.state;
        const index = comboItems.findIndex(obj => obj.itemCombo === chooseItem);
        if (index !== -1) {
            const updatedItemId = [...comboItems[index].selectedItemsId];
            let updatedprice = comboItems[index].price;
            let updatedstatus = comboItems[index].status;
            if (updatedItemId.includes(combo.id)) {
                updatedItemId.splice(updatedItemId.indexOf(combo.id), 1);
                if (offer && offer > 0) {
                    updatedprice -= (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                } else {
                    updatedprice -= (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                }
                if (updatedItemId.length === combo_quantity) {
                    updatedstatus = true;
                } else {
                    updatedstatus = false;
                }
            } else {
                if (updatedItemId.length < combo_quantity) {
                    updatedItemId.push(combo.id);
                    if (offer && offer > 0) {
                        updatedprice += (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                    } else {
                        updatedprice += (parseFloat(combo.price) - (parseFloat(combo.price) * parseFloat(offer) / 100));
                    }
                } else {
                    this.setState({ combo_limit: false })
                    setTimeout(() => {
                        this.setState({ combo_limit: true })
                    }, 1);
                    setTimeout(() => {
                        this.setState({ combo_limit: false })
                    }, 2000);
                }
                if (updatedItemId.length === combo_quantity) {
                    updatedstatus = true;
                } else {
                    updatedstatus = false;
                }
            }
            const updatedObject = { ...comboItems[index], selectedItemsId: updatedItemId, price: updatedprice, status: updatedstatus };
            const updatedArray = [...comboItems];
            updatedArray[index] = updatedObject;
            this.setState({ comboItems: updatedArray, category_id: combo.combo_categories_id });
        }
        this.forceStateUpdate()
    }
    handleOnChangeExtra = (e, chooseItem, combo_category, combo, offer) => {
        e.preventDefault();
        const { extraItems } = this.state;
        const index = extraItems.findIndex(obj => obj.itemExtra === combo_category.id);
        if (index !== -1) {
            const updatedItemId = [...extraItems[index].selectedItemsId];
            let updatedprice = extraItems[index].price;
            if (updatedItemId.includes(combo.id)) {
                updatedItemId.splice(updatedItemId.indexOf(combo.id), 1);
                if (offer && offer > 0) {
                    updatedprice -= (Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(offer) / 100));
                } else {
                    updatedprice -= (Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(offer) / 100));
                }
            } else {
                updatedItemId.push(combo.id);
                if (offer && offer > 0) {
                    updatedprice += (Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(offer) / 100));
                } else {
                    updatedprice += (Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(offer) / 100));
                }
            }
            const updatedObject = { ...extraItems[index], selectedItemsId: updatedItemId, price: updatedprice };
            const updatedArray = [...extraItems];
            updatedArray[index] = updatedObject;
            // //// console.log(updatedArray, 'updatedArray')
            this.setState({ extraItems: updatedArray });
        }
        this.forceStateUpdate()
    }

    render() {
        const { product, singleBtn, addProduct, cartProducts, homeBtn, storeBtn, cartBtn, mealBtn, dealToday } = this.props;
        const { comboItems, freeItems, extraItems, combo_limit, itemQuantity, notSelected } = this.state;
        // //// console.log(product,'productttttt');

        return (
            <React.Fragment>

                {singleBtn ? (
                    <>
                        {cartProducts.find((cp) => cp.id === product.id) !== undefined ? (
                            <Link to="/cart">
                                <div className='px-3 pt-2 mt-1'>
                                    <button
                                        type="button"
                                        className='text-center w-100  add-button py-3 px-2'
                                    >
                                        Go to Cart
                                    </button>
                                </div>
                            </Link>
                        ) : (
                            <div className='px-3 w-100 position-fixed ms-2 me-2' style={{ bottom: '15px', left: '-7px' }}>
                                <button
                                    className='text-center w-100 add-button py-3 px-5'
                                    onClick={this.handlePopupOpen}
                                >
                                    Add to Cart
                                </button>
                            </div>
                        )}
                    </>
                ) : homeBtn ? (
                    <div className='top-food-add-min-btn' onClick={this.handlePopupOpen}>
                        <AiOutlinePlus
                            style={{
                                color: "green",
                            }}
                            size={14}
                        />
                    </div>
                ) : storeBtn ? (
                    <div className='text-secondary fw-bolder fs-7 text-center pt-1'>
                        {/* <div className='text-secondary fw-bolder fs-7 text-center pt-1'>
                        <div className="d-flex justify-content-center pt-1">
                            <div className="top-food-btn px-3"
                                onClick={this.handlePopupOpen}   >
                        <span>Buy {product.combo_items_count} Get  {product.free_items_count}</span>
                                <span style={{ color: "green", }}   >  Customise   </span>
                            </div>
                        </div>
                    </div> */}
                        <div className="d-flex justify-content-center pt-1">
                            <div className="top-food-btn px-3"
                                onClick={this.handlePopupOpen}   >
                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                <span style={{ color: "green", }}   >
                                    ADD
                                </span>
                            </div>
                        </div>
                    </div>
                ) : cartBtn ? (
                    <div
                        type="button"
                        className="cart-customise"
                        onClick={this.handlePopupOpen}
                    > Customise <IoIosArrowDown />
                    </div>
                ) : dealToday ? (
                    <div className="d-flex align-items-center" style={{ color: "green", }}>
                        <div className="top-food-btn" onClick={this.handlePopupOpen}  >
                            Add <AiOutlinePlus
                                size={14}
                            />
                        </div>
                    </div>
                ) : mealBtn ? (
                    <div className="top-food-btn px-3 w-100"
                        onClick={this.handlePopupOpen}   >
                        <span style={{ color: "green", }}   >
                            Customise
                        </span>
                    </div>
                ) : (
                    <div className="top-food-add-min-btn"
                        onClick={this.handlePopupOpen}
                    >
                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                    </div>
                )
                }
                <BottomSheet
                    open={this.state.open}
                    onDismiss={() => this.handlePopupClose()}
                    snapPoints={({ maxHeight }) => (0.9 * maxHeight)}
                >
                    {combo_limit &&
                        <div className="position-fixed text-center w-100 px-3" style={{ zIndex: '100', bottom: '30vw' }}>
                            <div className="px-3 py-2" style={{ backgroundColor: '#000', borderRadius: '10px', color: '#fff' }}>
                                You have reached the maximum limit of addons for this item.
                            </div>
                        </div>
                    }
                    <div style={{ paddingBottom: '15vh', overflowY: 'scroll' }} className="px-3 pt-1">
                        <div className='d-flex justify-content-end' onClick={() => this.handlePopupClose()}>
                            <AiOutlineClose style={{
                                color: "#b34700",
                            }}
                                size={20} />
                        </div>
                        {(product?.combo_categories?.map((combo_category, index) => (!combo_category.is_extra) ? (
                            <div key={combo_category.id} className="addon-category-block">
                                <React.Fragment>
                                    <div className="w-100 " style={{ fontWeight: '600', fontSize: '14px' }}>
                                        <Truncate lines={1}>
                                            {/* {combo_category.name} {" "}{product?.combo_items_count + product?.free_items_count} {' ' + product?.name + ' '}
                                            {'(Buy ' + product?.combo_items_count + ' Get ' + product?.free_items_count + ' Free)'} */}
                                            <span className="text-muted">{product?.name}</span>
                                            <span className="text-muted"> • </span>

                                            {product?.dealofdays?.length > 0 && product?.selling_price > 0 ?
                                                <>
                                                    <span className="text-muted" style={{ fontSize: "14px", fontWeight: "600" }}>
                                                        ₹ {product?.selling_price - (product?.selling_price * parseFloat(product.dealofdays[0].offer) / 100)}{" "}
                                                    </span>
                                                    {/* <span className="text-muted"> • </span>
                                                    <span className="ms-2" style={{ color: "red", fontSize: "13px", }} >
                                                        {' ₹' + product?.selling_price}
                                                    </span> */}
                                                </>
                                                : <span className="text-muted">
                                                    {'₹ ' + product?.selling_price}
                                                </span>
                                            }
                                        </Truncate>
                                    </div>
                                    <div style={{ fontWeight: '800', fontSize: '18px' }}>
                                        {/* Customise as per your taste */}
                                    </div>

                                    {comboItems.map((item, countIndex) => (
                                        combo_category?.combos?.length > 0 && (
                                            <div className="mt-4" >
                                                <div className="py-2 d-flex">
                                                    <div>
                                                        Choose Your {item.itemCombo + 1} {product?.name}
                                                    </div>
                                                    <HeadShake when={notSelected && item?.status === false}>
                                                        <span className="ms-2" style={item?.status ? {} : { color: 'red' }}>
                                                            {'(' + item?.selectedItemsId?.length + '/' + combo_category?.combo_quantity + ')'}
                                                        </span>
                                                    </HeadShake>
                                                </div>
                                                <div className="py-3 px-2 mt-2" style={{ boxShadow: '0px 0px 20px 2px #efefef', borderRadius: '12px' }}>
                                                    {combo_category?.combos?.filter(combo => combo.is_free === 0).sort((a, b) => a.price - b.price)?.map((combo, comboIndex) => (
                                                        <React.Fragment key={combo.id} >
                                                            <div className="form-group addon-list mt-1">
                                                                <div htmlFor={combo.name} className="d-flex justify-content-between align-items-center">
                                                                    <div>
                                                                        <label className="d-flex text addon-label align-items-center" htmlFor={combo.name}>
                                                                            <IoStopCircleOutline className="me-3" color={'#007a34'} />
                                                                            {combo.name}
                                                                        </label>
                                                                    </div>
                                                                    <span className="addon-price ms-2 d-flex justify-content-end" style={{ whiteSpace: 'nowrap' }}>
                                                                        {product?.dealofdays?.length > 0 && combo.price > 0 ?
                                                                            <div className='d-flex align-items-center gap-3'>
                                                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                    ₹ {Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(product.dealofdays[0].offer) / 100)}
                                                                                </span>
                                                                                <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                    ₹ {Math.abs(combo.price)}
                                                                                </s>
                                                                            </div>
                                                                            : <>
                                                                                ₹ {Math.abs(combo.price)}
                                                                            </>
                                                                        }
                                                                        {/* ₹  {Math.abs(combo.price)} */}
                                                                        <div className="magic-checkbox ms-2" onClick={(e) => {
                                                                            this.handleOnChange(e, countIndex, combo_category?.combo_quantity, combo, product?.dealofdays[0]?.offer ?? 0);
                                                                            this.forceStateUpdate();
                                                                        }}>
                                                                            {item?.selectedItemsId?.includes(combo.id) ?
                                                                                <MdCheckBox />
                                                                                :
                                                                                <MdCheckBoxOutlineBlank />
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    ))}
                                    {freeItems.map((item, countIndex) => (
                                        combo_category?.combos?.length > 0 && (
                                            <div className="mt-4" >
                                                <div className="py-2 d-flex">
                                                    <div>
                                                        Choose Your {item.itemFree + 1} Free {product?.name}
                                                    </div>
                                                    <HeadShake when={notSelected && item?.status === false}>
                                                        <span className="ms-2" style={item?.status ? {} : { color: 'red' }}>
                                                            {'(' + item?.selectedItemsId?.length + '/' + combo_category?.combo_quantity + ')'}
                                                        </span>
                                                    </HeadShake>
                                                </div>
                                                <div className="py-3 px-2 mt-2" style={{ boxShadow: '0px 0px 20px 2px #efefef', borderRadius: '12px' }}>
                                                    {combo_category?.combos?.sort((a, b) => a.price - b.price)?.filter((a) => a.is_free)?.map((combo, comboIndex) => (
                                                        <React.Fragment key={combo.id} >
                                                            <div className="form-group addon-list mt-1">

                                                                <div htmlFor={combo.name} className="d-flex justify-content-between ">
                                                                    <div>
                                                                        <label className="text addon-label align-items-center" htmlFor={combo.name}>
                                                                            <IoStopCircleOutline className="me-3" color={'#007a34'} />
                                                                            {combo.name}
                                                                        </label>
                                                                    </div>
                                                                    <span className="addon-price ms-2 d-flex justify-content-end" style={{ whiteSpace: 'nowrap' }}>
                                                                        {product?.dealofdays?.length > 0 && combo.price > 0 ?
                                                                            <div className='d-flex align-items-center gap-3'>
                                                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                    ₹ {Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(product.dealofdays[0].offer) / 100)}
                                                                                </span>
                                                                                <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                    ₹ {Math.abs(combo.price)}
                                                                                </s>
                                                                            </div>
                                                                            : <>
                                                                                ₹ {Math.abs(combo.price)}
                                                                            </>
                                                                        }
                                                                        <div className="magic-checkbox ms-2" onClick={(e) => {
                                                                            this.handleOnChangeFree(e, countIndex, combo_category?.combo_quantity, combo, product?.dealofdays[0]?.offer ?? 0);
                                                                            this.forceStateUpdate();
                                                                        }}>
                                                                            {item?.selectedItemsId?.includes(combo.id) ?
                                                                                <MdCheckBox />
                                                                                :
                                                                                <MdCheckBoxOutlineBlank />
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </React.Fragment>
                            </div>
                        ) : (
                            <div key={combo_category.id} className="addon-category-block">
                                <React.Fragment>
                                    {extraItems.map((item, countIndex) => (item.itemExtra == combo_category.id) && (
                                        combo_category?.combos?.length > 0 && (
                                            <div className="mt-4" >
                                                <div className="py-2 d-flex">
                                                    <div>
                                                        Add A {combo_category?.name}
                                                    </div>
                                                    <span className="ms-2">
                                                        {'(' + item?.selectedItemsId?.length + '/' + combo_category?.combos?.length + ')'}
                                                    </span>
                                                </div>
                                                <div className="py-3 px-2 mt-2" style={{ boxShadow: '0px 0px 20px 2px #efefef', borderRadius: '12px' }}>
                                                    {combo_category?.combos?.sort((a, b) => a.price - b.price)?.map((combo, comboIndex) => (
                                                        <React.Fragment key={combo.id} >
                                                            <div className="form-group addon-list mt-1">
                                                                <div htmlFor={combo.name} className="d-flex justify-content-between ">
                                                                    <div>
                                                                        <label className="d-flex text addon-label align-items-center" htmlFor={combo.name}>
                                                                            <IoStopCircleOutline className="me-3" color={'#007a34'} />
                                                                            {combo.name}
                                                                        </label>
                                                                    </div>
                                                                    <span className="addon-price ms-2 d-flex justify-content-end" style={{ whiteSpace: 'nowrap' }}>
                                                                        {product?.dealofdays?.length > 0 && combo.price > 0 ?
                                                                            <div className='d-flex align-items-center gap-3'>
                                                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                    ₹ {Math.abs(combo.price) - (Math.abs(combo.price) * parseFloat(product.dealofdays[0].offer) / 100)}
                                                                                </span>
                                                                                <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                    ₹ {Math.abs(combo.price)}
                                                                                </s>
                                                                            </div>
                                                                            : <>
                                                                                ₹ {Math.abs(combo.price)}
                                                                            </>
                                                                        }
                                                                        <div className="magic-checkbox ms-2" onClick={(e) => {
                                                                            this.handleOnChangeExtra(e, countIndex, combo_category, combo, product?.dealofdays[0]?.offer ?? 0);
                                                                            this.forceStateUpdate();
                                                                        }}>
                                                                            {item?.selectedItemsId?.includes(combo.id) ?
                                                                                <MdCheckBox />
                                                                                :
                                                                                <MdCheckBoxOutlineBlank />
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    ))}

                                </React.Fragment>
                            </div>
                        )))}
                        {cartBtn
                        }
                        <div className='d-flex justify-content-between align-items-center position-fixed w-100 py-3 gap-3 px-3' style={{ bottom: '0px', left: '0px', backgroundColor: '#fff', boxShadow: '0px -5px 20px 5px #0000001a' }}>
                            <div className='d-flex justify-content-between align-items-center py-2 px-3' style={{ border: '1px solid #e7e7e7', borderRadius: '8px' }} >
                                <div style={{ fontSize: '1em', color: '#009c27', fontWeight: '650' }} onClick={() => this.onMinus()}> <ImMinus /></div>
                                <div className="px-4" style={{ fontSize: '1.4em', color: '#009c27', fontWeight: '650' }}> {itemQuantity} </div>
                                <div style={{ fontSize: '1em', color: '#009c27', fontWeight: '650' }} onClick={() => this.onPlus()}> <ImPlus /> </div>
                            </div>
                            <button className='flex-grow-1 addon-category-add px-2 py-3'
                                onClick={() => {
                                    product.quantity = 1;
                                    this._processCombos(product);
                                    this.forceStateUpdate();
                                }}>
                                Add Item |  ₹ {this.totalPrice()}
                            </button>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products
});

export default connect(mapStateToProps, {
    addProduct,
    updateCart
})(ComboCustomization)
