import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from "../../Elements/MobileBackButton";
import { MdNotificationsNone } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import filter from "../../../assets/images/Filter.png";
import vegan from "../../../assets/images/vegan lover.png";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from 'react-content-loader'
import { PLACE_HOLDER_IMAGE } from "../../../api";


export class RestarauntFilter extends Component {
    state = {
        loading: false
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}

                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>

                            <rect x="10" y="27" rx="10" ry="10" width="30" height="30" />
                            <rect x="10" y="80" rx="8" ry="8" width="75" height="12" />
                            <rect x="10" y="105" rx="8" ry="8" width="121" height="12" />
                            <rect x="228" y="75" rx="10" ry="10" width="28" height="28" />
                            <rect x="10" y="140" rx="8" ry="8" width="210" height="30" />
                            <rect x="228" y="140" rx="10" ry="10" width="38" height="30" />
                            <rect x="10" y="185" rx="10" ry="10" width="70" height="30" />
                            <rect x="92" y="185" rx="10" ry="10" width="70" height="30" />
                            <rect x="10" y="228" rx="8" ry="8" width="105" height="12" />
                            <rect x="20" y="250" rx="10" ry="10" width="115" height="130" />
                            <rect x="150" y="250" rx="10" ry="10" width="115" height="130" />
                            <rect x="20" y="400" rx="10" ry="10" width="115" height="130" />
                            <rect x="150" y="400" rx="10" ry="10" width="115" height="130" />
                            <rect x="20" y="550" rx="10" ry="10" width="115" height="130" />
                            <rect x="150" y="550" rx="10" ry="10" width="115" height="130" />






                        </ContentLoader>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="d-flex flex-column">
                            <div className="mobile-ntfcn-head py-4">
                                <div className="pt-3 px-1">
                                    <MobileBackButton />
                                </div>
                                <div className="mt-5 ms-4 ">
                                    <div className="d-flex justify-content-between">
                                        Find Your
                                        <div className="notification-explore-menu">
                                            <MdNotificationsNone
                                                style={{
                                                    color: "#FEA24B",
                                                    fontSize: "2rem",
                                                    padding: "2%",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="" style={{ marginTop: "-0.3em" }}>
                                        Favourite Food
                                    </div>
                                </div>
                            </div>
                            <div className="px-3 d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center explore-menu-searchBar  py-2 px-2">
                                    <div className="" style={{ color: "#DA6317" }}>
                                        <BiSearch
                                            set="curved"
                                            style={{
                                                fontSize: "2em",
                                                color: "#DA6317",
                                            }}
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        className="mobile-inventory-search w-100 "
                                        style={{ paddingLeft: "1em" }}
                                        placeholder="What do you want to order ?"
                                    // onChange={(event) => { this.search(event.target.value) }}
                                    />
                                </div>
                                <div className="filter-image-exploremenu">
                                    <img src={filter} alt="filter" />
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-3 px-3">
                                <div className="rest-filter text-center">
                                    <span
                                        style={{
                                            color: "#DA6317",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        10Km{" "}
                                        <AiOutlineClose style={{ marginLeft: "1em" }} />
                                    </span>
                                </div>
                                <div
                                    className="rest-filter text-center"
                                    style={{ marginLeft: "1em" }}
                                >
                                    <span
                                        style={{
                                            color: "#DA6317",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Soup{" "}
                                        <AiOutlineClose style={{ marginLeft: "1em" }} />
                                    </span>
                                </div>
                            </div>
                            <div
                                className="description mt-3 px-4"
                                style={{ fontWeight: "700", fontSize: "15px" }}
                            >
                                Popular Restaraunt
                            </div>
                            <div
                                className="d-grid align-items-center justify-content-center px-4 gap-2"
                                style={{ gridTemplateColumns: "repeat(2,1fr)" }}
                            >
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em" }}>
                                    <div className="">
                                        <ProgressiveImage
                                            delay={20}
                                            src={vegan}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "19vw",
                                                        objectFit: "cover",
                                                        height: "17vw",
                                                        borderRadius: "10px",
                                                        // backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        marginLeft: "-0.6em",
                                                        marginTop: "2em",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div
                                        style={{
                                            FontSize: "16px",
                                            fontWeight: "700",
                                            paddingTop: "2em",
                                        }}
                                    >
                                        Vegan Resto
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "1em",
                                            fontSize: "13px",
                                            opacity: "0.5",
                                        }}
                                    >
                                        12 Mins
                                    </div>
                                </div>

                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(RestarauntFilter);
