import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import Profile from "../../../assets/images/profile-img.png";
import ProgressiveImage from "react-progressive-image";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { IoIosCheckmarkCircle } from "react-icons/io";
import ContentLoader from "react-content-loader";
import { getUserDetails, editUserDetails } from "../../../redux/mobile/user/action";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_BASE_URL } from "../../../api";
import Modal from "react-modal";
import Shake from 'react-reveal/Shake';
export class EditProfile extends Component {
    state = {
        loading: false,
        EditUser: "",
        file: "",
        imageError: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getUserDetails(user.data.auth_token).then((response) => {
            this.setState({ EditUser: response.payload.data });
        });
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
        ////// console.log(this.state.file)
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { EditUser } = this.state;
        this.setState({ imageError: false })
        if (EditUser?.image?.size > 200000) {
            setTimeout(() => {
                this.setState({ imageError: true })
            }, 1);
        } else {
            this.setState({ loading: true });
            ////// console.log('image bigg')
            let formData = new FormData();
            formData.append("name", EditUser.name);
            formData.append("image", EditUser.image);
            formData.append("phone", EditUser.phone);
            formData.append("email", EditUser.email);
            formData.append("token", user.data.auth_token);
            this.props.editUserDetails(formData).then((response) => {
                if (response && response.data && response.data.success) {
                    this.setState({ loading: false, modal: true, imageError: false });
                }
            });
        }
    };
    handleInput(e, name, index) {
        let user = this.state.EditUser;
        user[name] = e;
        this.setState({ EditUser: user });
    }
    handlePopup = (e) => {
        e.preventDefault();
        this.setState({ modal: false });
        // window.location.replace("/my-profile")
    }
    render() {
        const { userDetails } = this.props;
        const { imageError } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="25" rx="8" ry="8" width="30" height="30" />
                            <rect x="105" y="30" rx="8" ry="8" width="100" height="20" />
                            <rect x="100" y="80" rx="50" ry="50" width="85" height="85" />
                            <rect x="15" y="180" rx="8" ry="8" width="50" height="20" />
                            <rect x="15" y="205" rx="8" ry="8" width="250" height="35" />
                            <rect x="15" y="250" rx="8" ry="8" width="50" height="20" />
                            <rect x="15" y="275" rx="8" ry="8" width="250" height="35" />
                            <rect x="15" y="320" rx="8" ry="8" width="50" height="20" />
                            <rect x="15" y="345" rx="8" ry="8" width="250" height="35" />
                            <rect x="15" y="390" rx="8" ry="8" width="50" height="20" />
                            <rect x="15" y="415" rx="8" ry="8" width="250" height="75" />
                            <rect x="15" y="530" rx="8" ry="8" width="250" height="35" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MobileBackButtonWithCenterTitle title="Edit Profile" history={this.props.history} />

                        <div className="d-flex flex-row align-items-center justify-content-center px-3">
                            <div className="d-flex flex-row card-profile position-relative pt-2">
                                <div
                                    className=" align-items-center  position-absolute pt-2"
                                    style={{ bottom: "4px" }}
                                >
                                    <ProgressiveImage
                                        delay={20}
                                        placeholder={
                                            PLACE_HOLDER_IMAGE
                                        }
                                    >
                                        {(src, loading) => (
                                            <img
                                                className=""
                                                src={this.state.file ? this.state.file : this.state.EditUser.image ? IMAGE_BASE_URL + this.state.EditUser.image : Profile}
                                                style={{
                                                    width: "30vw",
                                                    objectFit: "cover",
                                                    height: "30vw",
                                                    borderRadius: "50%",
                                                    backgroundRepeat: "round",
                                                }}
                                                alt="main-item"
                                            />
                                        )}
                                    </ProgressiveImage>
                                </div>
                                {/* <MdAdd
                                    className="position-absolute"
                                    style={{
                                        zIndex: "10",
                                        fontSize: "1.5rem",
                                        right: "7px",
                                        bottom: "4px",
                                        backgroundColor: "white",
                                        color: "#FEAD1D",
                                        borderRadius: "12px",
                                    }}
                                /> */}
                            </div>
                        </div>
                        <Form onSubmit={this.onSubmit}>
                            <div className="d-flex flex-column align-items-center text-center">
                                <label for="image"
                                    className="fs-6 user-select-none mt-2"
                                    style={{ color: "#3795e6" }} >
                                    Change Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    style={{ display: "none" }}
                                    id="image"
                                    onChange={(e) => {
                                        let file = e.target.files[0];
                                        this.setState({ file: URL.createObjectURL(file), });
                                        this.handleInput(file, "image");
                                    }}
                                />
                                {imageError &&
                                    <Shake Shake duration={250} right>
                                        <span style={{ color: 'red' }}><i>* Image should be lesser than 200kb</i></span>
                                    </Shake>
                                }
                            </div>
                            <div className="py-2">
                                <div className="d-flex flex-column px-4">
                                    <div style={{ paddingTop: "5%", fontSize: "15px", fontWeight: "400", }}  >
                                        Name
                                    </div>
                                </div>
                                <div className="px-3">
                                    <InputGroup className="mt-1 w-100 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl
                                            type="text"
                                            value={this.state.EditUser && this.state.EditUser.name}
                                            placeholder="User Name"
                                            className="input-coupon py-1"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            <div className="py-2">
                                <div className="d-flex flex-column px-4">
                                    <div style={{ fontSize: "15px", fontWeight: "400", }} >
                                        Phone number
                                    </div>
                                </div>
                                <div className="px-3">
                                    <InputGroup className="mt-1 w-100 coupon-input"
                                        style={{ boxSizing: "border-box" }}  >
                                        <FormControl
                                            type="text"
                                            value={this.state.EditUser && this.state.EditUser?.phone}
                                            placeholder="Phone Number"
                                            className="input-coupon py-1"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "phone");
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            <div className="py-2">
                                <div className="d-flex flex-column px-4">
                                    <div style={{ fontSize: "15px", fontWeight: "400" }}  >
                                        Email
                                    </div>
                                </div>
                                <div className="px-3">
                                    <InputGroup
                                        className="mt-1 w-100 coupon-input"
                                        style={{ boxSizing: "border-box " }}>
                                        <FormControl
                                            type="email"
                                            value={this.state.EditUser && this.state.EditUser.email}
                                            placeholder="Email"
                                            className="input-coupon py-1"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.handleInput(value, "email");
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            <div className=" px-3 py-3 position-fixed bottom-0 w-100 ">
                                <button type="submit" className="edit-profile-button btn btn py-2 w-100 text-white fw-bold" >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </React.Fragment>
                )}

                <Modal
                    isOpen={this.state.modal}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.handlePopup()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>
                        <div className=' '>
                            <IoIosCheckmarkCircle size={80} color="#34c970" />
                        </div>
                        <div className='text-muted mt-2' style={{ fontSize: '13px' }} >
                            <span>Your Profile Updated Succssfully</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-2">
                        <div className="text-center w-100">
                            <button className="add-button-meals p-2 px-2 text-center text-white position-relative"
                                onClick={(e) => this.handlePopup(e)}
                                style={{ width: "50%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}>
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userDetails: state.user.userDetails,
});

export default connect(mapStateToProps, { getUserDetails, editUserDetails })(EditProfile);
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
