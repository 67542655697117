import React, { Component } from "react";
import { BiSearch } from "react-icons/bi";
import { connect } from "react-redux";
import VendorFooter from "../VendorFooter";
import Loading from "../../Elements/loading";
import { Link } from "react-router-dom";
import Switch from "react-input-switch";
import Ink from "react-ink";
import {
    toggleSingleStore,
    getUserStore,
} from "../../../redux/vendor/user/action";
import {
    getVendorSingleOrder,
    acceptVendorOrder,
    declineVendorOrder,
    prepareVendorOrder,
    pickupVendorOrder,
} from "../../../redux/vendor/order/action";
import VendorBackButton from "../../Elements/VendorBackButton";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { FiFileText } from "react-icons/fi";
import { WEBSITE_URL } from "../../../api";
import { FaRegClock } from "react-icons/fa";

import non_veg_icon from '../../../assets/images/non-veg-icon.png'
import veg_icon from '../../../assets/images/veg-icon.png'


export class VendorSingleOrder extends Component {
    state = {
        value: "Closed",
        all_order: true,
        view_order: false,
        is_medicine: false,
        prescription: false,
        loading: false,
        is_accepted: false,
        is_declined: false,
        order_id: this.props.match.params.id,
        order: [],
        acceptSheet: false,
        declineSheet: false,
        prepareSheet: false,
        pickupSheet: false,
        type: this.props.match.params.type,
        cancelReason: "",
        check: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        //// console.log(this.props.match.params.type,)
        this.props
            .getUserStore(user && user.data && user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    if (response.payload.store.is_active == 1) {
                        this.setState({ value: "Opened" });
                    } else {
                        this.setState({ value: "Closed" });
                    }
                } else {
                    //// console.log(response, 'API error')
                }
            });
        this.props
            .getVendorSingleOrder(
                user && user.data && user.data.auth_token,
                this.state.order_id
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        order: response.payload.order,
                    });
                    //// console.log(response.payload.order)
                    if (
                        response &&
                        response.payload &&
                        response.payload.order.store &&
                        response.payload.order.store.is_active
                    ) {
                        this.setState({ value: "Opened" });
                    } else {
                        this.setState({ value: "Closed" });
                    }
                }
            });
    }

    _acceptSheetOpen = (order_id) => {
        this.setState({ acceptSheet: true, check: false, order_id: order_id })
    };
    _acceptSheetClose = () => {
        this.setState({ acceptSheet: false, check: false });
    };
    _declineSheetOpen = () => {
        this.setState({ declineSheet: true });
    };
    _declineSheetClose = () => {
        this.setState({ declineSheet: false });
    };
    _prepareSheetOpen = (order_id) => {
        this.setState({ prepareSheet: true, order_id: order_id });
    };
    _prepareSheetClose = () => {
        this.setState({ prepareSheet: false });
    };
    _pickupSheetOpen = (order_id) => {
        this.setState({ pickupSheet: true, order_id: order_id });
    };
    _pickupSheetClose = () => {
        this.setState({ pickupSheet: false });
    };
    getReason = (e) => {
        this.setState({ cancelReason: e });
    };
    __handlePrepareOrder = (order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        // e.prevent.default();
        if (order == "prepare") {
            this.props
                .prepareVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorSingleOrder(
                                user && user.data && user.data.auth_token,
                                this.state.order_id
                            )
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order: response.payload.order,
                                        prepareSheet: false,
                                    });
                                    //// console.log(response.payload.order)
                                    if (
                                        response &&
                                        response.payload &&
                                        response.payload.order.store &&
                                        response.payload.order.store.is_active
                                    ) {
                                        this.setState({ value: "Opened" });
                                    } else {
                                        this.setState({ value: "Closed" });
                                    }
                                }
                            });
                    }
                });
        }
    };
    __handlePickupOrder = (order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        if (order == "pickup") {
            this.props
                .pickupVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.props
                            .getVendorSingleOrder(
                                user && user.data && user.data.auth_token,
                                this.state.order_id
                            )
                            .then((response) => {
                                if (
                                    response &&
                                    response.payload &&
                                    response.payload.success
                                ) {
                                    this.setState({
                                        loading: false,
                                        order: response.payload.order,
                                        pickupSheet: false,
                                    });
                                    //// console.log(response.payload.order)
                                    if (
                                        response &&
                                        response.payload &&
                                        response.payload.order.store &&
                                        response.payload.order.store.is_active
                                    ) {
                                        this.setState({ value: "Opened" });
                                    } else {
                                        this.setState({ value: "Closed" });
                                    }
                                }
                            });
                    }
                });
        }
    };

    __handleOrder = (e, Order) => {
        this.setState({ loading: true });
        const { user } = this.props;
        const { cancelReason } = this.state;
        // e.prevent.default();
        if (Order == "Accept") {
            this.props
                .acceptVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.setState({
                            loading: false,
                            is_accepted: true,
                            acceptSheet: false,
                        });
                        // this.props.getSingleOrder(this.state.id)
                        // window.location.href("/vendor-order")
                        this.props.history.push("/vendor-order");
                    }
                });
        } else {
            this.props
                .declineVendorOrder(
                    user && user.data && user.data.auth_token,
                    this.state.order_id,
                    cancelReason
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.setState({
                            loading: false,
                            is_declined: true,
                            declineSheet: false,
                        });
                        // window.location.href("/vendor-order")
                        this.props.history.push("/vendor-order");
                    }
                });
        }
    };
    changeStatus = (e) => {
        // e.prevent.default();
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.toggleSingleStore(user && user.data && user.data.auth_token);
        if (this.state.value == "Closed") {
            this.setState({ value: "Opened" });
            setTimeout(() => {
                this.setState({ loading: false });
            }, 500);
        } else {
            this.setState({ value: "Closed" });
            setTimeout(() => {
                this.setState({ loading: false });
            }, 500);
        }
    };
    isAccepted() {
        this.setState({ view_order: true });
        this.setState({ all_order: false });
    }

    printOrderBill = (order_id, order) => {


        if (window.ReactNativeWebView) {
            const response = {
                type: "print_bill",
                data: order,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        } else {
            window.location =
                WEBSITE_URL +
                "/print-order/" +
                this.props.user.data.auth_token +
                "/" +
                order_id;
        }
    };

    showSheduleOrder = (date, time) => {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        let formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
            day
        ).padStart(2, "0")}`;
        let formattedTime = `${String(hours).padStart(2, "0")}:${String(
            minutes
        ).padStart(2, "0")}`;

        let currentDateTime = new Date(formattedDate + "T" + formattedTime);
        let scheduleDateTime = new Date(date + "T" + time);

        // console.log(scheduleDateTime, currentDateTime);

        let diffMs = scheduleDateTime - currentDateTime;
        let diffMins = diffMs / (1000 * 60);
        if (diffMins < 45) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { order } = this.state;
        const { user } = this.props;
        // console.log("order :", order);
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    order && (
                        <React.Fragment>
                            <div>
                                <VendorBackButton title="Single Order" />
                                <div
                                    className="d-flex flex-row justify-content-center "
                                    style={{ paddingTop: "25vw" }}
                                >
                                    {order.order_status_id != 5 ? (
                                        <div
                                            className="text-end"
                                            style={{
                                                color: "#000",
                                                fontWeight: "800",
                                            }}
                                        >
                                            {order &&
                                                order.orderstatus &&
                                                order.orderstatus.name}
                                        </div>
                                    ) : order.order_status_id == 5 ? (
                                        <div
                                            className="text-end"
                                            style={{
                                                color: "#32ba7c",
                                                fontWeight: "800",
                                            }}
                                        >
                                            Order Completed
                                        </div>
                                    ) : null}
                                </div>
                                <div className="card-restaurant py-3 mt-3">
                                    <div className="d-flex justify-content-between px-3">
                                        <div className="explore-heading">
                                            <span className="text-muted">
                                                {" "}
                                                Order ID
                                            </span>
                                        </div>
                                        <div className="explore-id">
                                            {order.unique_order_id ? (
                                                <>
                                                    {order.unique_order_id
                                                        .length > 5
                                                        ? order.unique_order_id.slice(
                                                            0,
                                                            -5
                                                        ) // Display all characters except the last 5
                                                        : null}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontWeight: "bold",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        {order.unique_order_id.slice(
                                                            -5
                                                        )}{" "}
                                                        {/* Last 5 characters in red */}
                                                    </span>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="" style={{ marginRight: '1rem', marginLeft: '1rem' }}>
                                    {order.is_schedule === 1 &&
                                        <div className="d-flex justify-content-between align-items-center" style={{ "color": "#fd8814", "border": "1px solid", "borderColor": "#fd8814", "padding": "7px 10px", "borderRadius": "10px", "marginTop": "15px", "fontWeight": "bold" }}>
                                            <div>
                                                <FaRegClock style={{ "fontSize": "19px", "marginRight": "3px" }} /> <span style={{ "fontSize": "15px" }}>Scheduled</span>
                                            </div>

                                            <div>
                                                <span>{order.schedule_date} {order.schedule_time}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card-restaurant py-3 mt-3 px-3 gap-2 flex-column d-flex">
                                    {order &&
                                        order.orderitems &&
                                        order.orderitems.map((value, index) => (
                                            <React.Fragment>
                                                {value?.orderitemaddons &&
                                                    value?.orderitemaddons.length ==
                                                    0 ? (
                                                    <div className="d-flex flex-column justify-content-between mb-1 mt-2">
                                                        <div className="explore-general-one">
                                                            <span className="text-muted">
                                                                {value.name}
                                                                {value.is_free_item ==
                                                                    1 && (
                                                                        <span
                                                                            className="  text-[#32ba7c] px-2 py-1"
                                                                            style={{
                                                                                color: "#32ba7c",
                                                                            }}
                                                                        >
                                                                            ( Free
                                                                            Item )
                                                                        </span>
                                                                    )}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between gap-3 ">
                                                            <div className="explore-general-two">
                                                                <span className="fw-700 fs-5">
                                                                    X{" "}
                                                                    {
                                                                        value.quantity
                                                                    }{" "}
                                                                </span>
                                                            </div>
                                                            <div className="explore-general-two ">
                                                                <span className="fw-700">
                                                                    {" "}
                                                                    ₹{" "}
                                                                    {value.price.toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="addon-combo mt-1"
                                                        style={{
                                                            margin: "-8px",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <div className="d-flex align-items-center gap-3 ">
                                                                <div className="explore-general-one d-flex align-items-center">
                                                                    {value?.item?.is_veg == 1 &&
                                                                        <img className="me-2" src={veg_icon} style={{ width: "1rem" }} />

                                                                    }
                                                                    {value?.item?.is_veg == 0 &&
                                                                        <img src={non_veg_icon} style={{ width: "1rem" }} />

                                                                    }

                                                                    <span className="text-muted">
                                                                        {
                                                                            value.name
                                                                        }
                                                                        {value.is_free_item ==
                                                                            1 && (
                                                                                <span
                                                                                    className="  text-[#32ba7c] px-2 py-1"
                                                                                    style={{
                                                                                        color: "#32ba7c",
                                                                                    }}
                                                                                >
                                                                                    (
                                                                                    Free
                                                                                    Item
                                                                                    )
                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                </div>
                                                                {value?.orderitemaddons &&
                                                                    value?.orderitemaddons
                                                                        ?.length == 0 &&

                                                                    <div className="explore-general-two">
                                                                        <span className="fw-700">
                                                                            {
                                                                                value.quantity
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="explore-general-two">
                                                                <span className="text-muted">
                                                                    {" "}
                                                                    = RS{" "}
                                                                    {
                                                                        value.price
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {value?.orderitemaddons &&
                                                            value?.orderitemaddons
                                                                .length > 0 ? (
                                                            <React.Fragment>
                                                                {value?.orderitemaddons.filter(
                                                                    (cp) =>
                                                                        cp.is_combo &&
                                                                        cp.combo_type ===
                                                                        "COMBO"
                                                                ).length > 0 ? (
                                                                    <div className="  ">
                                                                        <span className="fw-650 fs-6">
                                                                            Combo
                                                                            Items
                                                                        </span>
                                                                        <div className=" py-2 px-2">
                                                                            {value?.orderitemaddons
                                                                                .filter(
                                                                                    (
                                                                                        cp
                                                                                    ) =>
                                                                                        cp.is_combo &&
                                                                                        cp.combo_type ===
                                                                                        "COMBO"
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        combo
                                                                                    ) => (
                                                                                        <div className="d-flex justify-content-between mb-1">
                                                                                            <div className="explore-general-one">
                                                                                                <span className="text-muted">
                                                                                                    {" "}
                                                                                                    Item-
                                                                                                    {
                                                                                                        combo.varient_no
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="explore-general-two">
                                                                                                <span className="text-muted">
                                                                                                    {
                                                                                                        combo.name
                                                                                                    }{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {value?.orderitemaddons.filter(
                                                                    (cp) =>
                                                                        cp.is_combo &&
                                                                        cp.combo_type ===
                                                                        "FREE"
                                                                ).length > 0 ? (
                                                                    <div className="  ">
                                                                        <span className="fw-650 fs-6">
                                                                            Combo
                                                                            Free
                                                                            Items
                                                                        </span>
                                                                        <div className=" py-2 px-2">
                                                                            {value?.orderitemaddons
                                                                                .filter(
                                                                                    (
                                                                                        cp
                                                                                    ) =>
                                                                                        cp.is_combo &&
                                                                                        cp.combo_type ===
                                                                                        "FREE"
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        combo
                                                                                    ) => (
                                                                                        <div className="d-flex justify-content-between mb-1">
                                                                                            <div className="explore-general-one">
                                                                                                <span className="text-muted">
                                                                                                    {" "}
                                                                                                    Item-
                                                                                                    {
                                                                                                        combo.varient_no
                                                                                                    }{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="explore-general-two">
                                                                                                <span className="text-muted">
                                                                                                    {
                                                                                                        combo.name
                                                                                                    }{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {value?.orderitemaddons.filter(
                                                                    (cp) =>
                                                                        cp.is_combo &&
                                                                        cp.combo_type ===
                                                                        "EXTRA"
                                                                ).length > 0 ? (
                                                                    <div className=" ">
                                                                        <span className="fw-650 fs-6">
                                                                            Extra
                                                                            addons{" "}
                                                                        </span>
                                                                        <div className=" py-2 px-2">
                                                                            {value?.orderitemaddons
                                                                                .filter(
                                                                                    (
                                                                                        cp
                                                                                    ) =>
                                                                                        cp.is_combo &&
                                                                                        cp.combo_type ===
                                                                                        "EXTRA"
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        combo,
                                                                                        index
                                                                                    ) => (
                                                                                        <div className="d-flex justify-content-between mb-1">
                                                                                            <div className="explore-general-one">
                                                                                                <span className="text-muted">
                                                                                                    Item-
                                                                                                    {index +
                                                                                                        1}{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="explore-general-two">
                                                                                                <span className="text-muted">
                                                                                                    {
                                                                                                        combo.name
                                                                                                    }{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {value?.orderitemaddons?.filter(
                                                                    (cp) =>
                                                                        !cp.is_combo
                                                                )?.length >
                                                                    0 ? (
                                                                    <div className="  ">
                                                                        <span className="fw-650 fs-6">
                                                                            Addons{" "}
                                                                        </span>
                                                                        <div className=" py-3 px-2">
                                                                            {value?.orderitemaddons
                                                                                ?.filter(
                                                                                    (
                                                                                        cp
                                                                                    ) =>
                                                                                        !cp.is_combo
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        combo,
                                                                                        index
                                                                                    ) => (
                                                                                        <div className="d-flex justify-content-between mb-1">
                                                                                            <div className="explore-general-one">
                                                                                                <span className="text-muted fs-4">
                                                                                                    {combo.addon_quantity} {" "}X {" "}
                                                                                                </span>
                                                                                                <span className="text-muted">
                                                                                                    {
                                                                                                        combo.name
                                                                                                    }{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="explore-general-two">
                                                                                                <span className="text-muted">
                                                                                                    {" "}
                                                                                                    = RS{" "}
                                                                                                    {
                                                                                                        combo.price
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </React.Fragment>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                </div>
                                <div className="card-restaurant py-3 mt-3">
                                    <div className="d-flex justify-content-between px-3">
                                        <div className="explore-heading">
                                            <span className="text-danger fw-bolder fs-6">
                                                {" "}
                                                Comments/Suggestions
                                            </span>
                                        </div>
                                        <div className="explore-id">
                                            <span className="text-danger fw-bolder fs-6">
                                                {" "}
                                                #{order.order_comment}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {order.cancellation_reason !== null &&
                                    order?.order_status_id == 6 && (
                                        <div className="card-restaurant py-3 mt-3">
                                            <div className="d-flex justify-content-between px-3">
                                                <div className="explore-heading">
                                                    <span className="text-muted">
                                                        {" "}
                                                        Cancellation Reason
                                                    </span>
                                                </div>
                                                <div className="explore-id">
                                                    <span className="text-muted">
                                                        {" "}
                                                        #
                                                        {
                                                            order.cancellation_reason
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <div style={{ paddingBottom: "30vw" }}>
                                    <div className="card-restaurant py-3 mt-3 px-3">
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="explore-general-one">
                                                <span className="text-muted">
                                                    Subtotal
                                                </span>
                                            </div>
                                            <div className="explore-general-two">
                                                <span className="text-muted">
                                                    {" "}
                                                    Rs.{" "}
                                                    {order.sub_total
                                                        ? order.sub_total
                                                        : "--"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="explore-general-one">
                                                <span className="text-muted">
                                                    {" "}
                                                    Store Charge
                                                </span>
                                            </div>
                                            <div className="explore-general-two">
                                                <span className="text-muted">
                                                    Rs.{" "}
                                                    {order.store_charges
                                                        ? order.store_charges
                                                        : "--"}{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="explore-general-one">
                                                <span className="text-muted">
                                                    {" "}
                                                    Coupon Discount{" "}
                                                </span>
                                            </div>
                                            <div className="explore-general-two">
                                                <span className="text-muted">
                                                    Rs.{" "}
                                                    {order.coupon_discount
                                                        ? order.coupon_discount
                                                        : "--"}{" "}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex justify-content-between mb-1">
                                            <div className="explore-general-one">
                                                <span className="text-muted">
                                                    {" "}
                                                    Tax
                                                </span>
                                            </div>
                                            <div className="explore-general-two">
                                                <span className="text-muted">
                                                    Rs.{" "}
                                                    {order.tax
                                                        ? order.tax
                                                        : "--"}{" "}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="explore-general-one">
                                                <span className="text-muted">
                                                    {" "}
                                                    Delivery Charge
                                                </span>
                                            </div>
                                            <div className="explore-general-two">
                                                <span className="text-muted">
                                                    Rs.{" "}
                                                    {order.delivery_charge
                                                        ? order.delivery_charge
                                                        : "--"}{" "}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between mb-1 mt-2">
                                            <div className="explore-general-total">
                                                <span className="text-muted">
                                                    Total
                                                </span>
                                            </div>
                                            <div className="explore-general-amount">
                                                <span className="text-muted">
                                                    Rs. {order.total}{" "}
                                                </span>
                                            </div>
                                        </div> */}
                                    </div>
                                    {order.is_medicine == 1 && (
                                        <Link
                                            to={
                                                "/vendor-prescription/" +
                                                order.id
                                            }
                                        >
                                            <div className="d-flex justify-content-center mt-2">
                                                <button className="medicine-button py-1 px-2">
                                                    View Prescription
                                                </button>
                                            </div>
                                        </Link>
                                    )}

                                    {order.order_status_id == 15 ? (
                                        <div className="d-flex justify-content-end vendor-store-btn mt-3 px-3">
                                            <button
                                                className="accept-button py-2 px-2"
                                                onClick={() =>
                                                    this._pickupSheetOpen(
                                                        order.id
                                                    )
                                                }
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-cart-plus"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                                                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                                <span className="ps-2">
                                                    Ready To Pick Up
                                                </span>
                                            </button>
                                        </div>
                                    ) : null}
                                    {order.order_status_id == 1 ? (
                                        <div className="d-flex justify-content-between w-100 pt-4 px-3">
                                            <button
                                                className="decline-button py-3 px-4"
                                                onClick={() =>
                                                    this._declineSheetOpen(
                                                        order.id
                                                    )
                                                }
                                            >
                                                Cancel
                                            </button>

                                            {order?.is_schedule !== 1 ? (
                                                <button
                                                    className="accept-button py-3 px-4"
                                                    onClick={() => this._acceptSheetOpen(order.id)}
                                                >
                                                    Accept
                                                </button>
                                            ) : (

                                                this.showSheduleOrder(order.schedule_date, order.schedule_time) ? (
                                                    <button
                                                        className="accept-button py-3 px-4"
                                                        onClick={() =>
                                                            this._acceptSheetOpen(
                                                                order.id
                                                            )
                                                        }
                                                    >
                                                        Accept
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="accept-button py-3 px-4"
                                                        disabled
                                                        style={{ opacity: "0.5" }}
                                                    >
                                                        Accept
                                                    </button>
                                                ))}





                                        </div>
                                    ) : null}
                                    <div className="text-center mt-2">
                                        {/* <button
                                            className="print-button py-3 px-4"
                                            onClick={() =>
                                                this.printOrderBill(order.id, order)
                                            }
                                        >
                                            <FiFileText size={15} /> Print
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                            {/* accept */}
                            <BottomSheet
                                open={this.state.acceptSheet}
                                onDismiss={() => this._acceptSheetClose()}
                            >
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{
                                        backgroundColor: "#fff",
                                        padding: "13px",
                                    }}
                                >
                                    <div className="text-center">
                                        <h3>Accept Order</h3>
                                    </div>
                                    <div className="text-muted ms-4 mt-3">
                                        <span>
                                            Are you sure do you wanna Accept
                                            order?
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                        <div
                                            className="text-center py-3 position-relative decline-button"
                                            onClick={(e) =>
                                                this._acceptSheetClose(e)
                                            }
                                        >
                                            Close
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                        <div
                                            className="text-center accept-button ms-2 py-3 position-relative"
                                            onClick={(e) =>
                                                this.__handleOrder(e, "Accept")
                                            }
                                        >
                                            Accept Order
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </BottomSheet>
                            {/* decline */}
                            <BottomSheet
                                open={this.state.declineSheet}
                                onDismiss={() => this._declineSheetClose()}
                            >
                                <Form
                                    onSubmit={(e) =>
                                        this.__handleOrder(e, "Decline")
                                    }
                                >
                                    <div
                                        className="d-flex flex-column align-items-center"
                                        style={{
                                            backgroundColor: "#fff",
                                            padding: "13px",
                                        }}
                                    >
                                        <div className="text-center">
                                            <h3>Cancel Order</h3>
                                        </div>
                                        <div className="text-muted ms-4 mt-3">
                                            <span>
                                                Are you sure do you wanna cancel
                                                order?
                                            </span>
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 decline-button w-100 mt-4 py-2"
                                                style={{
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <FormControl
                                                    onChange={(e) => {
                                                        let value =
                                                            e.target.value;
                                                        this.getReason(value);
                                                    }}
                                                    type="text"
                                                    placeholder="Cancel Reason"
                                                    required
                                                    className="input-login-phone w-100"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-center mt-4 mb-4 w-100">
                                            <div
                                                className="text-center py-3 position-relative decline-button"
                                                onClick={
                                                    this._declineSheetClose
                                                }
                                            >
                                                Close
                                                <Ink
                                                    duration={500}
                                                    style={{ color: "#AAAAAA" }}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="text-center accept-button py-3 position-relative"
                                            >
                                                Cancel Order
                                                <Ink
                                                    duration={500}
                                                    style={{ color: "#AAAAAA" }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </BottomSheet>
                            {/* prepare */}
                            <BottomSheet
                                open={this.state.prepareSheet}
                                onDismiss={() => this._prepareSheetClose()}
                            >
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{
                                        backgroundColor: "#fff",
                                        padding: "13px",
                                    }}
                                >
                                    <div className="text-center">
                                        <h3>Prepare Order</h3>
                                    </div>
                                    <div className="text-muted ms-4 mt-3">
                                        <span>
                                            Are you sure to Prepare this order?
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                        <div
                                            className="text-center position-relative py-3 decline-button"
                                            onClick={(e) =>
                                                this._prepareSheetClose(e)
                                            }
                                        >
                                            Close
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                        <div
                                            className="text-center accept-button ms-2 py-3 position-relative"
                                            onClick={() =>
                                                this.__handlePrepareOrder(
                                                    "prepare"
                                                )
                                            }
                                        >
                                            Prepare Order
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </BottomSheet>
                            {/* ready to pick up */}
                            <BottomSheet
                                open={this.state.pickupSheet}
                                onDismiss={() => this._pickupSheetClose()}
                            >
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{
                                        backgroundColor: "#fff",
                                        padding: "13px",
                                    }}
                                >
                                    <div className="text-center">
                                        <h3>Pickup Order</h3>
                                    </div>
                                    <div className="text-muted ms-4 mt-3">
                                        <span>
                                            Are you sure the order is ready to
                                            be picked up?
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center mt-4 mb-4 w-100">
                                        <div
                                            className="text-center position-relative py-3 decline-button"
                                            onClick={(e) =>
                                                this._pickupSheetClose(e)
                                            }
                                        >
                                            Close
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                        <div
                                            className="text-center accept-button ms-2 py-3 position-relative"
                                            onClick={() =>
                                                this.__handlePickupOrder(
                                                    "pickup"
                                                )
                                            }
                                        >
                                            Pickup Order
                                            <Ink
                                                duration={500}
                                                style={{ color: "#AAAAAA" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </BottomSheet>
                            <VendorFooter active_orders={true} />
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    order: state.vendor.order,
    user: state.vendor.store_user,
});

export default connect(mapStateToProps, {
    getVendorSingleOrder,
    toggleSingleStore,
    acceptVendorOrder,
    declineVendorOrder,
    getUserStore,
    prepareVendorOrder,
    pickupVendorOrder,
})(VendorSingleOrder);
