import React, { Component } from "react";
import { connect } from "react-redux";
import { MdToggleOn } from "react-icons/md";
import { MdToggleOff } from "react-icons/md";
import lock from "../../../assets/images/lock.png";
import food from "../../../assets/images/food.png";
import info from "../../../assets/images/info.png";
import order from "../../../assets/images/order.png";
import tag from "../../../assets/images/tag.png";
import setting from "../../../assets/images/setting.png";
import time from "../../../assets/images/time.png";
import Sale from "../../../assets/images/Sale.png";
import logout from "../../../assets/images/logout.png";
import { Link } from "react-router-dom";
import Footer from "../VendorFooter";
import Loading from '../../Elements/loading'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { toggleSingleStore, getUserStore, logoutUser } from '../../../redux/vendor/user/action'
import Ink from "react-ink";
import { BsStarHalf } from "react-icons/bs";
import { BiPrinter, BiTrash } from "react-icons/bi";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { WEBSITE_URL } from "../../../api";
import moment from 'moment-timezone';

export class Profile extends Component {
    state = {
        open: false,
        openDelete: false,
        order: false,
        loading: false,
        store: '',
        showScheduleModal: false,
        selectedDateTime: '',
        nextBusinessDay: null,
        nextBusinessSchedule: null,
        scheduleDataExists: false,
        customDateSelected: false, // New state for custom date selection
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, store: response.payload.store })
                //// console.log(response.payload.store, 'sdsd')
                if (response.payload.store.is_active == 1) {
                    this.setState({ order: true })
                } else {
                    this.setState({ order: false })
                }
            } else {
                //// console.log(response, 'API error')
            }
        })
    }

    _openSheet = () => {
        this.setState({ open: !this.state.open });
    };
    __handleLogout = () => {
        const { user } = this.props;
        this.setState({ loading: true });

        this.props.logoutUser(user?.data?.auth_token).then(() => {

            localStorage.clear()
            setTimeout(() => {

                window.location.replace("/vendor-login");
                this.props.history.replace("/vendor-login");
            }, 2000);
        });


    }


    _openDeleteSheet = () => {
        this.setState({ openDelete: !this.state.openDelete });
    };
    // changeStatus = () => {
    //     const { user } = this.props;

    //     // console.log(this.state.store,'this.state.store');
    //     if (!this.state.store?.order  && this.state.store?.is_schedule == 0) {

    //         this.setState({ loading: true })

    //         this.props.toggleSingleStore(user && user.data && user.data.auth_token)
    //             .then((response) => {
    //                 if (response && response.payload && response.payload.success) {


    //                     this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
    //                         if (response && response.payload && response.payload.success) {
    //                             this.setState({ loading: false, store: response.payload.store })
    //                             //// console.log(response.payload.store, 'sdsd')
    //                             if (response.payload.store.is_active == 1) {
    //                                 this.setState({ order: true })
    //                             } else {
    //                                 this.setState({ order: false })
    //                             }
    //                         } else {
    //                             //// console.log(response, 'API error')
    //                         }
    //                     })
    //                 } else {
    //                     //// console.log(response, 'API error')
    //                 }
    //             })

    //     } else {
    //         const scheduleData = user.data.store[0]?.schedule_data ? JSON.parse(user.data.store[0]?.schedule_data) : {};
    //         const nextBusinessDay = this.getNextBusinessDay(); // Calculate next business day dynamically
    //         const nextBusinessSchedule = scheduleData[nextBusinessDay] || null;
    //         this.setState({
    //             showScheduleModal: true,
    //             nextBusinessDay,
    //             nextBusinessSchedule,
    //             scheduleDataExists: Boolean(nextBusinessSchedule),
    //         });
    //     }
    // }

    changeStatus = () => {
        const { user } = this.props;

        // console.log(this.state.store, 'this.state.store');


        if (this.state.store?.is_schedule == 1 && this.state.store?.is_active == 1) {
            const scheduleData = user.data.store[0]?.schedule_data ? JSON.parse(user.data.store[0]?.schedule_data) : {};
            const nextBusinessDay = this.getNextBusinessDay(); // Calculate next business day dynamically
            const nextBusinessSchedule = scheduleData[nextBusinessDay] || null;

            if (nextBusinessSchedule && nextBusinessSchedule?.length > 0) {

                const openingTime = nextBusinessSchedule[0].open;
                const nextBusinessDate = moment.tz('Asia/Kolkata');
                while (nextBusinessDate.format('dddd').toLowerCase() !== nextBusinessDay) {
                    nextBusinessDate.add(1, 'day');
                }
                const [hours, minutes] = openingTime.split(':').map(Number);
                nextBusinessDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

                // Format the date to ISO 8601 in the local time zone
                const selectedDateTime = nextBusinessDate.format(); // This keeps it in the local time zone

                this.setState({
                    showScheduleModal: true,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: Boolean(nextBusinessSchedule),
                    selectedDateTime,
                });
            } else {
                this.setState({
                    showScheduleModal: true,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: false,
                });
            }
        } else {
            this.setState({ loading: true })

            this.props.toggleSingleStore(user && user.data && user.data.auth_token)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {


                        this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
                            if (response && response.payload && response.payload.success) {
                                this.setState({ loading: false, store: response.payload.store })
                                //// console.log(response.payload.store, 'sdsd')
                                if (response.payload.store.is_active == 1) {
                                    this.setState({ order: true })
                                } else {
                                    this.setState({ order: false })
                                }
                            } else {
                                //// console.log(response, 'API error')
                            }
                        })
                    } else {
                        //// console.log(response, 'API error')
                    }
                })
        }

    }
    getNextBusinessDay = () => {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = new Date();
        let nextDayIndex = today.getDay() + 1; // Get the index of the next day

        if (nextDayIndex === daysOfWeek.length) {
            nextDayIndex = 0; // If today is Saturday, the next day is Sunday
        }

        return daysOfWeek[nextDayIndex];
    }

    openPrinterPage = () => {
        if (window.ReactNativeWebView) {
            const response = {
                type: "open_printer_page",
                data: "no-data",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
    }


    handleScheduleSubmit = () => {
        const { selectedItem, selectedDateTime } = this.state;
        const { user } = this.props;

        axios.post(WEBSITE_URL + "/add-store-reopen-time", {
            date: selectedDateTime,
            id: this.state.store?.id,
        }).then(response => {
            this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, store: response.payload.store })
                    //// console.log(response.payload.store, 'sdsd')
                    if (response.payload.store.is_active == 1) {
                        this.setState({ order: true })
                    } else {
                        this.setState({ order: false })
                    }
                } else {
                    //// console.log(response, 'API error')
                }
            })
        });

        this.setState({ showScheduleModal: false, selectedDateTime: '', customDateSelected: false });
    }


    handleOptionChange = (event) => {
        const isCustomDate = event.target.value === 'custom';
        this.setState({ customDateSelected: isCustomDate });
    }
    render() {
        const { store } = this.props;
        const { showScheduleModal, selectedItem, selectedDateTime,
            nextBusinessDay, nextBusinessSchedule, scheduleDataExists, customDateSelected, } = this.state;
        return (
            <React.Fragment>
                {/* Schedule Modal */}
                <Modal show={showScheduleModal} style={{ marginTop: 200 }} onHide={() => this.setState({ showScheduleModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Item Reopening</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {scheduleDataExists ? (
                            <div>
                                <form>
                                    <div className="form-group">
                                        <label>Reopen Time</label>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`next-business-day-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="next-business-day"
                                                checked={!customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`next-business-day-radio${selectedItem?.id}`}>
                                                Next business day at {nextBusinessSchedule && nextBusinessSchedule[0].open}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`choose-time-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="custom"
                                                checked={customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                                I will choose the time
                                            </label>
                                        </div>
                                        {customDateSelected && (
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={selectedDateTime}
                                                onChange={(e) => this.setState({ selectedDateTime: e.target.value })}
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className="text-danger fw-bold fs-7">
                                Oops... Please turn on auto schedule and add scheduled opening and closing time for this item.
                                Then only item and items will be automatically turned on for the selected date and time.
                                <br />

                                <Link to={'/vendor-timing'}>

                                    <Button variant="primary" className='btn text-white inventory-browse-btn fs-6 mt-2' onClick={this.handleScheduleSubmit}>
                                        Click to add scheduled opening and closing time for your item
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showScheduleModal: false })}>
                            Close
                        </Button>
                        {scheduleDataExists && (
                            <Button variant="primary" className='btn text-white inventory-browse-btn' onClick={this.handleScheduleSubmit}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <div className="pb-5">
                            <div
                                className="profile-heading px-3 pt-4 py-3"
                            >
                                <span className="profile-name">{store?.store?.name}</span>
                                <br></br>
                                <span className="profile-address text-muted">
                                    {store?.store?.address}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center px-3 py-3 vendor-profile-toggle" >
                                <div style={{ fontSize: "16px", color: "#09051C", fontWeight: 600, }} >
                                    Recieving Orders
                                </div>
                                <div onClick={this.changeStatus}>
                                    {this.state.order ?
                                        <MdToggleOn set="curved" style={{ fontSize: "4em", color: "#59D69D", }} />
                                        :
                                        <MdToggleOff set="curved" style={{ fontSize: "4em", color: "#cfcfcf" }} />
                                    }
                                </div>
                            </div>
                            <div className="profile-tabs px-3 pt-2">
                                <Link to="/vendor-timing">
                                    <div className="vendor-profile-menu-item d-flex align-items-center mt-3">
                                        <img src={lock} alt="" />
                                        <span className="ms-4">Open / Close time</span>
                                    </div>
                                </Link>
                                <Link to="/vendor-category-timing">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={food} alt="" />
                                        <span className="ms-4">
                                            Category Open/Close time
                                        </span>
                                    </div>
                                </Link>
                                <Link to="/vendor-preparation-time">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={time} alt="" />
                                        <span className="ms-4">Prep time</span>
                                    </div>
                                </Link>
                                {/* {store?.store?.store_category_id !== 2 ? ( */}
                                <Link to="/vendor-deals-today">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={Sale} alt="" />
                                        <span className="ms-4">Deals Today</span>
                                    </div>
                                </Link>
                                {/* ):("")} */}
                                <Link to="/vendor-offer">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={tag} alt="" />
                                        <span className="ms-4">Offers/Coupons</span>
                                    </div>
                                </Link>
                                <Link to={"/vendor-past-order/" + store.store.id}>
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={order} alt="" />
                                        <span className="ms-4">Past Orders</span>
                                    </div>
                                </Link>
                                <Link to={"/vendor-scheduled-orders"}>
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={time} alt="" />
                                        <span className="ms-4">Scheduled Orders</span>
                                    </div>
                                </Link>
                                <Link to="/vendor-store-rating" >
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <BsStarHalf size={25} style={{
                                            color: '#ff9012',
                                        }} />
                                        <span className="ms-4">Rating & Review</span>
                                    </div>
                                </Link>
                                <Link to="/vendor-outlet-info">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={info} alt="" />
                                        <span className="ms-4">Store info</span>
                                    </div>
                                </Link>

                                <Link to="/vendor-account-settings">
                                    <div className="vendor-profile-menu-item d-flex align-items-center">
                                        <img src={setting} alt="" />
                                        <span className="ms-4">Account Settings</span>
                                    </div>
                                </Link>

                                {/*  <div onClick={() => this.openPrinterPage()} className="vendor-profile-menu-item d-flex align-items-center">
                                    <BiPrinter size={25} style={{
                                        color: '#ff9012',
                                    }} />
                                    <span className="ms-4">Printer Setup</span>
                                </div> */}

                                <div onClick={() => this._openDeleteSheet()} className="vendor-profile-menu-item d-flex align-items-center">
                                    <BiTrash size={25} style={{
                                        color: '#ff9012',
                                    }} />
                                    <span className="ms-4">Delete My Account</span>
                                </div>
                            </div>

                            <div className="vendor-profile-logout d-flex align-items-center justify-content-center text-center mt-3 py-1" onClick={() => this._openSheet()}>
                                <img src={logout} alt="" />
                                <span>Logout</span>
                            </div>
                        </div>

                        <BottomSheet
                            open={this.state.openDelete}
                            onDismiss={() => this._openDeleteSheet()}>
                            <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>
                                <div className='markdown prose w-full break-words dark:prose-invert light'>
                                    <h1>
                                        <span style={{ fontSize: '0.9rem' }}>
                                            We understand that you may need to delete your account for various reasons. We're sorry to see you go, but we respect your decision and are here to assist you through the process.
                                        </span>
                                    </h1>
                                    <h2>How to Delete Your Account</h2>
                                    <p>
                                        Due to security reasons, we require you to contact us directly to delete your account. This is to ensure that the request is genuine and to protect your personal data from unauthorized access.
                                    </p>
                                    <p>Please follow the steps below to delete your account:</p>
                                    <ol>
                                        <li>
                                            <p>
                                                <strong>Contact Us via WhatsApp:</strong> Reach out to us on our official WhatsApp number: <a href="https://wa.me/917841066676" target="_blank"><b>+91</b></a><b>8999973121</b>. Please make sure to include the following details in your message:
                                            </p>
                                            <ul>
                                                <li>Your full name as registered on the account</li>
                                                <li>The email address associated with the account</li>
                                                <li>A brief reason for deleting your account (this is optional, but it helps us improve our services)</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Wait for Confirmation:</strong> Our support team will review your request and send you a confirmation message within 24-48 hours. This message will include any additional steps you may need to take.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <strong>Finalize Account Deletion:</strong> Once you confirm your request, we will proceed with the deletion process. Please note that this action is irreversible. All your personal data will be permanently deleted from our servers, and you won't be able to recover your account.
                                            </p>
                                        </li>
                                    </ol>
                                    <h2>Things to Consider Before Deleting Your Account</h2>
                                    <p>Before you decide to delete your account, please keep in mind the following:</p>
                                    <ul>
                                        <li><strong>Data Loss:</strong> All your personal data, settings, and preferences will be permanently deleted. This action is irreversible.</li>
                                        <li><strong>Service Access:</strong> You will lose access to all our services associated with your account.</li>
                                        <li><strong>Subscription Services:</strong> If you have any active subscriptions, they will be cancelled, and no refunds will be issued for the remaining period.</li>
                                    </ul>
                                    <p>If you have any questions or need further assistance, please don't hesitate to contact us via WhatsApp. We're here to help!</p>
                                    <p>Thank you for using our services. We hope to see you again in the future.</p>
                                </div>
                                <div className='d-flex flex-row align-items-center mt-4 mb-4 w-100'>
                                    <div className='text-center py-3 decline-button position-relative w-full'
                                        onClick={() => this._openDeleteSheet()}>
                                        Close
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </div>

                                </div>
                            </div>
                        </BottomSheet>


                        <BottomSheet
                            open={this.state.open}
                            onDismiss={() => this._openSheet()}>
                            {/* // snapPoints={({ maxHeight }) => maxHeight}> */}
                            <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>
                                <div className="text-center">
                                    <h2>Logout ?</h2>
                                </div>
                                <div className='text-muted ms-4 mt-3'>
                                    <span>Are you sure you wanna logout?</span>
                                </div>
                                <div className='d-flex flex-row align-items-center mt-4 mb-4 w-100'>
                                    <div className='text-center py-3 decline-button position-relative'
                                        onClick={() => this._openSheet()}>
                                        Close
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </div>
                                    <div className='text-center accept-button ms-2 py-3 position-relative'
                                        onClick={this.__handleLogout}>
                                        Logout
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </div>
                                </div>
                            </div>
                        </BottomSheet>

                        <Footer active_profile={true} />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    store: state.vendor.store
});

export default connect(mapStateToProps, {
    toggleSingleStore,
    getUserStore,
    logoutUser
})(Profile);
