import React, { Component } from 'react'
import { connect } from 'react-redux'
import successtick from '../../../assets/images/successtick.png'
import ContentLoader from 'react-content-loader'

class TaskSuccess extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
        // this.props.getAllHomeData()
    }
  render() {
    return (
        <React.Fragment>
             {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                            speed={1}
                      viewBox="0 0 280 576"
                      backgroundColor={'#F3F3F3'}
                      foregroundColor={'#FFFFFF'}>
                      <rect x="78" y="160" rx="10" ry="10" width="18" height="18" />
                      <rect x="87" y="176" rx="60" ry="60" width="110" height="110" />
                      <rect x="191" y="179" rx="10" ry="10" width="12" height="12" />
                      <rect x="78" y="250" rx="10" ry="10" width="9" height="9" />
                      <rect x="87" y="298" rx="10" ry="10" width="110" height="23" />
                      <rect x="42" y="323" rx="10" ry="10" width="211" height="20" />
                       <rect x="34" y="514" rx="10" ry="10" width="200" height="43" />
                      </ContentLoader>
                            </React.Fragment>
                    ):(
                        <React.Fragment>

        <div>
            <div className='mobile-subs-success-bg'>
            </div>
            <div className="d-flex justify-content-center mobile-subs-success-img">
                <img src={successtick} alt="Success" />
            </div>
            <div className="d-flex justify-content-center mobile-subs-success-head">
                <span>Thank You</span>
            </div>
            <div className="mobile-subs-success-text px-3">
                <div>
                    <span>Your Task Placed Successfully</span>
                </div>
            </div>
            <div className='d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn'>
                <button className="p-3 mobile-subs-success-button">Back to Home</button>
            </div>
        </div>
    </React.Fragment>
     )}
    </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(TaskSuccess)
