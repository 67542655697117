
import React, { Component } from "react";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { GiHamburger } from "react-icons/gi";
import { GiScooter } from "react-icons/gi";
import { IoFastFoodOutline } from "react-icons/io5";
import { getAllResturants } from "../../../../redux/mobile/home/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import Truncate from "react-truncate";

const tabs = [
    { id: "all", value: "All" },
    { id: "rating", value: "Rating" },
    { id: "time", value: "Time" },
    { id: "buy_get", value: "Buy 1 Get 1" },
    { id: "free_delivery", value: "Free Delivery" },
    { id: "free_item", value: "Free Item" },
];

export class AllMedicalStore extends Component {
    state = {
        data: false,
        free: null,
        rating: null,
        distance: null,
        time: null,
        seletedTab: "all",
        // selectedCity: 'City',
    };
    componentDidMount() {
        // this.props.getAllResturants(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
        //     if (response && response.payload && response.payload.success) {
        //         this.setState({ data: true });
        //     }
        // });
    }
    getAvailableStores = () => {
        const { allStores } = this.props;
        const { seletedTab } = this.state;
        if (allStores && allStores?.allStores?.length > 0) {
            let available_stores = [...allStores?.allStores];

            available_stores.sort((a, b) => b.is_active - a.is_active);

            // if (seletedTab === "free") {
            //     available_stores = available_stores.filter((store) => store.is_freedelivery)
            // } else
            if (seletedTab === "rating") {
                available_stores = available_stores.sort(
                    (a, b) => b.rating - a.rating
                );
                // } else if (seletedTab === "distance") {
                //     available_stores = available_stores.sort((a, b) => a.delivery_radius - b.delivery_radius)
            } else if (seletedTab === "time") {
                available_stores = available_stores.sort(
                    (a, b) => a.preparation_time - b.preparation_time
                );
            } else if (seletedTab === "veg") {
                available_stores = available_stores.filter(
                    (a) => a.is_veg === 1
                );
            } else if (seletedTab === "buy_get") {
                available_stores = available_stores.filter(
                    (a) => a.is_buy_text != null
                );
            } else if (seletedTab === "free_delivery") {
                available_stores = available_stores.filter(
                    (a) => a.is_freedelivery == 1
                );
            } else if (seletedTab === "free_item") {
                available_stores = available_stores.filter(
                    (a) => a.is_free_item == 1
                );
            }
            //  else if (seletedTab === "city") {
            //     available_stores = available_stores.sort((a, b) => a.preparation_time - b.preparation_time)
            // }
            return available_stores;
        }
    };

    __getStoreCouponText = (store) => {
        return (
            <div
                className="d-flex flex-column justify-content-center position-absolute w-100"
                style={{
                    borderRadius: "0px 0px 20px 20px",
                    bottom: "0px",
                    lineHeight: "1.2",
                    background: "linear-gradient(0deg,black, transparent )",
                }}
            >
                {store?.is_free_item ? (
                    <>
                        <span className="all-restuarant-for-you-coupon1 px-2 ms-1 pb-1">
                            FREE ITEM
                        </span>
                    </>
                ) : (
                    <>
                        {store?.coupons?.length > 0 ? (
                            store?.coupons[0]?.discount_type == "PERCENTAGE" ? (
                                <>
                                    <span className="all-restuarant-for-you-coupon1 px-2 ms-1 mt-2">
                                        {store?.coupons[0]?.coupon_discount}% OFF
                                    </span>
                                    {store?.coupons[0]?.min_sub_total > 0 && (
                                        <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                            ABOVE ₹ {store?.coupons[0]?.min_sub_total}
                                        </span>
                                    )}

                                    {store?.coupons[0]?.min_sub_total < 0 &&
                                        store?.coupons[0]?.max_discount > 0 && (
                                            <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                                UPTO ₹ {store?.coupons[0]?.max_discount}
                                            </span>
                                        )}
                                </>
                            ) : (
                                <>
                                    {store?.coupons[0]?.discount_type == "ITEM" ? (
                                        <span className="all-restuarant-for-you-coupon1 px-2 ms-1 pb-1">
                                            FREE ITEM
                                        </span>
                                    ) : (
                                        <>
                                            <span className="all-restuarant-for-you-coupon1 px-2 ms-1 mt-2">
                                                ₹{store?.coupons[0]?.coupon_discount} OFF
                                            </span>
                                            {store?.coupons[0]?.min_sub_total > 0 && (
                                                <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                                    ABOVE ₹ {store?.coupons[0]?.min_sub_total}
                                                </span>
                                            )}

                                            {store?.coupons[0]?.min_sub_total < 0 &&
                                                store?.coupons[0]?.max_discount > 0 && (
                                                    <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                                        UPTO ₹ {store?.coupons[0]?.max_discount}
                                                    </span>
                                                )}
                                        </>
                                    )}
                                </>
                            )
                        ) : null}
                    </>
                )}
            </div>
        );
    };

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })

        total = total / (count ? count : 1);
        total = Math.round(total);

        return total;
    }

    render() {
        const available_stores = this.getAvailableStores();
        const { allStores } = this.props;
        return (
            <React.Fragment>
                {allStores?.allStores && allStores?.allStores?.length > 0 ? (
                    <div>
                        <div className="px-3 mt-3 d-flex align-items-center">
                            <GiHamburger
                                size={20}
                                style={{ color: "FF9A62" }}
                            />
                            <span className="home-heading ms-2">
                                All Stores For You
                            </span>
                        </div>
                        <div className="d-flex flex-column px-3 mt-3 ">
                            <div className="d-flex flex-row align-items-center ">
                                <div
                                    className="filter-menu hidden-scroll-bar text-center gap-2 pb-3"
                                    style={{
                                        gridTemplateColumns: `repeat(7,1fr)`,
                                    }}
                                >
                                    {tabs.map((tab, index) => (
                                        <div
                                            key={tab.id}
                                            className={
                                                tab.id === this.state.seletedTab
                                                    ? "py-1 filter-btn-active"
                                                    : "py-1 filter-btn"
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    seletedTab: tab.id,
                                                    selectedCity: "City",
                                                });
                                            }}
                                        >
                                            {tab.value}
                                        </div>
                                    ))}
                                    {/* tab.id === 'city' ?
                                                    <select className={tab.id === this.state.seletedTab ? "py-1 filter-select-btn-active" : "py-1 filter-select-btn"} name="Phone" id="Phone" value={this.state.selectedCity}
                                                        onChange={(e) => {
                                                            this.setState({ selectedCity: e.target.value, seletedTab: tab.id })
                                                            ////// console.log(e.target.value, 'e')
                                                        }} >
                                                        <option value="City" disabled selected hidden>City</option>
                                                        <option value="Firefox">Firefox</option>
                                                        <option value="Safari">Safari</option>
                                                        <option value="Edge">Edge </option>
                                                    </select>
                                                    : */}
                                </div>
                            </div>
                        </div>
                        <div style={{ transition: "all 0.5s ease" }}>
                            {available_stores?.map((store) => (
                                <Link key={store.id} to={"/single-store/" + store.id}>
                                    <div className="px-3 mt-3 d-flex align-items-center">
                                        <div className={`position-relative ${!store?.is_active && 'store-not-active'}`}>
                                            <ProgressiveImage
                                                delay={20}
                                                src={
                                                    IMAGE_BASE_URL +
                                                    store?.image
                                                }
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "30vw",
                                                            height: "33vw",
                                                            borderRadius:
                                                                "20px",
                                                            objectFit: "cover",
                                                            backgroundRepeat:
                                                                "round",
                                                        }}
                                                        className=""
                                                        alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    top: 0,
                                                    // backgroundColor:  "rgba(0,0,0, 0.1)",
                                                    width: "30vw",
                                                    height: "33vw",
                                                    borderRadius: "20px",
                                                }}
                                            ></div>
                                            {store.is_buy_text ? (
                                                <div className="ribbon-buy">
                                                    <span>
                                                        {store.is_buy_text}
                                                    </span>
                                                </div>
                                            ) : null}

                                            {/* {!store.is_buy_text && store.coupons && store.coupons.some(coupon => coupon.discount_type === "ITEM") ? (
                                                        <div className="ribbon-buy">
                                                            <span>
                                                                FREE ITEM
                                                            </span>
                                                        </div>
                                                    ) : null} */}
                                            {(store?.offer_promotion_text ||
                                                store?.coupons?.length > 0) && (
                                                    <>
                                                        {this.__getStoreCouponText(store)}
                                                    </>
                                                )}
                                            {/* {(store?.offer_promotion_text || store?.coupons?.length > 0) &&
                                                        <div className='d-flex flex-column justify-content-center position-absolute w-100' style={{ borderRadius: "0px 0px 20px 20px", bottom: '0px', lineHeight: '1.2', background: 'linear-gradient(0deg,black, transparent )' }}>
                                                            {store?.coupons?.length > 0 &&
                                                                store?.coupons[0]?.description.length > 0 ? (
                                                                <>
                                                                    <span className='all-restuarant-for-you-coupon1 px-2 ms-1 mt-2'>
                                                                        {store?.coupons[0]?.description.toUpperCase().substring(0, (store?.coupons[0]?.description.length / 2) - 1)}
                                                                    </span>
                                                                    <span className='all-restuarant-for-you-coupon2 px-2 ms-1'>
                                                                        {store?.coupons[0]?.description.toUpperCase().substring((store?.coupons[0]?.description.length / 2) - 1, store?.coupons[0]?.description.length)}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className='all-restuarant-for-you-coupon1 ms-1 px-2 mt-2'>
                                                                        {store?.offer_promotion_text.toUpperCase()}
                                                                    </span>
                                                                    <span className='all-restuarant-for-you-coupon2 px-2 ms-1'>
                                                                        {store?.offer_promotion_text2.toUpperCase()}
                                                                    </span>
                                                                </>
                                                            )}

                                                        </div>
                                                    } */}
                                        </div>
                                        <div className={`flex-grow-1 ms-3 pe-5 mt-1`}>
                                            <h5
                                                className="fs-6 m-0"
                                                style={{
                                                    color: "#09051C",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {store.name}
                                            </h5>
                                            <div className={`d-flex align-items-center ${!store?.is_active && 'store-not-active'}`}>

                                                {store?.ratings?.length > 0 ? <>
                                                    <span className="m all-resturant-rating">
                                                        {this.ratingCount(store?.ratings, store?.ratings?.length)}
                                                    </span>{" "}
                                                    <AiFillStar className="me-2"
                                                        style={{ color: "FF9A62" }}
                                                        size={11}
                                                    />
                                                </>
                                                    : <>
                                                        <span className="m all-resturant-rating">
                                                            {store?.rating}
                                                        </span>{" "}
                                                        <AiFillStar className="me-2"
                                                            style={{ color: "FF9A62" }}
                                                            size={11}
                                                        />
                                                    </>}
                                                <span
                                                    className=" me-2"
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: "650",
                                                        color: "#3B3B3B",
                                                    }}
                                                >
                                                    {store?.preparation_time
                                                        ? store.preparation_time +
                                                        " Min - "
                                                        : ""}{" "}
                                                    {store?.delivery_radius
                                                        ? store.delivery_radius +
                                                        " Km "
                                                        : ""}
                                                </span>
                                            </div>
                                            <div
                                                className="resturant-description"
                                                style={{
                                                    color: "#b5b5b5",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                <Truncate lines={2}>
                                                    {store?.description}
                                                </Truncate>
                                            </div>
                                            {store?.is_freedelivery === 1 && store?.is_active ? (
                                                <div className="d-flex align-items-center resturant-description-top">
                                                    <GiScooter
                                                        style={{
                                                            color: "FF9A62",
                                                        }}
                                                        size={20}
                                                    />
                                                    <span className="all-resturant-delivery ms-2 mt-1">
                                                        Free Delivery Upto...
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {!store?.is_active ? (
                                                <div className="text-danger">
                                                    Store Closed
                                                </div>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    // allStores: state.home.allStores,
    allStores: state.medicine.medicine
});

export default connect(mapStateToProps, { getAllResturants })(
    AllMedicalStore
);
