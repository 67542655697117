import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from "react-modal";
export class CancelOrder extends Component {

    state = {
        modal: false,
    }
    handlePopup = () => {

        this.setState({ modal: false });

    }
    render() {
        return (
            <React.Fragment>
                <div className='px-4 mt-2' style={{ paddingBottom: '70vw' }}>
                    <div className="cancel-card p-3  w-100">
                        <div className="text-dark fs-6 fw-bolder pb-3">
                            Review Your order and details to avoid cancellations
                        </div>
                        <div className='text-secondary fs-8 fw-bolder'>
                            <span className='text-danger fw-bolder'> Note:  </span>
                            If you cancel within 60 seconds of placing your order, a 100% refund will be issued. No refund for cancellations made after 60 seconds.
                        </div>
                        <div className='fs-10 my-3 fw-semibold text-secondary'>
                            Avoid cancellation as it leads to food wastage
                        </div>

                        <span className="fw-bolder" onClick={() => this.setState({ modal: true })} style={{ color: "#fea24b", textDecoration: "underline" }}>READ CANCELLATION POLICY</span>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modal}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.handlePopup()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className='d-flex flex-column align-items-center' style={{ backgroundColor: '#fff', padding: '13px' }}>

                        <div className='text-muted mt-2' style={{ fontSize: '13px' }} >
                            <span className='fw-bolder'> Cancellation and Refund Policy</span>
                            <br />
                            Any Capitalized terms used but not defined herein shall have the meaning assigned to them under the Terms of Use which govern your use of our website www.Grosav.com (the “Website”) and our ‘Grosav’ application for mobile and handheld devices (the “App”). The Website and the App are jointly referred to as the “Platform”.<br />

                            <span className="fw-bolder">Customer Cancellation</span><br />

                            As a general rule Buyer shall not be entitled to cancel Order once placed. Buyer may choose to cancel Order only within one-minute of the Order being placed. However, subject to Buyer’s previous cancellation history, Grosav reserves the right to deny any refund to Buyer pursuant to a cancellation initiated by Buyer even if the same is within one-minute followed by suspension of account, as may be necessary in the sole discretion of Grosav.

                            If Buyer cancels his/her Order after one minute of placing it, Grosav shall have a right to collect a penalty of 100% of the Order amount for breach of contract terms as a compensation for the damages suffered by Grosav, with a right to either not to refund the Order value in case Buyer’s Order is prepaid or recover from the Buyer’s subsequent Order in case his/her Order is postpaid, to compensate the Merchants and PDPs.<br />

                            <span className='fw-bolder'>Non-Customer Cancellation</span><br />

                            Grosav reserves the right to collect a penalty for the Orders constrained to be cancelled by Grosav for reasons not attributable to Grosav, including but not limited to:

                            in the event if the address provided by Buyer is either wrong or falls outside the delivery zone;

                            failure to contact Buyer by phone or email at the time of delivering the Order booking;

                            failure to deliver Buyer Order due to lack of information, direction or authorization from Buyer at the time of delivery; or

                            unavailability of all the items ordered by Buyer at the time of booking the Order; or

                            unavailability of all the items ordered by Buyer at the time of booking the Order. However, in the unlikely event of an item in an Order being unavailable, Grosav will contact the Buyer on the phone number provided to us at the time of placing the Order and inform Buyer of such unavailability. In such an event Buyer will be entitled to cancel the entire Order and shall be entitled to a refund to an amount upto 100% of the Order value.

                            In case of cancellations for the reasons attributable to Grosav or the Merchant or PDP, Grosav shall not collect any penalty from the Buyer.<br />

                            <span className='fw-bolder'> Cancellation policy for Instamart/Meat stores/Supermarkets/Pet stores/Fruits and vegetables/Wellness essentials/Gourmet & Organic</span><br />

                            The Orders placed by Buyers using the Platform are non-cancellable and non-refundable except if refund is requested under the following conditions –

                            If the Order could not be delivered within the estimated time while placing the order;
                            If the Order has not been picked by the PDP

                            If the Merchant doesn't accept or cancels the Order due to reasons not attributable to Buyer, including but not limited to store being closed, non-availability of items, store cannot service online orders at that moment, store is overcrowded, etc.

                            If Grosav cancels the Order due to reasons not attributable to Buyer, including but not limited to non-availability of PDP, etc.

                            Grosav reserves the right to look into the cancellation request of the Buyer and determine if such cancelation request falls under the conditions mentioned above. If Grosav is satisfied that the request and same fulfills any of the aforesaid conditions, then Grosav shall process the cancellation request and refund amounts to the Buyer.<br />

                            <span className='fw-bolder'> Refunds</span><br />

                            Buyer may be entitled to a refund for prepaid Orders.  Grosav retains the right to retain the penalty payable by the Buyer in Section I(2) from the amount refundable to him/her. The Buyer shall also be entitled to a refund of proportionate value in the event packaging of an item in an Order or the complete Order, is either tampered or damaged and the Buyer refuses to accept at the time of delivery for the said reason;

                            Buyer may be entitled to a refund upto 100% of the Order value if PDP fails to deliver the Order due to a cause attributable to either PDP or Grosav, however such refunds will be assessed on a case to case basis by Grosav.

                            Our decision on refunds shall be final and binding.

                            All refund amounts shall be credited to Buyer’s account as may be stipulated as per the payment mechanism of Buyer’s choice, the estimated timelines are detailed as below, in case Buyer don't choose to credit it to Buyer’s wallet with his/her Grosav Account;<br />
                        </div>
                    </div>
                    <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-2">
                        <div className="text-center w-100">
                            <button className="add-button-meals p-2 px-2 text-center text-white position-relative"
                                onClick={(e) => this.handlePopup()}
                                style={{ width: "50%", backgroundColor: "#ce0060", borderColor: "#ce0060", }}>
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps)(CancelOrder)
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        height: "50%",
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
