import {
    GET_NOTIFICATIONS

} from "./actionType";


const initialState = {
    notification: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case   GET_NOTIFICATIONS:
            return { ...state, notification: action.payload };
        default:
            return state;
    }
}

