//items
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEM_SCHEDULE_DATE = "GET_ITEM_SCHEDULE_DATE";
export const ADD_ITEM_SCHEDULE_DATE = "ADD_ITEM_SCHEDULE_DATE";

export const GET_ITEM_CATEGORY_SCHEDULE_DATE = "GET_ITEM_CATEGORY_SCHEDULE_DATE";
export const ADD_ITEM_CATEGORY_SCHEDULE_DATE = "ADD_ITEM_CATEGORY_SCHEDULE_DATE";
//item category
export const GET_ITEM_CATEGORIES = "GET_ITEM_CATEGORIES";

//addon
export const GET_ADDONS = "GET_ADDONS";

//addons category
export const GET_ADDON_CATEGORIES = "GET_ADDON_CATEGORIES";

export const GET_ALL_STORE_ITEMS = "GET_ALL_STORE_ITEMS";
export const ADD_DEAL_OF_TODAY = 'ADD_DEAL_OF_TODAY';
export const CHANGE_ITEM_STATUS = "CHANGE_ITEM_STATUS";
export const CHANGE_DEAL_STATUS = "CHANGE_DEAL_STATUS";

