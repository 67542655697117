import React, { Component } from 'react'
import { AiFillSmile } from 'react-icons/ai'
import Ink from 'react-ink';
import { connect } from 'react-redux'
import Smile from '../../../../assets/images/smile.png'
export class Tip extends Component {
    render() {
        const { addTip, tip } = this.props;
        return (
            <React.Fragment>
                <div className='px-3 pt-3'>
                    <h1 className='pt-2 mobile-cart-tip'>Tip Your Delivery Guy</h1>
                    <div className='mobile-cart-tip-card py-3 mt-4'>
                        <div className='mobile-cart-tip-cmnt px-3'>Thank your delivery partner for helping
                            you stay safe indoors.
                        </div>
                        <div className='d-flex flex-row justify-content-around align-items-center pt-4 px-2'>
                            <div className={tip === "10" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('10')}>
                                Rs 10 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "20" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('20')}>
                                Rs 20 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "50" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('50')}>
                                Rs 50 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "100" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('100')}>
                                Rs 100 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            {/* <div className='mobile-cart-tip-btn py-1 px-2'>OTHER</div> */}
                        </div>
                        {tip && tip.length > 0 ? (
                            <button className='mt-2 btn btn-sm fw-bolder position-relative mx-3' style={{ backgroundColor: '#ff6262', color: '#fff', borderRadius: '10px' }} onClick={() => addTip('')}>
                                Remove
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </button>
                        ) : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Tip)
