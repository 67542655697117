export const GET_ALL_STORE_CATEGORIES = "GET_ALL_STORE_CATEGORIES";
export const GET_SLIDERS = "GET_SLIDERS";
export const GET_SINGLE_SLIDER = "GET_SINGLE_SLIDER";
export const GET_COUPONS = "GET_COUPONS";
export const GET_SINGLE_COUPON = "GET_SINGLE_COUPON";
export const GET_ALL_BANNER_FOOD = "GET_ALL_BANNER_FOOD";
export const GET_TOP_FOOD = "GET_TOP_FOOD";
export const GET_TOP_DEALS = "GET_TOP_DEALS";
export const GET_ALL_RESTURANTS = "GET_ALL_RESTURANTS";
export const GET_ALL_RECOMMENTED_STORES = "GET_ALL_RECOMMENTED_STORES";
export const GET_ALL_NEAR_STORES = "GET_ALL_NEAR_STORES";
export const GET_ALL_POPULAR_STORES = "GET_ALL_POPULAR_STORES";
export const GET_ALL_ITEM_CATEGORY = "GET_ALL_ITEM_CATEGORY";
export const GET_ALL_ITEM_GROUP = "GET_ALL_ITEM_GROUP";
export const GET_POPULAR_BRANDS = "GET_POPULAR_BRANDS";
export const GET_DEALS_TODAY = "GET_DEALS_TODAY";
