import React, { Component } from 'react'
import { connect } from 'react-redux'
// import image from '../../../../assets/images/img.png'
import { AiOutlineMinus, AiOutlineStop } from 'react-icons/ai'
import { AiOutlinePlus } from 'react-icons/ai'
// import { Link } from 'react-router-dom'
import { BiEditAlt, BiGridSmall } from 'react-icons/bi'
import ProgressiveImage from 'react-progressive-image'
import { addProduct } from '../../../../redux/mobile/cart/action'
// import FloatCart from '../FloatCart'
import { IMAGE_BASE_URL } from '../../../../api'
import Ink from 'react-ink'
import { forEach, reduce } from 'lodash'
import ComboCustomization from '../../ComboCustomization'
import { formatPrice } from '../../../Helpers/formatPrice'
// import { remove } from 'lodash'
import non_veg_icon from '../../../../assets/images/non-veg-icon.png'
import veg_icon from '../../../../assets/images/veg-icon.png'


export class Items extends Component {
    state = {
        count: 0,
        update: false,

    };

    handleIncrement = (e) => {
        this.setState(prevState => {
            return { ...prevState, count: prevState.count + 1 }
        })
        // this.setState({ count: this.state.count + 1 });
    };
    handleDecrement = (e) => {
        this.state.count && this.setState({ count: this.state.count - 1 });
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    __comboPrice = (item) => {
        let data = item?.selectedcombos;
        let sum = 0;
        let subtotal = sum + item?.selling_price;
        if (item?.dealofdays?.length > 0) {
            sum = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sum = subtotal;
        }
        data?.forEach((i) => {
            sum += parseFloat(i.price)
        });
        return formatPrice(sum);
    }

    __addonPrice = (item, addon) => {
        let data = item?.selectedaddons;
        let sum = 0;
        const index = item?.selectedaddons.findIndex(
            (p) => p.addon_id === addon.addon_id
        );
        sum = parseFloat(data[index].price)
        let subtotal = sum + item?.selling_price;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price;
        return total;
    }

    __addonSum = (item) => {
        let data = item?.selectedaddons;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price * i.quantity + item?.selling_price * i.quantity)
        });
        let subtotal = sum;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }


    __comboSum = (item) => {
        let data = item?.selectedcombos;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price)
        });
        let subtotal = sum + item?.selling_price;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }

    superSum = (item) => {

        if (item.selectedcombos?.length > 0) {
            return parseFloat(this.__comboSum(item));
        }

        if (item.selectedaddons?.length > 0) {
            return parseFloat(this.__addonSum(item));
        }

        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = item?.selling_price - parseFloat(item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = item.selling_price;
        }
        return formatPrice(sell_price * item.quantity);
    }

    render() {

        const { addProductQuantity, removeProductQuantity, products, coupon } = this.props;

        return (
            <React.Fragment>
                {/* {////// console.log(products?.coupons.length > 0)}  */}

                {products && products.length > 0 && products.sort((a, b) => {
                    const hasCouponA = coupon?.items?.find(cp => cp.id === a.id) ? 1 : 0;
                    const hasCouponB = coupon?.items?.find(cp => cp.id === b.id) ? 1 : 0;
                    return hasCouponB - hasCouponA;
                }).map((product, index) => (
                    product?.selectedaddons?.length > 0 ?
                        product?.selectedaddons?.map((addon) =>
                            <div className='mobile-cart-items  mx-3 pb-1 mt-3'>
                                <div className='py-3 px-3 d-flex justify-content-between'>
                                    {/* <div className='d-flex justify-content-between'>
                                        <div>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + product?.image}
                                                placeholder={
                                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                                }>
                                                {(src, loading) => (
                                                    <img
                                                        className=""
                                                        style={{
                                                            width: "18vw",
                                                            objectFit: "cover",
                                                            height: "18vw",
                                                            borderRadius: "14px",
                                                            backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                            backgroundRepeat: "round",
                                                            // boxShadow: "0px 0px 15px 8px #cccccc50",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                    </div> */}
                                    <div className='flex-grow-1 ps-2 me-2 mobile-cart-spicy d-flex flex-column mt-2' style={{
                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                    }}>
                                        <div className="d-flex align-items-center">

                                            <img src={product?.is_veg ? veg_icon : non_veg_icon} style={{ width: "1rem" }} />
                                            <div className='hide-text ms-2'>
                                                {product?.name}
                                            </div>
                                        </div>

                                        <div className=''>
                                            <span className=''>
                                                <BiGridSmall />    {addon?.addon_name}
                                            </span>
                                        </div>
                                        <div className=''>
                                            {product?.is_prescription ?
                                                <span className='prescription-icon' >
                                                    Prescription needed
                                                </span>
                                                : null}
                                        </div>
                                    </div>

                                    {product?.is_free_item == 1 ? (
                                        <div className='mt-3'>

                                            <div><s>  Rs {parseFloat(product?.market_price)}</s></div>
                                            <div className='mobile-cart-warenik bg-[#32ba7c] text-white px-2 py-1 rounded-2' style={{ backgroundColor: '#32ba7c' }}>
                                                Free Item
                                            </div>
                                        </div>
                                    ) : (<div className='d-flex flex-column align-items-end position-relative' >
                                        <div className='d-flex flex-row align-items-center'>
                                            <div className="add-button p-1 px-2 position-relative" onClick={() => removeProductQuantity(product, 'addon', addon?.addon_id)}>
                                                <AiOutlineMinus style={{ fontSize: "1em", }} />
                                                <Ink duration={500} style={{ color: "#aaa" }} />
                                            </div>
                                            <span className="mx-1 item-count">{addon?.quantity}</span>
                                            <div className="add-button p-1 px-2 position-relative" onClick={() => addProductQuantity(product, addon?.addon_id)}>


                                                <AiOutlinePlus style={{ fontSize: "1em" }} />
                                                <Ink duration={500} style={{ color: "#aaa" }} />
                                            </div>
                                        </div>
                                        <div className='pt-1'>
                                            <div className='text-center'>
                                                {coupon?.item_id == product?.id ?
                                                    <s>  Rs {this.__addonPrice(product, addon)}</s>
                                                    :
                                                    <span className='mobile-cart-price'  >   Rs {this.__addonPrice(product, addon)}</span>
                                                }
                                            </div>
                                            {coupon?.item_id == product?.id ? (
                                                <div className='text-end me-2 text-success' style={{ fontSize: '16px', fontWeight: '600' }}> Free </div>
                                            ) : null}
                                        </div>
                                    </div>)}

                                </div>

                                {coupon?.items?.find((cp) => (cp?.id === product?.id)) ? (
                                <div style={{
                                    borderRadius: '0px 0px 15px 15px', backgroundColor: 'rgb(62, 153, 111)',
                                    color: 'white', fontWeight: 'bold', textAlign: 'center', padding: '3px'
                                }}>
                                    <span>COUPON APPLIED</span>
                                </div>
                            ) : null}
                            </div>
                        ) :

                        <div className='mobile-cart-items  mx-3 pb-1 mt-3'>

                            <div className='py-3 d-flex justify-content-between px-3'>
                                {/* <div className='d-flex justify-content-between'>
                                    <div>
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + product?.image}
                                            placeholder={
                                                "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                            }>
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "18vw",
                                                        objectFit: "cover",
                                                        height: "18vw",
                                                        borderRadius: "14px",
                                                        backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                                        backgroundRepeat: "round",
                                                        // boxShadow: "0px 0px 15px 8px #cccccc50",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                </div> */}
                                <div className='flex-grow-1 ps-2 me-2 mobile-cart-spicy d-flex flex-column mt-2' style={{
                                    textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                }}>
                                    <div className="d-flex align-items-center">

                                        <img src={product?.is_veg ? veg_icon : non_veg_icon} style={{ width: "1rem" }} />
                                        <div className='hide-text ms-2'>
                                            {product?.name}
                                        </div>
                                    </div>

                                    {product?.selectedcombos?.length > 0 && (
                                        <div className=''>
                                            <span span className='mobile-cart-warenik'>
                                            </span>
                                            <ComboCustomization
                                                product={product}
                                                addProduct={addProduct}


                                                cartBtn={true} />
                                        </div>
                                    )}
                                    <div className=''>
                                        {product?.is_prescription ?
                                            <span className='prescription-icon' >
                                                Prescription needed
                                            </span>
                                            : null}
                                    </div>
                                    {/* <div className=''>
                                <Link className='mobile-cart-edit'>
                                    Edit <BiEditAlt />
                                </Link>
                            </div> */}
                                </div>


                                {product?.is_free_item === 1 ? (
                                    <div className='mt-3'>

                                        <div><s>  Rs {parseFloat(product?.market_price)}</s></div>
                                        <div className='mobile-cart-warenik bg-[#32ba7c] text-white px-2 py-1 rounded-2' style={{ backgroundColor: '#32ba7c' }}>
                                            Free Item
                                        </div>
                                    </div>
                                ) : (<div className='d-flex flex-column align-items-end position-relative' >
                                    {/* <div className='position-absolute' style={{bottom:'0px',right:'0px'}}> */}
                                    <div className='d-flex flex-row align-items-center'>
                                        <div className="add-button p-1 px-2 position-relative" onClick={() => removeProductQuantity(product, 'item')}>
                                            <AiOutlineMinus style={{ fontSize: "1em", }} />
                                            <Ink duration={500} style={{ color: "#aaa" }} />
                                        </div>
                                        <span className="mx-1 item-count">{product?.quantity}</span>

                                        {product?.store?.storecategory?.check_stock === 1 ||  product?.store?.store_category?.check_stock === 1?
                                            (product.stock > product.quantity ? (
                                                <div className="add-button p-1 px-2 position-relative" onClick={() => addProductQuantity(product)}>
                                                    <AiOutlinePlus style={{ fontSize: "1em" }} />
                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                </div>
                                            ) : (
                                                <div className="add-button p-1 px-2 position-relative" >
                                                    <AiOutlineStop style={{ fontSize: "1em" }} />
                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                </div>
                                            )

                                        ) : (
                                                <div className="add-button p-1 px-2 position-relative" onClick={() => addProductQuantity(product)}>
                                                    <AiOutlinePlus style={{ fontSize: "1em" }} />
                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                </div>
                                        )
                                        }


                                    </div>
                                    <div className='pt-1'>
                                        {product?.selectedcombos?.length > 0 && product?.selectedcombos ? (
                                            <div className='text-center'>
                                                {coupon?.item_id == product?.id ?
                                                    <s>  Rs {this.__comboPrice(product)}</s>
                                                    :
                                                    <span className='mobile-cart-price'  >   Rs {this.__comboPrice(product)}</span>
                                                }
                                            </div>
                                        ) : (
                                            <div className='text-center'>
                                                {coupon?.item_id == product?.id ?
                                                    <s>
                                                        {product?.dealofdays?.length > 0 ?
                                                            <>
                                                                <span span className='mobile-cart-price'>Rs {product?.selling_price - parseFloat(product?.selling_price * parseFloat(product.dealofdays[0].offer) / 100)}</span> {parseFloat(product?.market_price) > (product?.selling_price - parseFloat(product?.selling_price) * parseFloat(product.dealofdays[0].offer) / 100) ? <span style={{ color: "orange" }}><s>Rs {parseFloat(product?.market_price)}</s></span> : ''}
                                                            </>
                                                            : <>
                                                                <span className='mobile-cart-price'>Rs {parseFloat(product?.selling_price)}</span> {parseFloat(product?.market_price) > parseFloat(product?.selling_price) ? <span style={{ color: "orange" }}><s>Rs {parseFloat(product?.market_price)}</s></span> : ''}
                                                            </>
                                                        }
                                                    </s>
                                                    :
                                                    <span className='mobile-cart-price'  >
                                                        {product?.dealofdays?.length > 0 ?
                                                            <>
                                                                <span className='mobile-cart-price'>Rs {product?.selling_price - parseFloat(product?.selling_price * parseFloat(product.dealofdays[0].offer) / 100)}</span> <span style={{ color: "orange", fontSize: '14px' }}><s>Rs {parseFloat(product?.selling_price)}</s></span>
                                                            </>
                                                            :
                                                            <>
                                                                <span className='mobile-cart-price'>Rs {parseFloat(product?.selling_price)}</span> {parseFloat(product?.market_price) > parseFloat(product?.selling_price) ? <span style={{ color: "orange", fontSize : '10px' }}>

                                                                    <s>Rs {parseFloat(product?.market_price)}</s></span> : ''}
                                                            </>
                                                        }
                                                    </span>
                                                }
                                            </div>
                                        )}
                                        {coupon?.item_id == product?.id ? (
                                            <div className='text-end me-2 text-success' style={{ fontSize: '16px', fontWeight: '600' }}> Free </div>
                                        ) : null}
                                        {/* <span className='mobile-cart-price'>Rs {product.selling_price}</span> */}
                                    </div>
                                    {/* </div> */}
                                </div>)}

                            </div>
                            {/* {product?.selectedaddons?.length > 0 && product?.selectedaddons?.map((addon) =>
                            <div className=''>
                                <div className='' style={{ fontWeight: '600' }}>
                                    {addon.addon_name} : Rs.{addon.price}
                                </div>
                            </div>
                        )} */}
                            {coupon?.items?.find((cp) => (cp?.id === product?.id)) ? (
                                <div style={{
                                    borderRadius: '0px 0px 15px 15px', backgroundColor: 'rgb(62, 153, 111)',
                                    color: 'white', fontWeight: 'bold', textAlign: 'center', padding: '3px'
                                }}>
                                    <span>COUPON APPLIED</span>
                                </div>
                            ) : null}
                        </div>


                ))
                }
            </React.Fragment>
        )

    }
}


const mapStateToProps = (state) => ({
    cartProducts: state.cart.items,
    products: state.cart.products,
    coupon: state.mobileCoupon.appliedCoupon,
})



export default connect(mapStateToProps, {
    addProduct
})(Items)
