import { GET_COUPONS,GET_SINGLE_COUPON} from "./actionType";


const initialState = {
    coupons: [],
    singleCoupon:[],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COUPONS:
            return { ...state, coupons: action.payload };
        case GET_SINGLE_COUPON:
            return { ...state, singleCoupon: action.payload };
        default:
            return state;
    }
}
