export const LOGIN_STORE_USER = "LOGIN_STORE_USER";
export const SEND_STORE_USER_OTP = "SEND_STORE_USER_OTP";
export const LOGIN_OTP_STORE_USER = "LOGIN_OTP_STORE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER = "GET_USER";
export const GET_ORDER = "GET_ORDER";
export const VIEW_CITIES = "VIEW_CITIES";
export const GET_USER_PASSWORD = "GET_USER_PASSWORD";
export const GET_USER_STORES = "GET_USER_STORES";
export const TOGGLE_SINGLE_STORE = "TOGGLE_SINGLE_STORE";
export const GET_TOGGLE_DISPLAY_TOKEN = "GET_TOGGLE_DISPLAY_TOKEN";
export const GET_STORE_USERS = "GET_STORE_USERS";
export const GET_ORDER_STATUS = "GET_ORDER_STATUS";
export const GET_SCHEDULE_DATE = "GET_SCHEDULE_DATE";
export const GET_STORE_RATING = "GET_STORE_RATING";
export const ADD_SCHEDULE_DATE = "ADD_SCHEDULE_DATE";
export const GET_SEARCH_ORDERS = 'GET_SEARCH_ORDERS';
export const GET_STORE_CATEGORIES = "GET_STORE_CATEGORIES";
export const GET_CATEGORY_SCHEDULE_DATE = "GET_CATEGORY_SCHEDULE_DATE";
export const ADD_CATEGORY_SCHEDULE_DATE = "ADD_CATEGORY_SCHEDULE_DATE";
export const EXPORT_DATE_WISE_SALES = "EXPORT_DATE_WISE_SALES";
export const GET_EXPORTED_DATE_WISE_SALES = "GET_EXPORTED_DATE_WISE_SALES";
export const CHANGE_ITEM_SCHEDULE_STATUS = "CHANGE_ITEM_SCHEDULE_STATUS";
export const CHANGE_STORE_SCHEDULE_STATUS = "CHANGE_STORE_SCHEDULE_STATUS";

