import React, { Component } from "react";
import { connect } from "react-redux";
import ScratchCard from "react-scratchcard";
import card from "../../../../assets/images/1234.jpg";
import img from "../../../../assets/images/scratch-card.png";
import noData from "../../../../assets/images/Nodata-pana.svg";
import MobileBackButtonWithCenterTitle from "../../../Elements/MobileBackButtonWithCenterTitle";

import { getAllScratchCard } from "../../../../redux/mobile/scratchCard/action";
import { scratchComplete } from "../../../../redux/mobile/scratchCard/action";
import { IMAGE_BASE_URL } from "../../../../api";
import notOpen from "../../../../assets/images/card-not-open.jpg";
export class MyRewards extends Component {
    state = {
        no_transactions: false,
        loading: true,
        cards: [],
        selectedCard: null,
        popup: false,
        prize: false,
    };

    componentDidMount() {
        const { user } = this.props;

        this.props.getAllScratchCard(user.data.auth_token, user.data.id);
    }
    clickCard = (card) => {
        if (!card.is_collected) {
            this.setState({
                selectedCard: card,
                popup: true,
            });
        }
    };

    handleScratchComplete = () => {
        const { user } = this.props;
        this.props
            .scratchComplete(user.data.auth_token, this.state.selectedCard.id)
            .then((response) => {
                if (response) {
                    if (this.state.selectedCard.card.amount > 0) {
                        localStorage.setItem("scratchEarned", "true");
                    }
                    window.location.reload();
                }
            });
    };
    render() {
        if (this.state.popup === true && this.state.selectedCard) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        const { cards } = this.props;
        return (
            <React.Fragment>
                <div>
                    <MobileBackButtonWithCenterTitle title="My Rewards" history={this.props.history} />
                </div>
                <div className="" style={{ paddingBottom: '10vw' }}>
                    <h6 className="home-heading px-3 text-center" style={{ fontSize: "16px" }}  >
                        Your total rewards earned
                    </h6>

                    <div style={{ marginTop: "5rem" }}>
                        <div className="row" style={{ margin: "10px" }}>
                            {cards?.data?.length > 0 ? (
                                <React.Fragment>
                                    {cards?.data.map((card) => (
                                        <div
                                            onClick={() => this.clickCard(card)}
                                            key={card.id}
                                            className="col-6"
                                            style={{ marginTop: "30px" }}
                                        >
                                            <div
                                                style={{
                                                    height: "11rem",
                                                    backgroundColor: "#f8fafb",
                                                    borderRadius: "10px",
                                                    filter: "drop-shadow(1px 0px 6px #b8b9bd45)",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "11rem",
                                                        backgroundColor:
                                                            "rgb(248, 250, 251)",
                                                        borderRadius: "10px",
                                                        filter: "drop-shadow(rgba(184, 185, 189, 0.00000001) 1px 0px 6px)",
                                                        alignItems: "center",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            card?.is_collected && card?.card?.image
                                                                ? IMAGE_BASE_URL + card?.card?.image
                                                                : notOpen
                                                        }
                                                        style={{
                                                            width: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: '10px'
                                                        }}
                                                        alt={"Grosav Rewards"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>

                                    <img src={noData} alt="" width="50%" height="50%"></img>
                                    <div
                                        style={{ width: "100vw" }}
                                        className="mt-100 fw-bolder fs-5 text-muted text-center pb-5"
                                    >
                                        No Rewards

                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {this.state.popup === true && this.state.selectedCard && (
                        <div
                            style={{
                                position: "absolute",
                                top: "0px",
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "#0000009c",
                                zIndex: "99999",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "33vh",
                                    right: "10vw",
                                }}
                            >
                                <ScratchCard
                                    width={300}
                                    height={300}
                                    finishPercent={50}
                                    onComplete={() =>
                                        this.handleScratchComplete()
                                    }
                                    image={notOpen}
                                >
                                    <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <img
                                            src={IMAGE_BASE_URL + this.state.selectedCard?.card?.image}
                                            style={{
                                                width: "300px",
                                                height: "300px",
                                                borderRadius: "10px",
                                                objectFit: "cover",
                                            }}
                                            alt={"GROSAV"}
                                        />
                                    </div>
                                </ScratchCard>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    cards: state.cards.cards,
});

export default connect(mapStateToProps, { getAllScratchCard, scratchComplete })(
    MyRewards
);
