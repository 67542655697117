import React, { Component } from 'react'
import { AiFillSmile } from 'react-icons/ai'
import Ink from 'react-ink';
import { connect } from 'react-redux'
import Smile from '../../../../assets/images/smile.png'
export class Tip extends Component {
    render() {
        const { addTip, tip } = this.props;
        return (
            <React.Fragment>
                <div className='px-4 mt-2'>
                    <div className='mobile-cart-tip-card px-2  w-100' style={{ border: '1px solid #fea24b' }}>
                        <div style={{ paddingTop: "1em", }} className="d-flex justify-content-between px-3"  >
                            <p style={{ color: "#7b7b7b", fontSize: "14px", fontWeight: "650" }} >
                                Tip Your Delivery Guy
                            </p>
                            {tip && tip.length > 0 ? (
                                <button className='mt-2 btn btn-sm fw-bolder position-relative' style={{ backgroundColor: '#ff6262', color: '#fff', borderRadius: '10px' }} onClick={() => addTip('')}>
                                    Remove
                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                </button>
                            ) : null
                            }
                        </div>
                        <div className="py-3 w-100  d-flex flex-row justify-content-between align-items-center px-3" style={{ fontSize: '13px' }}>
                            <div className={tip === "10" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('10')}>
                                Rs 10 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "20" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('20')}>
                                Rs 20 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "50" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('50')}>
                                Rs 50 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            <div className={tip === "100" ? 'mobile-cart-tip-active p-1 position-relative' : 'mobile-cart-tip-btn p-1 position-relative'} onClick={() => addTip('100')}>
                                Rs 100 <AiFillSmile size="1.2em" />
                                <Ink duration={500} style={{ color: "#aaa" }} />
                            </div>
                            {/* <div className='mobile-cart-tip-btn py-1 px-2'>OTHER</div> */}
                        </div>

                    </div>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Tip)
