import Axios from "axios";
import {
    GET_ORDER_RATING_URL,
    GET_ALL_RUNNING_ORDERS_URL,
    CANCEL_ORDER_URL,
    ACCEPT_ORDER_URL,
    COURIER_CHECK_OUT_URL,
    COURIER_STATUS_URL,
    PICKUP_STATUS_URL,
    CHECK_COURIER_PICKUP_ADDRESS_URL,
    CHECK_COURIER_DELIVERY_ADDRESS_URL,
    CANCEL_COURIER_URL,
    GET_ORDERS_TO_REVIEW_URL,
    SUBMIT_ORDER_REVIEW_URL,
    GET_SINGLE_ORDER_URL,
    ADD_STORE_REVIEW_URL
} from "../../../api/index"
import {
    GET_ORDER_RATING,
    GET_ALL_RUNNING_ORDERS,
    CANCEL_ORDER,
    ACCEPT_ORDER,
    COURIER_CHECK_OUT,
    COURIER_STATUS,
    PICKUP_STATUS,
    CHECK_COURIER_PICKUP_ADDRESS,
    CHECK_COURIER_DELIVERY_ADDRESS,
    CANCEL_COURIER,
    GET_ORDERS_TO_REVIEW,
    SUBMIT_ORDER_REVIEW,
    GET_SINGLE_ORDER,
    ADD_STORE_REVIEW
} from "./actionType";




export const getOrderRating = (formData) => (dispatch) => {
    return Axios.post(GET_ORDER_RATING_URL, formData)
        .then((response) => {
            const ratings = response.data;
            return dispatch({
                type: GET_ORDER_RATING,
                payload: ratings
            })

        })
}

export const getAllRunningOrders = (token) => (dispatch) => {
    return Axios.post(GET_ALL_RUNNING_ORDERS_URL, { token }).then((response) => {
        const running_order = response.data;
        return dispatch({
            type: GET_ALL_RUNNING_ORDERS,
            payload: running_order
        })

    })
}

export const getSingleOrder = (token,order_id) => (dispatch) => {
    return Axios.post(GET_SINGLE_ORDER_URL, { token,order_id })
        .then((response) => {
            const single_order = response.data;
            return dispatch({
                type: GET_SINGLE_ORDER,
                payload: single_order
            })

        })
}
export const addStoreReview = (token, order_id, store_id, rating, review) => (dispatch) => {
    return Axios.post(ADD_STORE_REVIEW_URL, { token, order_id, store_id, rating, review })
        .then((response) => {
            const data = response.data;
            return dispatch({
                type: ADD_STORE_REVIEW,
                payload: data
            })

        })
}

export const pickupStatus = (token, id) => (dispatch) => {
    return Axios.post(PICKUP_STATUS_URL, { token, id })
        .then((response) => {
            const pickup = response.data;
            return dispatch({ type: PICKUP_STATUS, payload: pickup })

        })
}

export const cancelOrder = (token, unique_order_id, reason, refund_method, type) => (dispatch) => {
    return Axios.post(CANCEL_ORDER_URL, {
        token, unique_order_id,
        reason, refund_method, type
    })
        .then(response => {
            const cancelMessage = response.data;
            return dispatch({ type: CANCEL_ORDER, payload: cancelMessage });
        })
        .catch(function (error) {
            // console.log(error);
        });
};


export const acceptOrder = (token, unique_order_id) => (dispatch) => {
    return Axios.post(ACCEPT_ORDER_URL, {
        token, unique_order_id,
    })
        .then(response => {
            const accept_message = response.data;
            return dispatch({ type: ACCEPT_ORDER, payload: accept_message });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const checkPickupAddress = (latitude, longitude) => (dispatch) => {
    return Axios.post(CHECK_COURIER_PICKUP_ADDRESS_URL, {
        latitude, longitude
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: CHECK_COURIER_PICKUP_ADDRESS, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const checkDeliveryAddress = (latitude, longitude, city_id) => (dispatch) => {
    return Axios.post(CHECK_COURIER_DELIVERY_ADDRESS_URL, {
        latitude, longitude, city_id
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: CHECK_COURIER_DELIVERY_ADDRESS, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const courierCheckOut = (token, city_id, pickup_address, delivery_address, selectedItem, instructions, total_charge, delivery_charge, payment_type,courier_fee) => (dispatch) => {
    return Axios.post(COURIER_CHECK_OUT_URL, { token, city_id, pickup_address, delivery_address, selectedItem, instructions, total_charge, delivery_charge, payment_type ,courier_fee})
        .then((response) => {
            const courier = response.data;
            return dispatch({
                type: COURIER_CHECK_OUT,
                payload: courier
            })

        })
}

export const courierStatus = (token, id) => (dispatch) => {
    return Axios.post(COURIER_STATUS_URL, { token, id })
        .then((response) => {
            const courier = response.data;
            return dispatch({ type: COURIER_STATUS, payload: courier })

        })
}


export const cancelCourier = (token, unique_courier_id, reason, refund_method) => (dispatch) => {
    return Axios.post(CANCEL_COURIER_URL, {
        token, unique_courier_id,
        reason, refund_method
    })
        .then(response => {
            const cancelMessage = response.data;
            return dispatch({ type: CANCEL_COURIER, payload: cancelMessage });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const getOrderForReview = (token, id) => (dispatch) => {
    return Axios.post(GET_ORDERS_TO_REVIEW_URL, { token, id })
        .then((response) => {
            const review = response.data;
            return dispatch({ type: GET_ORDERS_TO_REVIEW, payload: review })
        })
}

export const submitOrderReview = (token, id, rating, review) => (dispatch) => {
    return Axios.post(SUBMIT_ORDER_REVIEW_URL, { token, id, rating, review })
        .then((response) => {
            const review = response.data;
            return dispatch({ type: SUBMIT_ORDER_REVIEW, payload: review })
        })
}
