import React from 'react'
import ProgressiveImage from 'react-progressive-image'
import { PLACE_HOLDER_IMAGE } from '../../../../api'
import { FiPhoneCall } from 'react-icons/fi'

const AgentDetails = (props) => {
    const { name, phone, image, hide_phone } = props
    return (
        <div className='my-2 border rounded-3 px-2 py-1'>

            <div style={{ color: '#FD8814', fontWeight: '600' }}> Delivery Agent Details</div>
            <div className='my-2 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-3'>
                    <ProgressiveImage
                        delay={20}
                        src={image}
                        placeholder={PLACE_HOLDER_IMAGE}
                    >
                        {(src, loading) => (
                            <img
                                src={src}
                                className="" style={{ width: '15vw', borderRadius: '12px', height: '15vw', objectFit: 'cover' }}
                                alt='main-item'
                            />
                        )}
                    </ProgressiveImage>
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                        <h6 className='fs-6 fw-bold'>{name}</h6>
                        {!hide_phone && <h6 className='fs-7' style={{ color: '#FD8814', }}>{phone}</h6>}
                    </div>

                </div>
                {!hide_phone &&
                    <div className='me-1 px-3 py-2 text-white rounded-3 d-flex align-items-center ' style={{ backgroundColor: '#FD8814' }}>
                        <FiPhoneCall /><span className='ms-2'>Click To Call</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default AgentDetails
