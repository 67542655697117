import React, { Component } from "react";
import { connect } from "react-redux";
import VendorBackButton from "../../Elements/VendorBackButton";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import voucher from "../../../assets/images/Voucher 1.png";
import { getSingleVendorCoupon, updateSingleCoupon } from "../../../redux/vendor/coupon/action";
import Loading from "../../Elements/loading";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_BASE_URL } from "../../../api";
import { DatePicker } from "antd";
import moment from 'moment';
import ProgressiveImage from "react-progressive-image";
import Truncate from "react-truncate";
import { RiCoupon3Fill } from "react-icons/ri";
import { IoIosArrowDropright } from "react-icons/io";
import { BottomSheet } from "react-spring-bottom-sheet";
import { AiFillCheckSquare } from "react-icons/ai";
import { IoSquareOutline } from "react-icons/io5";
export class VendorEditCoupon extends Component {
    state = {
        id: this.props.match.params.id,
        loading: false,
        EditCoupon: {},
        modalDiscountType: false,
        modalUserType: false,
        modalItems: false,
        selectedItem: [],
        selectedAllItem: false

    };
    componentDidMount() {
        const { user } = this.props;
        const { coupon } = this.props;
        //// console.log(this.state.id);
        this.props.getSingleVendorCoupon(user.data.auth_token, this.state.id)
            .then((response) => {
                this.setState({ EditCoupon: response.payload.coupon, selectedItem: response?.payload?.coupon?.items })
            });
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    selectedItem = (item, index) => {
        this.setState({ selectedAllItem: false });
        let data = this.state.selectedItem;
        if (data.length === 0) {
            data.push(item);
            this.setState({ selectedItem: data });
        } else {
            if (data.find((cp) => cp.id === item.id) === undefined) {
                data.push(item);
                this.setState({ selectedItem: data });
            } else {
                data.splice(data.findIndex(x => x.id === item.id), 1);
                this.setState({ selectedItem: data });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { user, store } = this.props;
        const { EditCoupon, selectedItem, selectedAllItem } = this.state;
        let formData = new FormData()
        formData.append("name", EditCoupon.name)
        formData.append("coupon_id", this.state.id)
        formData.append("description", EditCoupon.description)
        formData.append("code", EditCoupon.code)
        formData.append("discount_type", EditCoupon.discount_type)
        formData.append("coupon_discount", EditCoupon.coupon_discount)
        formData.append("max_discount", EditCoupon.max_discount)
        formData.append("max_count", EditCoupon.max_count)
        formData.append("user_type", EditCoupon.user_type)
        formData.append("max_count_per_user", EditCoupon.max_count_per_user)
        formData.append("start_date", EditCoupon.start_date)
        formData.append("end_date", EditCoupon.end_date)
        formData.append("min_sub_total", EditCoupon.min_sub_total)
        formData.append("token", user.data.auth_token)
        let selectedItems = JSON.stringify(selectedItem)
        formData.append("selectedItem", selectedItems)
        formData.append("store_id", store?.store?.id)
        let selectAllItems = selectedAllItem ? "ALL" : "SINGLE";
        formData.append("selectAllItems", selectAllItems)
        this.props.updateSingleCoupon(formData)
            .then((response) => {
                if (response) {
                    //// console.log(response)
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 500);
                    window.location.replace('/vendor-offer');
                    this.props.history.replace('/vendor-offer');
                }
            })
    }

    handleInput(e, name, index) {
        let coupon = this.state.EditCoupon;
        coupon[name] = e;
        this.setState({ EditCoupon: coupon });
    }
    changeUserType = () => {
        this.setState({ modalUserType: !this.state.modalUserType })
    }
    changeDiscountType = () => {
        this.setState({ modalDiscountType: !this.state.modalDiscountType })
    }
    closeCategoryMenu = () => {
        this.setState({ modalItems: !this.state.modalItems })
    }
    selectedAllItems = () => {
        this.setState({ modalItems: !this.state.modalItems, selectedAllItem: true, selectedItem: [] })
    }
    render() {
        const { coupon, items } = this.props;
        const { selectedItem,EditCoupon } = this.state;
        const disabledDate = (current) => {
            // Can not select days before today and today
            return current && current <= moment().startOf('day');
        };
        const userTypes = [{ 'value': 'ALL', 'label': 'Unlimited Times For All Users' },
        { 'value': 'ONCENEW', 'label': 'Once For New User For First Order' },
        { 'value': 'ONCE', 'label': 'Once Per User' },
        { 'value': 'CUSTOM', 'label': 'Define Custom Limit Per User' }];
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="pt-3">
                            <VendorBackButton title="Edit Coupon" />
                        </div>

                        <div className="py-4 d-flex flex-column px-2 mt-5">
                            <div className="px-3 py-2" style={{ backgroundColor: '#f4f4f4', borderRadius: '20px' }}>
                                <div className="position-relative py-2 w-100">
                                    <ProgressiveImage delay={20} src={IMAGE_BASE_URL + "/" + coupon?.coupon?.image} placeholder={PLACE_HOLDER_IMAGE}  >
                                        {(src, loading) => (
                                            <img className="mobile-coupon-edit-img" style={{ objectFit: 'cover', }} src={src} alt='main-item' />
                                        )}
                                    </ProgressiveImage>
                                    <div className="position-absolute px-3 py-3 w-100" style={{ top: '0' }}>
                                        <div className="d-flex flex-row align-items-center justify-content-between px-2 py-1" style={{ backgroundColor: '#fff', borderRadius: ' 12px ' }}>
                                            <div className="flex-grow-1 ms-2 py-2" style={{ color: '#000', fontWeight: '750', fontSize: '1.1em' }}>
                                                <Truncate lines={1}>
                                                    {coupon?.coupon?.name ? coupon?.coupon?.name : '--not given--'}
                                                </Truncate>
                                            </div>
                                            <div className="px-2 py-1 ms-3" style={{ backgroundColor: '#fd8814', borderRadius: '10px ', color: '#fff' }} >
                                                <RiCoupon3Fill /> {coupon?.coupon?.code}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="description py-2">Description</div>
                                <div className="paragraph">
                                    {coupon?.coupon?.description}
                                </div>
                            </div>

                            <div className="px-2">
                                <Form onSubmit={this.onSubmit}>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                        Coupon Name
                                    </div>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}   >
                                        <FormControl type="text" value={EditCoupon?.name} placeholder="Coupon Name" className="input-coupons"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }} />
                                    </InputGroup>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                        Coupon Code
                                    </div>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="text" value={EditCoupon?.code} placeholder="Coupon Code" className="input-coupons"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "code");
                                            }} />
                                    </InputGroup>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}   >
                                        Description
                                    </div>
                                    <InputGroup
                                        className="mt-2 coupon-input"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <FormControl
                                            as="textarea" rows={3}
                                            placeholder="Item Description"
                                            className="input-coupon"
                                            value={EditCoupon?.description}
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="description pt-4"
                                        style={{ paddingLeft: "3px" }}  >
                                        Discount Type
                                    </div>
                                    <div onClick={this.changeDiscountType}
                                        className="mt-2 coupon-input w-100"   >
                                        <div className="coupon-input-type text-center py-2 fs-6 justify-content-between w-100" style={{ fontWeight: '700' }} >
                                            <div>
                                                {EditCoupon?.discount_type}
                                            </div>
                                            <div>
                                                <IoIosArrowDropright size={25} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="description pt-4"
                                        style={{ paddingLeft: "3px" }}  >
                                        Coupon Discount {EditCoupon?.discount_type === "FIXED" ? "(Rs)" : "(%)"}
                                    </div>
                                    <InputGroup
                                        className="mt-2 coupon-input"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <FormControl
                                            type="text"
                                            value={
                                                EditCoupon?.coupon_discount
                                            }
                                            placeholder="40% of on all items "
                                            className="input-coupons"
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "coupon_discount");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                        Maximum Discount
                                    </div>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Enter Maximum Discount"
                                            className="input-coupons"
                                            value={EditCoupon?.max_discount}
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "max_discount");
                                            }}
                                        />
                                    </InputGroup>
                                    <div
                                        className="description pt-4"
                                        style={{ paddingLeft: "3px" }}
                                    >
                                        Start Date
                                    </div>
                                    <InputGroup
                                        className="mt-3 coupon-input d-flex justify-content-between"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div className="text-muted coupon-date-text px-3">
                                            <span>Start Date </span>
                                        </div>
                                        <div className="">
                                            <DatePicker
                                                placeholder={EditCoupon?.start_date}
                                                className="coupon-date"
                                                onChange={e => {
                                                    let value = moment(e).format('YYYY-MM-DD');
                                                    this.handleInput(value, "start_date");
                                                }}

                                                disabledDate={disabledDate}
                                            />
                                        </div>
                                    </InputGroup>
                                    <div
                                        className="description pt-4"
                                        style={{ paddingLeft: "3px" }}
                                    >
                                        End Date
                                    </div>
                                    <InputGroup
                                        className="mt-3 coupon-input d-flex justify-content-between"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div className="text-muted coupon-date-text px-3">
                                            <span>End Date</span>
                                        </div>
                                        <div className="">
                                            <DatePicker
                                                placeholder={EditCoupon?.end_date}
                                                className="coupon-date"
                                                onChange={e => {
                                                    let value = moment(e).format('YYYY-MM-DD');
                                                    this.handleInput(value, "end_date");
                                                }}
                                                disabledDate={disabledDate}
                                            />
                                        </div>
                                    </InputGroup>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                        Min Sub Total
                                    </div>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Enter Min Sub Total"
                                            className="input-coupons"
                                            value={EditCoupon?.min_sub_total}
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "min_sub_total");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                        Max No of use in total
                                    </div>
                                    <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                        <FormControl type="number" min={0} placeholder="Max No of use in total"
                                            className="input-coupons"
                                            value={EditCoupon?.max_count}
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "max_count");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="description pt-4"
                                        style={{ paddingLeft: "3px" }}  >
                                        User Type
                                    </div>
                                    <div onClick={this.changeUserType}
                                        className="mt-2 coupon-input w-100"   >
                                        <div className="coupon-input-type text-center py-2 fs-6 justify-content-between w-100" style={{ fontWeight: '700' }} >
                                            <div>
                                                {userTypes.filter((store) => EditCoupon?.user_type === store.value)[0]?.label}
                                            </div>
                                            <div>
                                                <IoIosArrowDropright size={25} />
                                            </div>
                                        </div>
                                    </div>
                                    {userTypes.filter((store) => EditCoupon?.user_type === store.value)[0]?.value === "CUSTOM" ?
                                        <div>
                                            <div className="description pt-4" style={{ paddingLeft: "3px" }}  >
                                                Max Number Of Use Per User
                                            </div>
                                            <InputGroup className="mt-2 coupon-input" style={{ boxSizing: "border-box" }}  >
                                                <FormControl type="number" min={0} placeholder="Max Number Of Use Per User"
                                                    className="input-coupons"
                                                    value={EditCoupon?.max_count_per_user}
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.handleInput(value, "max_count_per_user");
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                        : null}
                                    <div className="description pt-4" style={{ paddingLeft: "3px" }}>
                                        Items
                                    </div>
                                    <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2 coupon-input" onClick={(e) => this.closeCategoryMenu(e)}>
                                        <div className="flex-grow-1 d-flex flex-column py-1">
                                            <div className=" fw-bolder text-muted fs-6">
                                                {selectedItem.length > 0 ? "Selected Items" : "Select Items"}
                                            </div>
                                            <div className="w-75">
                                                {this.state.selectedAllItem ? (
                                                    <span>All Items Selected</span>
                                                ) :
                                                    <Truncate lines={1} className="">
                                                        {selectedItem && selectedItem.length > 0 ? selectedItem?.map((item, index) => (
                                                            <span className="text-muted">{index === selectedItem.length - 1 ? item.name + "" : item.name + ', '}</span>
                                                        )) : (
                                                            <span className="text-muted">Set offer for items</span>
                                                        )}
                                                    </Truncate>
                                                }
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <IoIosArrowDropright size={25} />
                                        </div>
                                    </div>

                                    <div className=" mt-3 py-2 pb-4 w-100" style={{ bottom: "0px", backgroundColor: "#fff", }}>
                                        <button type="submit" className="btn w-100 py-3"
                                            style={{
                                                color: "#FEFEFF", fontWeight: "700", fontSize: "14px",
                                                background: "#FEA24B", borderRadius: "15px",
                                            }}>  Update  </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <BottomSheet open={this.state.modalUserType}
                    onDismiss={() => this.changeUserType()}  >

                    <div className="px-3 pb-5">
                        <div className="mt-1 mb-2" style={{ fontWeight: "600", fontSize: "16px", }}  >
                            Select User Type
                        </div>

                        <div className="d-flex flex-column justify-content-center mt-4 align-content-around">
                            <div className="d-grid gap-2" style={{ paddingBottom: '20vw' }}>
                                {userTypes.map((value, index) => (
                                    <button className={EditCoupon?.user_type == value.value
                                        ? "offer-sheet-btn-active"
                                        : "offer-sheet-btn"}
                                        onClick={(e) => {
                                            this.changeUserType()
                                            this.handleInput(value.value, "user_type");
                                        }}  >
                                        {value.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </BottomSheet>

                <BottomSheet open={this.state.modalDiscountType}
                    onDismiss={() => this.changeDiscountType()}  >

                    <div className="px-3 pb-5">
                        <div className="mt-1 mb-2" style={{ fontWeight: "600", fontSize: "16px", }}  >
                            Make Your Coupons For
                        </div>
                        <div className="d-flex flex-column justify-content-center mt-4 align-content-around">
                            <div className="d-grid gap-2" style={{ paddingBottom: '20vw' }}>
                                <button className={EditCoupon?.discount_type == "FIXED"
                                    ? "offer-sheet-btn-active "
                                    : "offer-sheet-btn"}
                                    onClick={(e) => {
                                        this.changeDiscountType()
                                        this.handleInput("FIXED", "discount_type");
                                    }}
                                >
                                    FIXED
                                </button>
                                <button className={EditCoupon?.discount_type == "PERCENTAGE"
                                    ? "offer-sheet-btn-active"
                                    : "offer-sheet-btn"}
                                    onClick={(e) => {
                                        this.changeDiscountType()
                                        this.handleInput("PERCENTAGE", "discount_type");
                                    }}  >
                                    PERCENTAGE
                                </button>
                            </div>
                        </div>
                    </div>
                </BottomSheet>
                <BottomSheet open={this.state.modalItems}
                    onDismiss={() => this.closeCategoryMenu()}  >
                    <div className="px-3 pb-5">
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            <div className="text-end" style={{ fontWeight: 700, fontSize: '1.2em', color: '#fd8814 ' }}>Total Items: {items?.items?.length}</div>
                            <div className="d-grid gap-2 mt-1" style={{ paddingBottom: '20vw' }}>
                                {items?.items?.map((item, index) => (
                                    <span className="form-check" onClick={(e) => { this.selectedItem(item, index); }} >
                                        {this.state.selectedItem?.find((cp) => (cp.id === item.id)) !== undefined ? (
                                            <AiFillCheckSquare className="me-2" style={{ color: "orange", fontSize: "1.6rem", }} />
                                        ) : (
                                            <IoSquareOutline className="me-2" style={{ fontSize: "1.6rem", color: "#c3c3c3", }} />
                                        )}
                                        <span style={{ color: "black" }} >
                                            {item.name}
                                        </span>
                                    </span>
                                ))}
                            </div>
                            <div className="fixed-bottom d-flex flex-row justify-content-between align-items-center px-3 py-2 pb-4 w-100">
                                {/* <div style={{ whiteSpace: "nowrap" }}>
                                    <span style={{ fontWeight: 700, fontSize: '1.1em', color: '#0d6d0d' }}>Selected Items:  {this.state.selectedItem?.length}</span>
                                </div> */}
                                <button className="coupon-btn p-3 w-100 ms-4" type="submit" onClick={this.selectedAllItems}  >
                                    Select All Items
                                </button>
                                <button className="coupon-btn p-3 w-100 ms-4" type="submit" onClick={this.closeCategoryMenu}  >
                                    Select
                                </button>
                            </div>
                        </div>
                    </div>

                </BottomSheet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    items: state.items.items,
    coupon: state.coupons.singleCoupon,
    store: state.vendor.store
});

export default connect(mapStateToProps, { getSingleVendorCoupon, updateSingleCoupon })(
    VendorEditCoupon
);
